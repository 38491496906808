import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import { dateFilterOperators, filterOperators, numberFilterOperators } from '../../../../constants';
import { baseCell } from '../cells/base-cell';
import { Chip, Tooltip, Typography, Zoom } from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import React from 'react';
import DataGridCell from 'components/common/DataGridCell';

export const InventoryCheckColumns = (): GridColumns => {
  const translationPrefix = 'entities.stock_quantity';
  const { t } = useTranslation();
  return [
    {
      field: 'product',
      headerName: t(`global.product`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'description',
      headerName: t(`global.description`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: '.quantity',
      valueGetter: (params) => params.row.quantity,
      headerName: t(`global.quantity`),
      width: 200,
      filterOperators: numberFilterOperators,
      renderCell: baseCell,
      sortable: false,
      filterable: false
    },
    {
      field: 'expected_quantity',
      headerName: t(`global.expected_quantity`),
      width: 200,
      filterOperators: numberFilterOperators,
      renderCell: (params: GridRenderCellParams) => (
        <DataGridCell content={(params.value as string) ?? 'N/A'} />
      ),
      sortable: false,
      filterable: false
    },
    {
      field: 'difference',
      headerName: t(`global.difference`),
      width: 200,
      filterOperators: numberFilterOperators,
      renderCell: baseCell,
      sortable: false,
      filterable: false
    },
    {
      field: 'product_batch',
      headerName: t(`${translationPrefix}.product_batch`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'expiration_date',
      headerName: t(`${translationPrefix}.expiration_date`),
      type: 'date',
      filterOperators: dateFilterOperators,
      width: 200,
      renderCell: baseCell,
      sortable: false,
      filterable: false
    },
    {
      field: 'verified',
      headerName: t(`global.status`),
      width: 100,
      type: 'boolean',
      renderCell: (params: GridRenderCellParams<string>) => (
        <Tooltip
          TransitionComponent={Zoom}
          arrow
          title={
            !params.value &&
            params.row.error && (
              <React.Fragment>
                <Typography fontSize={14} color="inherit">
                  {params.row.error}
                </Typography>
              </React.Fragment>
            )
          }>
          <Chip
            sx={{ background: 'transparent' }}
            icon={
              !params.row.open &&
              (params.value ? <Check color="success" /> : <Close color="error" />)
            }
          />
        </Tooltip>
      )
    }
  ];
};
