import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { useState } from 'react';
import { IsSuperAdmin } from './IsSuperAdmin';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { useTranslation } from 'react-i18next';

interface PurgeButtonProps {
  service: any;
  refresh?: any;
}

export function PurgeLinksButton({ service, refresh }: PurgeButtonProps) {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);

  const toggleOpenDialog = () => {
    setOpenDialog(!openDialog);
  };

  const confirmPurgeLinks = () => {
    service?.purgeAllLinks().then(() => {
      if (refresh) refresh?.current();
    });
  };

  return (
    <>
      <ButtonGroup className="px-2">
        <IsSuperAdmin>
          <Button variant="outlined" size="small" onClick={toggleOpenDialog} color="error">
            <LinkOffIcon />
          </Button>
        </IsSuperAdmin>
      </ButtonGroup>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="md">
        <DialogTitle id="alert-dialog-title">{t(`global.purge-links-confirmation`)}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>{t(`global.purge-links-confirmation-extended`)}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="w-full flex justify-between">
            <Button onClick={toggleOpenDialog}>{t('actions.cancel')}</Button>
            <div>
              <Button variant="contained" color="error" onClick={confirmPurgeLinks} autoFocus>
                {t('actions.delete')}
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
