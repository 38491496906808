import { useEffect, useState } from 'react';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { IOption } from 'interfaces';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { contractService } from 'services/api';
import { DatePickerAdapter } from 'components/form/DatePickerAdapter';
import { required } from 'helpers/validators';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';

interface EquipmentInvoiceFormParams {
  onSubmit: any;
}
function EquipmentInvoiceForm(params: EquipmentInvoiceFormParams) {
  const { t } = useTranslation();
  const { onSubmit } = params;

  const [contracts, setContracts] = useState<IOption[]>([]);

  useEffect(() => {
    contractService.getAllBaseInformation().then((res) => {
      setContracts(
        res.map((contract) => {
          return {
            ...contract,
            code: `${contract.supplier} - ${contract.code}`
          };
        })
      );
    });
  }, []);

  return (
    <div>
      <ReactFinalForm onSubmit={onSubmit} formId="newInvoice" hideControls>
        <div className="flex flex-wrap">
          <div className="w-full p-2">
            <IdentityField
              name="contract"
              component={AutocompleteAdapter}
              options={contracts}
              placeholder={t('global.contract')}
              label={t('global.contract')}
              validate={required}
              required
            />
          </div>
          <div className="p-2 w-full md:w-1/2">
            <IdentityField
              name="ref"
              label={t(`entities.ddt.invoice_ref`)}
              component={TextFieldAdapter}
              required
            />
          </div>
          <div className="p-2 w-full md:w-1/2">
            <IdentityField name="date" label={t(`global.date`)} component={DatePickerAdapter} />
          </div>
        </div>
      </ReactFinalForm>
    </div>
  );
}

export default EquipmentInvoiceForm;
