import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { expertiseService } from 'services/api/ExpertiseService';
import { useState } from 'react';
import { IExpertise } from 'interfaces';
import ExpertiseForm from '../ExpertiseForm';
import toast from 'features/toast';

function AddExpertise() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const translationPrefix = 'pages.expertises.new';

  const [expertise, setExpertise] = useState<Partial<IExpertise>>({
    fte: 1,
    monthly_hours: 164
  });

  const saveExpertise = (value) => {
    expertiseService.add(value).then((res) => {
      if (res) {
        toast.success(t('pages.expertises.new.success'));
        navigate(`../${res.id}`);
      }
    });
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <div className="mt-6">
        <ExpertiseForm expertise={expertise as IExpertise} onSubmit={saveExpertise} />
      </div>
    </TenantPage>
  );
}

export default AddExpertise;
