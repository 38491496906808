import { movementReasonService, movementService, unapprovedMovementService } from 'services/api';
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { GridColDef } from '@mui/x-data-grid-premium';
import { IOption } from 'interfaces';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import { IsTechnicianOrAbove } from 'components/helpers/IsTechnicianOrAbove';
import { loadingActions } from 'features/loadingSlice';
import { NavLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/store';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CollapsableColumns from 'components/common/CollapsableColumns';
import TenantPage from 'components/common/TenantPage';
import { MovementsColumns } from 'components/common/enhanced/common-headers/movements';
import toast from 'features/toast';
import { printLabels } from 'services/zebra/zebraUtils';
import ManualMovement from '../ManualMovement';
import { LocalizationProvider } from '@mui/x-date-pickers';
import React from 'react';
import { DateRange, DateRangePicker } from '@mui/x-date-pickers-pro';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { PurgeButton } from 'components/helpers/PurgeButton';
import SelectMultiBatches from '../SelectMultiBatches';

export const Movements = () => {
  const translationPrefix = 'pages.movements';
  const datagridRefresh = useRef(null);
  const sap_active = useAppSelector((state) => state.auth.sap_active);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [movementReasons, setMovementReasons] = useState<IOption[]>([]);
  const [insert, setInsert] = useState(false);
  const [numMovementPending, setNumMovementPending] = useState(0);

  const today = dayjs();
  const a_weeek_ago = today.subtract(7, 'days');
  const [range, setRange] = useState<DateRange<Dayjs>>([
    dayjs(`${a_weeek_ago.year()}-${a_weeek_ago.month() + 1}-${a_weeek_ago.date()}`),
    dayjs(`${today.year()}-${today.month() + 1}-${today.date()}`)
  ]);

  const [params, setParams] = useState<Record<string, string>>({
    timestamp__gte: range[0]?.format('YYYY-MM-DD'),
    timestamp__lte: range[1]?.format('YYYY-MM-DD')
  });

  const onChange = (date: DateRange<Dayjs>, keyboardInputValue?: string) => {
    if (date[0] && date[1]) setRange([date[0], date[1]]);
  };

  const onAccept = (date: DateRange<Dayjs>) => {
    setParams({
      timestamp__gte: date[0]?.format('YYYY-MM-DD'),
      timestamp__lte: range[1]?.format('YYYY-MM-DD')
    });
  };

  const columns: GridColDef[] = MovementsColumns(
    movementReasons.map((reason) => reason.code),
    false,
    false,
    false,
    undefined,
    false,
    sap_active
  );

  useEffect(() => {
    unapprovedMovementService.getAllPaginated().then((res) => {
      setNumMovementPending(res.count);
    });
    movementReasonService.getAllPaginated().then((res) => {
      setMovementReasons(res.results);
    });
  }, []);

  // State to hold the current movement object, initially set to null
  const [currentMovement, setCurrentMovement] = useState<any>(null);

  // State to hold batch expiration options, initialized as an empty array
  const [batchesExpirationOption, setBatchesExpirationOption] = useState<any[]>([]);

  // State to control the visibility of the product batch dialog, initially false (closed)
  const [openProductBatchDialog, setOpenProductBatchDialog] = useState<boolean>(false);

  // State to hold the current batch, initially an empty string
  const [batch, setBatch] = useState<string>('');

  // Function to close the product batch dialog
  const handleCloseEditDialog = () => {
    setOpenProductBatchDialog(false);
  };

  // First submission handler function
  const onSubmitCheckBatches = async (values) => {
    const batches = values.batches;
    const movement = values.movement;
    if (batches.length > 1) {
      if (
        batches.filter(
          (ba) =>
            ba.expiration_date <
            batches.find((batch) => batch.id == movement.product_batch).expiration_date
        ).length > 0
      ) {
        setCurrentMovement(movement);
        // Update the batch state with the provided product_batch
        setBatch(movement.product_batch);
        // Set batch expiration options from the response
        setBatchesExpirationOption(batches);
        // Open the product batch dialog
        setOpenProductBatchDialog(true);
      } else {
        onSubmit(movement);
      }
    } else {
      onSubmit(movement);
    }
  };

  // Second submission handler function
  const onSubmit = async (values) => {
    // Start the loading indicator
    dispatch(loadingActions.startLoading());
    // Call the movementService to move the product with the updated movement object
    movementService
      .moveProduct(values)
      .then((res) => {
        // If the response contains warnings
        if ('warnings' in res) {
          res.warnings.map((warning) => {
            if (warning?.batches) {
              toast.warning(
                t('alerts.earlier-expiration', { batches: warning.batches.join(', ') })
              );
              return;
            }
            toast.warning(warning.type);
          });
        } else {
          // If print_labels is requested, print labels with the provided values
          if (values['print_labels']) {
            printLabels(
              values.product,
              values.product_batch,
              values.expiration_date,
              values.moved_quantity,
              values.description
            );
          }
        }
        if (datagridRefresh?.current === 'function') datagridRefresh.current();
        toast.success('Movimentazione avvenuta con successo');
      })
      .catch((e: Error) => {
        toast.error('Movimentazione fallita'); // Show error toast if the move fails
      });

    if (datagridRefresh && typeof datagridRefresh.current === 'function') datagridRefresh.current();
    // Clear the batch state
    setBatch('');
    // Close the product batch dialog
    setOpenProductBatchDialog(false);
    // Stop the loading indicator
    dispatch(loadingActions.stopLoading());
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      menuRight={
        <div className="flex items-center">
          <div className="mr-5">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker
                inputFormat="DD/MM/YYYY"
                calendars={2}
                value={range}
                onChange={onChange}
                onAccept={onAccept}
                closeOnSelect={false}
                componentsProps={{
                  actionBar: {
                    actions: ['cancel', 'accept']
                  }
                }}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <TextField {...startProps} variant="standard" label={t('global.start')} />

                    <Box sx={{ mx: 2 }}>
                      <ArrowForwardIosIcon color={range[1] ? 'primary' : 'disabled'} />
                    </Box>

                    <TextField {...endProps} variant="standard" label={t('global.end')} />
                  </React.Fragment>
                )}
              />
            </LocalizationProvider>
          </div>
          <ButtonGroup size="small" className="mr-2">
            <IsTenantAdministrator>
              <Button size="small" color={'primary'}>
                <NavLink to="uninvoiced">
                  <>{t(`${translationPrefix}.go-to-uninvoiced`)}</>
                </NavLink>
              </Button>
              <Button size="small" color={numMovementPending > 0 ? 'error' : 'success'}>
                <NavLink to="unapproved">
                  <>
                    {t(`${translationPrefix}.to_be_approved`)} ({numMovementPending})
                  </>
                </NavLink>
              </Button>
              <Button size="small" variant="outlined">
                <NavLink to="reasons">{t(`${translationPrefix}.reasons`)}</NavLink>
              </Button>
            </IsTenantAdministrator>
          </ButtonGroup>
          <IsTechnicianOrAbove>
            <ButtonGroup size="small">
              <Button onClick={() => setInsert(!insert)}>{t(`${translationPrefix}.new`)}</Button>
            </ButtonGroup>
          </IsTechnicianOrAbove>
          <PurgeButton service={movementService} />
        </div>
      }>
      <CollapsableColumns
        size="w-1/3"
        expand={insert}
        contentLeft={
          <EnhancedDataGrid
            columns={columns}
            service={movementService}
            refresh={datagridRefresh}
            outerParams={params}
            getMethod={movementService.getAggregated}
            initialOptions={{
              pinnedColumns: {
                left: ['direction', 'moved_quantity'],
                right: ['actions']
              }
            }}
          />
        }
        contentRight={<ManualMovement onSubmit={onSubmitCheckBatches} />}
      />
      <div>
        <Dialog
          open={openProductBatchDialog}
          onClose={handleCloseEditDialog}
          fullWidth
          maxWidth="md">
          <DialogTitle id="alert-dialog-title">
            {t(`pages.movements.multi-batches.title`)}
          </DialogTitle>
          <DialogContent>
            <SelectMultiBatches
              movement={currentMovement}
              setBatch={setBatch}
              batches={batchesExpirationOption}
              onSubmit={onSubmit}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditDialog} color="error">
              {t('actions.cancel')}
            </Button>
            <Button type="submit" form="updateBatches">
              {t(`actions.confirm`)}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </TenantPage>
  );
};

export default Movements;
