import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

function WidgetSquarePlaceholder() {
  return (
    <div>
      <div className="flex flex-col justify-center items-center">
        <Skeleton className="mb-2" variant="circular" width={40} height={40} />
        <Skeleton className="mb-2" variant="rectangular" width={210} height={40} />
        <Skeleton className="mb-2" variant="rectangular" width={60} height={20} />
      </div>
    </div>
  );
}

export default WidgetSquarePlaceholder;
