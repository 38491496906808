import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { purchaseRequestService } from 'services/api';
import ViewListIcon from '@mui/icons-material/ViewList';
import WidgetSquarePlaceholder from './WidgetSquarePlaceholder';

function PurchaseRequestsToApprove() {
  const { t } = useTranslation();
  const [counter, setCounter] = useState(undefined);

  useEffect(() => {
    purchaseRequestService
      .getAllPaginated(1, 0, { status: 'NEW' })
      .then((res) => setCounter(res.count));
  }, []);

  return counter !== undefined ? (
    <div className="w-full flex flex-col justify-center items-center px-1">
      {counter > 0 && (
        <>
          <ViewListIcon fontSize="large" color="warning" />
          <span className="text-center">
            {t(`dashboard.purchase-tequests-to-approve.body-error`)}
          </span>
          <NavLink to={'/tenant/purchase_requests?blameMode=true'}>
            <Button color="warning">{t(`dashboard.purchase-tequests-to-approve.go`)}</Button>
          </NavLink>
        </>
      )}
      {counter === 0 && (
        <>
          <ViewListIcon fontSize="large" color="success" />
          <span className="text-center">
            {t(`dashboard.purchase-tequests-to-approve.body-success`)}
          </span>
          <NavLink to={'/tenant/purchase_requests?blameMode=true'}>
            <Button color="success">{t(`dashboard.purchase-tequests-to-approve.go`)}</Button>
          </NavLink>
        </>
      )}
    </div>
  ) : (
    <>
      {counter}
      <WidgetSquarePlaceholder />
    </>
  );
}

export default PurchaseRequestsToApprove;
