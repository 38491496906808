import { useEffect, useState } from 'react';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { IContractLine, IProductWarehouseContract } from 'interfaces';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { required } from 'helpers/validators';
import { contractLineService } from 'services/api';

interface UnapprovedFormParams {
  pwc: IProductWarehouseContract;
  identificators: number[];
  onSubmit: any;
}

function EditUnapprovedMovement(params: UnapprovedFormParams) {
  const { t } = useTranslation();
  const { pwc, onSubmit } = params;
  const translationPrefix = 'pages.movements.unapproved';

  const [contractLines, setContractLines] = useState<IContractLine[]>([]);

  useEffect(() => {
    const params = {
      product: pwc.product,
      product__exact: true,
      warehouses__contains: pwc.warehouse
    };
    contractLineService.getAllBaseInformation(params).then((res) => {
      setContractLines(
        res.map((contractLine) => {
          return {
            id: contractLine.id,
            code: `${contractLine['code']}`
          };
        })
      );
    });
  }, []);

  return (
    <div>
      <div className="align-top p-3">
        {t(`${translationPrefix}.edit-dialog`, {
          product: pwc.product,
          warehouse: pwc.warehouse,
          date: pwc.date
        })}
      </div>
      <div className="align-middle p-2">
        <ReactFinalForm onSubmit={onSubmit} formId="updateMovement" hideControls>
          <div className="p-2 ">
            <IdentityField
              name="contract"
              label={t('global.contract')}
              component={AutocompleteAdapter}
              options={contractLines}
              type="text"
              optionValue="id"
              defaultValue={pwc.contract}
              validate={required}
            />
          </div>
        </ReactFinalForm>{' '}
      </div>
    </div>
  );
}

export default EditUnapprovedMovement;
