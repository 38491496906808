import { currencyFormatter } from 'helpers/currency-formatter';
import { IContract } from 'interfaces';

interface ContractInsightsProps {
  contract: IContract;
}

function ContractInsights(props: ContractInsightsProps) {
  const { contract } = props;
  return (
    <div>
      <p className="mx-2 py-0 my-0">
        Valore Annuale Contratto:
        <b>{currencyFormatter(contract.annual_value, { significantDigits: 3 })}</b> - Reale Valore
        Annuale Contratto:
        <b>{currencyFormatter(contract.real_annual_value, { significantDigits: 3 })}</b>
      </p>
      <p className="mx-2 py-0 my-0">
        Valore Contratto: <b>{currencyFormatter(contract.total_value, { significantDigits: 3 })}</b>
        - Reale Valore Contratto:
        <b>{currencyFormatter(contract.real_total_value, { significantDigits: 3 })}</b>
      </p>
    </div>
  );
}

export default ContractInsights;
