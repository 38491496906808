import { loadingActions } from 'features';
import { regionalRateService } from 'services/api';
import { useAppDispatch } from 'app/store';
import TenantPage from 'components/common/TenantPage';
import { useTranslation } from 'react-i18next';
import { RegionalRateForm } from '../RegionalRateForm';
import toast from 'features/toast';
import { IRegionalRate } from 'interfaces';

const initRegionalRate: IRegionalRate = {
  id: null,
  code: null,
  description: null,
  rate: null
};

export const CreateRegionalRate = () => {
  const translationPrefix = 'pages.regional-rate.new';

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const createRegionalRate = async (values) => {
    dispatch(loadingActions.startLoading());
    regionalRateService.add(values).then(() => {
      toast.success('Tariffa aggiunta con successo');
    });
    dispatch(loadingActions.stopLoading());
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <RegionalRateForm regionalRate={initRegionalRate} onSubmit={createRegionalRate} />
    </TenantPage>
  );
};

export default CreateRegionalRate;
