import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  context: 'LAB'
};

export const contextSlice = createSlice({
  name: 'context',
  initialState,
  reducers: {
    setContext: (state, { payload }) => {
      state.context = payload;
    },
    resetContext: (state) => {
      state.context = 'LAB';
    }
  }
});

export const contextActions = contextSlice.actions;
