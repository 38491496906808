import { lisTranscodesService, macroExamService } from 'services/api';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import LISTranscodesForm from '../LISTranscodesForm';
import { ILISTranscode } from 'interfaces';

const initLISTranscode: ILISTranscode = {
  id: null,
  laboratory: null,
  transcode: ''
};

export const CreateLISTranscode = () => {
  const translationPrefix = 'pages.lis-transcodes.new';
  const { t } = useTranslation();
  const navigate = useNavigate();

  const createTranscode = async (values) => {
    lisTranscodesService.add(values).then((res) => {
      if (res?.id) {
        navigate(`../${res.id}`);
      }
    });
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <LISTranscodesForm lisTranscode={initLISTranscode} onSubmit={createTranscode} />
    </TenantPage>
  );
};

export default CreateLISTranscode;
