import { TenantPage } from 'components/common';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { equipmentInvoiceService } from 'services/api';
import { uniqueId } from 'lodash';
import { EquipmentInvoicesColumns } from 'components/common/enhanced/common-headers/equipment-invoices';
import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import EquipmentInvoiceForm from './EquipmentInvoiceForm';
import { NavLink } from 'react-router-dom';
import { IsSuperAdmin } from 'components/helpers/IsSuperAdmin';
import { PurgeButton } from 'components/helpers/PurgeButton';

export function EquipmentInvoices() {
  const { t } = useTranslation();
  const translationPrefix = 'pages.equipment-invoices.list';
  const datagridRefresh = useRef(null);

  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onSubmit = (values) => {
    equipmentInvoiceService.add(values).then(() => {
      setOpenDialog(false);
      datagridRefresh.current();
    });
  };

  const columns = EquipmentInvoicesColumns(datagridRefresh.current);
  const filters = [];
  const initialParams = {};
  filters.push({
    id: uniqueId(),
    columnField: 'closed',
    operatorValue: 'is',
    value: 'false'
  });
  initialParams['closed'] = 'false';

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      menuRight={
        <div className="flex flex-wrap">
          <ButtonGroup size="small">
            <Button className="pr-4" onClick={() => setOpenDialog(true)}>
              <AddIcon />
              {t(`${translationPrefix}.new`)}
            </Button>
          </ButtonGroup>
          <ButtonGroup size="small">
            <NavLink to="all">
              <Button>{t(`${translationPrefix}.all`)}</Button>
            </NavLink>
            <IsSuperAdmin>
              <PurgeButton service={equipmentInvoiceService} refresh={datagridRefresh} />
            </IsSuperAdmin>
          </ButtonGroup>
        </div>
      }>
      <EnhancedDataGrid
        columns={columns}
        initialFilterItems={filters}
        initialParams={initialParams}
        service={equipmentInvoiceService}
        refresh={datagridRefresh}
      />
      <Dialog open={openDialog} maxWidth="lg" fullWidth>
        <DialogTitle>{t(`${translationPrefix}.add-dialog`)}</DialogTitle>
        <DialogContent>
          <EquipmentInvoiceForm onSubmit={onSubmit} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="error">
            {t('actions.cancel')}
          </Button>
          <Button type="submit" form="newInvoice">
            {t(`actions.confirm`)}
          </Button>
        </DialogActions>
      </Dialog>
    </TenantPage>
  );
}

export default EquipmentInvoices;
