import { ITenantSettings } from 'interfaces';
import { SignJWT } from 'jose';
import { useEffect, useState } from 'react';
import { tenantSettingsService } from 'services/api';

interface CostAnalysisViewerProps {
  dashboardId: number;
}

const CostAnalysisViewer = (props: CostAnalysisViewerProps) => {
  const { dashboardId } = props;

  const [iframeUrl, setIframeUrl] = useState('');
  const [settings, setSettings] = useState<ITenantSettings>();

  let metabaseUrl = null;
  let secretKey = null;

  useEffect(() => {
    tenantSettingsService.get(1).then(setSettings);
  }, []);

  useEffect(() => {
    if (settings && settings.metabase_token && dashboardId) {
      metabaseUrl = process.env.REACT_APP_METABASE_SITE_URL;
      secretKey = new TextEncoder().encode(settings.metabase_token);
      const payload = {
        resource: { dashboard: +dashboardId },
        params: {}
      };

      new SignJWT(payload)
        .setProtectedHeader({ alg: 'HS256' })
        .setExpirationTime('1h')
        .sign(secretKey)
        .then((token) => {
          setIframeUrl(
            metabaseUrl +
              '/embed/dashboard/' +
              token +
              '#theme=transparent&bordered=false&titled=false'
          );
        });
    }
  }, [settings, dashboardId]);

  return (
    <div className="h-screen">
      <iframe
        src={iframeUrl}
        width="100%"
        height="100%"
        style={{ background: 'transparent', border: '0' }}></iframe>
    </div>
  );
};

export default CostAnalysisViewer;
