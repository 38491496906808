import { Button, ButtonGroup } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import TenantPage from 'components/common/TenantPage';
import { expertiseService } from 'services/api/ExpertiseService';
import { ExpertisesColumns } from 'components/common/enhanced/common-headers/expertises';

function ExpertiseList() {
  const translationPrefix = 'pages.expertises.list';
  const { t } = useTranslation();

  const columns: GridColDef[] = ExpertisesColumns();

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      menuRight={
        <div className="flex justify-end items-center">
          <ButtonGroup variant="outlined" size="small">
            <IsTenantAdministrator>
              <Link to="new">
                <Button color="success">
                  <AddIcon />
                  {t(`${translationPrefix}.add_expertise`)}
                </Button>
              </Link>
            </IsTenantAdministrator>
          </ButtonGroup>
        </div>
      }>
      <div className="mt-6">
        <EnhancedDataGrid columns={columns} service={expertiseService} />
      </div>
    </TenantPage>
  );
}

export default ExpertiseList;
