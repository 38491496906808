import { useEffect, useState } from 'react';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { IStatusOption } from 'interfaces';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { ticketingService } from 'services/api';
import { DateTimePickerAdapter } from 'components/form/DateTimePickerAdapter';
import dayjs from 'dayjs';

interface CloseTicketFormParams {
  onSubmit: any;
}
function CloseTicketForm(params: CloseTicketFormParams) {
  const { t } = useTranslation();

  const [statusOptions, setStatusOptions] = useState<IStatusOption[]>([]);

  useEffect(() => {
    ticketingService.options().then((res) => {
      setStatusOptions(
        res.actions.POST.status.choices
          .filter((item) => item.value === 3 || item.value === 4 || item.value === 5)
          .map((item) => {
            return {
              ...item,
              display_name: t(`pages.tickets.status_options.${item.display_name}`)
            };
          })
      );
    });
  }, []);

  const { onSubmit } = params;
  return (
    <div>
      <ReactFinalForm onSubmit={onSubmit} formId="closeTicket" hideControls>
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 p-2">
            <IdentityField
              name="resolved_at"
              component={DateTimePickerAdapter}
              label={t(`global.resolved_at`)}
              initialValue={dayjs().format('YYYY-MM-DD HH:mm')}
              type="text"
              required
            />
          </div>
          <div className="p-2 w-full md:w-1/2">
            <IdentityField
              name="status"
              label={t(`global.status`)}
              component={AutocompleteAdapter}
              options={statusOptions}
              type="text"
              optionLabel="display_name"
              optionValue="value"
              required
            />
          </div>
        </div>
      </ReactFinalForm>
    </div>
  );
}

export default CloseTicketForm;
