import { IDocument } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from '../BaseService';
import toast from 'features/toast';

class FetchDocumentService extends BaseService<IDocument> {
  constructor() {
    super('documents');
  }

  protected appendObjectsToFormData(formData: FormData, objects_id: any[], fieldName: string) {
    objects_id.forEach((item) => {
      formData.append(fieldName + 's', item.toString());
    });
  }

  uploadDocument = async (
    category: any,
    attachment: File,
    name: string,
    description: string,

    fieldName = '',
    objects_id?: any[]
  ) => {
    const formData = new FormData();
    formData.append('category', typeof category === 'string' ? category : category.name);
    formData.append('attachment', attachment);
    formData.append('name', name);
    formData.append('description', description);

    if (objects_id && objects_id.length > 0 && fieldName) {
      this.appendObjectsToFormData(formData, objects_id, fieldName);
    }

    const response = await axiosPrivate.post<IDocument>(
      `${this.modulePrefix}/${fieldName}/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    return response.data;
  };

  getAllPaginatedDocuments = async (documentType = '', limit = 0, offset = 0, params = {}) => {
    try {
      // Aggiunge una barra finale se non è presente
      const url = `${this.modulePrefix}/${documentType}/`.replace(/\/?$/, '/');
      const res = await axiosPrivate.get(url, {
        params: { limit, offset, ...params }
      });
      return res.data;
    } catch (e) {
      toast.error(e.message);
    }
  };

  downloadAttachment = async (entityId: number) => {
    axiosPrivate
      .get(`${this.modulePrefix}/${entityId}/download-attachment/`, {
        responseType: 'blob'
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const contentDisposition = res.headers['content-disposition'];
        if (contentDisposition?.indexOf('attachment') !== -1) {
          const filenameMatches = contentDisposition.match(
            /(?:.*filename\*|filename)=(?:([^'"]*)''|("))([^;]+)\2(?:[;`\n]|$)/
          );
          const filename = filenameMatches.pop();
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        }
      });
  };
}

export const fmDocumentService = new FetchDocumentService();
