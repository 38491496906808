import { IAdditionalEquipmentCost } from 'interfaces';
import { BaseService } from './BaseService';

class AdditionalEquipmentCostService extends BaseService<IAdditionalEquipmentCost> {
  constructor() {
    super('additional-equipment-costs');
  }
  canDelete = true;

  // attachFiles = async (attachment: File, attachmentName: string) => {
  //   const res = await axiosPrivate.postForm(`${this.modulePrefix}/attach-files/`, {
  //     [attachmentName]: attachment
  //   });
  //   return res.data;
  // };
  //
  // downloadAttachment = async (_: number, attachmentName: string) => {
  //   axiosPrivate
  //     .get(`${this.modulePrefix}/download-attachment/${attachmentName}/`, {
  //       responseType: 'blob'
  //     })
  //     .then((res) => {
  //       const url = window.URL.createObjectURL(new Blob([res.data]));
  //       const contentDisposition = res.headers['content-disposition'];
  //       if (contentDisposition?.indexOf('attachment') !== -1) {
  //         const filenameMatches = contentDisposition.match(
  //           /(?:.*filename\*|filename)=(?:([^'"]*)''|("))([^;]+)\2(?:[;`\n]|$)/
  //         );
  //         const filename = filenameMatches.pop();
  //         const link = document.createElement('a');
  //         link.href = url;
  //         link.setAttribute('download', filename);
  //         document.body.appendChild(link);
  //         link.click();
  //       }
  //     });
  // };
}

export const additionalEquipmentCostService = new AdditionalEquipmentCostService();
