import { FormEvent, useEffect, useState } from 'react';
import { loadingActions } from 'features/loadingSlice';
import { authActions, login } from 'features';
import { ROLE_SUPER_ADMIN } from '../../../constants';
import { useAppDispatch } from 'app/store';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import toast from 'features/toast';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

export const Login = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const greet = (username: string) => {
    enqueueSnackbar(`Hi ${username} :)`);
  };

  useEffect(() => {
    const user = searchParams.get('user');
    const role = searchParams.get('role');
    if (user && role) {
      dispatch(authActions.superadminLogin({ user, groups: [role] }));
      navigate('/tenant');
      greet(user);
    }
  }, []);

  const performLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(loadingActions.startLoading());
    await dispatch(login({ username, password }))
      .unwrap()
      .then((res) => {
        if (res.user.groups.includes(ROLE_SUPER_ADMIN)) {
          navigate('/tenant');
        } else {
          navigate('/tenant');
        }
        greet(username);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        dispatch(loadingActions.stopLoading());
        setUsername('');
        setPassword('');
      });
  };

  return (
    <>
      <div
        className="min-h-screen absolute top-0 w-full"
        style={{ backgroundImage: 'url(background.jpg)' }}></div>
      <div className="min-h-screen bg-white bg-opacity-50 backdrop-blur-md flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-40 w-auto" src="logo512.png" alt="themeColor" />
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={(e) => performLogin(e)}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  {t('entities.user.email')}
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="text"
                    autoComplete="email"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  {t('entities.user.password')}
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
