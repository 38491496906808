import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';

import { IOption, IStatusOption } from 'interfaces';

import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';

interface FormParams {
  onSubmit: any;
  products: IOption[];
  statusOptions: IStatusOption[];
  labelOptions: IOption[];
}
function ProductWarehouseForm(params: FormParams) {
  const { labelOptions, statusOptions, products, onSubmit } = params;
  const { t } = useTranslation();

  return (
    <div>
      <ReactFinalForm onSubmit={onSubmit} formId="addProductThreshold" hideControls>
        <div className="grid gap-2 p-2">
          <div className="grid grid-cols-2 gap-2">
            <div className="">
              {products && (
                <IdentityField
                  name="product"
                  component={AutocompleteAdapter}
                  options={products}
                  label={t('global.product')}
                />
              )}
            </div>
            <div className="">
              {statusOptions && (
                <IdentityField
                  name="status"
                  label={t(`entities.product.status`)}
                  component={AutocompleteAdapter}
                  options={statusOptions}
                  placeholder="Status"
                  type="text"
                  optionLabel="display_name"
                  optionValue="value"
                />
              )}
            </div>
          </div>
          <div className=" grid grid-cols-4 gap-2">
            <div className="">
              <IdentityField
                name="label"
                component={AutocompleteAdapter}
                options={labelOptions}
                label={t('global.label')}
              />
            </div>
            <div className="">
              <IdentityField
                name="understock_threshold"
                component={TextFieldAdapter}
                label={t('entities.product.understock_threshold')}
                type="number"
              />
            </div>
            <div className="">
              <IdentityField
                name="overstock_threshold"
                component={TextFieldAdapter}
                label={t('entities.product.overstock_threshold')}
                type="number"
              />
            </div>
            <div className="">
              <IdentityField
                name="restock_threshold"
                component={TextFieldAdapter}
                label={t('entities.product.restock_threshold')}
                type="number"
              />
            </div>
          </div>
        </div>
        <div className="w-full gap-2 p-2">
          <IdentityField
            name="notes"
            component={TextFieldAdapter}
            label={t('global.notes')}
            type="text"
          />
        </div>
      </ReactFinalForm>
    </div>
  );
}

export default ProductWarehouseForm;
