import { INotification, IPage } from 'interfaces';
import { BaseService } from './BaseService';

class NotificationService extends BaseService<INotification> {
  constructor() {
    super('notifications');
  }

  async getUnread(): Promise<IPage<INotification>> {
    return await this.getAllPaginated(10, 0, { read: false });
  }
}

export const notificationService = new NotificationService();
