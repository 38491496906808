import {
  GridActionsCellItem,
  GridColDef,
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
  GridValueFormatterParams
} from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import { IMovement } from 'interfaces';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

import {
  filterOperators,
  dateFilterOperators,
  numberFilterOperators,
  booleanFilterOperators
} from '../../../../constants';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import { createSearchParams, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { baseCell } from '../cells/base-cell';
import { currencyCell } from '../cells/currency-cell';
import productStatusCell from '../cells/product-status-cell';
import { booleanCell } from '../cells/boolean-cell';
import { dateCell } from '../cells/date-cell';
import { currencyFormatter } from 'helpers/currency-formatter';
import dayjs from 'dayjs';

export function UninvoicedMovementsColumns(
  types: string[],
  editableExpiration = false
): GridColDef[] {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const translationPrefix = 'entities.movement';

  const movementsDetail = (params) => {
    const filteredParams = _.omitBy(params, _.isEmpty);
    filteredParams['discounted'] = params['discounted'];
    navigate({
      pathname: '/tenant/movements/detail/',
      search: `${createSearchParams(filteredParams)}`
    });
  };

  const columns: GridColumns | any[] = [
    {
      field: 'actions',
      type: 'actions',
      headerName: t(`global.actions`),
      getActions: (params: GridRowParams) => {
        const movementRow = params.row;
        return [
          <GridActionsCellItem
            key={2}
            label="Dettaglio"
            icon={<ManageSearchIcon />}
            onClick={() =>
              movementsDetail({
                product__id: `${movementRow.product__id}`,
                warehouse: movementRow.warehouse,
                type: movementRow.type,
                created_by: movementRow.created_by,
                product_batch: movementRow.product_batch,
                purchase_order: movementRow.purchase_order,
                timestamp: movementRow.timestamp,
                discounted: movementRow.discounted
              })
            }
          />
        ];
      },
      filterable: false
    },
    {
      field: 'moved_quantity',
      sortable: false,
      headerName: t(`${translationPrefix}.moved_quantity`),
      type: 'number',
      renderCell: (params: GridRenderCellParams<string>) => {
        const movement = params.row as IMovement;
        return (
          <>
            {movement.direction === 1 ? (
              <ArrowDownwardIcon color="success" />
            ) : (
              <ArrowUpward color="warning" />
            )}{' '}
            {movement.moved_quantity}
          </>
        );
      }
    },
    {
      field: 'product',
      headerName: t(`global.product`),
      filterOperators,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'status',
      headerName: t(`global.status`),
      width: 150,
      renderCell: productStatusCell(t),
      filterable: false,
      sortable: false
    },
    {
      field: 'description',
      headerName: t(`global.description`),
      filterOperators,
      width: 300,
      renderCell: baseCell
    },
    {
      field: 'timestamp',
      headerName: t(`${translationPrefix}.timestamp`),
      dateFilterOperators,
      type: 'date',
      width: 250,
      renderCell: dateCell
    },
    {
      field: 'discounted',
      headerName: t(`${translationPrefix}.discounted`),
      renderCell: booleanCell(t)
    },
    {
      field: 'unit_price',
      headerName: t(`${translationPrefix}.unit_price`),
      filterOperators: numberFilterOperators,
      type: 'number',
      width: 150,
      valueFormatter: (params: GridValueFormatterParams) => {
        return currencyFormatter(params.value, { significantDigits: 3 });
      },
      renderCell: (params: GridRenderCellParams) => currencyCell(params, 3)
    },
    {
      field: 'price',
      headerName: t(`${translationPrefix}.price`),
      filterOperators: numberFilterOperators,
      type: 'number',
      width: 150,
      valueFormatter: (params: GridValueFormatterParams) => {
        return currencyFormatter(params.value, { significantDigits: 3 });
      },
      renderCell: (params: GridRenderCellParams) => currencyCell(params, 3)
    },
    {
      field: 'category',
      headerName: t(`entities.product.category`),
      filterOperators,
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'supplier',
      headerName: t(`global.supplier`),
      filterOperators,
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'warehouse',
      headerName: t(`global.warehouse`),
      filterOperators,
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'product_batch',
      headerName: t(`${translationPrefix}.product_batch`),
      filterOperators,
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'expiration_date',
      headerName: t(`${translationPrefix}.expiration_date`),
      dateFilterOperators,
      valueFormatter: (params) => dayjs(params.value).format('DD/MM/YYYY'),
      editable: editableExpiration,
      type: 'date',
      width: 250,
      renderCell: dateCell
    },
    {
      field: 'notes',
      headerName: t(`global.notes`),
      width: 250,
      renderCell: baseCell,
      sortable: false,
      filterable: false
    },
    {
      field: 'product_notes',
      headerName: t(`global.product-notes`),
      width: 250,
      renderCell: baseCell,
      sortable: false,
      filterable: false
    },
    {
      field: 'type',
      headerName: t(`${translationPrefix}.type`),
      type: 'singleSelect',
      valueOptions: types,
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'order_code',
      headerName: t(`entities.movement.order_code`),
      filterOperators,
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'order_date',
      headerName: t(`${translationPrefix}.order_date`),
      width: 200,
      renderCell: dateCell
    },
    {
      field: 'transport_document',
      valueGetter: (params) => params.row.transport_document_ref,
      headerName: t(`${translationPrefix}.transport_document`),
      filterOperators,
      width: 200,
      renderCell: baseCell
    },
    {
      field: 'billable',
      headerName: t(`entities.ddt.billable`),
      filterOperators: booleanFilterOperators,
      width: 150,
      type: 'boolean',
      renderCell: booleanCell(t)
    },
    {
      field: 'credit_memo_ref',
      headerName: t(`entities.ddt.credit_memo_ref`),
      filterable: false,
      sortable: false,
      width: 200,
      renderCell: baseCell
    },
    {
      field: 'credit_memo_date',
      headerName: t(`entities.ddt.credit_memo_date`),
      filterable: false,
      sortable: false,
      width: 200,
      renderCell: dateCell
    },
    {
      field: 'credit_memo_amount',
      headerName: t(`entities.ddt.credit_memo_amount`),
      filterable: false,
      sortable: false,
      width: 200,
      renderCell: currencyCell
    },
    {
      field: 'transport_document_date',
      headerName: t(`${translationPrefix}.transport_document_date`),
      width: 200,
      type: 'date',
      renderCell: dateCell
    },
    {
      field: 'created_by',
      headerName: t(`${translationPrefix}.created_by`),
      filterOperators,
      width: 200,
      renderCell: baseCell
    }
  ];

  return columns;
}
