export const required = (value) => (value ? undefined : 'This field is required.');

export const validEmail = (value) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? undefined : 'Invalid email address.';

export const mustBeNumber = (value) => (isNaN(value) ? 'Must be a number.' : undefined);

export const mustBeCurrency = (value) =>
  value &&
  (/^[0-9]*(?:,[0-9]{1,3})?$/i.test(value)
    ? undefined
    : 'Up to three comma-separated decimal places.');

export const validMonthDay = (value) =>
  value < 1 || value > 28 ? 'Must be in [1, 28].' : undefined;

export const validWeekDay = (value) => (value < 1 || value > 7 ? 'Must be in [1, 7].' : undefined);

export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce((error, validator) => error || validator(value), undefined);
