import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { transportDocumentLineService } from 'services/api';
import { GridColDef } from '@mui/x-data-grid-premium';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { TransportDocumentLinesColumns } from 'components/common/enhanced/common-headers/transport-document-lines';

export function InvoicedMovements() {
  const translationPrefix = 'pages.ddt';
  const { t } = useTranslation();
  const columns: GridColDef[] = TransportDocumentLinesColumns(true);

  return (
    <TenantPage
      title={t('pages.ddt.list.invoiced-movements')}
      subtitle={t(`${translationPrefix}.list.subtitle`)}
      menuRight={<div></div>}>
      <EnhancedDataGrid
        columns={columns}
        service={transportDocumentLineService}
        getMethod={transportDocumentLineService.invoicedMovements}
        initialParams={{ closed: 'true' }}
      />
    </TenantPage>
  );
}

export default InvoicedMovements;
