import { IWorkstation } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class WorkstationService extends BaseService<IWorkstation> {
  constructor() {
    super('workstations');
  }
  canDelete = true;

  importFromFile = async (importFile: File, attachmentName = 'import_file') => {
    const res = await axiosPrivate.postForm(`${this.modulePrefix}/import-from-file/`, {
      [attachmentName]: importFile
    });
    return res.data;
  };
}

export const workstationService = new WorkstationService();
