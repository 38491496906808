import { ButtonGroup, Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import TenantPage from 'components/common/TenantPage';
import { costReportService } from 'services/api/CostReport';
import { costAnalysisService } from 'services/api';
import { Dayjs } from 'dayjs';
import * as React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import Box from '@mui/material/Box';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useState } from 'react';
import { CostReport } from 'components/common/enhanced/common-headers/cost-report';

export const CostReportList = () => {
  const translationPrefix = 'pages.cost_report.list';
  const { t } = useTranslation();

  const [range, setRange] = useState<DateRange<Dayjs>>([null, null]);

  const performReport = () => {
    costAnalysisService.runCostAnalysis(
      range[0].format('YYYY-MM-DD'),
      range[1].format('YYYY-MM-DD')
    );
  };

  const columns = CostReport();

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      menuRight={
        <div className="flex align-bottom">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              inputFormat="DD/MM/YYYY"
              calendars={2}
              value={range}
              onChange={(newRange) => {
                setRange(newRange);
              }}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} variant="standard" label={t('global.start')} />

                  <Box sx={{ mx: 2 }}>
                    <ArrowForwardIosIcon color={range[1] ? 'primary' : 'disabled'} />
                  </Box>

                  <TextField {...endProps} variant="standard" label={t('global.end')} />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
          <ButtonGroup size="small" className="ml-4">
            <Button onClick={performReport} variant="text">
              <PlayArrowIcon />
            </Button>
          </ButtonGroup>
        </div>
      }>
      <div className="mt-6">
        <EnhancedDataGrid columns={columns} service={costReportService} />
      </div>
    </TenantPage>
  );
};

export default CostReportList;
