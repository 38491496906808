import { Button } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';

export const UnderMaintenance = () => {
  const navigate = useNavigate();

  const routeChange = () => {
    navigate('login');
  };

  return (
    <>
      <div className="min-h-full pt-16 pb-12 flex flex-col bg-white">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 flex justify-center">
            <a href="/" className="inline-flex">
              <span className="sr-only">Workflow</span>
              <img
                className="h-12 w-auto"
                src="https://tailwindui.com/img/logos/workflow-mark.svg?color=indigo&shade=600"
                alt=""
              />
            </a>
          </div>
          <div className="py-16">
            <div className="text-center">
              <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                Server is under maintenance.
              </h1>
              <p className="mt-2 text-base text-gray-500">
                Check again later. Sorry for the inconvenience.
              </p>
              <div className="mt-6">
                <NavLink to="/">
                  <a className="text-base font-medium text-secondary-600 hover:text-secondary-500">
                    Log in back<span aria-hidden="true"> &rarr;</span>
                  </a>
                </NavLink>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};
