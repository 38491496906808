import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import DataGridCell from 'components/common/DataGridCell';
import { currencyFormatter } from 'helpers/currency-formatter';

export function currencyCell<T>(params: GridRenderCellParams<T>, decimalPlaces = 3) {
  return (
    <DataGridCell
      content={
        params.value !== null
          ? currencyFormatter(params.value, { significantDigits: decimalPlaces })
          : ''
      }
    />
  );
}
