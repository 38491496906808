import toast from 'features/toast';
import { t } from 'i18next';
import { ITransportDocument } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class EquipmentInvoiceService extends BaseService<ITransportDocument> {
  constructor() {
    super('equipment-invoices');
  }
  canDelete = true;
  canBulkDelete = true;

  emailInvoiceAgreement = async (transportDocumentId: number) => {
    this.email('email-frontispiece', transportDocumentId);
  };

  emailCreditMemo = async (transportDocumentId: number) => {
    this.email('email-credit-memo', transportDocumentId);
  };

  async email(endpoint: string, transportDocumentId: number) {
    try {
      const res = await axiosPrivate.get(`${this.modulePrefix}/${transportDocumentId}/${endpoint}`);
      if (res.status === 200) {
        toast.info(t('toasts.email-sent-to-supplier'));
      }
      return res.data;
    } catch (e) {
      toast.error(e.message);
    }
  }

  downloadInvoiceAgreement = async (transportDocumentId: number) => {
    this.download('download-invoice-frontispiece', transportDocumentId);
  };

  downloadCreditMemo = async (transportDocumentId: number) => {
    this.download('download-credit-memo', transportDocumentId);
  };

  download = async (endpoint: string, transportDocumentId: number) => {
    axiosPrivate
      .get(`${this.modulePrefix}/${transportDocumentId}/${endpoint}/`, {
        responseType: 'blob'
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const contentDisposition = res.headers['content-disposition'];
        if (contentDisposition?.indexOf('attachment') !== -1) {
          const filenameMatches = contentDisposition.match(
            /(?:.*filename\*|filename)=(?:([^'"]*)''|("))([^;]+)\2(?:[;`\n]|$)/
          );
          const filename = filenameMatches.pop();
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        }
      });
  };

  generateCreditMemo = async (transportDocumentId: number, credit_memo_date?: string) => {
    try {
      const res = await axiosPrivate.post(
        `${this.modulePrefix}/${transportDocumentId}/generate-credit-memo/`,
        { credit_memo_date }
      );
      if (res.status === 200) {
        toast.info(t('toasts.credit-memo-generated'));
      }
      return res.data;
    } catch (e) {
      toast.error(e.message);
    }
  };
}

export const equipmentInvoiceService = new EquipmentInvoiceService();
