import { examService } from 'services/api';
import { IdentityField } from 'components/form/IdentityField';
import { IMacroExam, IOption } from 'interfaces';
import { MultipleAutocompleteAdapter } from 'components/form/MultipleAutocompleteAdapter';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { useEffect, useState } from 'react';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { useTranslation } from 'react-i18next';
import { required } from 'helpers/validators';

interface MacroExamFormParams {
  macroExam: IMacroExam;
  onSubmit: any;
}

function MacroExamForm(params: MacroExamFormParams) {
  const { t } = useTranslation();

  const { onSubmit, macroExam } = params;

  const [exams, setExams] = useState<IOption[]>([]);

  useEffect(() => {
    examService.getAllBaseInformation().then(setExams);
  }, []);

  return (
    <div>
      {macroExam && (
        <ReactFinalForm onSubmit={onSubmit}>
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/3 pr-1">
              <IdentityField
                name="code"
                initialValue={macroExam.code}
                component={TextFieldAdapter}
                label={t('global.code')}
                type="text"
                validate={required}
                required
              />
            </div>
            <div className="w-full md:w-2/3 pl-1">
              <IdentityField
                name="description"
                initialValue={macroExam.description}
                component={TextFieldAdapter}
                label={t('global.description')}
                type="text"
              />
            </div>
            <div className="w-full my-4">
              <IdentityField
                name="exams"
                component={MultipleAutocompleteAdapter}
                options={exams}
                initValue={macroExam.exams}
                label={t('global.exams')}
                type="text"
              />
            </div>
          </div>
        </ReactFinalForm>
      )}
    </div>
  );
}

export default MacroExamForm;
