/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useRef } from 'react';
import { Button } from '@mui/material';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title as ChartTitle,
  Tooltip,
  Legend
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-dayjs-3';
import { IStockData } from 'interfaces';
import { schemeCategory10 } from 'd3-scale-chromatic';

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  ChartTitle,
  Tooltip,
  Legend,
  zoomPlugin
);

interface IStockChartProps {
  movements: IStockData[];
}

export const StockChart = (props: IStockChartProps) => {
  const { movements } = props;

  const chartRef = useRef(null);

  const datasets = movements.map(({ product, data }, idx) => {
    {
      const color = schemeCategory10[idx % schemeCategory10.length];
      const semitransparentColor = color + 80; // 50% opacity
      return {
        label: product,
        data,
        borderColor: color,
        backgroundColor: semitransparentColor
      };
    }
  });

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const
      },
      // title: {
      //   display: true,
      //   text: 'Stock quantities'
      // },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
          modifierKey: 'ctrl'
        },
        zoom: {
          drag: {
            enabled: true
          },
          mode: 'x'
        }
      }
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day'
        }
      }
    },
    elements: {
      point: { pointRadius: 5 }
    }
  };

  const onResetZoom = () => {
    const chart = chartRef.current;
    chart.resetZoom();
  };

  return (
    <div>
      <Line options={options} data={{ datasets }} ref={chartRef} />
      <Button variant="outlined" onClick={onResetZoom}>
        Reset zoom
      </Button>
    </div>
  );
};

export default StockChart;
