import ReactFinalForm from 'components/form/ReactFinalForm';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { required } from 'helpers/validators';
import { italianDistricts } from '../../../constants';
import { ISupplier } from 'interfaces';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import SectionTitle from 'components/common/SectionTitle';
import { CheckboxAdapter } from 'components/form/CheckboxAdapter';

interface SupplierFormParams {
  supplier: ISupplier;
  onSubmit: any;
}
function SupplierForm(params: SupplierFormParams) {
  const { t } = useTranslation();

  const { supplier, onSubmit } = params;
  return (
    <div>
      {supplier && (
        <ReactFinalForm onSubmit={onSubmit} edit={!!supplier.id}>
          <div className="flex flex-wrap">
            <div className="w-full p-2">
              <SectionTitle>{t('pages.supplier.new.registry')}</SectionTitle>
            </div>
            <div className="w-full md:w-1/3 p-2">
              <IdentityField
                name="code"
                initialValue={supplier.code}
                component={TextFieldAdapter}
                label={t('global.code')}
                placeholder={t('global.code')}
                type="text"
                validate={required}
              />
            </div>
            <div className="w-full md:w-1/2 p-2">
              <IdentityField
                name="company_name"
                initialValue={supplier.company_name}
                component={TextFieldAdapter}
                label={t('entities.supplier.company_name')}
                placeholder={t('entities.supplier.company_name')}
                type="text"
                validate={required}
              />
            </div>
            <div className="p-2 w-1/6 md:pl-2">
              <IdentityField
                name="active"
                component={CheckboxAdapter}
                initialValue={supplier.active}
                label={t('global.active')}
                type="checkbox"
              />
            </div>
            <div className="w-full md:w-2/5 p-2">
              <IdentityField
                name="city"
                initialValue={supplier.city}
                component={TextFieldAdapter}
                label={t('entities.supplier.city')}
                placeholder={t('entities.supplier.city')}
                type="text"
              />
            </div>
            <div className="w-full md:w-2/5 p-2">
              <IdentityField
                name="district"
                component={AutocompleteAdapter}
                initValue={italianDistricts.find((s) => s.code === supplier.district)}
                options={italianDistricts}
                optionValue={'code'}
                placeholder={t('entities.supplier.district')}
                type="text"
              />
            </div>
            <div className="w-full md:w-1/5 p-2">
              <IdentityField
                name="zip_code"
                initialValue={supplier.zip_code}
                component={TextFieldAdapter}
                label={t('entities.supplier.zip_code')}
                placeholder={t('entities.supplier.zip_code')}
                type="text"
              />
            </div>
            <div className="w-full md:w-1/2 p-2">
              <IdentityField
                name="address"
                initialValue={supplier.address}
                component={TextFieldAdapter}
                label={t('entities.supplier.address')}
                placeholder={t('entities.supplier.address')}
                type="text"
              />
            </div>
            <div className="w-full md:w-1/2 p-2">
              <IdentityField
                name="address_2"
                initialValue={supplier.address_2}
                component={TextFieldAdapter}
                label={t('entities.supplier.address_2')}
                placeholder={t('entities.supplier.address_2')}
                type="text"
              />
            </div>
            <div className="w-full md:w-1/2 p-2">
              <IdentityField
                name="vat_number"
                initialValue={supplier.vat_number}
                component={TextFieldAdapter}
                label={t('entities.supplier.vat_number')}
                placeholder={t('entities.supplier.vat_number')}
                type="text"
                inputProps={{ pattern: '[0-9]{5,15}' }}
              />
            </div>
            <div className="w-full md:w-1/2 p-2">
              <IdentityField
                name="fiscal_code"
                initialValue={supplier.fiscal_code}
                component={TextFieldAdapter}
                label={t('entities.supplier.fiscal_code')}
                placeholder={t('entities.supplier.fiscal_code')}
                type="text"
              />
            </div>
            <div className="p-2 w-full">
              <IdentityField
                name="notes"
                label={t(`global.notes`)}
                placeholder={t(`global.notes`)}
                initialValue={supplier.notes}
                component={TextFieldAdapter}
              />
            </div>
            <div className="w-full p-2">
              <SectionTitle>{t('pages.supplier.new.contact')}</SectionTitle>
            </div>
            <div className="w-full md:w-1/3 p-2">
              <IdentityField
                name="email"
                initialValue={supplier.email}
                component={TextFieldAdapter}
                label={t('entities.supplier.email')}
                placeholder={t('entities.supplier.email')}
                type="email"
              />
            </div>
            <div className="w-full md:w-1/3 p-2">
              <IdentityField
                name="email_credit_note"
                initialValue={supplier.email_credit_note}
                component={TextFieldAdapter}
                label={t('entities.supplier.email_credit_note')}
                type="email"
              />
            </div>
            <div className="w-full md:w-1/2 p-2">
              <IdentityField
                name="phone_number"
                initialValue={supplier.phone_number}
                component={TextFieldAdapter}
                label={t('entities.supplier.phone_number')}
                placeholder={t('entities.supplier.phone_number')}
                type="text"
              />
            </div>
            <div className="w-full md:w-1/2 p-2">
              <IdentityField
                name="fax_number"
                initialValue={supplier.fax_number}
                component={TextFieldAdapter}
                label={t('entities.supplier.fax_number')}
                placeholder={t('entities.supplier.fax_number')}
                type="text"
              />
            </div>
            <div className="w-full p-2">
              <SectionTitle>{t('pages.supplier.new.payment')}</SectionTitle>
            </div>
            <div className="w-full md:w-4/12 p-2">
              <IdentityField
                name="iban"
                initialValue={supplier.iban}
                component={TextFieldAdapter}
                label={t('entities.supplier.iban')}
                placeholder={t('entities.supplier.iban')}
                type="text"
              />
            </div>
            <div className="w-full md:w-8/12 p-2">
              <IdentityField
                name="payment_terms"
                initialValue={supplier.payment_terms}
                component={TextFieldAdapter}
                label={t('entities.supplier.payment_terms')}
                placeholder={t('entities.supplier.payment_terms')}
                type="text"
              />
            </div>
          </div>
        </ReactFinalForm>
      )}
    </div>
  );
}

export default SupplierForm;
