import { tenantSettingsService } from 'services/api';
import { ITenantSettings } from 'interfaces';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { useEffect, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import CostAnalysisViewer from 'components/Reporting/CostAnalysisViewer';
import { useAppSelector } from 'app/store';
import { ROLE_SUPER_ADMIN } from '../../../../constants';

function CostReportDetail() {
  const translationPrefix = 'pages.cost_report.view';
  const { t } = useTranslation();
  const groups = useAppSelector((state) => state.auth.groups);

  const [settings, setSettings] = useState<ITenantSettings>();
  const [value, setValue] = useState<number | null>(null);

  useEffect(() => {
    tenantSettingsService.get(1).then((settings: ITenantSettings) => {
      setSettings(settings);
      if (settings.cost_dashboards.length) setValue(settings.cost_dashboards[0].dashboard_id);
    });
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(+newValue);
  };

  return (
    <TenantPage title={t(`${translationPrefix}.title`)} subtitle="">
      <Tabs
        sx={{ overflow: 'scroll', maxWidth: 'calc(100vw - 300px)' }}
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        variant="scrollable"
        aria-label="secondary tabs example">
        {settings?.cost_dashboards
          ?.filter(
            (dashboard) =>
              dashboard.title !== 'ADMIN DASHBOARD' || (groups && groups.includes(ROLE_SUPER_ADMIN))
          )
          .map((dashboard) => (
            <Tab
              key={dashboard.dashboard_id}
              value={dashboard.dashboard_id}
              label={dashboard.title}></Tab>
          ))}
      </Tabs>
      {value && <CostAnalysisViewer dashboardId={value} />}
      <Outlet />
    </TenantPage>
  );
}

export default CostReportDetail;
