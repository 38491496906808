import { useEffect, useState } from 'react';
import { IOption, IStatusOption } from 'interfaces';
import { laboratoryService, productService, warehouseService } from 'services/api';
import { useTranslation } from 'react-i18next';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { IdentityField } from 'components/form/IdentityField';
import toast from 'features/toast';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { productWarehouseService } from 'services/api/ProductWarehouseService';

interface AssignProductToWarehouseProps {
  productId: number;
  callback?: any;
}

const AssignProductToWarehouse = (props: AssignProductToWarehouseProps) => {
  const { productId, callback } = props;
  const { t } = useTranslation();

  const [laboratories, setLaboratories] = useState<IOption[]>([]);
  const [warehouses, setWarehouses] = useState<IOption[]>([]);
  const [statusOptions, setStatusOptions] = useState<IStatusOption[]>([]);

  const [laboratory, setLaboratory] = useState<IOption | null>(null);
  const [warehouse, setWarehouse] = useState<IOption | null>(null);

  useEffect(() => {
    laboratoryService.getAllBaseInformation().then((res) => {
      setLaboratories(res);
    });
    productWarehouseService.options().then((res) => {
      setStatusOptions(
        res.actions.POST.status.choices.map((item) => {
          return {
            ...item,
            display_name: t(`entities.product.status_options.${item.display_name}`)
          };
        })
      );
    });
  }, []);

  useEffect(() => {
    if (laboratory) {
      warehouseService.getAllBaseInformation({ laboratory: laboratory.id }).then((res) => {
        setWarehouses(res);
      });
    }
    setWarehouse(null);
    setWarehouses([]);
  }, [laboratory]);

  const onSubmit = (e) => {
    warehouseService
      .assignProduct(
        warehouse.id,
        productId,
        +e.restock_threshold,
        +e.understock_threshold,
        +e.overstock_threshold,
        +e.status,
        e.notes
      )
      .then((res) => {
        toast.success('Magazzino aggiunto con successo.');
        if (callback) callback();
      });
  };

  return (
    <div className="w-full my-2 md:pr-2">
      <ReactFinalForm onSubmit={onSubmit}>
        <div className="w-full my-4">
          {laboratories && (
            <IdentityField
              name="laboratory"
              component={AutocompleteAdapter}
              initValue={laboratory}
              defaultValue={laboratory}
              setValue={setLaboratory}
              options={laboratories}
              label={t('global.laboratory')}
            />
          )}
        </div>
        <div className="w-full my-4">
          {warehouses && (
            <IdentityField
              name="warehouse"
              component={AutocompleteAdapter}
              initValue={warehouse}
              defaultValue={warehouse}
              setValue={setWarehouse}
              options={warehouses}
              label={t('global.warehouse')}
              type="text"
            />
          )}
        </div>
        <div className="w-full my-4">
          {statusOptions && (
            <IdentityField
              name="status"
              label={t(`entities.product.status`)}
              component={AutocompleteAdapter}
              options={statusOptions}
              placeholder="Status"
              type="text"
              optionLabel="display_name"
              optionValue="value"
            />
          )}
        </div>
        <div className="flex flex-wrap">
          <div className="pr-2 w-1/3">
            <IdentityField
              name="restock_threshold"
              label={t(`entities.product.restock_threshold`)}
              component={TextFieldAdapter}
              placeholder="Restock threshold"
              type="number"
            />
          </div>
          <div className="pr-2 w-1/3">
            <IdentityField
              name="understock_threshold"
              label={t(`entities.product.understock_threshold`)}
              component={TextFieldAdapter}
              placeholder="Understock threshold"
              type="number"
            />
          </div>
          <div className="w-1/3">
            <IdentityField
              name="overstock_threshold"
              label={t(`entities.product.overstock_threshold`)}
              component={TextFieldAdapter}
              placeholder="Overstock threshold"
              type="number"
            />
          </div>
          <div className="w-full mt-4">
            <IdentityField
              name="notes"
              component={TextFieldAdapter}
              label={t('global.notes')}
              type="text"
            />
          </div>
        </div>
      </ReactFinalForm>
    </div>
  );
};

export default AssignProductToWarehouse;
