import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Tooltip
} from '@mui/material';
import {
  laboratoryService,
  locationService,
  sectorService,
  tenantSettingsService,
  warehouseService
} from 'services/api';
import { NavLink, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import TenantPage from 'components/common/TenantPage';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import ImportFileUploader from 'components/common/file-uploader/ImportFileUploader';
import { operationalUnitService } from 'services/api/OperationalUnitService';

export const Topology = () => {
  const translationPrefix = 'pages.topology.main';
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [entityToAdd, setEntityToAdd] = useState({
    name: 'laboratory',
    code: '',
    description: '',
    callback: (entity) => null
  });
  const [selected, setSelected] = useState({
    district: 1,
    lab: null,
    operational_unit: null,
    sector: null,
    warehouse: null,
    location: null
  });
  const [laboratories, setLaboratories] = useState([]);
  const [operationalUnits, setOperationalUnits] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [locations, setLocations] = useState([]);

  const loadOperationalUnits = (labId) => {
    setSelected({ ...selected, lab: labId });
    setWarehouses([]);
    setLocations([]);
    operationalUnitService
      .getAllBaseInformation({ laboratory: labId })
      .then((res) => setOperationalUnits(res));
  };

  const loadSectors = (opUnitId) => {
    setSelected({ ...selected, operational_unit: opUnitId });
    setWarehouses([]);
    setLocations([]);
    sectorService
      .getAllBaseInformation({ operational_unit: opUnitId })
      .then((res) => setSectors(res));
  };

  const loadWarehouses = (labId) => {
    setLocations([]);
    setSelected({ ...selected, lab: labId });
    warehouseService.getAllBaseInformation({ laboratory: labId }).then((res) => setWarehouses(res));
  };

  const loadLocations = (wId) => {
    setSelected({ ...selected, warehouse: wId });
    locationService.getAllBaseInformation({ warehouse: wId }).then((res) => setLocations(res));
  };

  // Add entity
  const newLaboratory = () => {
    setEntityToAdd({
      name: 'laboratory',
      code: '',
      description: '',
      callback: (entity) => {
        laboratoryService
          .add({
            id: null,
            code: entity.code,
            description: entity.description
          })
          .then((res) => {
            setLaboratories([...laboratories, res]);
          });
      }
    });
    setOpen(true);
  };

  const newOperationalUnit = () => {
    setEntityToAdd({
      name: 'operationalunit',
      code: '',
      description: '',
      callback: (entity) => {
        operationalUnitService
          .add({
            id: null,
            code: entity.code,
            description: entity.description,
            laboratory: selected.lab
          })
          .then((res) => {
            setOperationalUnits([...operationalUnits, res]);
          });
      }
    });
    setOpen(true);
  };
  const newSector = () => {
    setEntityToAdd({
      name: 'sector',
      code: '',
      description: '',
      callback: (entity) => {
        sectorService
          .add({
            id: null,
            code: entity.code,
            description: entity.description,
            operational_unit: selected.operational_unit
          })
          .then((res) => {
            setSectors([...sectors, res]);
          });
      }
    });
    setOpen(true);
  };

  // const newWarehouse = () => {
  //   setEntityToAdd({
  //     name: 'warehouse',
  //     code: '',
  //     description: '',
  //     callback: (entity) => {
  //       warehouseService
  //         .add({
  //           id: null,
  //           code: entity.code,
  //           description: entity.description,
  //           laboratory: selected.lab
  //         })
  //         .then((res) => {
  //           setWarehouses([...warehouses, res]);
  //         });
  //     }
  //   });
  //   setOpen(true);
  // };
  const newLocation = () => {
    setEntityToAdd({
      name: 'location',
      code: '',
      description: '',
      callback: (entity) => {
        locationService
          .add({
            id: null,
            code: entity.code,
            description: entity.description,
            warehouse: selected.warehouse
          })
          .then((res) => {
            setLocations([...locations, res]);
          });
      }
    });
    setOpen(true);
  };

  const saveEntity = () => {
    entityToAdd.callback(entityToAdd);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    laboratoryService.getAllBaseInformation().then((res) => setLaboratories(res));
  }, []);

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <div className="flex items-center justify-between">
            <ButtonGroup className="mr-4 space-x-2">
              <IsTenantAdministrator>
                <Tooltip title={t(`import-modal.title`)}>
                  <ImportFileUploader service={tenantSettingsService} />
                </Tooltip>
              </IsTenantAdministrator>
              <Tooltip title={t(`${translationPrefix}.to-tree-view`)}>
                <NavLink to="tree">
                  <Button size="small">
                    <AccountTreeIcon />
                  </Button>
                </NavLink>
              </Tooltip>
            </ButtonGroup>
            <ButtonGroup>
              <Button size="small" onClick={newLaboratory} disabled={!selected.district}>
                <AddIcon></AddIcon>
                {t(`global.laboratory`)}
              </Button>
              <Button size="small" onClick={newOperationalUnit} disabled={!selected.lab}>
                <AddIcon></AddIcon>
                {t(`global.operational_unit`)}
              </Button>
              <Button size="small" onClick={newSector} disabled={!selected.operational_unit}>
                <AddIcon></AddIcon>
                {t(`global.sector`)}
              </Button>
              {/* <Button size="small" onClick={newWarehouse} disabled={!selected.sector}>
                <AddIcon></AddIcon>
                {t(`global.warehouse`)}
              </Button>
              <Button size="small" onClick={newLocation} disabled={!selected.warehouse}>
                <AddIcon></AddIcon>
                {t(`global.location`)}
              </Button> */}
            </ButtonGroup>
          </div>
        }>
        <div className="flex">
          {[
            { key: 'laboratory', data: laboratories, loadMethod: loadOperationalUnits },
            { key: 'operationalunit', data: operationalUnits, loadMethod: loadSectors },
            { key: 'sector', data: sectors, loadMethod: null }
            // { key: 'warehouse', data: warehouses, loadMethod: loadLocations },
            // { key: 'location', data: locations, loadMethod: null }
          ].map((entity) => (
            <div className="w-1/3 border-r border-slate-200" key={entity.key}>
              <h3 className="font-bold border-b border-slate-200">{t(`global.${entity.key}`)}</h3>
              {entity.data.map((item) => (
                <ListItemButton selected={item.id === selected[entity.key]} key={item.id}>
                  <ListItem
                    disableGutters
                    onClick={() => entity.loadMethod && entity.loadMethod(item.id)}
                    onDoubleClick={() => navigate(`${entity.key}/${item.id}`)}
                    secondaryAction={
                      <div>
                        {entity.loadMethod && (
                          <IconButton onClick={() => entity.loadMethod(item.id)}>
                            <ArrowForwardIosIcon />
                          </IconButton>
                        )}
                      </div>
                    }>
                    <ListItemText primary={item.description} secondary={item.code} />
                  </ListItem>
                </ListItemButton>
              ))}
            </div>
          ))}
        </div>
        <div>
          <Dialog open={open}>
            <DialogTitle>
              {t('pages.topology.main.add-new-modal.title', {
                entityName: t(`global.${entityToAdd.name}`).toLowerCase()
              })}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>{t('pages.topology.main.add-new-modal.body')}</DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="code"
                label={t(`global.code`)}
                fullWidth
                variant="standard"
                value={entityToAdd.code}
                onChange={(e) => setEntityToAdd({ ...entityToAdd, code: e.target.value })}
              />
              <TextField
                autoFocus
                margin="dense"
                id="description"
                label={t(`global.description`)}
                fullWidth
                variant="standard"
                value={entityToAdd.description}
                onChange={(e) => setEntityToAdd({ ...entityToAdd, description: e.target.value })}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={saveEntity}>Subscribe</Button>
            </DialogActions>
          </Dialog>
        </div>
      </TenantPage>
    </>
  );
};

export default Topology;
