import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { IMedicalService } from 'interfaces';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { medicalServiceService } from 'services/api';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import WidgetSquarePlaceholder from './WidgetSquarePlaceholder';

const DAYS_THRESHOLD = 15;

function LisStatusWidget() {
  const { t } = useTranslation();
  const [medicalService, setMedicalService] = useState<IMedicalService>();
  const [outdated, setOutadted] = useState<boolean>(false);

  useEffect(() => {
    medicalServiceService
      .getAllPaginated(1, 0, { ordering: '-service_date' })
      .then((res) => setMedicalService(res.results[0]));
  }, []);

  useEffect(() => {
    if (medicalService) {
      const days = dayjs().diff(medicalService.service_date, 'days');
      setOutadted(days > DAYS_THRESHOLD);
    }
  }, [medicalService]);

  return medicalService ? (
    <div className="w-full flex flex-col justify-center items-center px-1">
      {outdated && (
        <>
          <MedicalServicesIcon fontSize="large" color="error" />
          <span className="text-center">{t(`dashboard.lis-status.body-error`)}</span>
          <NavLink to={'/tenant/medical-services?blameMode=true'}>
            <Button color="error">{t(`dashboard.lis-status.go`)}</Button>
          </NavLink>
        </>
      )}
      {!outdated && (
        <>
          <MedicalServicesIcon fontSize="large" color="success" />
          <span className="text-center">{t(`dashboard.lis-status.body-success`)}</span>
          <NavLink to={'/tenant/medical-services?blameMode=true'}>
            <Button color="success">{t(`dashboard.lis-status.go`)}</Button>
          </NavLink>
        </>
      )}
    </div>
  ) : (
    <WidgetSquarePlaceholder />
  );
}

export default LisStatusWidget;
