import { Button } from '@mui/material';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { MultipleAutocompleteAdapter } from 'components/form/MultipleAutocompleteAdapter';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { composeValidators, mustBeCurrency, mustBeNumber, required } from 'helpers/validators';
import { IOption } from 'interfaces';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';

interface ContractLineFormProps {
  onSubmit: any;
  products: IOption[];
  warehouses: IOption[];
}

function ContractLineForm(props: ContractLineFormProps) {
  const { onSubmit, products, warehouses } = props;
  const { t } = useTranslation();

  return (
    <div>
      <ReactFinalForm onSubmit={onSubmit} formId="addProductForm" hideControls={true}>
        <div className="flex flex-wrap">
          <div className="w-1/2 mb-4 pr-4">
            <IdentityField
              name="product"
              component={AutocompleteAdapter}
              options={products}
              optionLabel="description"
              label={t('global.product')}
              type="text"
              submitObject
            />
          </div>
          <div className="w-1/2 mb-4">
            <IdentityField
              name="warehouses"
              component={MultipleAutocompleteAdapter}
              options={warehouses}
              optionValue="code"
              label={t('global.warehouses')}
              type="text"
            />
          </div>
          <div className="w-1/4 mb-4 pr-4">
            <IdentityField
              name="unit_price"
              label={t('entities.contract_line.unit_price')}
              component={TextFieldAdapter}
              type="text"
              validate={composeValidators(required, mustBeCurrency)}
            />
          </div>
          <div className="w-1/4 mb-4 pr-4">
            <IdentityField
              name="initial_available_units"
              label={t('entities.contract_line.initial_available_units')}
              component={TextFieldAdapter}
              type="text"
              validate={composeValidators(required, mustBeNumber)}
            />
          </div>
          <div className="w-1/4 mb-4 pr-4 flex">
            <IdentityField
              name="initial_discounted_units"
              label={t('entities.contract_line.initial_discounted_units')}
              component={TextFieldAdapter}
              type="text"
              validate={composeValidators(required, mustBeNumber)}
            />
          </div>
          <div className="w-1/4 mb-4 py-auto flex">
            <Button type="submit" form="addProductForm" variant="outlined" className="w-full">
              {t('actions.insert')}
            </Button>
          </div>
        </div>
      </ReactFinalForm>
    </div>
  );
}

export default ContractLineForm;
