import { IPurchaseRequest } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class PurchaseRequestService extends BaseService<IPurchaseRequest> {
  constructor() {
    super('purchreqhead');
  }
  canBulkDelete = true;

  preApproveRequest = async (entity: { id: number; lines: any[] }) => {
    const res = await axiosPrivate.patch(`${this.modulePrefix}/${entity.id}/pre_approve_request/`, {
      lines: entity.lines
    });
    return res.data;
  };

  approveRequest = async (entity: { id: number; lines: any[] }, sendEmail?: boolean) => {
    const res = await axiosPrivate.patch(
      `${this.modulePrefix}/${entity.id}/approve_request/`,
      {
        lines: entity.lines
      },
      {
        params: {
          sendEmail
        }
      }
    );
    return res.data;
  };

  getOrderPreview = async (req: IPurchaseRequest) => {
    const res = await axiosPrivate.post(`${this.modulePrefix}/${req.id}/order_preview/`, req);
    return res.data['detail'];
  };
}

export const purchaseRequestService = new PurchaseRequestService();
