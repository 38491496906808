import { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface CollapsableColumnsProps {
  contentLeft: any;
  contentRight: any;
  size: 'w-1/2' | 'w-1/3' | 'w-1/4' | 'w-2/5' | 'w-full';
  expand: boolean;
  onClose?: () => any;
}

const complementar = {
  'w-1/2': 'w-1/2',
  'w-1/3': 'w-2/3',
  'w-1/4': 'w-3/4',
  'w-2/5': 'w-3/5',
  'w-full': 'w-full order-first'
};

export default function CollapsableColumns(props: CollapsableColumnsProps) {
  const { contentLeft, contentRight, expand, size, onClose } = props;

  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    setExpanded(expand);
    if (onClose && !expand) {
      onClose();
    }
  }, [expand]);

  const collapse = () => {
    setExpanded(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <div className="w-full flex flex-wrap items-start">
      <div className={`transition-all ${expanded ? `${complementar[size]} pr-2` : 'w-full'}`}>
        {contentLeft}
      </div>
      {
        <div
          className={`relative transition-all ${
            expanded ? `opacity-100 ${size}` : 'opacity-0 w-0'
          } py-4 px-2 bg-gray-100 rounded-[20px] drop-shadow-lg`}>
          <div className="absolute top-0 right-0">
            <IconButton onClick={collapse} color="error">
              <CloseIcon />
            </IconButton>
          </div>
          <br />
          {contentRight}
        </div>
      }
    </div>
  );
}
