import { useRef } from 'react';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { unapprovedMovementService } from 'services/api';
import { filterOperators } from '../../../../constants';
import { baseCell } from 'components/common/enhanced/cells/base-cell';
import { Link } from 'react-router-dom';
import DataGridCell from 'components/common/DataGridCell';

export const UnapprovedMovementsDDT = () => {
  const translationPrefix = 'pages.movements.unapproved';
  const { t } = useTranslation();
  const datagridRefresh = useRef(null);

  const columns: GridColumns = [
    {
      field: 'transport_document_code',
      headerName: t(`entities.movement.transport_document`),
      filterOperators,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const transport_document_code = params.row.transport_document_code;
        return (
          <div>
            <Link to={`${encodeURIComponent(transport_document_code) || '_/?noDDT=true'}`}>
              <b className="text-secondary-500">{transport_document_code || 'No DDT'}</b>
            </Link>
          </div>
        );
      }
    },
    {
      field: 'supplier',
      headerName: t(`global.supplier`),
      flex: 1,
      filterOperators,
      renderCell: (params: GridRenderCellParams<string>) => (
        <DataGridCell content={params.row.transport_document_code ? params.value : ''} />
      )
    },
    {
      field: 'transport_document_date',
      headerName: t(`entities.movement.transport_document_date`),
      flex: 1,
      filterOperators,
      renderCell: baseCell
    }
  ];

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <EnhancedDataGrid
        columns={columns}
        service={unapprovedMovementService}
        getMethod={unapprovedMovementService.aggregatedDdt}
        refresh={datagridRefresh}
      />
    </TenantPage>
  );
};

export default UnapprovedMovementsDDT;
