import { GridColDef } from '@mui/x-data-grid-premium';
import { filterOperators, numberFilterOperators } from './../../../../constants';
import { useTranslation } from 'react-i18next';
import { baseCell } from '../cells/base-cell';
import { currencyCell } from '../cells/currency-cell';
import DataGridCell from 'components/common/DataGridCell';

export function RoleColumns(): GridColDef[] {
  const { t } = useTranslation();
  return [
    {
      field: 'role',
      headerName: t(`global.role`),
      filterOperators,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'role_name',
      headerName: t(`entities.expertise.role_name`),
      filterOperators,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'fte',
      headerName: t(`entities.expertise.fte`),
      filterOperators: numberFilterOperators,
      type: 'number',
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'monthly_hours',
      headerName: t(`entities.expertise.monthly_hours`),
      filterOperators: numberFilterOperators,
      type: 'number',
      width: 150,
      renderCell: (params) => (
        <DataGridCell
          content={((+params.row.monthly_hours).toFixed(2) as string).replace('.', ',')}
        />
      )
    },
    {
      field: 'monthly_cost',
      headerName: t(`entities.expertise.monthly_cost`),
      filterOperators: numberFilterOperators,
      type: 'number',
      flex: 1,
      renderCell: currencyCell
    }
  ];
}
