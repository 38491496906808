import { IInventoryCheckLine } from 'interfaces';
import { BaseService } from './BaseService';

class InventoryCheckLineService extends BaseService<IInventoryCheckLine> {
  constructor() {
    super('inventory-check-lines');
  }
}

export const inventoryCheckLineService = new InventoryCheckLineService();
