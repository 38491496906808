import { useEffect, useState } from 'react';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { IOption } from 'interfaces';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { contractService, equipmentInvoiceLineService } from 'services/api';
import { required } from 'helpers/validators';

interface EquipmentInvoiceLineFormParams {
  onSubmit: any;
  contractId: number;
}

function EquipmentInvoiceLineForm(params: EquipmentInvoiceLineFormParams) {
  const { t } = useTranslation();
  const { onSubmit, contractId } = params;

  const [options, setOptions] = useState<IOption[]>([]);

  useEffect(() => {
    if (contractId) {
      equipmentInvoiceLineService.getUnassigned(10000, 0, { contract: contractId }).then((res) => {
        setOptions(
          res.results.map((cost) => {
            const typeString = t(`pages.equipment-invoices.detail.modes.${cost.type}`);
            const code = cost.contract_equipment ? cost.equipment : cost.code;
            const description = `${code} - ${cost.description}`;
            return {
              id: cost.id,
              code: `${description} ${cost.initialization_date}, € ${cost.expected_amount.replace(
                '.',
                ','
              )} (${typeString})`
            };
          })
        );
      });
    } else {
      setOptions([]);
    }
  }, [contractId]);

  return (
    <div>
      <ReactFinalForm onSubmit={onSubmit} formId="newInvoice" hideControls>
        <div className="flex flex-wrap">
          <div className="w-full p-2">
            <IdentityField
              name="invoice_line"
              component={AutocompleteAdapter}
              options={options}
              label={t('pages.equipment-invoices.detail.invoice-line')}
              validate={required}
              required
            />
          </div>
        </div>
      </ReactFinalForm>
    </div>
  );
}

export default EquipmentInvoiceLineForm;
