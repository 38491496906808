import { Alert, Button, Skeleton, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { IOption, IStockQuantity } from 'interfaces';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { stockQuantityService } from 'services/api';
import BiotechIcon from '@mui/icons-material/Biotech';
import { useAppSelector } from 'app/store';
import { ROLE_TENANT_OPERATOR } from '../../constants';

function ExpiringProducts() {
  const { t } = useTranslation();
  const [warehouse, setWarehouse] = useState<IOption | null>(null);
  const [stockQuantities, setStockQuantities] = useState<IStockQuantity[]>();

  const defaultWarehouse = useAppSelector((state) => state.auth.warehouse);
  const groups = useAppSelector((state) => state.auth.groups);
  const isOperator = groups && groups.includes(ROLE_TENANT_OPERATOR);

  useEffect(() => {
    setWarehouse({ code: defaultWarehouse, id: 0 });
  }, [defaultWarehouse]);

  useEffect(() => {
    setStockQuantities(null);
    stockQuantityService
      .getAllPaginated(3, 0, {
        expiration_date__lte: isOperator
          ? dayjs().format('YYYY-MM-DD')
          : dayjs().add(8, 'days').format('YYYY-MM-DD'),
        expiration_date__gte: dayjs().format('YYYY-MM-DD'),
        ordering: 'expiration_date',
        warehouse: warehouse && warehouse.code
      })
      .then((res) => setStockQuantities(res.results));
  }, [warehouse]);

  return (
    <div>
      <Typography sx={{ fontSize: 14 }} color="secondary" gutterBottom>
        <BiotechIcon />
        {t('dashboard.expiration-products.title')}
      </Typography>
      {!stockQuantities && (
        <>
          <Skeleton variant="rectangular" height={32} />
          <br />
          <Skeleton variant="rectangular" height={32} />
          <br />
          <Skeleton variant="rectangular" height={32} />
        </>
      )}
      {stockQuantities &&
        stockQuantities.map((stockQuantity) => (
          <Tooltip key={stockQuantity.id} title={stockQuantity.warehouse}>
            <Alert
              severity={dayjs().isAfter(stockQuantity.expiration_date) ? 'error' : 'warning'}
              className="mb-2"
              action={
                <NavLink to={`/tenant/products?code=${stockQuantity.product}`}>
                  <Button color="inherit" size="small">
                    {t('dashboard.expiration-products.go')}
                  </Button>
                </NavLink>
              }>
              {t('dashboard.expiration-products.row-body', {
                code: stockQuantity.product,
                description: stockQuantity.description,
                supplier: stockQuantity.product_batch,
                date: stockQuantity.expiration_date
              })}
            </Alert>
          </Tooltip>
        ))}
    </div>
  );
}

export default ExpiringProducts;
