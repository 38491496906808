import {
  Products,
  CreateProduct,
  ProductsSkuMapping,
  EditProduct,
  Categories,
  CreateCategory,
  Types,
  CreateType,
  Suppliers,
  AddSupplier,
  Contracts,
  AddContract,
  StockQuantities,
  Movements,
  MovementReasons,
  PurchaseRequest,
  PurchaseRequestList,
  ArchivedMaintenances,
  InventoryCheck,
  InventoryCheckArchive,
  PurchaseOrder,
  PurchaseOrderList,
  EditContract,
  EditSupplier,
  EditCategory,
  EditType,
  ConsumptionDetail,
  DeliveryExpected,
  CollapsedMovements,
  StockQuantitiesAtDate,
  Maintenance,
  Tickets,
  Ticket,
  ArchivedTickets,
  AddTicket,
  EditInventory,
  AddInventoryCheck,
  StockUnderstockProducts
} from 'components/inventory';
import { CreateWarehouse, EditWarehouse, Warehouses } from 'components/inventory/Warehouses';
import { CreateLocation, EditLocation, Locations } from 'components/inventory/Locations';
import {
  CreateExam,
  CreateWorkstation,
  Exams,
  MacroExams,
  EditMacroExam,
  CreateMacroExam,
  Workstations,
  MedicalServices,
  EditExam,
  EditWorkstation,
  Equipments,
  CreateEquipment,
  EditEquipment,
  RegionalRates,
  EditRegionalRate,
  CreateRegionalRate
} from 'components/cost-analysis';
import {
  Topology,
  TopologyTree,
  Login,
  NotFound,
  UnderDevelop,
  NotificationList,
  UsersManager,
  TenantSettings,
  SAPSettings,
  NotificationDetail
} from 'components/common';
import { Navigate, Route, Routes } from 'react-router-dom';
import Public from './layouts/Public';
import Tenant from './layouts/Tenant';
import RequireAuth from './components/RequireAuth';
import RequireRoles from './components/RequireRoles';
import { NotOperatorRoles, SuperAdminRoles, TenantRoles } from './constants';
import TenantHome from './pages/tenant/Home';
import Base from 'pages/Base';
import { AddCostElement, CostList, EditCostElement } from 'components/cost-analysis/Costs';
import ReportViewer from 'components/Reporting/ReportViewer';
import EditLayer from 'components/common/Topology/edit-layer/EditLayer';
import UnapprovedMovements from 'components/inventory/Movements/list-unapproved/UnapprovedMovements';
import UnapprovedMovementsDDT from 'components/inventory/Movements/list-unapproved/UnapprovedMovementsDDT';
import AddMovementReason from 'components/inventory/Movements/reasons/add/AddMovementReason';
import EditMovementReason from 'components/inventory/Movements/reasons/edit/EditMovementReason';
import ReportList from 'components/Reporting/ReportList';
import AllQuantities from 'components/inventory/Stock/all/AllQuantities';
import CostReportList from 'components/cost-analysis/CostReport/list/CostReportList';
import {
  EquipmentInvoices,
  TransportDocumentDetail,
  EquipmentInvoiceDetail,
  TransportDocumentList,
  ExpectedEquipmentInvoices,
  InvoicedMovements
} from 'components/invoices/TransportDocument';
import CostReportDetail from 'components/cost-analysis/CostReport/view/CostReportDetail';
import UninvoicedMovements from 'components/inventory/Movements/list-uninvoiced/UninvoicedMovements';
import {
  AddExpertise,
  ExpertiseList,
  ExpertiseView,
  ActivitiyList,
  RoleNameList,
  RoleList
} from 'components/staff-management';
import CreateTransportDocument from 'components/invoices/TransportDocument/add/CreateTransportDocument';
import {
  CreateLISTranscode,
  EditLISTranscode,
  LISTranscodes
} from 'components/cost-analysis/LISTranscodes';
import { CreateInvoice, InvoiceDetail, Invoices } from 'components/invoices';
import { UnderMaintenance } from 'pages/UnderMaintenance';
import {
  CreateRequestDepartmentTranscode,
  EditRequestDepartmentTranscode,
  RequestDepartmentTranscodes
} from 'components/inventory/RequestDepartmentTranscodes';
import _ from 'lodash';
import { DocumentsList } from 'components/inventory/DocumentsG4';

const AppRoutes = () => {
  return (
    <Routes>
      {/* public routes */}
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Public />}>
        <Route path="" element={<Base />} />
        <Route path="login" element={<Login />} />
        <Route path="under-develop" element={<UnderDevelop />} />
        <Route path="maintenance" element={<UnderMaintenance />} />
      </Route>

      {/* protected routes */}
      <Route element={<RequireAuth />}>
        <Route element={<RequireRoles allowedRoles={[...TenantRoles, ...SuperAdminRoles]} />}>
          <Route path="tenant" element={<Tenant />}>
            <Route path="under-develop" element={<Navigate to="/under-develop" />} />

            <Route path="" element={<Navigate to="home" />} />
            <Route path="home" element={<TenantHome />} />
            <Route path="users">
              <Route path="" element={<UsersManager />} />
            </Route>
            <Route path="stock">
              <Route path="" element={<StockQuantities />} />
              <Route path="all" element={<AllQuantities />} />
              <Route path="past" element={<StockQuantitiesAtDate />} />
              <Route path="understock" element={<StockUnderstockProducts />} />
            </Route>
            <Route path="contracts">
              <Route path="" element={<Contracts />} />
              <Route path="new" element={<AddContract />} />
              <Route path=":contractId" element={<EditContract />} />
            </Route>
            <Route path="suppliers">
              <Route path="" element={<Suppliers />} />
              <Route element={<RequireRoles allowedRoles={[...NotOperatorRoles]} />}>
                <Route path="new" element={<AddSupplier />} />
                <Route path=":supplierId" element={<EditSupplier />} />
              </Route>
            </Route>
            <Route path="products">
              <Route path="" element={<Products />} />
              <Route element={<RequireRoles allowedRoles={[...NotOperatorRoles]} />}>
                <Route path="new" element={<CreateProduct />} />
                <Route path=":productId" element={<EditProduct />} />
              </Route>
            </Route>
            <Route path="warehouses">
              <Route path="" element={<Warehouses />} />
              <Route path="new" element={<CreateWarehouse />} />
              <Route path=":warehouseId" element={<EditWarehouse />} />
            </Route>
            <Route path="locations">
              <Route path="" element={<Locations />} />
              <Route path="new" element={<CreateLocation />} />
              <Route path=":locationId" element={<EditLocation />} />
            </Route>
            <Route path="equipments">
              <Route path="" element={<Equipments />} />
              <Route element={<RequireRoles allowedRoles={[...NotOperatorRoles]} />}>
                <Route path="new" element={<CreateEquipment />} />
                <Route path=":equipmentId" element={<EditEquipment />} />
                <Route path="invoices">
                  <Route path="" element={<EquipmentInvoices />} />
                  <Route path="all" element={<ExpectedEquipmentInvoices />} />
                  <Route path=":invoiceId" element={<EquipmentInvoiceDetail />} />
                </Route>
              </Route>
              <Route path="maintenance">
                <Route path="" element={<Maintenance />} />
                <Route path="archive" element={<ArchivedMaintenances />} />
                <Route path=":maintenanceId" element={<Maintenance />} />
              </Route>
              <Route path="ticketing">
                <Route path="" element={<Tickets />} />
                <Route path="new" element={<AddTicket />} />
                <Route path="archive" element={<ArchivedTickets />} />
                <Route path=":ticketId" element={<Ticket />} />
              </Route>
            </Route>
            <Route path="categories">
              <Route path="" element={<Categories />} />
              <Route path="new" element={<CreateCategory />} />
              <Route path=":categoryId" element={<EditCategory />} />
            </Route>
            <Route path="types">
              <Route path="" element={<Types />} />
              <Route path="new" element={<CreateType />} />
              <Route path=":typeId" element={<EditType />} />
            </Route>
            <Route path="skus">
              <Route path="" element={<ProductsSkuMapping />} />
              {/* <Route path="new" element={<CreateType />} /> */}
            </Route>
            <Route path="inventory-check">
              <Route path="" element={<InventoryCheck />} />
              <Route path="new" element={<AddInventoryCheck />} />
              <Route path="archive" element={<InventoryCheckArchive />} />
              <Route path=":inventoryId" element={<EditInventory />} />
            </Route>
            <Route path="cost-analysis">
              <Route path="" element={<CostReportList />} />
              {/* <Route path="report-2" element={<CostReport />} /> */}
              <Route path="reports" element={<CostReportDetail />} />
              <Route path="workstations">
                <Route path="" element={<Workstations />} />
                <Route path="new" element={<CreateWorkstation />} />
                <Route path=":workstationId" element={<EditWorkstation />} />
              </Route>
              <Route path="exams">
                <Route path="" element={<Exams />} />
                <Route path="new" element={<CreateExam />} />
                <Route path=":examId" element={<EditExam />} />
              </Route>
              <Route path="macro-exams">
                <Route path="" element={<MacroExams />} />
                <Route path="new" element={<CreateMacroExam />} />
                <Route path=":macroExamId" element={<EditMacroExam />} />
              </Route>
              <Route path="regional-rates">
                <Route path="" element={<RegionalRates />} />
                <Route path="new" element={<CreateRegionalRate />} />
                <Route path=":regionalRateId" element={<EditRegionalRate />} />
              </Route>
              <Route path="costs">
                <Route path="" element={<CostList />} />
                <Route path="add" element={<AddCostElement />} />
                <Route path=":costElementId" element={<EditCostElement />} />
              </Route>
            </Route>
            <Route path="staff-management">
              <Route path="role-names">
                <Route path="" element={<RoleNameList />} />
                <Route path="by-role" element={<RoleList />} />
              </Route>
              <Route path="expertises">
                <Route path="" element={<ExpertiseList />} />
                <Route path="new" element={<AddExpertise />} />
                <Route path=":expertiseId" element={<ExpertiseView />} />
              </Route>
              <Route path="activities" element={<ActivitiyList />} />
            </Route>
            <Route path="reporting" element={<ReportList />}>
              <Route path=":dashboardId" element={<ReportViewer />} />
            </Route>
            <Route path="ddt">
              <Route path="" element={<TransportDocumentList />} />
              <Route path="new" element={<CreateTransportDocument />} />
              <Route path="invoiced-movements" element={<InvoicedMovements />} />
              <Route path=":id" element={<TransportDocumentDetail />} />
            </Route>
            <Route path="invoices">
              <Route path="" element={<Invoices />} />
              <Route path="new" element={<CreateInvoice />} />
              {/* <Route path="invoiced-movements" element={<InvoicedMovements />} /> */}
              <Route path=":invoiceId" element={<InvoiceDetail />} />
            </Route>
            <Route path="medical-services" element={<MedicalServices />} />
            <Route path="consumption" element={<ConsumptionDetail />} />
            <Route path="movements">
              <Route path="" element={<Movements />} />
              <Route path="detail" element={<CollapsedMovements />} />
              <Route path="unapproved">
                <Route path="" element={<UnapprovedMovementsDDT />} />
                <Route path=":transportDocument" element={<UnapprovedMovements />} />
              </Route>
              <Route path="uninvoiced" element={<UninvoicedMovements />} />
              <Route path="reasons">
                <Route path="" element={<MovementReasons />} />
                <Route path="new" element={<AddMovementReason />} />
                <Route path=":movementReasonId" element={<EditMovementReason />} />
              </Route>
            </Route>
            {/* <Route path="laboratory/assign" element={<AssignLaboratory />} /> */}
            <Route path="orders">
              <Route path="" element={<PurchaseOrderList />} />
              <Route path="delivery" element={<DeliveryExpected />} />
              <Route path="view/:purchaseOrderId" element={<PurchaseOrder />} />
            </Route>
            <Route path="notifications">
              <Route path="" element={<NotificationList />} />
              <Route path="view/:notificationId" element={<NotificationDetail />} />
            </Route>
            <Route path="purchase_requests">
              <Route path="" element={<PurchaseRequestList />} />
              <Route path="new/" element={<PurchaseRequest />} />
              <Route path="view/:purchaseRequestId" element={<PurchaseRequest />} />
            </Route>
            <Route path="topology">
              <Route path="" element={<Topology />} />
              <Route path=":layer/:id" element={<EditLayer />} />
              <Route path="tree" element={<TopologyTree />} />
            </Route>
            <Route path="settings">
              <Route path="" element={<TenantSettings />} />
              <Route path="sap/" element={<SAPSettings />} />
            </Route>
            <Route path="custom-reports" element={<ReportList category="CUSTOM" />}>
              <Route path=":dashboardId" element={<ReportViewer />} />
            </Route>
            <Route path="documents">
              <Route path="" element={<DocumentsList />} />
            </Route>

            {process.env.REACT_APP_METABASE_DASHBOARD_TABS &&
              process.env.REACT_APP_METABASE_DASHBOARD_TABS.split(',').map((context) => {
                return (
                  <Route
                    key={context}
                    path={_.kebabCase(context)}
                    element={<ReportList category={context} />}>
                    <Route path=":dashboardId" element={<ReportViewer />} />
                  </Route>
                );
              })}

            <Route path="lis-transcodes">
              <Route path="" element={<LISTranscodes />} />
              <Route path="new" element={<CreateLISTranscode />} />
              <Route path=":lisTranscodeId" element={<EditLISTranscode />} />
            </Route>

            <Route path="department-transcodes">
              <Route path="" element={<RequestDepartmentTranscodes />} />
              <Route path="new" element={<CreateRequestDepartmentTranscode />} />
              <Route path=":typeId" element={<EditRequestDepartmentTranscode />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRoutes;
