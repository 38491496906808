import { ITransportDocumentLine } from 'interfaces';
import { BaseService } from './BaseService';
import { axiosPrivate } from 'services/axios';

class TransportDocumentLineService extends BaseService<ITransportDocumentLine> {
  constructor() {
    super('transport-document-lines');
  }

  invoicedMovements = async (limit = 0, offset = 0, params: Record<string, unknown> = {}) => {
    const ticketId = params.ticketId;
    console.log(ticketId);
    const res = await axiosPrivate.get(`${this.modulePrefix}/${ticketId}/invoiced/`, {
      params: { limit, offset, ...params }
    });
    return res.data;
  };
}

export const transportDocumentLineService = new TransportDocumentLineService();
