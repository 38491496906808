import HomeIcon from '@mui/icons-material/Home';
import { Breadcrumbs as MUIBreadcrumbs, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const Breadcrumbs = (props: { friendlyId?: string }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { friendlyId } = props;

  const pathnames = pathname
    .replace('/tenant', '')
    .replace('/view', '')
    .split('/')
    .filter((path) => !['', 'home'].includes(path));

  return (
    pathnames.length > 0 && (
      <MUIBreadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="inherit"
          onClick={() => navigate('/')}
          className="cursor-pointer">
          <HomeIcon sx={{ mt: -0.5 }} fontSize="small" />
        </Link>
        {pathnames.map((name, index) => {
          const routeTo = `/tenant/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;
          return isLast ? (
            <Typography
              color="text.primary"
              key={name}
              className="cursor-pointer text-primary-500 font-bold">
              {friendlyId || t(`global.${name}`)}
            </Typography>
          ) : (
            <Link
              underline="hover"
              color="inherit"
              className="cursor-pointer"
              key={name}
              onClick={() => navigate(routeTo)}>
              {t(`global.${name}`)}
            </Link>
          );
        })}
      </MUIBreadcrumbs>
    )
  );
};

export default Breadcrumbs;
