import { IUnapprovedMovement } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';
import ContractLineForm from 'components/inventory/Contracts/ContractLineForm';

class UnapprovedMovementService extends BaseService<IUnapprovedMovement> {
  constructor() {
    super('unapproved-movements');
  }

  aggregated = async (limit = 0, offset = 0, params: Record<string, unknown> = {}) => {
    const res = await axiosPrivate.get(`${this.modulePrefix}/aggregated/`, {
      params: { limit, offset, ...params }
    });
    return res.data;
  };

  aggregatedDdt = async (limit = 0, offset = 0, params: Record<string, unknown> = {}) => {
    const res = await axiosPrivate.get(`${this.modulePrefix}/aggregated-ddt/`, {
      params: { limit, offset, ...params }
    });
    return res.data;
  };

  approveSelected = async (movements: number[]) => {
    const res = await axiosPrivate.post(`${this.modulePrefix}/bulk-approve/`, {
      movements
    });
    return res.data;
  };

  deleteSelected = async (movements: number[]) => {
    const res = await axiosPrivate.post(`${this.modulePrefix}/bulk-delete/`, {
      movements
    });
    return res.data;
  };

  editAggregatedRow = async (movements: number[], contract: string) => {
    const res = await axiosPrivate.post(`${this.modulePrefix}/bulk-edit/`, {
      movements,
      contract
    });
    return res.data;
  };
}

export const unapprovedMovementService = new UnapprovedMovementService();
