import { ThemeProvider } from '@emotion/react';
import { useAppSelector } from 'app/store';
import ScrollToTop from 'components/helpers/ScrollToTop';
import StyleProvider from 'helpers/StyleProvider';
import { refertingTheme, evaluateTheme, labTheme, settingsTheme } from 'helpers/themes';
import { useEffect, useState } from 'react';
import './App.css';
import AppRoutes from './AppRoutes';
import Spinner from './components/common/Spinner';

function App() {
  const [theme, setTheme] = useState(labTheme);

  const context = useAppSelector((state) => state.contextData.context);

  useEffect(() => {
    switch (context.toUpperCase()) {
      case 'LAB':
      default:
        setTheme(labTheme);
        break;
      case 'REFERTING':
        setTheme(refertingTheme);
        break;
      case 'SETTINGS':
        setTheme(settingsTheme);
        break;
      case 'EVALUATE':
        setTheme(evaluateTheme);
        break;
    }
  }, [context]);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <StyleProvider />
        <Spinner />
        <ScrollToTop />
        <AppRoutes />
      </div>
    </ThemeProvider>
  );
}

export default App;
