import { AxiosError, AxiosResponse } from 'axios';
import { logout } from 'features';
import toast from 'features/toast';
import { axiosPrivate, axiosPublic } from '.';
import { useNavigate } from 'react-router-dom';

let store;

export const injectStore = (_store) => {
  store = _store;
};

export const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

export const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  if (error?.response?.config?.url === 'logout/') return;
  if (error?.response?.status === 401) {
    try {
      await axiosPublic.post('token/refresh/', {}, { withCredentials: true });
      return axiosPrivate.request(error.config);
    } catch (err) {
      store.dispatch(logout());
    }
  } else if (error?.response?.status === 502 && !(process.env.NODE_ENV === 'development')) {
    window.location.replace('/maintenance');
    toast.error(JSON.stringify(error?.response?.data).slice(0, 255));
  } else {
    toast.error(JSON.stringify(error?.response?.data).slice(0, 255));
  }
  throw error;
};
