import ReactFinalForm from 'components/form/ReactFinalForm';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { IOption, IProduct } from 'interfaces';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import SectionTitle from 'components/common/SectionTitle';
import { useEffect, useState } from 'react';
import { categoryService, typeService } from 'services/api';

interface ProductFormParams {
  product: IProduct;
  onSubmit: any;
}
function ProductForm(params: ProductFormParams) {
  const translationPrefix = 'entities.product';
  const { t } = useTranslation();

  const [categories, setCategories] = useState<IOption[]>([]);
  const [types, setTypes] = useState<IOption[]>([]);

  useEffect(() => {
    categoryService.getAllBaseInformation().then(setCategories);
    typeService.getAllBaseInformation().then(setTypes);
  }, []);

  const { product, onSubmit } = params;
  return (
    <div>
      {product && (
        <ReactFinalForm onSubmit={onSubmit} edit={!!product.id}>
          <div className="flex flex-wrap">
            <div className="w-full p-2">
              <SectionTitle>{t('global.base-info')}</SectionTitle>
            </div>
            <div className="p-2 w-full md:w-1/4">
              <IdentityField
                name="code"
                label={t(`global.code`)}
                initialValue={product.code}
                component={TextFieldAdapter}
                placeholder={t('global.code')}
                type="text"
              />
            </div>
            <div className="p-2 w-full md:w-1/4">
              <IdentityField
                name="ref"
                placeholder={t(`${translationPrefix}.ref`)}
                label={t(`${translationPrefix}.ref`)}
                initialValue={product.ref}
                component={TextFieldAdapter}
                type="text"
              />
            </div>
            <div className="p-2 w-full md:w-1/2">
              <IdentityField
                name="description"
                label={t(`global.description`)}
                initialValue={product.description}
                component={TextFieldAdapter}
                placeholder={t('global.description')}
                type="text"
              />
            </div>
            <div className="p-2 w-full md:w-1/2">
              <IdentityField
                name="category"
                label={t(`global.category`)}
                initValue={product.category}
                component={AutocompleteAdapter}
                options={categories}
                placeholder="Category"
                type="text"
              />
            </div>
            <div className="p-2 w-full md:w-1/2">
              <IdentityField
                name="type"
                label={t(`global.type`)}
                initValue={product.type}
                component={AutocompleteAdapter}
                options={types}
                placeholder="Type"
                type="text"
              />
            </div>
            <div className="p-2 w-full md:w-1/3">
              <IdentityField
                name="manufacturer_name"
                label={t(`entities.product.manufacturer_name`)}
                initialValue={product.manufacturer_name}
                component={TextFieldAdapter}
              />
            </div>
            <div className="p-2 w-full md:w-1/3">
              <IdentityField
                name="ce_regulation"
                label={t(`entities.product.ce_regulation`)}
                initialValue={product.ce_regulation}
                component={TextFieldAdapter}
              />
            </div>
            <div className="p-2 w-full md:w-1/3">
              <IdentityField
                name="id_issuing_authority"
                label={t(`entities.product.id_issuing_authority`)}
                initialValue={product.id_issuing_authority}
                component={TextFieldAdapter}
              />
            </div>
            <div className="p-2 w-full md:w-4/12">
              <IdentityField
                name="ivdr"
                label={t(`entities.product.ivdr_class`)}
                component={TextFieldAdapter}
                initialValue={product.ivdr}
                type="text"
              />
            </div>
            <div className="p-2 w-full md:w-4/12">
              <IdentityField
                name="cnd_code"
                label={t(`entities.product.cnd_code`)}
                initialValue={product.cnd_code}
                component={TextFieldAdapter}
                type="text"
              />
            </div>
            <div className="p-2 w-full md:w-4/12">
              <IdentityField
                name="rdm_code"
                label={t(`entities.product.rdm_code`)}
                initialValue={product.rdm_code}
                component={TextFieldAdapter}
                type="text"
              />
            </div>
            <div className="p-2 w-full">
              <SectionTitle>{t('entities.product.packaging')}</SectionTitle>
            </div>
            <div className="p-2 w-full md:w-4/12">
              <IdentityField
                name="uom"
                label={t(`entities.product.uom`)}
                component={TextFieldAdapter}
                initialValue={product.uom}
                type="text"
              />
            </div>
            <div className="p-2 w-full md:w-4/12">
              <IdentityField
                name="packaging"
                label={t(`entities.product.packaging`)}
                initialValue={product.packaging}
                component={TextFieldAdapter}
                type="text"
              />
            </div>
            <div className="p-2 w-full md:w-4/12">
              <IdentityField
                name="units_per_package"
                label={t(`entities.product.units_per_package`)}
                initialValue={product.units_per_package}
                component={TextFieldAdapter}
                type="number"
              />
            </div>
            <div className="p-2 w-full">
              <SectionTitle>{t('global.thresholds')}</SectionTitle>
            </div>
            <div className="p-2 w-full md:w-1/2">
              <IdentityField
                name="expiration_alert_threshold"
                label={t(`entities.product.expiration_alert_threshold`)}
                initialValue={product.expiration_alert_threshold}
                component={TextFieldAdapter}
                type="number"
              />
            </div>
            <div className="p-2 w-full md:w-1/2">
              <IdentityField
                name="preservation_temperature"
                label={t(`entities.product.preservation_temperature`)}
                initialValue={product.preservation_temperature}
                component={TextFieldAdapter}
                type="number"
              />
            </div>
            <div className="p-2 w-full">
              <IdentityField
                name="notes"
                label={t(`global.notes`)}
                initialValue={product.notes}
                component={TextFieldAdapter}
              />
            </div>
            <div className="p-2 w-full">
              <IdentityField
                name="order_notes"
                label={t(`entities.product.order_notes`)}
                initialValue={product.order_notes}
                component={TextFieldAdapter}
              />
            </div>
          </div>
        </ReactFinalForm>
      )}
    </div>
  );
}

export default ProductForm;
