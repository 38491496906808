import { useAppSelector } from 'app/store';
import { Navigate } from 'react-router-dom';
import { SuperAdminRoles, TenantRoles } from './../constants';

export default function Base() {
  const groups = useAppSelector((state) => state.auth.groups);
  if (!groups) return <Navigate to="/login" />;
  try {
    if (TenantRoles.some((role) => groups.includes(role))) {
      return <Navigate to="/tenant" />;
    } else if (SuperAdminRoles.some((role) => groups.includes(role))) {
      return <Navigate to="/tenant" />;
    }
  } catch (e) {
    <Navigate to="/login" />;
  }
}
