import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import {
  equipmentService,
  examService,
  productService,
  sectorService,
  workstationService
} from 'services/api';
import { IdentityField } from 'components/form/IdentityField';
import { IOption, IStatusOption, IWorkstation, IWorkstationLink } from 'interfaces';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { useEffect, useState } from 'react';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { useTranslation } from 'react-i18next';
import { MultipleAutocompleteAdapter } from 'components/form/MultipleAutocompleteAdapter';
import {
  DataGridPremium,
  GridCellEditCommitParams,
  GridColumns,
  itIT,
  MuiEvent
} from '@mui/x-data-grid-premium';
import { WorkstationLinkColumns } from 'components/common/enhanced/common-headers/workstation-links';
import _ from 'lodash';
import SectionTitle from 'components/common/SectionTitle';
import { v4 as uuidv4 } from 'uuid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from '@mui/material';
import { required } from 'helpers/validators';

interface WorkstationFormParams {
  workstation: IWorkstation;
  onSubmit: any;
}

function WorkstationForm(params: WorkstationFormParams) {
  const translationPrefix = 'entities.workstation';
  const { t } = useTranslation();

  const { workstation, onSubmit } = params;

  const [sectors, setSectors] = useState<IOption[]>([]);
  const [products, setProducts] = useState<IOption[]>([]);
  const [equipments, setEquipments] = useState<IOption[]>([]);
  const [workstations, setWorkstations] = useState<IOption[]>([]);
  const [workstationLinks, setWorkstationLinks] = useState<IWorkstationLink[]>([]);
  const [phaseOptions, setPhaseOptions] = useState<IStatusOption[]>([]);
  const [exams, setExams] = useState<IOption[]>([]);

  useEffect(() => {
    productService.getAllBaseInformation().then(setProducts);
    equipmentService.getAllBaseInformation().then(setEquipments);
    sectorService.getAllBaseInformation().then(setSectors);
    workstationService.getAllBaseInformation().then(setWorkstations);
    examService.getAllBaseInformation().then(setExams);
    workstationService.options().then((res) => {
      setPhaseOptions([
        { value: null, display_name: '' },
        ...res.actions.POST.phase.choices.map((item) => {
          return {
            ...item,
            display_name: t(`${translationPrefix}.phase_options.${item.display_name}`)
          };
        })
      ]);
    });
  }, []);

  useEffect(() => {
    if (workstation?.workstations) setWorkstationLinks(workstation.workstations);
  }, [workstation]);

  const handleDelete = (line) => {
    setWorkstationLinks((prevState) =>
      _.filter(prevState, function (p) {
        return p.id !== line.id;
      })
    );
  };

  const handleEdit = (params: GridCellEditCommitParams, event: MuiEvent) => {
    setWorkstationLinks((prevState) =>
      prevState.map((p) => {
        if (p.id === params.id)
          return {
            ...p,
            [params.field]: params.value
          };
        return p;
      })
    );
  };

  const onAddLink = (values) => {
    const linkToAdd = {
      id: uuidv4(),
      workstation_origin: workstation.id,
      destination_code: workstations.find((ws) => ws.id == values.workstation_destination).code,
      workstation_destination: values.workstation_destination,
      weight: values.weight ?? 1
    };
    setWorkstationLinks((prevState) => [...prevState, linkToAdd]);
  };

  const columns: GridColumns = WorkstationLinkColumns(handleDelete);

  return (
    <div>
      {workstation && (
        <>
          <ReactFinalForm
            onSubmit={(values) => onSubmit({ ...values, workstations: workstationLinks })}
            edit={!!workstation.id}>
            <div className="flex flex-wrap">
              <div className="w-full mb-4">
                <IdentityField
                  initValue={workstation.sector}
                  name="sector"
                  component={AutocompleteAdapter}
                  options={sectors}
                  label="Sector"
                  type="text"
                  required
                />
              </div>
              <div className="w-1/2 pr-2">
                <IdentityField
                  name="code"
                  defaultValue={workstation.code}
                  component={TextFieldAdapter}
                  label={t('global.code')}
                  type="text"
                />
              </div>
              <div className="w-full md:w-1/2">
                <IdentityField
                  name="phase"
                  label={t(`${translationPrefix}.phase`)}
                  initValue={
                    workstation.phase
                      ? {
                          ...workstation.phase,
                          display_name: t(
                            `${translationPrefix}.phase_options.${workstation.phase.display_name}`
                          )
                        }
                      : {
                          value: null,
                          display_name: ''
                        }
                  }
                  defaultValue={workstation.phase ? workstation.phase.value : 1}
                  component={AutocompleteAdapter}
                  options={phaseOptions}
                  type="text"
                  optionLabel="display_name"
                  optionValue="value"
                />
              </div>
              <div className="w-full mt-4">
                <IdentityField
                  name="description"
                  defaultValue={workstation.description}
                  component={TextFieldAdapter}
                  label={t('global.description')}
                  type="text"
                />
              </div>
              <div className="mt-4 w-full">
                <IdentityField
                  name="products"
                  component={MultipleAutocompleteAdapter}
                  options={products}
                  initValue={workstation.products}
                  optionLabel={'description'}
                  label={t('global.products')}
                  type="text"
                />
              </div>
              <div className="mt-4 w-full">
                <IdentityField
                  name="equipments"
                  component={MultipleAutocompleteAdapter}
                  options={equipments}
                  initValue={workstation.equipments}
                  optionLabel={'description'}
                  label={t('global.equipments')}
                  type="text"
                />
              </div>
              <div className="mt-4 w-full">
                <IdentityField
                  name="exams"
                  component={MultipleAutocompleteAdapter}
                  options={exams}
                  initValue={workstation.exams}
                  optionLabel={'description'}
                  label={t('global.exams')}
                  type="text"
                />
              </div>
            </div>
          </ReactFinalForm>
          <div className="w-full p-2 mt-2">
            <SectionTitle>{t('global.non_productive_workstations')}</SectionTitle>
          </div>
          <Accordion className="bg-slate-50 mt-2">
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t('entities.workstation_link.add')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex flex-wrap">
                <div className="w-full p-2">
                  <ReactFinalForm onSubmit={onAddLink} formId="addWorkstationLink" hideControls>
                    <div className="flex flex-wrap mb-2 w-full">
                      <div className="w-3/4 mb-4 pr-4">
                        <IdentityField
                          name="workstation_destination"
                          component={AutocompleteAdapter}
                          options={workstations}
                          label={t('global.workstation')}
                          validate={required}
                          required
                        />
                      </div>
                      <div className="w-1/4 mb-4">
                        <IdentityField
                          name="weight"
                          label={t(`global.weight`)}
                          component={TextFieldAdapter}
                          type="number"
                        />
                      </div>
                      <div className="w-full mb-4 py-auto flex flex-wrap justify-end">
                        <Button
                          type="submit"
                          form="addWorkstationLink"
                          variant="outlined"
                          className="w-full">
                          {t('global.add')}
                        </Button>
                      </div>
                    </div>
                  </ReactFinalForm>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <div className="w-full mt-2">
            <DataGridPremium
              density="compact"
              rows={workstationLinks}
              localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
              sx={{ border: 'none' }}
              autoHeight
              disableSelectionOnClick
              columns={columns}
              onCellEditCommit={handleEdit}
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default WorkstationForm;
