import { costAnalysisService } from 'services/api';
import { DatePickerAdapter } from 'components/form/DatePickerAdapter';
import { IdentityField } from 'components/form/IdentityField';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactFinalForm from 'components/form/ReactFinalForm';
import TenantPage from 'components/common/TenantPage';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { Chart as ChartJS, ArcElement } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { schemeCategory10 } from 'd3-scale-chromatic';
import ExamCostRow from '../ExamCostRow';

ChartJS.register(ArcElement);

interface IMetaInfo {
  code: string;
  description: string;
}
interface ICostReportLayer {
  meta: IMetaInfo;
  tot: IMetaInfo;
}

export const CostReport2 = () => {
  const { t } = useTranslation();
  const translationPrefix = 'cost-analysis.dashboard';

  const [data, setData] = useState<any>();
  const [report, setReport] = useState<any>({});

  const performReport = (values) => {
    costAnalysisService.runCostAnalysis(values['date_start'], values['date_end']).then(setReport);
  };
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const loadGraph = (exams) => {
    const dataX = [...exams].reduce((prev, current, idx) => {
      if (!prev[current.category]) {
        prev[current.category] = current.cost;
      } else {
        prev[current.category] += current.cost;
      }
      return prev;
    }, {});

    const data = {
      labels: [],
      datasets: [
        {
          label: 'Cost per category',
          data: [],
          borderWidth: 1,
          borderColor: [],
          backgroundColor: []
        }
      ]
    };
    Object.entries(dataX).forEach((entry, idx) => {
      const color = schemeCategory10[idx % schemeCategory10.length];
      data.labels.push(entry[0]);
      data.datasets[0].data.push(entry[1]);
      data.datasets[0].borderColor.push(schemeCategory10[idx % schemeCategory10.length]);
      data.datasets[0].backgroundColor.push(color + 80);
    });

    setData(data);
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      menuRight={
        <div>
          <ReactFinalForm onSubmit={performReport} hideControls={true}>
            <div className="flex justify-end items-center">
              <div className="mr-4">
                <IdentityField
                  name="date_start"
                  component={DatePickerAdapter}
                  label="From date"
                  type="date"
                />
              </div>
              <div className="mr-4">
                <IdentityField
                  name="date_end"
                  component={DatePickerAdapter}
                  label="To date"
                  type="date"
                />
              </div>
              <div>
                <Button variant="outlined" type="submit">
                  Run
                </Button>
              </div>
            </div>
          </ReactFinalForm>
        </div>
      }>
      <div className="w-full flex flex-wrap">
        <div className="lg:w-1/2 md:w-2/3 w-full">
          {Object.entries(report).map((layer, idx) => {
            // LAB
            const [k, lab] = [layer[0] as string, layer[1] as ICostReportLayer];

            return (
              <div key={idx}>
                <h3 className="text-left mb-4">{`${lab.meta.description}: Tot ${lab.tot} €`}</h3>
                <div>
                  {Object.entries(lab)
                    .filter((kv) => kv[0] !== 'meta' && kv[0] !== 'tot')
                    .map((layer, idx) => {
                      // Sector
                      const [k, sector] = [layer[0] as string, layer[1] as ICostReportLayer];

                      return (
                        <Accordion key={idx} onChange={handleChange('panel1')}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header">
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                              {`${sector.meta.description}`}
                            </Typography>
                            <Typography sx={{ color: 'text.secondary' }}>
                              {`Tot ${sector.tot} €`}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {Object.entries(sector)
                              .filter((kv) => kv[0] !== 'meta' && kv[0] !== 'tot')
                              .map((layer, idx) => {
                                // Sector
                                const [k, workstation] = [
                                  layer[0] as string,
                                  layer[1] as ICostReportLayer
                                ];

                                return (
                                  <Accordion key={idx} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1bh-content"
                                      id="panel1bh-header">
                                      <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                        {`${workstation.meta.description}`}
                                      </Typography>
                                      <Typography sx={{ color: 'text.secondary' }}>
                                        {`Tot ${workstation.tot} €`}
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      {Object.entries(workstation)
                                        .filter((kv) => kv[0] !== 'meta' && kv[0] !== 'tot')
                                        .map((layer, idx) => {
                                          // Exam
                                          const [k, exam] = [layer[0] as string, layer[1]];

                                          return <ExamCostRow key={idx} exam={exam} />;
                                        })}
                                      <Button
                                        variant="outlined"
                                        onClick={() =>
                                          loadGraph(
                                            Object.entries(workstation)
                                              .filter((kv) => kv[0] !== 'meta' && kv[0] !== 'tot')
                                              .map((layer) => layer[1])
                                          )
                                        }>
                                        graph
                                      </Button>
                                    </AccordionDetails>
                                  </Accordion>
                                );
                              })}
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                </div>
              </div>
            );
          })}
        </div>
        <div className="lg:w-1/2 md:w-1/3 w-full">{data && <Doughnut data={data} />}</div>
      </div>
    </TenantPage>
  );
};

export default CostReport2;
