import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { orderLineService } from 'services/api';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import WidgetSquarePlaceholder from './WidgetSquarePlaceholder';

function DeliveryExpectedWidget() {
  const { t } = useTranslation();

  useEffect(() => {
    orderLineService
      .getAllPaginated(1, 0, {
        requested_shipment_date__lt: dayjs().format('YYYY-MM-DD'),
        status: 'PENDING'
      })
      .then((res) => setCounter(res.count));
  }, []);
  const [counter, setCounter] = useState<number>(undefined);
  return counter !== undefined ? (
    <div className="w-full flex flex-col justify-center items-center px-1">
      {counter > 0 && (
        <>
          <EventBusyIcon fontSize="large" color="error" className="mx-0" />
          <span className="text-center">{t(`dashboard.expected-delivery.body-error`)}</span>
          <NavLink to={'/tenant/orders/delivery?blameMode=true'}>
            <Button color="error">{t(`dashboard.expected-delivery.go`)}</Button>
          </NavLink>
        </>
      )}
      {counter === 0 && (
        <>
          <EventAvailableIcon fontSize="large" color="success" className="mx-0" />
          <span className="text-center">{t(`dashboard.expected-delivery.body-success`)}</span>
        </>
      )}
    </div>
  ) : (
    <WidgetSquarePlaceholder />
  );
}

export default DeliveryExpectedWidget;
