import { TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useField } from 'react-final-form';

export const DatePickerAdapter = ({
  input: { name, value },
  disableOpenPicker,
  disabled,
  noFuture,
  setValue,
  ...rest
}: any) => {
  const { input, meta } = useField(name);

  const onChange = (value) => {
    if (value) {
      input.onChange(value.format('YYYY-MM-DD'));
      if (setValue) setValue(value);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        onChange={onChange}
        value={value}
        disableFuture={noFuture ? true : false}
        inputFormat="DD/MM/YYYY"
        disableOpenPicker={disableOpenPicker ?? false}
        disabled={disabled ?? false}
        renderInput={(params) => (
          <TextField
            {...params}
            {...rest}
            helperText={meta.touched ? meta.error : ''}
            error={meta.error && meta.touched}
            fullWidth
          />
        )}
      />
    </LocalizationProvider>
  );
};
