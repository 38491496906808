import { useState, useEffect, useRef } from 'react';
import { IPurchaseOrder, IStatusOption } from 'interfaces';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { orderService } from 'services/api';
import CollapsableColumns from 'components/common/CollapsableColumns';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ReplayIcon from '@mui/icons-material/Replay';
import { OrderDetail } from 'components/inventory/PurchaseOrders';
import {
  GridActionsCellItem,
  GridCellParams,
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
  GridValueFormatterParams
} from '@mui/x-data-grid-premium';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import { Button, ButtonGroup } from '@mui/material';
import { loadingActions } from 'features';
import { useAppDispatch, useAppSelector } from 'app/store';
import { currencyCell } from 'components/common/enhanced/cells/currency-cell';
import dayjs from 'dayjs';
import { dateFilterOperators, filterOperators } from '../../../../constants';
import { currencyFormatter } from 'helpers/currency-formatter';
import { IsSuperAdmin } from 'components/helpers/IsSuperAdmin';
import ImportFileUploader from 'components/common/file-uploader/ImportFileUploader';
import toast from 'features/toast';
import { PurgeButton } from 'components/helpers/PurgeButton';
import { baseCell } from 'components/common/enhanced/cells/base-cell';

export const PurchaseOrderList = () => {
  const translationPrefix = 'pages.orders.list';
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const refresh = useRef(null);

  const sap_active = useAppSelector((state) => state.auth.sap_active);
  const navigate = useNavigate();

  const [selectedOrder, setSelectedOrder] = useState<IPurchaseOrder>();
  const [statusOptions, setStatusOptions] = useState<IStatusOption[]>([]);

  const editOrder = (reqId) => {
    navigate(`../orders/view/${reqId}`);
  };

  useEffect(() => {
    dispatch(loadingActions.startLoading());
    orderService.options().then((res) => {
      setStatusOptions(res.actions.POST.status.choices);
    });
    dispatch(loadingActions.stopLoading());
  }, []);

  const downloadAttachment = (order) => {
    if (order?.id) {
      orderService.downloadAttachment(order.id);
    }
  };

  const regenerateDocument = (order) => {
    if (order?.id) {
      orderService.regeneratePDF(order.id).then(() => {
        toast.success('PDF rigenerato.');
      });
    }
  };

  const strikedThroughRenderCell = (params: GridCellParams) =>
    params.row.status === 'CANCELED' && 'line-through';

  const statusColor = (statusString: string) => {
    switch (statusString) {
      case 'ACTIVE':
        return 'text-orange-500';
      case 'COMPLETED':
        return 'text-green-500';
      default:
        return 'text-gray-500';
    }
  };

  const columns: GridColumns = [
    {
      field: 'ref',
      headerName: 'Ordine',
      cellClassName: strikedThroughRenderCell,
      flex: 1
    }
  ];

  if (sap_active) {
    columns.push({
      field: 'sap_id',
      headerName: t(`global.sap_id`),
      filterOperators,
      width: 100,
      renderCell: baseCell
    });
  }

  columns.push(
    {
      field: 'created_at',
      headerName: t(`entities.movement.timestamp`),
      filterOperators: dateFilterOperators,
      cellClassName: strikedThroughRenderCell,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div>
            <span>{dayjs(params.value).format('DD/MM/YYYY')}</span>
          </div>
        );
      }
    },
    {
      field: 'status',
      headerName: t(`global.status`),
      type: 'singleSelect',
      valueOptions: statusOptions.map((type) => type.display_name),
      width: 100,
      cellClassName: strikedThroughRenderCell,
      renderCell: (params: GridRenderCellParams) => {
        const value = params.value;
        return (
          <div>
            <span className={statusColor(value)}>{t(`entities.order_head.${value}`)}</span>
          </div>
        );
      }
    },
    {
      field: 'supplier',
      headerName: t(`entities.order.supplier`),
      flex: 1,
      cellClassName: strikedThroughRenderCell
    },
    {
      field: 'warehouse',
      headerName: t(`entities.order.warehouse`),
      flex: 1,
      cellClassName: strikedThroughRenderCell
    },
    {
      field: 'total_amount',
      headerName: t(`entities.order.total_amount`),
      width: 100,
      cellClassName: (params: GridCellParams) => params.row.status === 3 && 'line-through',
      valueFormatter: (params: GridValueFormatterParams) => {
        return currencyFormatter(params.value, { significantDigits: 3 });
      },
      renderCell: (params: GridRenderCellParams) => currencyCell(params, 3)
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t(`global.actions`),
      getActions: (params: GridRowParams) => {
        const actions = [];
        actions.push(
          <GridActionsCellItem
            key={1}
            icon={params.row.status !== 'ACTIVE' ? <RemoveRedEyeIcon /> : <EditIcon />}
            onClick={() => editOrder(params.row.id)}
            label="Edit"
          />
        );
        if (params.row.attachment) {
          actions.push(
            <IsTenantAdministrator key={2}>
              <GridActionsCellItem
                icon={<CloudDownloadIcon />}
                onClick={() => downloadAttachment(params.row)}
                label="View"
              />
            </IsTenantAdministrator>
          );
        }
        actions.push(
          <IsTenantAdministrator key={3}>
            <GridActionsCellItem
              icon={<ReplayIcon />}
              onClick={() => regenerateDocument(params.row)}
              label="Regenerate"
            />
          </IsTenantAdministrator>
        );
        return actions;
      }
    }
  );

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <ButtonGroup size="small">
            <NavLink to="delivery?blameMode=true">
              <Button>{t(`${translationPrefix}.delivery`)}</Button>
            </NavLink>
            <IsSuperAdmin>
              <ImportFileUploader
                service={orderService}
                importMethod={orderService.importAggregatedLinesFromFile}
                templateGetterMethod={orderService.getAggregatedImportTemplate}
              />
            </IsSuperAdmin>
            <PurgeButton service={orderService} refresh={refresh} />
          </ButtonGroup>
        }>
        <CollapsableColumns
          size="w-1/2"
          expand={!!selectedOrder}
          onClose={() => setSelectedOrder(null)}
          contentLeft={
            <EnhancedDataGrid columns={columns} service={orderService} refresh={refresh} />
          }
          contentRight={
            <div className="mt-6">{selectedOrder && <OrderDetail order={selectedOrder} />}</div>
          }
        />
      </TenantPage>
    </>
  );
};

export default PurchaseOrderList;
