import { INotification } from 'interfaces';
import { useEffect, useState } from 'react';
import { notificationService, orderLineService } from 'services/api';
import { Alert, Button, Skeleton, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'app/store';
import dayjs from 'dayjs';

export function DeliveriesWidget() {
  const { t } = useTranslation();
  const MAX_CHARACTERS = 150;
  const [orderLines, setOrderLines] = useState<any[]>();

  const defaultWarehouse = useAppSelector((state) => state.auth.warehouse);
  const groups = useAppSelector((state) => state.auth.groups);

  useEffect(() => {
    const params = {
      status: 'PENDING'
      // requested_shipment_date__lte: dayjs().add(1, 'month').endOf('month').format('YYYY-MM-DD')
    };
    if (defaultWarehouse) {
      params['warehouse'] = defaultWarehouse;
    }
    orderLineService.getAllPaginated(5, 0, params).then((res) => {
      setOrderLines(res.results);
    });
  }, [defaultWarehouse]);

  return (
    <div>
      <Typography sx={{ fontSize: 14 }} color="secondary" gutterBottom>
        <MarkunreadMailboxIcon />
        {t('dashboard.deliveries.title')}
      </Typography>
      {!orderLines && (
        <>
          <Skeleton variant="rectangular" height={32} />
          <br />
          <Skeleton variant="rectangular" height={32} />
          <br />
          <Skeleton variant="rectangular" height={32} />
        </>
      )}
      {orderLines &&
        orderLines.map((n, idx) => (
          <Alert key={n.id} className="mt-2" severity="warning">
            <p className="truncate">{`${n.remaining_quantity}x ${n.product.code} (${n.product.description}). Codice ordine ${n.ref}`}</p>
          </Alert>
        ))}
      <div className="flex justify-center mt-2">
        <NavLink to="/tenant/orders/delivery?blameMode=true">
          <Button variant="outlined" size="small">
            {t('global.view-all')}
          </Button>
        </NavLink>
      </div>
    </div>
  );
}

export default DeliveriesWidget;
