import { useEffect, useState } from 'react';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { IStatusOption } from 'interfaces';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { maintenanceService, ticketingService } from 'services/api';
import { DateTimePickerAdapter } from 'components/form/DateTimePickerAdapter';
import dayjs from 'dayjs';
import { DatePickerAdapter } from 'components/form/DatePickerAdapter';

interface CloseMaintenanceFormParams {
  onSubmit: any;
}
function CloseMaintenanceForm(params: CloseMaintenanceFormParams) {
  const { t } = useTranslation();

  const [statusOptions, setStatusOptions] = useState<IStatusOption[]>([]);

  useEffect(() => {
    maintenanceService.options().then((res) => {
      setStatusOptions(
        res.actions.POST.status.choices
          .filter((item) => item.value === 2 || item.value === 3)
          .map((item) => {
            return {
              ...item,
              display_name: t(`pages.tickets.status_options.${item.display_name}`)
            };
          })
      );
    });
  }, []);

  const { onSubmit } = params;
  return (
    <div>
      <ReactFinalForm onSubmit={onSubmit} formId="closeMaintenance" hideControls>
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 p-2">
            <IdentityField
              name="execution_date"
              component={DatePickerAdapter}
              label={t(`global.execution_date`)}
              initialValue={dayjs().format('YYYY-MM-DD')}
              type="text"
              required
            />
          </div>
          <div className="p-2 w-full md:w-1/2">
            <IdentityField
              name="status"
              label={t(`global.status`)}
              component={AutocompleteAdapter}
              options={statusOptions}
              type="text"
              optionLabel="display_name"
              optionValue="value"
              required
            />
          </div>
        </div>
      </ReactFinalForm>
    </div>
  );
}

export default CloseMaintenanceForm;
