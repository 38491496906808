import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { filterOperators } from './../../../../constants';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { baseCell } from '../cells/base-cell';
import { booleanCell } from '../cells/boolean-cell';
import { currencyCell } from '../cells/currency-cell';

export function CostElementsColumns(isPeriodical?: boolean): GridColDef[] {
  const { t } = useTranslation();
  const translationPrefix = 'entities.cost_element';
  const columns = [
    {
      field: 'code',
      width: 180,
      headerName: t(`global.code`),
      renderCell: (params: GridRenderCellParams) => {
        const category = params.row;
        return (
          <div>
            <Link to={`/tenant/cost-analysis/costs/${category.id}?isPeriodical=${isPeriodical}`}>
              <b className="text-secondary-500">{category.code}</b>
            </Link>
          </div>
        );
      }
    },
    {
      field: 'description',
      width: 250,
      headerName: t(`global.description`),
      renderCell: baseCell
    },
    {
      field: 'cost',
      width: 150,
      headerName: t(`${translationPrefix}.cost`),
      renderCell: currencyCell
    },
    {
      field: 'active',
      width: 100,
      headerName: t(`${translationPrefix}.active`),
      renderCell: booleanCell(t)
    },
    {
      field: 'content_type',
      width: 180,
      headerName: t(`${translationPrefix}.content_type`),
      renderCell: (params: GridRenderCellParams) => {
        return (
          params.value && (
            <div>
              <b className="text-secondary-500">{t(`global.${params.value}`)}</b>
            </div>
          )
        );
      }
    },
    {
      field: 'object_id',
      headerName: t(`${translationPrefix}.object_id`),
      filterOperators,
      width: 150,
      renderCell: baseCell
    }
  ];

  if (isPeriodical) {
    columns.push(
      ...[
        {
          field: 'date_start',
          headerName: t(`global.date_start`),
          filterOperators,
          width: 150,
          renderCell: baseCell
        },
        {
          field: 'date_end',
          headerName: t(`global.date_end`),
          filterOperators,
          width: 150,
          renderCell: baseCell
        }
      ]
    );
  } else {
    columns.push(
      ...[
        {
          field: 'date',
          headerName: t(`global.date`),
          filterOperators,
          width: 150,
          renderCell: baseCell
        }
      ]
    );
  }

  return columns;
}
