import { requestDepartmentLaboratoryService } from 'services/api';
import TenantPage from 'components/common/TenantPage';
import { useTranslation } from 'react-i18next';
import RequestDepartmentTranscodeForm from '../RequestDepartmentTranscodeForm';
import toast from 'features/toast';

const initTranscode = {
  id: null,
  laboratory: null,
  department: null
};

export const CreateRequestDepartmentTranscode = () => {
  const translationPrefix = 'pages.request-dep-lab.new';

  const { t } = useTranslation();

  const createTranscode = async (values) => {
    requestDepartmentLaboratoryService.add(values).then((res) => {
      if (res) toast.success('Transcodifica aggiunta con successo.');
    });
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <RequestDepartmentTranscodeForm transcode={initTranscode} onSubmit={createTranscode} />
    </TenantPage>
  );
};

export default CreateRequestDepartmentTranscode;
