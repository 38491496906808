import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  equipmentService,
  examService,
  laboratoryService,
  operationalUnitService,
  sectorService,
  workstationService
} from 'services/api';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { IdentityField } from 'components/form/IdentityField';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { useTranslation } from 'react-i18next';
import toast from 'features/toast';
import { useEffect, useState } from 'react';
import { activityService } from 'services/api/ActivityService';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import SectionTitle from 'components/common/SectionTitle';
import { DatePickerAdapter } from 'components/form/DatePickerAdapter';
import { roleNameService } from 'services/api/RoleNameService';

export default function AddRoleNameDialog(props: {
  open: boolean;
  setOpen: (value) => any;
  callback?: any;
}) {
  const { t } = useTranslation();

  const { open, setOpen, callback } = props;

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (values) => {
    roleNameService.add({ ...values }).then(() => {
      toast.success(t('pages.role-names.list.add-dialog.success'));
      handleClose();
      if (callback) callback();
    });
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle>{t('pages.role-names.list.add-dialog.title')}</DialogTitle>
      <DialogContent>
        <ReactFinalForm onSubmit={onSubmit} formId="addCostLink" hideControls>
          <div className="flex flex-wrap my-4">
            <SectionTitle>{t('pages.role-names.list.add-dialog.description-area')}</SectionTitle>
            <div className="w-full p-0 mt-4">
              <IdentityField
                name="code"
                component={TextFieldAdapter}
                label={t('global.code')}
                type="text"
              />
            </div>
          </div>
          <SectionTitle>{t('pages.role-names.list.add-dialog.cost-area')}</SectionTitle>
          <div className="flex flex-wrap my-4">
            <div className="w-1/3 p-0 ">
              <IdentityField
                name="fte"
                component={TextFieldAdapter}
                label={t('entities.role_name.fte')}
                type="number"
              />
            </div>
            <div className="w-1/3 p-0 px-2">
              <IdentityField
                name="monthly_hours"
                component={TextFieldAdapter}
                label={t('entities.role_name.monthly_hours')}
                type="number"
              />
            </div>
            <div className="w-1/3 p-0 ">
              <IdentityField
                name="monthly_cost"
                component={TextFieldAdapter}
                label={t('entities.role_name.monthly_cost')}
                type="number"
              />
            </div>
          </div>
        </ReactFinalForm>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('actions.cancel')}</Button>
        <Button type="submit" form="addCostLink">
          {t('global.add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
