import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';

interface DateTimeTextFieldProps {
  label: string;
  value: string;
  time?: boolean;
}

export function DateTimeTextField(props: DateTimeTextFieldProps) {
  const { label, value, time } = props;
  return (
    <>
      <InputLabel htmlFor="input-with-icon-adornment">{label}</InputLabel>
      <Input
        id="input-with-icon-adornment"
        startAdornment={
          <InputAdornment position="start">
            {time ? <ScheduleRoundedIcon /> : <CalendarMonthRoundedIcon />}
          </InputAdornment>
        }
        value={value}
        contentEditable={false}
      />
    </>
  );
}
