import { IStockQuantity, IMovementBody } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class StockQuantityService extends BaseService<IStockQuantity> {
  constructor() {
    super('stock-quantities');
  }

  getTotalsByProduct = async (limit = 0, offset = 0, params: Record<string, unknown> = {}) => {
    const res = await axiosPrivate.get(`${this.modulePrefix}/total_by_product/`, {
      params: { limit, offset, ...params }
    });
    return res.data;
  };

  getTotalsByProductAtDate = async (
    limit = 0,
    offset = 0,
    params: Record<string, unknown> = {}
  ) => {
    const res = await axiosPrivate.get(`${this.modulePrefix}/total_by_product_at_date/`, {
      params: { limit, offset, ...params }
    });
    return res.data;
  };

  getUnloadStocks = async (limit = 0, offset = 0, params: Record<string, unknown> = {}) => {
    const res = await axiosPrivate.get(`${this.modulePrefix}/unload_stocks/`, {
      params: { limit, offset, ...params }
    });
    return res.data;
  };

  getUnderstockProducts = async (limit = 0, offset = 0, params: Record<string, unknown> = {}) => {
    const res = await axiosPrivate.get(`${this.modulePrefix}/understock-products/`, {
      params: { limit, offset, ...params }
    });
    return res.data;
  };

  checkBatches = async (body: IMovementBody) => {
    const res = await axiosPrivate.get(`${this.modulePrefix}/expiring_batches/`, {
      params: { ...body }
    });
    return res.data;
  };
}
export const stockQuantityService = new StockQuantityService();
