import { Button, ButtonGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import TenantPage from 'components/common/TenantPage';
import { supplierService } from 'services/api';
import { GridColDef } from '@mui/x-data-grid-premium';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { SuppliersColumns } from 'components/common/enhanced/common-headers';
import { useAppSelector } from 'app/store';
import { NotOperatorRoles } from '../../../../constants';

export const Suppliers = () => {
  const { t } = useTranslation();
  const translationPrefix = 'pages.supplier.list';
  const sap_active = useAppSelector((state) => state.auth.sap_active);
  const groups = useAppSelector((state) => state.auth.groups);

  const columns: GridColDef[] = SuppliersColumns(
    sap_active,
    NotOperatorRoles.some((role) => groups.includes(role))
  );

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <div>
            <ButtonGroup>
              <IsTenantAdministrator>
                <Link to="new">
                  <Button color="primary" size="small">
                    <AddIcon />
                    {t(`${translationPrefix}.add`)}
                  </Button>
                </Link>
              </IsTenantAdministrator>
            </ButtonGroup>
          </div>
        }>
        <div className="mt-6">
          <EnhancedDataGrid columns={columns} service={supplierService} />
        </div>
      </TenantPage>
    </>
  );
};

export default Suppliers;
