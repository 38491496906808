import { equipmentService } from 'services/api';
import TenantPage from 'components/common/TenantPage';
import { useTranslation } from 'react-i18next';
import { IEquipment } from 'interfaces';
import { useNavigate } from 'react-router-dom';
import toast from 'features/toast';
import EquipmentForm from '../EquipmentForm';

const initEquipment: IEquipment = {
  id: null,
  code: '',
  description: '',
  workstation: null,
  status: null
};

export const CreateEquipment = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const translationPrefix = 'pages.equipment.new';

  const onSubmit = async (values) => {
    equipmentService
      .add({
        ...values,
        market_value: values['market_value'] && values['market_value'].replace(',', '.')
      })
      .then((res) => {
        if (res?.id) {
          toast.success('Strumento creato con successo');
          navigate(`../${res.id}`);
        }
      });
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <EquipmentForm equipment={initEquipment} onSubmit={onSubmit} />
    </TenantPage>
  );
};

export default CreateEquipment;
