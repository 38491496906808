import { IEquipment } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class EquipmentService extends BaseService<IEquipment> {
  constructor() {
    super('equipments');
  }
  canDelete = true;
  canBulkDelete = true;

  importFromFile = async (importFile: File, attachmentName = 'import_file') => {
    const res = await axiosPrivate.postForm(`${this.modulePrefix}/import-from-file/`, {
      [attachmentName]: importFile
    });
    return res.data;
  };

  addCostLink = async (equipmentId: number, values: Record<string, unknown>) => {
    const res = await axiosPrivate.post(
      `${this.modulePrefix}/${equipmentId}/add-cost-link/`,
      values
    );
    return res.data;
  };

  removeCostLink = async (equipmentId: number, values: Record<string, unknown>) => {
    const res = await axiosPrivate.post(
      `${this.modulePrefix}/${equipmentId}/remove-cost-link/`,
      values
    );
    return res.data;
  };

  purgeLinks = async (equipmentId: number) => {
    const res = await axiosPrivate.post(`${this.modulePrefix}/${equipmentId}/purge-links/`);
    return res.data;
  };

  purgeAllLinks = async () => {
    const res = await axiosPrivate.post(`${this.modulePrefix}/purge-all-links/`);
    return res.data;
  };
}

export const equipmentService = new EquipmentService();
