import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import DataGridCell from 'components/common/DataGridCell';

export const booleanCell = (t: any) => {
  const renderCell = (params: GridRenderCellParams) => {
    const value = params.value;

    return (
      <div>
        <DataGridCell content={value ? t('global.yes') : value === false ? 'No' : ''} />
      </div>
    );
  };
  return renderCell;
};
