import { Button, ButtonGroup } from '@mui/material';
import { categoryService } from 'services/api';
import { GridColDef } from '@mui/x-data-grid-premium';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import TenantPage from 'components/common/TenantPage';
import { ActivitiesColumns } from 'components/common/enhanced/common-headers/activities';
import { activityService } from 'services/api/ActivityService';

function ActivitiyList() {
  const translationPrefix = 'pages.activities.list';
  const { t } = useTranslation();

  const columns: GridColDef[] = ActivitiesColumns(translationPrefix);

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <div className="mt-6">
        <EnhancedDataGrid columns={columns} service={activityService} />
      </div>
    </TenantPage>
  );
}

export default ActivitiyList;
