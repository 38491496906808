import { useAppSelector } from 'app/store';
import { useEffect, useState } from 'react';

const primaryColorLab = {
  'color-50': '#29456c',
  'color-100': '#29456c',
  'color-200': '#29456c',
  'color-300': '#29456c',
  'color-400': '#29456c',
  'color-500': '#29456c',
  'color-600': '#29456c',
  'color-700': '#29456c',
  'color-800': '#29456c',
  'color-900': '#29456c'
};
const secondaryColorLab = {
  'color-50': '#2578b0',
  'color-100': '#2578b0',
  'color-200': '#2578b0',
  'color-300': '#2578b0',
  'color-400': '#2578b0',
  'color-500': '#2578b0',
  'color-600': '#2578b0',
  'color-700': '#2578b0',
  'color-800': '#2578b0',
  'color-900': '#2578b0'
};

const primaryColorReferting = {
  'color-50': '#0791e7',
  'color-100': '#0791e7',
  'color-200': '#0791e7',
  'color-300': '#0791e7',
  'color-400': '#0791e7',
  'color-500': '#0791e7',
  'color-600': '#0791e7',
  'color-700': '#0791e7',
  'color-800': '#0791e7',
  'color-900': '#0791e7'
};
const secondaryColorReferting = {
  'color-50': '#2578b0',
  'color-100': '#2578b0',
  'color-200': '#2578b0',
  'color-300': '#2578b0',
  'color-400': '#2578b0',
  'color-500': '#2578b0',
  'color-600': '#2578b0',
  'color-700': '#2578b0',
  'color-800': '#2578b0',
  'color-900': '#2578b0'
};

const primaryColorEvaluate = {
  'color-50': '#0163ae',
  'color-100': '#0163ae',
  'color-200': '#0163ae',
  'color-300': '#0163ae',
  'color-400': '#0163ae',
  'color-500': '#0163ae',
  'color-600': '#0163ae',
  'color-700': '#0163ae',
  'color-800': '#0163ae',
  'color-900': '#0163ae'
};
const secondaryColorEvaluate = {
  'color-50': '#2578b0',
  'color-100': '#2578b0',
  'color-200': '#2578b0',
  'color-300': '#2578b0',
  'color-400': '#2578b0',
  'color-500': '#2578b0',
  'color-600': '#2578b0',
  'color-700': '#2578b0',
  'color-800': '#2578b0',
  'color-900': '#2578b0'
};
const primaryColorSettings = {
  'color-50': '#64b4fe',
  'color-100': '#64b4fe',
  'color-200': '#64b4fe',
  'color-300': '#64b4fe',
  'color-400': '#64b4fe',
  'color-500': '#64b4fe',
  'color-600': '#64b4fe',
  'color-700': '#64b4fe',
  'color-800': '#64b4fe',
  'color-900': '#64b4fe'
};
const secondaryColorSettings = {
  'color-50': '#2578b0',
  'color-100': '#2578b0',
  'color-200': '#2578b0',
  'color-300': '#2578b0',
  'color-400': '#2578b0',
  'color-500': '#2578b0',
  'color-600': '#2578b0',
  'color-700': '#2578b0',
  'color-800': '#2578b0',
  'color-900': '#2578b0'
};

function StyleProvider() {
  const context = useAppSelector((state) => state.contextData.context);

  const [primary, setPrimaryColor] = useState(primaryColorLab);
  const [secondary, setSecondaryColor] = useState(secondaryColorLab);

  useEffect(() => {
    if (context === 'EVALUATE') {
      setPrimaryColor(primaryColorEvaluate);
      setSecondaryColor(secondaryColorEvaluate);
    } else if (context === 'REFERTING') {
      setPrimaryColor(primaryColorReferting);
      setSecondaryColor(secondaryColorReferting);
    } else if (context === 'SETTINGS') {
      setPrimaryColor(primaryColorSettings);
      setSecondaryColor(secondaryColorSettings);
    } else {
      setPrimaryColor(primaryColorLab);
      setSecondaryColor(secondaryColorLab);
    }
  }, [context]);

  return (
    <style>
      {`:root {
            --primary-color: yellow;
            --secondary-color: blue;
            --primary-color-50: ${primary['color-50']};
            --primary-color-100: ${primary['color-100']};
            --primary-color-200: ${primary['color-200']};
            --primary-color-300: ${primary['color-300']};
            --primary-color-400: ${primary['color-400']};
            --primary-color-500: ${primary['color-500']};
            --primary-color-600: ${primary['color-600']};
            --primary-color-700: ${primary['color-700']};
            --primary-color-800: ${primary['color-800']};
            --primary-color-900: ${primary['color-900']};
            --secondary-color-50: ${secondary['color-50']};
            --secondary-color-100: ${secondary['color-50']};
            --secondary-color-200: ${secondary['color-50']};
            --secondary-color-300: ${secondary['color-50']};
            --secondary-color-400: ${secondary['color-50']};
            --secondary-color-500: ${secondary['color-50']};
            --secondary-color-600: ${secondary['color-50']};
            --secondary-color-700: ${secondary['color-50']};
            --secondary-color-800: ${secondary['color-50']};
            --secondary-color-900: ${secondary['color-50']};
        }`}
    </style>
  );
}

export default StyleProvider;
