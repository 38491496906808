import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { filterOperators, numberFilterOperators } from './../../../../constants';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { baseCell } from '../cells/base-cell';
import { currencyCell } from '../cells/currency-cell';
import DataGridCell from 'components/common/DataGridCell';

export function ExpertisesColumns(): GridColDef[] {
  const { t } = useTranslation();
  return [
    {
      field: 'name',
      headerName: t(`entities.expertise.name`),
      width: 250,
      editable: true,
      filterOperators,
      renderCell: (params: GridRenderCellParams) => {
        const expertise = params.row;
        return (
          <div>
            <Link to={`${expertise.id}`}>
              <b className="text-secondary-500">{params.value}</b>
            </Link>
          </div>
        );
      }
    },
    {
      field: 'role',
      headerName: t(`entities.expertise.role`),
      filterOperators,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'role_name',
      headerName: t(`entities.expertise.role_name`),
      filterOperators,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'fte',
      headerName: t(`entities.expertise.fte`),
      editable: true,
      filterOperators: numberFilterOperators,
      type: 'number',
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'monthly_hours',
      headerName: t(`entities.expertise.monthly_hours`),
      filterable: false,
      sortable: false,
      type: 'number',
      width: 150,
      renderCell: (params) => (
        <DataGridCell content={((+params.value).toFixed(2) as string).replace('.', ',')} />
      )
    },
    {
      field: 'monthly_cost',
      headerName: t(`entities.expertise.monthly_cost`),
      filterable: false,
      sortable: false,
      type: 'number',
      width: 150,
      renderCell: currencyCell
    }
  ];
}
