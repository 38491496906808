import toast from 'features/toast';
import { ISupplier } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { ExportableResourceService } from './ExportableResourceService';

class SupplierService extends ExportableResourceService<ISupplier> {
  constructor() {
    super('suppliers');
  }

  public async getAllBaseInformation(params: Record<string, unknown> = {}) {
    try {
      const res = await axiosPrivate.get(`${this.modulePrefix}-summary/`, { params });
      return res.data.map((entity) => ({
        ...entity,
        description: `${entity.code} - ${entity.company_name}`
      }));
    } catch (e) {
      toast.error(e.message);
    }
  }

  importFromFile = async (importFile: File, attachmentName = 'import_file') => {
    const res = await axiosPrivate.postForm(`${this.modulePrefix}/import-from-file/`, {
      [attachmentName]: importFile
    });
    return res.data;
  };

  canDelete = true;
}

export const supplierService = new SupplierService();
