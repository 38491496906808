import React from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

interface ExpertiseChartProps {
  data: any;
}

function ExpertiseChart({ data }: ExpertiseChartProps) {
  return <Doughnut redraw={true} data={data} />;
}

export default ExpertiseChart;
