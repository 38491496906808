import { inventoryCheckService } from 'services/api';
import TenantPage from 'components/common/TenantPage';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IInventoryCheck } from 'interfaces';
import { useEffect, useState } from 'react';
import InventoryCheckForm from '../InventoryCheckForm';
import toast from 'features/toast';

export const EditInventory = () => {
  const translationPrefix = 'pages.inventory-check.edit';
  const { t } = useTranslation();

  const { inventoryId } = useParams();
  const [inventory, setInventory] = useState<IInventoryCheck>();

  useEffect(() => {
    inventoryCheckService.get(inventoryId).then(setInventory);
  }, []);

  const updateInventory = async (values) => {
    inventoryCheckService.update(inventory.id, values).then((res) => {
      toast.success('Inventario aggiornato con successo');
      inventoryCheckService.get(inventoryId).then(setInventory);
    });
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      entityCode="">
      <div className="flex flex-wrap">
        <div className="w-full p-4">
          <InventoryCheckForm inventory={inventory} onSubmit={updateInventory} />
        </div>
      </div>
    </TenantPage>
  );
};

export default EditInventory;
