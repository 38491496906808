import { macroExamService } from 'services/api';
import { IMacroExam } from 'interfaces';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { useEffect, useState } from 'react';
import toast from 'features/toast';
import MacroExamForm from '../MacroExamForm';

export const EditMacroExam = () => {
  const translationPrefix = 'pages.macro-exams.edit';
  const { t } = useTranslation();
  const { macroExamId } = useParams();

  const [macroExam, setMacroExam] = useState<IMacroExam>();

  useEffect(() => {
    macroExamService.get(+macroExamId).then(setMacroExam);
  }, []);

  const update = async (values) => {
    macroExamService.update(macroExam.id, values).then(() => {
      toast.success('Macro-esame aggiornato con successo');
    });
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <MacroExamForm macroExam={macroExam} onSubmit={update} />
      {/* {macroExam && (
        <div className="flex flex-wrap mt-4">
          <div className="w-full md:w-1/2">
            <Card variant="outlined">
              <CardContent>
                <EntityHistory service={examService} entityId={exam.id} />
              </CardContent>
            </Card>
          </div>
        </div>
      )} */}
    </TenantPage>
  );
};

export default EditMacroExam;
