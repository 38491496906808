import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import TenantPage from 'components/common/TenantPage';
import { expertiseService } from 'services/api/ExpertiseService';
import { useEffect, useRef, useState } from 'react';
import { IActivity, IExpertise } from 'interfaces';
import ExpertiseForm from '../ExpertiseForm';
import { activityService } from 'services/api/ActivityService';
import { ActivitiesColumns } from 'components/common/enhanced/common-headers/activities';
import toast from 'features/toast';
import ExpertiseChart from './ExpertiseChart';
import { Button, ButtonGroup } from '@mui/material';
import AddActivityDialog from './AddActivityDialog';
import SectionTitle from 'components/common/SectionTitle';

const backgroundColors = [
  'rgba(255, 99, 132, 0.2)',
  'rgba(54, 162, 235, 0.2)',
  'rgba(255, 206, 86, 0.2)',
  'rgba(75, 192, 192, 0.2)',
  'rgba(153, 102, 255, 0.2)',
  'rgba(255, 159, 64, 0.2)'
];
function ExpertiseView() {
  const dataTemplate = {
    labels: [],
    redraw: true,
    datasets: [
      {
        label: 'Hours [h]',
        data: [],
        backgroundColor: backgroundColors,
        borderWidth: 1
      },
      {
        label: 'Percentage [%]',
        backgroundColor: backgroundColors,
        data: [],
        borderWidth: 1
      },
      {
        label: 'Cost [€]',
        data: [],
        backgroundColor: backgroundColors,
        borderWidth: 1
      }
    ]
  };

  const { t } = useTranslation();
  const translationPrefix = 'pages.expertises.view';
  const columns = ActivitiesColumns(translationPrefix);

  const refreshTable = useRef();

  const [expertise, setExpertise] = useState<IExpertise>();
  const [activities, setActivities] = useState<IActivity[]>([]);
  const [graphData, setGraphData] = useState<any>();
  const [openActiviryModal, setOpenActiviryModal] = useState<boolean>(false);

  const { expertiseId } = useParams();

  useEffect(() => {
    expertiseService.get(+expertiseId).then(setExpertise);
  }, []);

  useEffect(() => {
    if (!activities || activities.length === 0) return;
    const d = { ...dataTemplate };
    activities.forEach((activity) => {
      d.labels.push(activity.name);
      d.datasets[0].data.push(
        activity.monthly_hours || (expertise?.monthly_hours * activity.monthly_percentage) / 100
      );
      d.datasets[1].data.push(
        activity.monthly_percentage || (activity.monthly_hours / expertise?.monthly_hours) * 100
      );
      d.datasets[2].data.push(
        expertise?.monthly_cost *
          (activity.monthly_percentage / 100 || activity.monthly_hours / expertise?.monthly_hours)
      );
    });
    setGraphData(d);
  }, [activities]);

  const updateExpertise = (value) => {
    expertiseService.update(expertise.id, value).then((res) => {
      if (res) {
        toast.success('Aggiunto con successo');
      }
    });
  };

  const getAllActivitiesPaginated = async (
    limit = 0,
    offset = 0,
    params: Record<string, unknown> = {}
  ) => {
    try {
      const res = await activityService.getAllPaginated(limit, offset, {
        expertise_id: expertiseId
      });
      setActivities(res.results);
      return res;
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        entityCode={expertise?.name}
        menuRight={
          <>
            <ButtonGroup size="small">
              <Button onClick={() => setOpenActiviryModal(true)}>
                {t(`${translationPrefix}.add-activity`)}
              </Button>
            </ButtonGroup>
          </>
        }>
        <div className="mt-6">
          <ExpertiseForm expertise={expertise} onSubmit={updateExpertise} />
        </div>
        <hr className="my-6" />
        <div className="flex justify-start">
          <SectionTitle>{t(`${translationPrefix}.activities`)}</SectionTitle>
        </div>
        <div className="flex">
          <div className="mt-6 w-2/3">
            {expertise && (
              <EnhancedDataGrid
                columns={columns}
                service={activityService}
                refresh={refreshTable}
                outerParams={{ expertise_id: `${expertise.id}` }}
                getMethod={getAllActivitiesPaginated}
              />
            )}
          </div>
          <div className="mt-6 w-1/3">{graphData && <ExpertiseChart data={graphData} />}</div>
        </div>
      </TenantPage>
      <AddActivityDialog
        open={openActiviryModal}
        setOpen={setOpenActiviryModal}
        callback={refreshTable.current}
        expertiseId={+expertiseId}></AddActivityDialog>
    </>
  );
}

export default ExpertiseView;
