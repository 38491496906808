import { MultipleAutocompleteAdapter } from 'components/form/MultipleAutocompleteAdapter';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { required, validEmail } from 'helpers/validators';
import { ILaboratory, IOption, ISupplier } from 'interfaces';
import { useEffect, useState } from 'react';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { productService } from 'services/api';

interface LaboratoryFormParams {
  laboratory: ILaboratory;
  onSubmit: any;
}
function LaboratoryForm(params: LaboratoryFormParams) {
  const { t } = useTranslation();
  const [products, setProducts] = useState<IOption[]>([]);

  useEffect(() => {
    productService.getAllBaseInformation().then(setProducts);
  }, []);

  const { laboratory, onSubmit } = params;
  return (
    <div>
      {laboratory && (
        <ReactFinalForm onSubmit={onSubmit} edit={!!laboratory.id}>
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/3 p-2">
              <IdentityField
                name="code"
                initialValue={laboratory.code}
                component={TextFieldAdapter}
                label={t('global.code')}
                type="text"
                validate={required}
              />
            </div>
            <div className="w-full md:w-2/3 p-2">
              <IdentityField
                name="description"
                initialValue={laboratory.description}
                component={TextFieldAdapter}
                label={t('global.description')}
                type="text"
                validate={required}
              />
            </div>

            <div className="p-2 w-full">
              <IdentityField
                name="products"
                component={MultipleAutocompleteAdapter}
                options={products}
                initValue={laboratory.products}
                label={t('global.products')}
                type="text"
              />
            </div>
          </div>
        </ReactFinalForm>
      )}
    </div>
  );
}

export default LaboratoryForm;
