import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';

import LockOpenIcon from '@mui/icons-material/LockOpen';
import { booleanCell } from 'components/common/enhanced/cells/boolean-cell';
import { Check } from '@mui/icons-material';
import PriorityHigh from '@mui/icons-material/PriorityHigh';
import { Chip, Tooltip, Typography, Zoom } from '@mui/material';
import React from 'react';
import ConfirmationDeleteTableDialog from 'components/helpers/ConfirmationGridDelete';
import toast from 'features/toast';
import { filterOperators } from '../../../../constants';
import { baseCell } from 'components/common/enhanced/cells/base-cell';

export const InventoryCheckArchiveColumns = (service: any, refresh: any): GridColumns => {
  const translationPrefix = 'entities.stock_quantity';
  const { t } = useTranslation();
  return [
    {
      field: 'notes',
      headerName: t(`global.description`),
      width: 350,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'warehouse',
      headerName: t(`global.warehouse`),
      width: 350,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'partial',
      headerName: t(`global.partial`),
      width: 100,
      type: 'boolean',
      renderCell: booleanCell(t)
    },
    {
      field: 'date',
      headerName: t(`global.date`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'created_by',
      headerName: t(`global.created_by`),
      width: 150,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'is_valid',
      headerName: t(`global.status`),
      width: 100,
      type: 'boolean',
      renderCell: (params: GridRenderCellParams<string>) =>
        !params.row.open && (
          <Tooltip
            TransitionComponent={Zoom}
            arrow
            title={
              !params.row.is_valid &&
              params.row.errors.length > 0 && (
                <React.Fragment>
                  <Typography fontSize={12} color="inherit">
                    <ul>
                      {params.row.errors.map((error, i) => (
                        <li key={i}>- {error}</li>
                      ))}
                    </ul>
                  </Typography>
                </React.Fragment>
              )
            }>
            <Chip
              sx={{ background: 'transparent' }}
              icon={params.value ? <Check color="success" /> : <PriorityHigh color="warning" />}
            />
          </Tooltip>
        )
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t(`global.actions`),
      getActions: (params: GridRowParams) => {
        const actions = [
          <GridActionsCellItem
            key={1}
            label="Reopen"
            icon={<LockOpenIcon />}
            onClick={() => {
              service.reopen(params.row.id).then(() => {
                refresh.current();
              });
            }}
          />,
          <ConfirmationDeleteTableDialog
            key={2}
            id={params.row.id}
            handleDelete={() => {
              service.delete(params.row.id).then(() => {
                toast.success(t('actions.successfully.deleted'));
                refresh.current();
              });
            }}
          />
        ];
        return actions;
      },
      filterable: false
    }
  ];
};
