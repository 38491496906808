import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IOption } from 'interfaces';
import {
  equipmentService,
  examService,
  laboratoryService,
  operationalUnitService,
  productService,
  sectorService,
  workstationService,
  macroExamService
} from 'services/api';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { IdentityField } from 'components/form/IdentityField';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { useTranslation } from 'react-i18next';
import toast from 'features/toast';
import { useEffect } from 'react';

export default function ProductToCostDialog(props: {
  productId: number;
  mode: string;
  setMode: any;
  callback?: any;
}) {
  const { t } = useTranslation();

  const { productId, mode, setMode, callback } = props;
  const [options, setOptions] = React.useState<IOption[]>([]);

  useEffect(() => {
    switch (mode) {
      case 'laboratories':
        laboratoryService.getAllBaseInformation().then((res) => {
          setOptions(
            res.map((item) => {
              return {
                id: item.id,
                code: item.description
              };
            })
          );
        });
        break;
      case 'operationalunits':
        operationalUnitService.getAllBaseInformation().then((res) => {
          setOptions(
            res.map((item) => {
              return {
                id: item.id,
                code: item.description
              };
            })
          );
        });
        break;
      case 'sectors':
        sectorService.getAllBaseInformation().then((res) => {
          setOptions(
            res.map((item) => {
              return {
                id: item.id,
                code: item.description
              };
            })
          );
        });
        break;
      case 'workstations':
        workstationService.getAllBaseInformation().then((res) => {
          setOptions(
            res.map((item) => {
              return {
                id: item.id,
                code: item.description
              };
            })
          );
        });
        break;
      case 'equipments':
        equipmentService.getAllBaseInformation().then((res) => {
          setOptions(
            res.map((item) => {
              return {
                id: item.id,
                code: item.description
              };
            })
          );
        });
        break;
      case 'exams':
        examService.getAllBaseInformation().then((res) => {
          setOptions(
            res.map((item) => {
              return {
                id: item.id,
                code: item.description
              };
            })
          );
        });
        break;
      case 'macroexams':
        macroExamService.getAllBaseInformation().then(setOptions);
        break;
      default:
        setOptions([]);
    }
  }, [mode]);

  const handleClose = () => {
    setMode('');
  };

  const onSubmit = (values) => {
    switch (mode) {
      case 'exams':
        examService.assignProduct(values['object_id'], productId).then(() => {
          toast.success('Esame associato.');
          handleClose();
          if (callback) callback();
        });
        break;
      default:
        productService.addCostLink(productId, { ...values, model: mode }).then(() => {
          toast.success('Fonte di costo aggiunta.');
          handleClose();
          if (callback) callback();
        });
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={mode.length > 0} onClose={handleClose}>
      <DialogTitle>Aggiungi fonte di costo</DialogTitle>
      <DialogContent>
        <ReactFinalForm onSubmit={onSubmit} formId="addCostLink" hideControls>
          <div className="w-full my-4">
            {options && (
              <IdentityField
                name="object_id"
                component={AutocompleteAdapter}
                options={options}
                label={t('global.cost-source')}
              />
            )}
          </div>
        </ReactFinalForm>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('actions.cancel')}</Button>
        <Button type="submit" form="addCostLink">
          {t('global.add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
