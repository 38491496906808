import { TextField } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useField } from 'react-final-form';

export const DateTimePickerAdapter = ({ input: { name, value }, setValue, ...rest }: any) => {
  const { input, meta } = useField(name);

  const onChange = (value) => {
    if (value) {
      input.onChange(value.format('YYYY-MM-DD HH:mm'));
      if (setValue) setValue(value);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
      <DateTimePicker
        onChange={onChange}
        value={value}
        ampm={false}
        inputFormat="DD/MM/YYYY HH:mm"
        renderInput={(params) => (
          <TextField
            {...params}
            {...rest}
            helperText={meta.touched ? meta.error : ''}
            error={meta.error && meta.touched}
            fullWidth
          />
        )}
      />
    </LocalizationProvider>
  );
};
