import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { maintenanceService } from 'services/api';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { GridColDef } from '@mui/x-data-grid-premium';
import { MaintenanceColumns } from 'components/common/enhanced/common-headers';

export const ArchivedMaintenances = () => {
  const translationPrefix = 'pages.maintenance-archive';

  const { t } = useTranslation();
  const datagridRefresh = useRef(null);

  const columns: GridColDef[] = MaintenanceColumns(null, true);

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}>
        <EnhancedDataGrid
          columns={columns}
          service={maintenanceService}
          getMethod={maintenanceService.getClosed}
          refresh={datagridRefresh}
        />
      </TenantPage>
    </>
  );
};

export default ArchivedMaintenances;
