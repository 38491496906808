import { laboratoryService } from 'services/api';
import { IdentityField } from 'components/form/IdentityField';
import { ILISTranscode, IOption } from 'interfaces';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { useEffect, useState } from 'react';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { useTranslation } from 'react-i18next';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';

interface LISTranscodesFormParams {
  lisTranscode: ILISTranscode;
  onSubmit: any;
}

function LISTranscodesForm(params: LISTranscodesFormParams) {
  const { t } = useTranslation();

  const { onSubmit, lisTranscode } = params;

  const [laboratories, setLaboratories] = useState<IOption[]>([]);

  useEffect(() => {
    laboratoryService.getAllBaseInformation().then(setLaboratories);
  }, []);

  return (
    <div>
      {lisTranscode && (
        <ReactFinalForm onSubmit={onSubmit}>
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 pr-1">
              <IdentityField
                name="laboratory"
                component={AutocompleteAdapter}
                options={laboratories}
                initValue={lisTranscode.laboratory}
                label={t('global.laboratory')}
                type="text"
              />
            </div>
            <div className="w-full md:w-1/2 pl-1">
              <IdentityField
                name="transcode"
                initialValue={lisTranscode.transcode}
                component={TextFieldAdapter}
                label={t('global.transcode')}
                type="text"
                required
              />
            </div>
          </div>
        </ReactFinalForm>
      )}
    </div>
  );
}

export default LISTranscodesForm;
