import { useSnackbar } from 'notistack';
import { loadingActions } from 'features';
import { supplierService } from 'services/api';
import { useAppDispatch, useAppSelector } from 'app/store';
import TenantPage from 'components/common/TenantPage';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ISupplier } from 'interfaces';
import { useEffect, useState } from 'react';
import SupplierForm from '../SupplierForm';
import { Card, CardContent } from '@mui/material';
import EntityHistory from 'components/widgets/EntityHistory';

export const EditSupplier = () => {
  const translationPrefix = 'pages.supplier.edit';
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { supplierId } = useParams();
  const sap_active = useAppSelector((state) => state.auth.sap_active);
  const [supplier, setSupplier] = useState<ISupplier>();

  useEffect(() => {
    supplierService.get(+supplierId).then(setSupplier);
  }, []);

  const updateSupplier = async (values) => {
    dispatch(loadingActions.startLoading());
    const data = Object.keys(supplier).reduce((acc, key) => {
      acc[key] = typeof values[key] === 'undefined' ? '' : values[key];
      return acc;
    }, {});
    supplierService.update(supplier.id, data).then((res) => {
      enqueueSnackbar('Fornitore aggiornato con successo');
      supplierService.get(+supplierId).then(setSupplier);
    });
    dispatch(loadingActions.stopLoading());
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      entityCode={supplier?.code + (sap_active ? ` (SAP ID ${supplier?.sap_id})` : '')}>
      <div className="flex flex-wrap">
        <div className="w-full p-4">
          <SupplierForm supplier={supplier} onSubmit={updateSupplier} />
        </div>
        <div className="w-full md:w-1/2 p-4">
          {supplier && (
            <Card variant="outlined">
              <CardContent>
                <EntityHistory service={supplierService} entityId={supplier.id} />
              </CardContent>
            </Card>
          )}
        </div>
      </div>
    </TenantPage>
  );
};

export default EditSupplier;
