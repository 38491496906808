import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import { booleanFilterOperators, filterOperators } from '../../../../constants';
import { baseCell } from '../cells/base-cell';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import FileUploader from 'components/common/file-uploader';
import LockIcon from '@mui/icons-material/Lock';
import { maintenanceService } from 'services/api';
import { compliantCell } from '../cells/compliant-cell';

export const MaintenanceColumns = (openDialog?: any, detail = false): GridColumns => {
  const translationPrefix = 'entities.equipment';
  const { t } = useTranslation();

  const columns = [];

  if (detail) {
    columns.push({
      field: 'equipment',
      headerName: t(`global.equipment`),
      width: 250,
      filterOperators,
      renderCell: baseCell
    });
    columns.push({
      field: 'description',
      headerName: t(`global.description`),
      width: 300,
      filterOperators,
      renderCell: baseCell
    });
    columns.push({
      field: 'serial_number',
      headerName: t(`entities.equipment.serial_number`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    });
    columns.push({
      field: 'supplier',
      headerName: t(`global.supplier`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    });
  }

  [
    {
      field: 'actions',
      type: 'actions',
      headerName: t(`global.actions`),
      getActions: (params: GridRowParams) => {
        {
          if (openDialog && params.row.id) {
            return [
              <GridActionsCellItem
                key={1}
                icon={<LockIcon />}
                onClick={() => {
                  openDialog(+params.row.id);
                }}
                label="Close"
              />
            ];
          } else {
            return [];
          }
        }
      },
      filterable: false
    },
    {
      field: 'expected_date',
      headerName: t(`entities.maintenance.expected_date`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'execution_date',
      headerName: t(`entities.maintenance.execution_date`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'compliant',
      headerName: t(`global.compliant`),
      width: 200,
      filterOperators: booleanFilterOperators,
      renderCell: compliantCell()
    },
    // {
    //   field: 'status',
    //   valueGetter: (params) => params.row.status.display_name,
    //   headerName: t(`global.status`),
    //   width: 100,
    //   renderCell: baseCell
    // },
    {
      field: 'technical_report',
      headerName: t(`${translationPrefix}.technical_report`),
      renderCell: (params: GridRenderCellParams) => {
        const maintenance = params.row;
        return (
          <IsTenantAdministrator>
            <div>
              <FileUploader
                service={maintenanceService}
                entityId={maintenance.id}
                attachmentName={'technical_report'}
                filename={maintenance.technical_report}
              />
            </div>
          </IsTenantAdministrator>
        );
      },
      width: 150,
      filterable: false,
      sortable: false
    },
    {
      field: 'checklist',
      headerName: t(`${translationPrefix}.checklist`),
      renderCell: (params: GridRenderCellParams) => {
        const maintenance = params.row;
        return (
          <IsTenantAdministrator>
            <div>
              <FileUploader
                service={maintenanceService}
                entityId={maintenance.id}
                attachmentName={'checklist'}
                filename={maintenance.checklist}
              />
            </div>
          </IsTenantAdministrator>
        );
      },
      width: 150,
      filterable: false,
      sortable: false
    }
  ].forEach((column) => columns.push(column));

  return columns;
};
