import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

export class ExportableResourceService<T extends { id: number }> extends BaseService<T> {
  exportAll = async (_?: Record<string, unknown>) => {
    axiosPrivate
      .get(`${this.modulePrefix}/export/`, {
        responseType: 'blob'
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const contentDisposition = res.headers['content-disposition'];
        if (contentDisposition?.indexOf('attachment') !== -1) {
          const filenameMatches = contentDisposition.match(
            /(?:.*filename\*|filename)=(?:([^'"]*)''|("))([^;]+)\2(?:[;`\n]|$)/
          );
          const filename = filenameMatches.pop();
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        }
      });
  };
}
