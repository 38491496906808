import { Dispatch, SetStateAction } from 'react';
import {
  GridActionsCellItem,
  GridColDef,
  GridColumns,
  GridRowParams
} from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import { filterOperators, dateFilterOperators, numberFilterOperators } from '../../../../constants';
import { baseCell } from '../cells/base-cell';
import { currencyCell } from '../cells/currency-cell';

export function UnapprovedMovementColumns(
  types: string[],
  warehouses: string[],
  setMovement?: Dispatch<SetStateAction<number>>
): GridColDef[] {
  const { t } = useTranslation();
  const translationPrefix = 'entities.movement';

  const columns: GridColumns | any[] = [
    {
      field: 'actions',
      type: 'actions',
      headerName: t(`global.actions`),
      getActions: (params: GridRowParams) => {
        {
          const movementRow = params.row;
          if (movementRow.id) {
            return [
              <GridActionsCellItem
                key={1}
                icon={<DeleteIcon />}
                onClick={() => {
                  setMovement(+params.row.id);
                }}
                label="Elimina"
              />
            ];
          } else {
            return [];
          }
        }
      },
      filterable: false
    },
    {
      field: 'quantity',
      headerName: t(`${translationPrefix}.moved_quantity`),
      type: 'number',
      editable: true,
      filterOperators: numberFilterOperators,
      renderCell: baseCell
    },
    {
      field: 'product',
      headerName: t(`global.product`),
      filterOperators,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'description',
      headerName: t(`global.description`),
      filterOperators,
      width: 300,
      renderCell: baseCell
    },
    {
      field: 'product_batch',
      headerName: t(`${translationPrefix}.product_batch`),
      filterOperators,
      width: 250,
      editable: true,
      renderCell: baseCell
    },
    {
      field: 'expiration_date',
      headerName: t(`${translationPrefix}.expiration_date`),
      filterOperators: dateFilterOperators,
      width: 250,
      editable: true,
      renderCell: baseCell
    },
    {
      field: 'product_notes',
      headerName: t(`entities.product.product_notes`),
      filterable: false,
      sortable: false,
      width: 300,
      renderCell: baseCell
    },
    {
      field: 'order_notes',
      headerName: t(`entities.product.purchase-request-notes`),
      filterable: false,
      sortable: false,
      width: 300,
      renderCell: baseCell
    },
    {
      field: 'supplier',
      headerName: t(`global.supplier`),
      filterOperators,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'timestamp',
      headerName: t(`${translationPrefix}.timestamp`),
      filterOperators: dateFilterOperators,
      width: 250,
      editable: true,
      renderCell: baseCell
    },
    {
      field: 'price',
      headerName: t(`${translationPrefix}.price`),
      filterable: false,
      sortable: false,
      type: 'number',
      width: 150,
      renderCell: currencyCell
    },
    {
      field: 'unit_price',
      headerName: t(`${translationPrefix}.unit_price`),
      filterOperators,
      editable: true,
      type: 'number',
      width: 150,
      renderCell: currencyCell
    },
    {
      field: 'warehouse',
      headerName: t(`global.warehouse`),
      filterOperators,
      type: 'singleSelect',
      valueOptions: warehouses,
      width: 250,
      editable: true,
      renderCell: baseCell
    },
    {
      field: 'movement_type',
      headerName: t(`${translationPrefix}.type`),
      type: 'singleSelect',
      valueOptions: types,
      width: 250,
      editable: true,
      renderCell: baseCell
    },
    {
      field: 'order_code',
      headerName: t(`entities.movement.order_code`),
      filterOperators,
      editable: true,
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'transport_document_code',
      headerName: t(`${translationPrefix}.transport_document`),
      filterOperators,
      width: 200,
      editable: true,
      renderCell: baseCell
    },
    {
      field: 'transport_document_date',
      headerName: t(`${translationPrefix}.transport_document_date`),
      filterOperators: dateFilterOperators,
      width: 200,
      editable: true,
      renderCell: baseCell
    },
    {
      field: 'created_by',
      headerName: t(`${translationPrefix}.created_by`),
      filterOperators,
      width: 200,
      renderCell: baseCell
    }
  ];

  return columns;
}
