import { Alert, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { IStockQuantity } from 'interfaces';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { stockQuantityService } from 'services/api';
import { useAppSelector } from 'app/store';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';

function ExpiringToday() {
  const { t } = useTranslation();
  const [stockQuantities, setStockQuantities] = useState<IStockQuantity[]>();

  const defaultWarehouse = useAppSelector((state) => state.auth.warehouse);

  useEffect(() => {
    const params = {
      expiration_date: dayjs().format('YYYY-MM-DD')
    };
    if (defaultWarehouse) params['warehouse'] = defaultWarehouse;
    setStockQuantities(null);
    stockQuantityService.getAllPaginated(5, 0, params).then((res) => {
      setStockQuantities(res.results);
    });
  }, [defaultWarehouse]);

  return (
    <div>
      {stockQuantities && stockQuantities.length > 0 && (
        <>
          <Typography sx={{ fontSize: 18, fontWeight: 'medium' }} color="secondary" gutterBottom>
            <AccessAlarmIcon fontSize="large" className="pr-2" />
            {t('dashboard.expiration-today.title')}
          </Typography>
          {stockQuantities.map((stockQuantity, i) => (
            <Tooltip key={i} title={stockQuantity.description}>
              <Alert severity="warning" className="mb-2">
                {t('dashboard.expiration-today.row-body', {
                  code: stockQuantity.product,
                  description: stockQuantity.description,
                  batch: stockQuantity.product_batch,
                  warehouse: stockQuantity.warehouse
                })}
              </Alert>
            </Tooltip>
          ))}
        </>
      )}
    </div>
  );
}

export default ExpiringToday;
