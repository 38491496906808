import {
  DataGridPremiumProps,
  GridColDef,
  GridColumns,
  GridRenderCellParams
} from '@mui/x-data-grid-premium';
import { TenantPage } from 'components/common';
import { StockQuantitiesColumns } from 'components/common/enhanced/common-headers';
import EnhancedDataGrid from 'components/common/enhanced/data-grid/EnhancedDataGrid';
import { IUnderStockQuantity } from 'interfaces';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { stockQuantityService } from 'services/api';
import { baseCell } from 'components/common/enhanced/cells/base-cell';
import { filterOperators } from '../../../../constants';
import { IsTenantCoordinatorOrAdmin } from 'components/helpers/IsTenantCoordinator';
import DataGridCell from 'components/common/DataGridCell';
import { currencyFormatter } from 'helpers/currency-formatter';
import StockQuantitiesDetail from '../detail/StockQuantitiesDetail';

export const StockUnderstockProducts = () => {
  const { t } = useTranslation();
  const translationPrefix = 'pages.stock_quantity.understock';

  const [understock, setUnderstock] = useState<IUnderStockQuantity[]>([]);

  useEffect(() => {
    stockQuantityService.getUnderstockProducts(10, 0).then((res) => {
      setUnderstock(res.results);
    });
  }, []);

  const columns: GridColumns = [
    {
      field: 'product',
      valueGetter: (params) => params.row.product__code,
      headerName: t(`global.product`),
      width: 250,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'warehouse',
      headerName: t(`global.warehouse`),
      width: 250,
      filterOperators,
      valueGetter: (params) => params.row.warehouse__code,
      renderCell: baseCell
    },
    {
      field: 'total_quantity',
      headerName: t(`entities.stock_quantity.total_residual_quantity`),
      width: 150,
      type: 'number',
      filterable: false,
      sortable: false,
      valueGetter: (params) => params.row.total_quantity,
      renderCell: baseCell
    },
    {
      field: 'understock_threshold',
      headerName: t(`entities.stock_quantity.understock_threshold`),
      width: 150,
      type: 'number',
      filterable: false,
      sortable: false,
      valueGetter: (params) => params.row.understock_threshold,
      renderCell: baseCell
    }
  ];

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <EnhancedDataGrid
        columns={columns}
        service={stockQuantityService}
        getMethod={stockQuantityService.getUnderstockProducts}
      />
    </TenantPage>
  );
};
