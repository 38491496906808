import { IOperationalUnit } from 'interfaces';
import { BaseService } from './BaseService';

class OperationalUnitService extends BaseService<IOperationalUnit> {
  constructor() {
    super('operational-units');
  }
}

export const operationalUnitService = new OperationalUnitService();
