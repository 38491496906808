import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { Button, ButtonGroup, Chip } from '@mui/material';
import { DatePickerAdapter } from 'components/form/DatePickerAdapter';
import {
  examService,
  laboratoryService,
  medicalServiceService,
  workstationService
} from 'services/api';
import { IdentityField } from 'components/form/IdentityField';
import {
  booleanFilterOperators,
  dateFilterOperators,
  filterOperators,
  numberFilterOperators
} from '../../../../constants';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { IMedicalService, IOption } from 'interfaces';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import { loadingActions } from 'features';
import { required } from 'helpers/validators';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { useAppDispatch } from 'app/store';
import { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import CollapsableColumns from 'components/common/CollapsableColumns';
import DataGridCell from 'components/common/DataGridCell';
import dayjs from 'dayjs';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import ReactFinalForm from 'components/form/ReactFinalForm';
import TenantPage from 'components/common/TenantPage';
import { baseCell } from 'components/common/enhanced/cells/base-cell';
import toast from 'features/toast';
import { PurgeButton } from 'components/helpers/PurgeButton';
import LISFileUploader from './LISFileImport';
import { Check, Close } from '@mui/icons-material';

export const MedicalServices = () => {
  const translationPrefix = 'pages.medical-services.list';

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const datagridRefresh = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const [openDialog, setOpenDialog] = useState(false);
  const [openLisDialog, setOpenLisDialog] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IMedicalService | null>(null);

  const [exams, setExams] = useState<IOption[]>([]);
  const [exam, setExam] = useState<IOption>();
  const [laboratories, setLaboratories] = useState<IOption[]>([]);
  const [lab, setLab] = useState<IOption>();
  const [workstations, setWorkstations] = useState<IOption[]>([]);

  const columns: GridColDef[] = [
    {
      field: 'exam',
      headerName: t(`${translationPrefix}.entity.exam`),
      flex: 1,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'registered',
      headerName: t(`global.registered`),
      width: 80,
      sortable: false,
      filterOperators: booleanFilterOperators,
      renderCell: (params: GridRenderCellParams) => (
        <Chip
          sx={{ background: 'transparent' }}
          icon={params.value ? <Check color="success" /> : <Close color="error" />}
        />
      )
    },
    {
      field: 'quantity',
      headerName: t(`global.quantity`),
      flex: 1,
      filterOperators: numberFilterOperators,
      renderCell: baseCell
    },
    {
      field: 'request_date',
      headerName: t(`${translationPrefix}.entity.request_date`),
      flex: 1,
      filterOperators: dateFilterOperators,
      renderCell: (params: GridRenderCellParams<string>) => (
        <DataGridCell content={params.value && dayjs(params.value).format('DD/MM/YYYY')} />
      )
    },
    {
      field: 'service_date',
      headerName: t(`${translationPrefix}.entity.service_date`),
      flex: 1,
      filterOperators: dateFilterOperators,
      renderCell: (params: GridRenderCellParams<string>) => (
        <DataGridCell content={dayjs(params.value).format('DD/MM/YYYY')} />
      )
    },
    {
      field: 'req_department',
      headerName: t(`${translationPrefix}.entity.request_department`),
      flex: 1,
      filterOperators,
      editable: true,
      renderCell: baseCell
    },
    {
      field: 'req_laboratory',
      headerName: t(`${translationPrefix}.entity.request_laboratory`),
      flex: 1,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'exec_laboratory',
      headerName: t(`${translationPrefix}.entity.execution_laboratory`),
      flex: 1,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'workstation',
      headerName: t(`global.workstation`),
      flex: 1,
      filterOperators,
      renderCell: baseCell
    }
    // {
    //   field: 'actions',
    //   headerName: t(`${translationPrefix}.entity.actions`),
    //   renderCell: (params: GridRenderCellParams) => {
    //     const product = params.row;
    //     return (
    //       <div>
    //         <EditIcon className="cursor-pointer" onClick={() => editMode(product)} />
    //       </div>
    //     );
    //   },
    //   flex: 1,
    //   filterable: false
    // }
  ];

  useEffect(() => {
    dispatch(loadingActions.startLoading());
    examService.getAllBaseInformation().then(setExams);
    laboratoryService.getAllBaseInformation().then(setLaboratories);
    dispatch(loadingActions.stopLoading());
  }, []);

  useEffect(() => {
    if (!exam || !lab) return;
    setWorkstations([]);
    workstationService
      .getAllBaseInformation({ exam: exam.id, laboratory: lab.id })
      .then(setWorkstations);
  }, [lab]);

  const onOpenDialog = () => {
    setOpenDialog(!openDialog);
    setEdit(false);
  };

  const onOpenLisDialog = () => {
    setOpenLisDialog(!openLisDialog);
  };

  const editMode = (service) => {
    dispatch(loadingActions.startLoading());
    medicalServiceService.get(service.id).then((res) => {
      setSelectedItem(res);
      setOpenDialog(true);
      setEdit(true);
    });
    dispatch(loadingActions.stopLoading());
  };

  const onSubmitCreate = async (values) => {
    medicalServiceService.generateLines(values).then(() => {
      toast.success('Prestazioni mediche aggiunte.');
      datagridRefresh.current();
    });
  };

  const onSubmitEdit = async (values) => {
    dispatch(loadingActions.startLoading());
    medicalServiceService.update(selectedItem.id, values).then(() => {
      enqueueSnackbar('Medical service updated successfully', { variant: 'success' });
      datagridRefresh.current();
    });
    dispatch(loadingActions.stopLoading());
  };

  const onUploadSuccess = () => {
    toast.success('Importazione avviata.');
  };

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <div className="flex justify-end items-center">
            <div>
              <ButtonGroup>
                <IsTenantAdministrator>
                  <Button onClick={onOpenLisDialog}>
                    <ReceiptIcon />
                    {t(`${translationPrefix}.add-lis`)}
                  </Button>
                  <Button onClick={onOpenDialog}>
                    <AddIcon />
                    {t(`${translationPrefix}.add`)}
                  </Button>
                </IsTenantAdministrator>
              </ButtonGroup>
            </div>
            <PurgeButton service={medicalServiceService} refresh={datagridRefresh} />
          </div>
        }>
        <CollapsableColumns
          expand={openDialog}
          onClose={() => {
            setSelectedItem(null);
            setOpenDialog(false);
          }}
          size="w-1/3"
          contentLeft={
            <EnhancedDataGrid
              refresh={datagridRefresh}
              service={medicalServiceService}
              columns={columns}
            />
          }
          contentRight={
            <div className="w-full flex flex-wrap">
              <h2 className="h2 font-bold text-lg text-center ">
                {edit && selectedItem ? `EDIT MEDICAL SERVICE ${selectedItem.ref}` : 'ADD NEW'}
              </h2>
              <ReactFinalForm onSubmit={onSubmitCreate}>
                <div className="flex flex-wrap my-4">
                  <div className="w-full md:w-full my-2">
                    <IdentityField
                      name="exam"
                      component={AutocompleteAdapter}
                      options={exams}
                      setValue={setExam}
                      label={t(`${translationPrefix}.entity.exam`)}
                      type="text"
                      optionLabel="description"
                      optionValue="code"
                      validate={required}
                      required
                    />
                  </div>
                  <div className="w-full md:w-full my-2">
                    <IdentityField
                      name="laboratory"
                      component={AutocompleteAdapter}
                      options={laboratories}
                      setValue={setLab}
                      label={t(`global.laboratory`)}
                      type="text"
                      optionValue="code"
                      validate={required}
                      required
                    />
                  </div>
                  <div className="w-full md:w-full my-2">
                    <IdentityField
                      name="request_laboratory"
                      component={AutocompleteAdapter}
                      options={laboratories}
                      label={t(`global.request_laboratory`)}
                      type="text"
                      optionValue="code"
                      validate={required}
                      required
                    />
                  </div>
                  <div className="w-full md:w-full my-2">
                    <IdentityField
                      name="workstation"
                      component={AutocompleteAdapter}
                      options={workstations}
                      label={t(`global.workstation`)}
                      type="text"
                      optionValue="code"
                    />
                  </div>
                  <div className="w-full my-2 md:pr-1">
                    <IdentityField
                      name="date"
                      component={DatePickerAdapter}
                      label={t(`${translationPrefix}.entity.service_date`)}
                      type="date"
                      validate={required}
                      required
                    />
                  </div>
                  <div className="w-full md:w-2/3 my-2 md:pr-1">
                    <IdentityField
                      name="request_date"
                      component={DatePickerAdapter}
                      label={t(`${translationPrefix}.entity.request_date`)}
                      type="date"
                      validate={required}
                      required
                    />
                  </div>
                  <div className="w-full md:w-1/3 my-2 md:pl-1">
                    <IdentityField
                      name="quantity"
                      component={TextFieldAdapter}
                      label={t(`${translationPrefix}.entity.quantity`)}
                      type="number"
                      initialValue="1"
                      required
                    />
                  </div>
                </div>
              </ReactFinalForm>
            </div>
          }
        />
        <LISFileUploader
          openDropzone={openLisDialog}
          setOpenDropzone={setOpenLisDialog}
          onUploadSuccess={onUploadSuccess}
        />
      </TenantPage>
    </>
  );
};

export default MedicalServices;
