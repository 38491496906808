import { useEffect, useState } from 'react';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { IOption } from 'interfaces';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { transportDocumentService } from 'services/api';
import { required } from 'helpers/validators';
import dayjs from 'dayjs';

interface TranportDocumentFormParams {
  onSubmit: any;
  supplierId: number;
}

export function TranportDocumentForm(params: TranportDocumentFormParams) {
  const { t } = useTranslation();
  const { onSubmit, supplierId } = params;

  const [options, setOptions] = useState<IOption[]>([]);

  useEffect(() => {
    if (supplierId) {
      transportDocumentService.getUnassigned(10000, 0, { supplier_id: supplierId }).then((res) => {
        setOptions(
          res.results.map((ddt) => {
            return {
              id: ddt.id,
              code: `N. ${ddt.ref} ${
                ddt.date ? '(' + dayjs(ddt.date).format('DD/MM/YYYY') + ')' : ''
              }`
            };
          })
        );
      });
    } else {
      setOptions([]);
    }
  }, [supplierId]);

  return (
    <div>
      <ReactFinalForm onSubmit={onSubmit} formId="newInvoice" hideControls>
        <div className="flex flex-wrap">
          <div className="w-full p-2">
            <IdentityField
              name="transport_document"
              component={AutocompleteAdapter}
              options={options}
              label={t('pages.invoices.detail.transport-document')}
              validate={required}
              required
            />
          </div>
        </div>
      </ReactFinalForm>
    </div>
  );
}

export default TranportDocumentForm;
