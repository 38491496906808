import {
  GridColumns,
  GridRenderCellParams,
  GridValueFormatterParams
} from '@mui/x-data-grid-premium';
import FileUploader from 'components/common/file-uploader';
import { NavLink } from 'react-router-dom';
import { transportDocumentService } from 'services/api';
import {
  booleanFilterOperators,
  dateFilterOperators,
  filterOperators
} from '../../../../constants';
import { baseCell } from '../cells/base-cell';
import { booleanValueFormatter } from '../data-grid/utils';
import { currencyCell } from '../cells/currency-cell';
import { useTranslation } from 'react-i18next';
import SectionTitle from 'components/common/SectionTitle';
import { booleanCell } from '../cells/boolean-cell';
import { currencyFormatter } from 'helpers/currency-formatter';

export function EquipmentInvoicesColumns(onUploadSuccess): GridColumns {
  const { t } = useTranslation();

  const columns: GridColumns = [
    {
      field: 'ref',
      headerName: t(`entities.ddt.invoice_ref`),
      width: 150,
      filterOperators,
      renderCell: (params: GridRenderCellParams<string>) => (
        <NavLink to={`${params.row?.id}`}>
          <SectionTitle>{params.value}</SectionTitle>
        </NavLink>
      )
    },
    {
      field: 'date',
      headerName: t(`global.date`),
      width: 100,
      filterOperators: dateFilterOperators,
      renderCell: baseCell
    },
    {
      field: 'closed',
      headerName: t(`entities.ddt.closed`),
      width: 150,
      filterOperators: booleanFilterOperators,
      renderCell: booleanCell(t)
    },
    {
      field: 'contract_code',
      headerName: t('global.contract'),
      filterOperators,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'supplier',
      headerName: t('global.supplier'),
      filterOperators,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'expected_amount',
      headerName: t(`entities.ddt.expected_amount`),
      width: 150,
      sortable: false,
      filterable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        return currencyFormatter(params.value);
      },
      renderCell: currencyCell
    },
    {
      field: 'amount',
      headerName: t(`entities.ddt.amount`),
      width: 150,
      sortable: false,
      filterable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        return currencyFormatter(params.value);
      },
      renderCell: currencyCell
    }
  ];

  ['invoice_file', 'invoice_revision_file'].forEach((file) => {
    columns.push({
      field: file,
      headerName: t(`entities.ddt.${file}`),
      filterOperators,
      filterable: false,
      sortable: false,
      width: 150,
      valueFormatter: booleanValueFormatter,
      renderCell: (params: GridRenderCellParams<string>) => (
        <FileUploader
          onUploadSuccess={onUploadSuccess}
          service={transportDocumentService}
          entityId={params.row?.id}
          attachmentName={file}
          filename={params.value}
        />
      )
    });
  });

  return columns;
}
