import { axiosPrivate } from 'services/axios';
import { IWarehouse } from 'interfaces';
import { BaseService } from './BaseService';

class WarehouseService extends BaseService<IWarehouse> {
  constructor() {
    super('warehouses');
  }
  canDelete = true;
  canBulkDelete = true;

  assignProduct = async (
    warehouseId: number,
    product: number,
    restock_threshold?: number,
    understock_threshold?: number,
    overstock_threshold?: number,
    status?: number,
    notes?: string
  ) => {
    const res = await axiosPrivate.patch(`${this.modulePrefix}/${warehouseId}/assign_product/`, {
      product,
      restock_threshold,
      understock_threshold,
      overstock_threshold,
      status,
      notes
    });
    return res.data;
  };

  removeProduct = async (warehouseId: number, product: number) => {
    const res = await axiosPrivate.patch(`${this.modulePrefix}/${warehouseId}/remove_product/`, {
      product
    });
    return res.data;
  };
}

export const warehouseService = new WarehouseService();
