import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { filterOperators, numberFilterOperators } from './../../../../constants';
import { useTranslation } from 'react-i18next';
import { baseCell } from '../cells/base-cell';
import { currencyCell } from '../cells/currency-cell';
import WarningIcon from '@mui/icons-material/Warning';
import { Tooltip } from '@mui/material';
import DataGridCell from 'components/common/DataGridCell';

export function RoleNameColumns(): GridColDef[] {
  const { t } = useTranslation();
  return [
    {
      field: 'code',
      headerName: t(`global.code`),
      width: 250,
      editable: true,
      filterOperators
    },
    {
      field: 'fte',
      headerName: t(`entities.role_name.fte`),
      editable: true,
      filterOperators: numberFilterOperators,
      type: 'number',
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'monthly_hours',
      headerName: t(`entities.role_name.monthly_hours`),
      editable: true,
      filterOperators: numberFilterOperators,
      type: 'number',
      width: 150,
      renderCell: (params) => (
        <DataGridCell
          content={((+params.row.monthly_hours).toFixed(2) as string).replace('.', ',')}
        />
      )
    },
    {
      field: 'monthly_cost',
      headerName: t(`entities.role_name.monthly_cost`),
      editable: true,
      filterOperators: numberFilterOperators,
      type: 'number',
      width: 150,
      renderCell: currencyCell
    },
    {
      field: 'used_fte',
      headerName: t(`entities.role_name.used_fte`),
      type: 'number',
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div>
            {((+params.value).toFixed(2) as string).replace('.', ',')}
            {params.value > params.row.fte ? (
              <Tooltip title={t(`entities.role_name.alerts.fte_exceeded`)}>
                <WarningIcon color="error" />
              </Tooltip>
            ) : (
              <></>
            )}
          </div>
        );
      }
    }
  ];
}
