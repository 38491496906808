import { useAppSelector } from 'app/store';
import { ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN, ROLE_TENANT_COORDINATOR } from './../../constants';

export function IsTenantCoordinatorOrAdmin({ children }) {
  const groups = useAppSelector((state) => state.auth.groups);
  if (
    groups &&
    (groups.includes(ROLE_TENANT_COORDINATOR) ||
      groups.includes(ROLE_TENANT_ADMIN) ||
      groups.includes(ROLE_SUPER_ADMIN))
  )
    return children;
}

export function IsTenantCoordinator({ children }) {
  const groups = useAppSelector((state) => state.auth.groups);
  if (groups && groups.includes(ROLE_TENANT_COORDINATOR)) return children;
}
