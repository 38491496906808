import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid-premium';
import DataGridCell from 'components/common/DataGridCell';
import { useTranslation } from 'react-i18next';
import { filterOperators } from '../../../../constants';
import { baseCell } from '../cells/base-cell';
import { booleanCell } from '../cells/boolean-cell';

export const CostReport = (): GridColumns => {
  const translationPrefix = 'entities.cost_report';
  const { t } = useTranslation();
  return [
    {
      field: 'id',
      headerName: t(`global.code`),
      // renderCell: (params: GridRenderCellParams) => {
      //   const reportId = params.value;
      //   return (
      //     <div>
      //       <Link to={`${reportId}`}>
      //         <b className="text-secondary-500">{reportId}</b>
      //       </Link>
      //     </div>
      //   );
      // }
      renderCell: baseCell
    },
    {
      field: 'date_start',
      type: 'timestamp',
      headerName: t(`${translationPrefix}.date_start`),
      width: 100,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'date_end',
      type: 'date',
      headerName: t(`${translationPrefix}.date_end`),
      width: 100,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'created_by',
      headerName: t(`global.created_by`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'status',
      headerName: t(`${translationPrefix}.status`),
      width: 100,
      filterOperators,
      renderCell: (params: GridRenderCellParams<number>) => (
        <DataGridCell content={t(`${translationPrefix}.status-${params.value}`)} />
      )
    },
    {
      field: 'validated',
      headerName: t(`${translationPrefix}.validated`),
      width: 100,
      type: 'boolean',
      editable: true,
      renderCell: booleanCell(t)
    }
  ];
};
