import { IPurchaseRequest } from 'interfaces';
import { BaseService } from './BaseService';

class PurchaseRequestLineService extends BaseService<IPurchaseRequest> {
  constructor() {
    super('purchreqline');
  }
}

export const purchaseRequestLineService = new PurchaseRequestLineService();
