import { Button, ButtonGroup } from '@mui/material';
import { categoryService } from 'services/api';
import { GridColDef } from '@mui/x-data-grid-premium';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import TenantPage from 'components/common/TenantPage';
import { CategoriesColumns } from 'components/common/enhanced/common-headers';
import { NotOperatorRoles } from '../../../../constants';
import { useAppSelector } from 'app/store';

export const Categories = () => {
  const translationPrefix = 'pages.category.list';
  const { t } = useTranslation();
  const groups = useAppSelector((state) => state.auth.groups);

  const columns: GridColDef[] = CategoriesColumns(
    translationPrefix,
    NotOperatorRoles.some((role) => groups.includes(role))
  );

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <div className="flex justify-end items-center">
            <ButtonGroup>
              <IsTenantAdministrator>
                <Link to="new">
                  <Button variant="outlined" size="small">
                    <AddIcon />
                    {t(`${translationPrefix}.add_category`)}
                  </Button>
                </Link>
              </IsTenantAdministrator>
            </ButtonGroup>
          </div>
        }>
        <div className="mt-6">
          <EnhancedDataGrid columns={columns} service={categoryService} />
        </div>
      </TenantPage>
    </>
  );
};

export default Categories;
