import { GridColDef, GridColumns, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { booleanFilterOperators, filterOperators } from '../../../../constants';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { baseCell } from '../cells/base-cell';
import DataGridCell from 'components/common/DataGridCell';

export function SuppliersColumns(sap_id = false, detailNav = false): GridColDef[] {
  const translationPrefix = 'entities.supplier';
  const { t } = useTranslation();
  const columns: GridColumns = [
    {
      field: 'code',
      headerName: t(`global.code`),
      filterOperators,
      renderCell: (params: GridRenderCellParams) => {
        const supplier = params.row;
        return detailNav ? (
          <div>
            <Link to={`${supplier.id}`}>
              <b className="text-secondary-500">{supplier.code}</b>
            </Link>
          </div>
        ) : (
          <DataGridCell content={params.value as string} />
        );
      }
    }
  ];

  if (sap_id) {
    columns.push({
      field: 'sap_id',
      headerName: t(`global.sap_id`),
      filterOperators,
      width: 100,
      renderCell: baseCell
    });
  }

  columns.push({
    field: 'active',
    headerName: t(`global.status`),
    filterOperators: booleanFilterOperators,
    width: 100,
    renderCell: (params: GridRenderCellParams) => {
      const value = params.value;
      return (
        <div>
          <span className={value ? 'text-green-500' : 'text-orange-500'}>
            {value
              ? t(`entities.product.status_options.ACTIVE`)
              : t(`entities.product.status_options.DECOMMISSIONED`)}
          </span>
        </div>
      );
    }
  });
  [
    'company_name',
    'city',
    'district',
    'zip_code',
    'address',
    'address_2',
    'vat_number',
    'fiscal_code',
    'email',
    'email_credit_note',
    'phone_number',
    'fax_number',
    'iban',
    'payment_terms',
    'notes'
  ].forEach((element) => {
    columns.push({
      field: element,
      filterOperators,
      headerName: t(`${translationPrefix}.${element}`),
      width: 150
    });
  });
  return columns;
}
