import {
  Accordion,
  AccordionSummary,
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack
} from '@mui/material';
import { TenantPage } from 'components/common';
import SectionTitle from 'components/common/SectionTitle';
import { ITransportDocument } from 'interfaces';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  transportDocumentLineService,
  transportDocumentService,
  unapprovedMovementService
} from 'services/api';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { currencyFormatter } from 'helpers/currency-formatter';
import FileUploader from 'components/common/file-uploader';
import dayjs from 'dayjs';
import toast from 'features/toast';
import { TransportDocumentLinesColumns } from 'components/common/enhanced/common-headers/transport-document-lines';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import TransportDocumentForm from '../TransportDocumentForm';
import { GridSelectionModel } from '@mui/x-data-grid-premium';
import { UnapprovedOrderlessMovementColumns } from 'components/common/enhanced/common-headers/unapproved-movements-orderless';

export function TransportDocumentDetail() {
  const { t } = useTranslation();
  const prefix = 'pages.ddt.detail';
  const columns = TransportDocumentLinesColumns();
  const { id } = useParams();
  const datagridRefresh = useRef(null);

  const [ddt, setDdt] = useState<ITransportDocument>();
  const [movements, setMovements] = useState<GridSelectionModel>([]);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    transportDocumentService.get(+id).then(setDdt);
  }, []);

  const downloadableFields = [
    'invoice_file',
    'ddt_file',
    'invoice_revision_file',
    'extra_1_file',
    'extra_2_file',
    'extra_3_file'
  ];

  const updateDDT = (values) => {
    delete values['credit_memo_amount'];
    transportDocumentService.update(ddt.id, values).then((res) => {
      setDdt(res);
      toast.success('DDT aggiornato con successo');
    });
  };

  const attachLines = () => {
    transportDocumentService.attachLines(ddt.id).then(() => {
      datagridRefresh.current();
    });
  };

  const unapprovedMovementColumns = UnapprovedOrderlessMovementColumns();

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setMovements([]);
    setOpenDialog(false);
  };

  const handleAttachLines = () => {
    transportDocumentService.attachUnapprovedLines(ddt.id, movements);
    handleCloseDialog();
  };

  return (
    <TenantPage
      title={t(`${prefix}.title`) + ' ' + ddt?.ref}
      subtitle={t(`${prefix}.subtitle`)}
      entityCode={ddt?.ref}>
      <div>
        <div className="w-full">
          {ddt && ddt.amount != null && +ddt.amount !== +ddt.expected_amount && (
            <Alert severity="warning" className="w-full">
              {t(`${prefix}.incoerent-amounts`)}
            </Alert>
          )}
        </div>
        <div className="mb-4">
          <SectionTitle>{t(`${prefix}.header`)}</SectionTitle>
          <div className="flex flex-wrap my-4">
            <div className="w-3/4 pr-8">
              {ddt && <TransportDocumentForm onSubmit={updateDDT} transport_document={ddt} />}
            </div>
            <div className="w-1/4">
              {ddt && (
                <div>
                  <b>{t(`entities.ddt.amount`)}</b>
                  <div className="flex flex-wrap">{currencyFormatter(ddt.amount)}</div>
                  <b>{t(`${prefix}.invoice-expected`)}</b>
                  <div className="flex flex-wrap">{currencyFormatter(ddt.expected_amount)}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mb-4">
          <SectionTitle>{t(`${prefix}.files`)}</SectionTitle>
          {ddt && (
            <div className="flex flex-wrap">
              {downloadableFields.map((field, i) => (
                <div key={i} className={`w-1/${downloadableFields.length}`}>
                  <span>{t(`entities.ddt.${field}`)}:</span>
                  <FileUploader
                    service={transportDocumentService}
                    entityId={ddt.id}
                    attachmentName={field}
                    filename={ddt[field]}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="mb-4">
          <SectionTitle>{t(`${prefix}.order-list`)}</SectionTitle>
          {ddt && ddt?.order && (
            <Accordion key={ddt.order.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography>
                  <b>{ddt.order.ref}</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className="flex flex-wrap">
                    <div className="mr-4">
                      {t('global.status')}: <b>{ddt.order.status}</b>
                    </div>
                    <div className="mr-4">
                      {t('global.supplier')}: <b>{ddt.order.supplier}</b>
                    </div>
                    <div className="mr-4">
                      {t('global.warehouse')}: <b>{ddt.order.warehouse}</b>
                    </div>
                    <div className="mr-4">
                      {t('entities.order.from_request')}: <b>{ddt.order.from_request}</b>
                    </div>
                    <div className="mr-4">
                      {t('entities.order.created_at')}:{' '}
                      <b>{dayjs(ddt.order.created_at).format('YYYY-MM-DD HH:mm')}</b>
                    </div>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          )}
        </div>
        {ddt && (
          <>
            <SectionTitle>{t(`${prefix}.movement-list`)}</SectionTitle>
            {id && (
              <EnhancedDataGrid
                columns={columns}
                service={transportDocumentLineService}
                outerParams={{ document_id: id }}
                refresh={datagridRefresh}
              />
            )}
          </>
        )}
      </div>
      <Dialog fullWidth maxWidth="lg" open={openDialog} onClose={() => handleCloseDialog()}>
        <DialogTitle>Aggiungi fonte di costo</DialogTitle>
        <DialogContent>
          <Stack sx={{ py: 2, height: '100%', boxSizing: 'border-box' }} direction="column">
            <Paper sx={{ flex: 1, mx: 'auto', width: '95%', p: 1 }}>
              <Stack direction="column" spacing={1} sx={{ height: 1 }}>
                <EnhancedDataGrid
                  columns={unapprovedMovementColumns}
                  service={unapprovedMovementService}
                  outerParams={{ noOrderCode: 'true' }}
                  checkboxSelection={true}
                  selectionModelChange={(newRowSelectionModel) => {
                    setMovements(newRowSelectionModel);
                  }}
                />
              </Stack>
            </Paper>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDialog()}>{t('actions.cancel')}</Button>
          <Button onClick={() => handleAttachLines()}>{t('global.add')}</Button>
        </DialogActions>
      </Dialog>
    </TenantPage>
  );
}

export default TransportDocumentDetail;
