import { Button, ButtonGroup } from '@mui/material';
import { macroExamService } from 'services/api';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import TenantPage from 'components/common/TenantPage';
import DataGridCell from 'components/common/DataGridCell';

export const MacroExams = () => {
  const translationPrefix = 'pages.macro-exams.list';
  const { t } = useTranslation();

  const columns: GridColumns = [
    {
      field: 'code',
      headerName: t(`global.code`),
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        const macroExam = params.row;
        return (
          <div>
            <Link to={`${macroExam.id}`}>
              <b className="text-secondary-500">{macroExam.code}</b>
            </Link>
          </div>
        );
      }
    },
    {
      field: 'description',
      headerName: t(`global.description`),
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return <DataGridCell content={params.value} />;
      }
    },
    {
      field: 'exams',
      headerName: t(`global.exams`),
      sortable: false,
      width: 350,
      valueGetter: (params) => params.value && params.value.join(', '),
      renderCell: (params: GridRenderCellParams) => {
        return <DataGridCell content={params.value} />;
      }
    }
  ];

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <div className="flex justify-end items-center">
            <ButtonGroup>
              <IsTenantAdministrator>
                <Link to="new">
                  <Button variant="outlined" size="small">
                    <AddIcon />
                    {t(`${translationPrefix}.add`)}
                  </Button>
                </Link>
              </IsTenantAdministrator>
            </ButtonGroup>
          </div>
        }>
        <div className="mt-6">
          <EnhancedDataGrid columns={columns} service={macroExamService} />
        </div>
      </TenantPage>
    </>
  );
};

export default MacroExams;
