import { axiosPrivate } from 'services/axios';
import { IProductWarehouse } from 'interfaces';
import { BaseService } from './BaseService';

class ProductWarehouseService extends BaseService<IProductWarehouse> {
  constructor() {
    super('warehouse-products');
  }
  canDelete = true;

  updateThresholds = async (thresholds: any[]) => {
    const res = await axiosPrivate.post(`${this.modulePrefix}/update-thresholds/`, {
      product_warehouses: thresholds
    });
    return res.data;
  };

  importFromFileDetail = async (
    importFile: File,
    warehouseId: number,
    attachmentName = 'import_file'
  ) => {
    const res = await axiosPrivate.postForm(`warehouses/${warehouseId}/import-from-file/`, {
      [attachmentName]: importFile
    });
    return res.data;
  };

  bulkDeleteByProduct = async (productId: number) => {
    const res = await axiosPrivate.post(`${this.modulePrefix}/bulk-delete-product/`, {
      product: productId
    });
    return res.data;
  };

  bulkDeleteWarehouse = async (warehouseId: number) => {
    const res = await axiosPrivate.post(`${this.modulePrefix}/bulk-delete-warehouse/`, {
      warehouse: warehouseId
    });
    return res.data;
  };
}

export const productWarehouseService = new ProductWarehouseService();
