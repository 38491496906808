import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';

import toast from 'features/toast';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { IOptionFM } from 'interfaces';

import { fmTagService } from 'services/api/documents/FMTagService';
import AddDocumentForm from './AddDocumentForm';

interface IAddDocumentDialog {
  onSubmit: (values) => void;
  originalService?: string;
  objectId?: number;
}

export const AddDocumentDialog = (params: IAddDocumentDialog) => {
  const { t } = useTranslation();

  const { onSubmit, objectId, originalService } = params;

  const [categorys, setCategorys] = useState<IOptionFM[]>([]);
  const [openDocumentDialog, setOpenDocumentDialog] = useState(false);

  // DIALOG functions management

  const handleCloseDocumentDialog = () => {
    setOpenDocumentDialog(false);
  };

  // TAGs management
  const startingUploadTags = () => {
    fmTagService.getAllPaginated(100, 0).then((response) => {
      setCategorys(response.results);
    });
  };

  /**
   * Function to handle the submission of a new document.
   * Uploads the document to the API and refreshes the list of documents.
   *
   * @param {Object} values - The form values with the document details.
   */
  const onSubmitNewDocument = async (values) => {
    if (values.attachment == null || values.attachment == undefined) {
      toast.error(t('entities.document.empty-error'));
      return;
    }

    onSubmit(values);

    // close document dialog
    setOpenDocumentDialog(false);
  };

  useEffect(() => {
    startingUploadTags();
  }, []);

  return (
    <>
      <Button variant="contained" className="" onClick={() => setOpenDocumentDialog(true)}>
        {t(`global.insert-in-archive`)}
      </Button>
      <Dialog open={openDocumentDialog} onClose={handleCloseDocumentDialog} fullWidth maxWidth="md">
        {/* Dialog for inserting new documents */}
        <div className="text-2xl p-5">{t('entities.document.title')}</div>
        <DialogContent>
          {/* Form for inserting new documents */}
          <AddDocumentForm onSubmit={onSubmitNewDocument} categorys={categorys} />
        </DialogContent>
        <DialogActions>
          {/* Buttons for closing the dialog */}
          <Button onClick={handleCloseDocumentDialog} color="error">
            {t('actions.reset')}
          </Button>
          <Button type="submit" form="addNewDocument">
            {t(`actions.confirm`)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
