import { useSnackbar } from 'notistack';
import { loadingActions } from 'features';
import { typeService } from 'services/api';
import { useAppDispatch } from 'app/store';
import TenantPage from 'components/common/TenantPage';
import { useTranslation } from 'react-i18next';
import TypeForm from '../TypeForm';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { IBaseEntity } from 'interfaces';

export function EditType() {
  const translationPrefix = 'types.pages.new';
  const { typeId } = useParams();
  const [type, setType] = useState<IBaseEntity>();

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    typeService.get(+typeId).then(setType);
  }, []);

  const updateType = async (values) => {
    dispatch(loadingActions.startLoading());
    typeService.update(type.id, values).then((res) => {
      enqueueSnackbar('Tipo aggiunto con successo');
    });
    dispatch(loadingActions.stopLoading());
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      entityCode={type?.code}>
      <TypeForm type={type} onSubmit={updateType} />
    </TenantPage>
  );
}

export default EditType;
