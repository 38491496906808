import { Alert, Button, Skeleton, Tooltip, Typography } from '@mui/material';
import { IUnderStockQuantity } from 'interfaces';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { stockQuantityService } from 'services/api';
import BiotechIcon from '@mui/icons-material/Biotech';

function UnderstockProducts() {
  const { t } = useTranslation();

  const [productsUnderStock, setProductsUnderStock] = useState<IUnderStockQuantity[]>();

  useEffect(() => {
    setProductsUnderStock(null);
    stockQuantityService
      .getUnderstockProducts(3, 0)
      .then((res) => setProductsUnderStock(res.results));
  }, []);

  return (
    <div>
      <Typography sx={{ fontSize: 14 }} color="secondary" gutterBottom>
        <BiotechIcon />
        {t('dashboard.notifications-understock.title')}
      </Typography>
      {!productsUnderStock && (
        <>
          <Skeleton variant="rectangular" height={32} />
          <br />
          <Skeleton variant="rectangular" height={32} />
          <br />
          <Skeleton variant="rectangular" height={32} />
        </>
      )}
      {productsUnderStock &&
        productsUnderStock.map((stock) => (
          <Tooltip
            key={stock.product__id + ' - ' + stock.warehouse__id}
            title={stock.product__code}>
            <Alert
              severity={'warning'}
              className="mb-2"
              action={
                // attenzione che qui rimando alla scheda del prodict e nulla di più
                <NavLink to={`/tenant/stock/understock`}>
                  <Button color="inherit" size="small">
                    {t('dashboard.notifications-understock.go')}
                  </Button>
                </NavLink>
              }>
              {t('dashboard.notifications-understock.row-body', {
                product: stock.product__code,
                warehouse: stock.warehouse__code
              })}
            </Alert>
          </Tooltip>
        ))}
    </div>
  );
}

export default UnderstockProducts;
