import {
  GridColDef,
  GridColumns,
  GridRenderCellParams,
  GridValueFormatterParams
} from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import FileUploader from 'components/common/file-uploader';
import dayjs from 'dayjs';
import { dateFilterOperators, filterOperators } from '../../../../constants';
import productStatusCell from 'components/common/enhanced/cells/product-status-cell';
import { Link } from 'react-router-dom';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';

export function ContractLinesAllColumns(service: any): GridColDef[] {
  const { t } = useTranslation();
  const translationPrefix = 'entities.movement';

  const columns: GridColumns | any[] = [
    {
      field: 'code',
      headerName: t(`global.code`),
      filterOperators,
      renderCell: (params: GridRenderCellParams) => {
        const contract = params.row;
        return (
          <div>
            <Link to={`${contract.id}`}>
              <b className="text-secondary-500">{contract.code}</b>
            </Link>
          </div>
        );
      }
    },
    { field: 'supplier', filterOperators, headerName: t(`global.supplier`), flex: 1 },
    {
      field: 'laboratories',
      filterOperators,
      headerName: t(`global.laboratories`),
      valueGetter: (params) => params.row.laboratory_codes,
      flex: 1
    },
    {
      field: 'active',
      type: 'boolean',
      headerName: t(`global.active`),
      width: 100,
      valueGetter: (params) => (params.value ? 1 : 2),
      valueFormatter: (params: GridValueFormatterParams) =>
        t(`entities.product.status_options.${params.value}`),
      renderCell: productStatusCell(t)
    },
    {
      field: 'tender_ref',
      filterOperators,
      headerName: t(`entities.contract.tender_ref`),
      flex: 1
    },
    {
      field: 'commission_ref',
      filterOperators,
      headerName: t(`entities.contract.commission_ref`),
      flex: 1
    },
    {
      field: 'drafting_date',
      filterOperators: dateFilterOperators,
      type: 'date',
      headerName: t(`entities.contract.drafting_date`),
      flex: 1
    },
    {
      field: 'expiration_date',
      headerName: t(`entities.contract.expiration_date`),
      type: 'date',
      filterOperators: dateFilterOperators,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const value = params.value;
        const diff = -1 * dayjs().diff(value, 'months');

        return (
          <div>
            {diff < 3 && <NewReleasesIcon color="warning" />}
            <span className="ml-2">{value}</span>
          </div>
        );
      }
    },
    {
      field: 'commercial_bid',
      headerName: t(`entities.contract.commercial_bid`),
      renderCell: (params: GridRenderCellParams) => {
        const contract = params.row;
        return (
          <IsTenantAdministrator>
            <div>
              <FileUploader
                service={service}
                entityId={contract.id}
                attachmentName={'commercial_bid'}
                filename={contract.commercial_bid}
              />
            </div>
          </IsTenantAdministrator>
        );
      },
      width: 150,
      filterable: false,
      sortable: false
    },
    {
      field: 'bid_approval',
      headerName: t(`entities.contract.bid_approval`),
      renderCell: (params: GridRenderCellParams) => {
        const contract = params.row;
        return (
          <IsTenantAdministrator>
            <div>
              <FileUploader
                service={service}
                entityId={contract.id}
                attachmentName={'bid_approval'}
                filename={contract.bid_approval}
              />
            </div>
          </IsTenantAdministrator>
        );
      },
      width: 150,
      filterable: false,
      sortable: false
    }
  ];

  return columns;
}
