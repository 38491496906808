import { ICostElement } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class CostElementService extends BaseService<ICostElement> {
  constructor() {
    super('cost-elements');
  }

  getLayerOptions = async () => {
    const res = await axiosPrivate.get(`${this.modulePrefix}/layer_model_options/`);
    return res.data;
  };

  importFromFile = async (importFile: File, attachmentName = 'import_file') => {
    const res = await axiosPrivate.postForm(`${this.modulePrefix}/import-from-file/`, {
      [attachmentName]: importFile
    });
    return res.data;
  };
}

export const costElementService = new CostElementService();
