import { SyntheticEvent } from 'react';
import { useField } from 'react-final-form';
import {
  Autocomplete,
  Box,
  Chip,
  Popper,
  styled,
  TextField,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { IOption } from 'interfaces';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import React from 'react';
import { autocompleteClasses } from '@mui/material/Autocomplete';

export const MultipleAutocompleteAdapter = ({
  input: { name },
  options,
  optionLabel,
  optionValue,
  initValue,
  setValue,
  freeSolo,
  ...rest
}: any) => {
  const { input, meta } = useField(name);

  const optLabel = optionLabel ?? 'code';
  const optValue = optionValue ?? 'id';

  const onChange = (e: SyntheticEvent, values) => {
    const newValues = [];
    if (values) {
      values.forEach((value) => {
        if (typeof value === 'string') {
          const x = {};
          x[optLabel] = value;
          x[optValue] = value;
          newValues.push(value ?? '');
        } else {
          newValues.push(value[optValue] ?? '');
        }
      });
      input.onChange(newValues);
    }
  };

  const getOptionLabel = (option: IOption | string) => {
    switch (typeof option) {
      case 'string':
        return option;

      default:
        return option[optLabel];
    }
  };

  const disabled = () => {
    if (freeSolo === true) return false;
    return !options.length;
  };

  // Start strange stuff
  const LISTBOX_PADDING = 16; // px

  function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
      lineHeight: '12px',
      paddingTop: '4px',
      fontSize: '12px',
      paddingBottom: '4px'
    };

    // eslint-disable-next-line no-debugger
    // eslint-disable-next-line no-prototype-builtins
    // return (
    //   <Box component="li" {...props}>
    //     <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
    //       {`${dataSet[0].key}`}
    //     </Typography>{' '}
    //   </Box>
    // );
    return (
      <Box component="li" {...dataSet[0]} style={inlineStyle}>
        {`${dataSet[0].key}`}
      </Box>
    );
  }

  const OuterElementContext = React.createContext({});

  // eslint-disable-next-line react/display-name
  const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
  });

  function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
      if (ref.current != null) {
        ref.current.resetAfterIndex(0, true);
      }
    }, [data]);
    return ref;
  }

  // Adapter for react-window
  const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
    function ListboxComponent(props, ref) {
      const { children, ...other } = props;
      const itemData: React.ReactElement[] = [];
      (children as React.ReactElement[]).forEach(
        (item: React.ReactElement & { children?: React.ReactElement[] }) => {
          itemData.push(item);
          itemData.push(...(item.children || []));
        }
      );

      const theme = useTheme();
      const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true
      });
      const itemCount = itemData.length;
      const itemSize = smUp ? 36 : 48;

      const getChildSize = (child: React.ReactElement) => {
        // eslint-disable-next-line no-prototype-builtins
        if (child.hasOwnProperty('group')) {
          return 48;
        }

        return itemSize;
      };

      const getHeight = () => {
        if (itemCount > 8) {
          return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
      };

      const gridRef = useResetCache(itemCount);

      return (
        <div ref={ref}>
          <OuterElementContext.Provider value={other}>
            <VariableSizeList
              itemData={itemData}
              height={getHeight() + 2 * LISTBOX_PADDING}
              width="100%"
              ref={gridRef}
              outerElementType={OuterElementType}
              innerElementType="ul"
              itemSize={(index) => getChildSize(itemData[index])}
              overscanCount={5}
              itemCount={itemCount}>
              {renderRow}
            </VariableSizeList>
          </OuterElementContext.Provider>
        </div>
      );
    }
  );

  const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
      boxSizing: 'border-box',
      '& ul': {
        padding: 0,
        margin: 0
      }
    }
  });

  return (
    <Autocomplete
      disableListWrap
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      multiple={true}
      freeSolo={freeSolo === true}
      getOptionLabel={(option) => option[optLabel] ?? ''}
      options={options}
      defaultValue={initValue ?? []}
      disabled={disabled()}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option[optValue] === value[optValue]}
      renderTags={(value: readonly string[], getTagProps) =>
        value.map((option: string, index: number) => (
          <Chip
            key={`${option}-${index}`}
            variant="outlined"
            label={getOptionLabel(option)}
            {...getTagProps({ index })}
          />
        ))
      }
      renderOption={(props, option, state) => [props, option, state.index] as React.ReactNode}
      // renderOption={(props, option) => {
      //   return (
      //     <Box component="li" {...props}>
      //       {option[optLabel]}
      //     </Box>
      //   );
      // }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            {...rest}
            helperText={meta.touched ? meta.error : ''}
            error={meta.error && meta.touched}
            fullWidth
          />
        );
      }}
    />
  );
};
