import { ISector } from 'interfaces';
import { ExportableResourceService } from './ExportableResourceService';

class TypeService extends ExportableResourceService<ISector> {
  constructor() {
    super('types');
  }
  canDelete = true;
}

export const typeService = new TypeService();
