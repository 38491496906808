import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IOption } from 'interfaces';
import {
  equipmentService,
  examService,
  laboratoryService,
  operationalUnitService,
  sectorService,
  workstationService
} from 'services/api';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { IdentityField } from 'components/form/IdentityField';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { useTranslation } from 'react-i18next';
import toast from 'features/toast';
import { useEffect, useState } from 'react';
import { activityService } from 'services/api/ActivityService';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import SectionTitle from 'components/common/SectionTitle';
import { DatePickerAdapter } from 'components/form/DatePickerAdapter';

type LayerType = {
  id: number;
  model: string;
};

export default function AddActivityDialog(props: {
  expertiseId: number;
  open: boolean;
  setOpen: (value) => any;
  callback?: any;
}) {
  const { t } = useTranslation();

  const { expertiseId, open, setOpen, callback } = props;
  const [options, setOptions] = useState<IOption[]>([]);
  const [mode, setMode] = useState<LayerType>({ id: null, model: null });
  const [modes, setModes] = useState<LayerType[]>([]);

  useEffect(() => {
    activityService.getLayerOptions().then(setModes);
  }, []);

  useEffect(() => {
    if (!mode) return;
    switch (mode.model) {
      case 'laboratory':
        laboratoryService.getAllBaseInformation().then(setOptions);
        break;
      case 'operationalunit':
        operationalUnitService.getAllBaseInformation().then(setOptions);
        break;
      case 'sector':
        sectorService.getAllBaseInformation().then(setOptions);
        break;
      case 'workstation':
        workstationService.getAllBaseInformation().then(setOptions);
        break;
      case 'equipment':
        equipmentService.getAllBaseInformation().then(setOptions);
        break;
      case 'exam':
        examService.getAllBaseInformation().then(setOptions);
        break;
      default:
        setOptions([]);
    }
  }, [mode]);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (values) => {
    activityService.add({ ...values, content_type: mode.id, expertise: expertiseId }).then(() => {
      toast.success('Attività aggiunta');
      handleClose();
      if (callback) callback();
    });
  };

  const handleModeChange = (event: SelectChangeEvent<number>) => {
    setMode(modes.find((m) => +m.id == +event.target.value));
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle>Aggiungi attività</DialogTitle>
      <DialogContent>
        <ReactFinalForm onSubmit={onSubmit} formId="addCostLink" hideControls>
          <div className="flex flex-wrap my-4">
            <SectionTitle>{t('global.description')}</SectionTitle>
            <div className="w-full p-0 mt-4">
              <IdentityField
                name="name"
                component={TextFieldAdapter}
                label={t('entities.activity.name')}
                type="text"
              />
            </div>
            <div className="w-full p-0 mt-4">
              <IdentityField
                name="description"
                component={TextFieldAdapter}
                label={t('global.description')}
                type="text"
              />
            </div>
          </div>
          <SectionTitle>Impegno</SectionTitle>
          <div className="flex flex-wrap my-4">
            <div className="w-1/3 p-0 ">
              <IdentityField
                name="monthly_hours"
                component={TextFieldAdapter}
                label={t('entities.activity.monthly_hours')}
                type="text"
              />
            </div>
            <div className="w-1/3 p-0 ">
              <IdentityField
                name="output_number"
                component={TextFieldAdapter}
                label={t('entities.activity.output_number')}
                type="text"
              />
            </div>
            <div className="w-1/3 p-0 ">
              <IdentityField
                name="monthly_percentage"
                component={TextFieldAdapter}
                label={t('entities.activity.monthly_percentage')}
                type="text"
              />
            </div>
          </div>
          <SectionTitle>Periodo validità</SectionTitle>
          <div className="flex flex-wrap my-4">
            <div className="w-1/2 p-0 ">
              <IdentityField
                name="date_start"
                component={DatePickerAdapter}
                label={t('global.date_start')}
                type="text"
              />
            </div>
            <div className="w-1/2 p-0 ">
              <IdentityField
                name="date_end"
                component={DatePickerAdapter}
                label={t('global.date_end')}
                type="text"
              />
            </div>
          </div>
          <div className="w-full my-4">
            <SectionTitle>Area</SectionTitle>

            <Select
              label="Layer"
              fullWidth
              value={mode.id}
              onChange={handleModeChange}
              className="mb-4">
              <MenuItem value="">
                <em>---</em>
              </MenuItem>
              {modes &&
                modes.map((mode) => (
                  <MenuItem key={mode.id} value={mode.id}>
                    {mode.model}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div className="w-full my-4">
            {options && (
              <IdentityField
                name="object_id"
                component={AutocompleteAdapter}
                options={options}
                label={t('global.cost-source')}
              />
            )}
          </div>
        </ReactFinalForm>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('actions.cancel')}</Button>
        <Button type="submit" form="addCostLink">
          {t('global.add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
