import { IEquipmentInvoiceLine } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class EquipmentInvoiceLineService extends BaseService<IEquipmentInvoiceLine> {
  constructor() {
    super('equipment-invoice-lines');
  }

  getUnassigned = async (limit = 25, offset = 0, params: Record<string, unknown> = {}) => {
    const res = await axiosPrivate.get(`${this.modulePrefix}/unassigned/`, {
      params: { limit, offset, ...params }
    });
    return res.data;
  };
}

export const equipmentInvoiceLineService = new EquipmentInvoiceLineService();
