import { Button, ButtonGroup, Tooltip } from '@mui/material';
import { laboratoryService, locationService, sectorService, warehouseService } from 'services/api';
import { loadingActions } from 'features/loadingSlice';
import { NavLink } from 'react-router-dom';
import { useAppDispatch } from 'app/store';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import Tree from 'react-d3-tree';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';

export const TopologyTree = () => {
  const { t } = useTranslation();
  const translationPrefix = 'topology.pages.tree';

  const dispatch = useAppDispatch();
  const [orgChart, setOrgChart] = useState<any>();

  const loadEntities = async () => {
    const laboratories = await laboratoryService.getAllBaseInformation();
    const sectors = await sectorService.getAllBaseInformation();
    const warehouses = await warehouseService.getAllBaseInformation();
    const locations = await locationService.getAllBaseInformation();
    return { laboratories, sectors, warehouses, locations };
  };

  useEffect(() => {
    dispatch(loadingActions.startLoading());
    loadEntities().then((res) => {
      const tmpOrgChart = {
        name: 'DISTRICT',
        children: []
      };

      res.laboratories.forEach((lab) => {
        tmpOrgChart.children.push({
          name: lab.code,
          children: res.sectors
            .filter((s) => s.laboratory === lab.id)
            .map((sector) => ({
              name: sector.code,
              children: res.warehouses
                .filter((w) => w.sector === sector.id)
                .map((w) => ({
                  name: w.code,
                  children: res.locations
                    .filter((location) => location.warehouse === w.id)
                    .map((location) => ({
                      name: location.code
                    }))
                }))
            }))
        });
      });

      setOrgChart(tmpOrgChart);
      dispatch(loadingActions.stopLoading());
    });
  }, []);

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      menuRight={
        <div>
          <ButtonGroup className="mr-4">
            <Tooltip title="Edit view">
              <NavLink to="..">
                <Button size="small">
                  <ViewWeekIcon />
                </Button>
              </NavLink>
            </Tooltip>
          </ButtonGroup>
        </div>
      }>
      <div>TopologyTree</div>
      <div id="treeWrapper" className="w-full h-96">
        {orgChart && <Tree hasInteractiveNodes data={orgChart} orientation="vertical" />}
      </div>
    </TenantPage>
  );
};

export default TopologyTree;
