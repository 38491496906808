import { IActivity } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class ActivityService extends BaseService<IActivity> {
  constructor() {
    super('staff-management/activities');
  }

  getLayerOptions = async () => {
    const res = await axiosPrivate.get(`${this.modulePrefix}/layer_model_options/`);
    return res.data;
  };

  canDelete = true;
}

export const activityService = new ActivityService();
