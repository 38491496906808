import { locationService, productService } from 'services/api';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import LocationForm from '../LocationForm';
import { useEffect, useState } from 'react';
import { ILocation, IOption } from 'interfaces';
import { useParams } from 'react-router-dom';

export function EditLocation() {
  const translationPrefix = 'pages.locations.edit';
  const { locationId } = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [location, setLocation] = useState<ILocation>();
  const [products, setProducts] = useState<IOption[]>([]);

  useEffect(() => {
    if (locationId) {
      locationService.get(+locationId).then(setLocation);
      productService.getAllBaseInformation().then(setProducts);
    }
  }, []);

  const updateLocation = async (values) => {
    locationService.update(location.id, values).then((res) => {
      enqueueSnackbar('Piazza modificata con successo');
    });
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      entityCode={location?.code}>
      {location && <LocationForm location={location} onSubmit={updateLocation} />}
    </TenantPage>
  );
}

export default EditLocation;
