import { ILISTranscode } from 'interfaces';
import { BaseService } from './BaseService';

class LISTranscodesService extends BaseService<ILISTranscode> {
  constructor() {
    super('req-lab-transcodes');
  }
}

export const lisTranscodesService = new LISTranscodesService();
