import { GridActionsCellItem, GridColumns, GridRowParams } from '@mui/x-data-grid-premium';
import { filterOperators } from '../../../../constants';
import { useTranslation } from 'react-i18next';
import { baseCell } from '../cells/base-cell';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ConfirmationDeleteTableDialog from 'components/helpers/ConfirmationGridDelete';

export function DocumentColumns(
  handleDownload?: (row: any) => void,
  handleDelete?: (id: number) => void
): GridColumns {
  const { t } = useTranslation();

  const columns: GridColumns = [
    {
      field: 'name',
      headerName: t(`global.name`),
      filterOperators,
      width: 200,
      renderCell: baseCell
    },
    {
      field: 'description',
      headerName: t(`global.description`),
      filterOperators,
      width: 400,
      renderCell: baseCell
    },
    {
      field: 'category',
      headerName: t(`global.category`),
      filterOperators,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'upload_date',
      headerName: t(`pages.document.upload_date`),
      filterOperators,
      width: 200,
      renderCell: baseCell
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t(`global.actions`),
      getActions: (params: GridRowParams) => {
        const actions = [];
        actions.push(
          <GridActionsCellItem
            key={998}
            icon={<CloudDownloadIcon />}
            onClick={() => handleDownload(params.row)}
            label="Download"
          />
        );
        actions.push(
          <ConfirmationDeleteTableDialog handleDelete={handleDelete} id={params.row.id} />
        );

        return actions;
      }
    }
  ];

  return columns;
}
