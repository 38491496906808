import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { ICategoryState, IOption } from 'interfaces';
import React, { useEffect, useState } from 'react';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { sectorService } from 'services/api';

interface CategoryFormProps {
  category: ICategoryState;
  onSubmit: any;
}
function CategoryForm(props: CategoryFormProps) {
  const { t } = useTranslation();
  const { category, onSubmit } = props;
  const [sectors, setSectors] = useState<IOption[]>([]);

  useEffect(() => {
    sectorService.getAllBaseInformation().then(setSectors);
  }, []);

  return (
    <div>
      {category && (
        <ReactFinalForm onSubmit={onSubmit} edit={!!category.id}>
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/3 p-0 md:pr-4">
              <IdentityField
                name="code"
                component={TextFieldAdapter}
                initialValue={category.code}
                label={t('global.code')}
                placeholder={t('global.code')}
                type="text"
              />
            </div>
            <div className="w-full md:w-2/3 ">
              <IdentityField
                name="description"
                component={TextFieldAdapter}
                initialValue={category.description}
                label={t('global.description')}
                placeholder={t('global.description')}
                type="text"
              />
            </div>
            <div className="w-full mb-4 pt-4">
              {sectors && (
                <IdentityField
                  name="sector"
                  component={AutocompleteAdapter}
                  initValue={sectors.find((s) => s.id === category.sector)}
                  options={sectors}
                  label={t('global.sector')}
                  type="text"
                />
              )}
            </div>
          </div>
        </ReactFinalForm>
      )}
    </div>
  );
}

export default CategoryForm;
