import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { filterOperators } from './../../../../constants';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { baseCell } from '../cells/base-cell';

export function RequestLaboratoryTranscodeColumns(): GridColDef[] {
  const { t } = useTranslation();
  return [
    {
      field: 'department',
      headerName: t(`global.department`),
      filterOperators,
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        const transcode = params.row;
        return (
          <div>
            <Link to={`${transcode.id}`}>
              <b className="text-transcode-500">{transcode.department}</b>
            </Link>
          </div>
        );
      }
    },
    {
      field: 'laboratory',
      headerName: t(`global.laboratory`),
      filterOperators,
      width: 200,
      renderCell: baseCell
    }
  ];
}
