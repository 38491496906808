import { IconButton } from '@mui/material';
import { ExamsColumns } from 'components/common/enhanced/common-headers';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { examService } from 'services/api';
import ProductToCostDialog from './ProductToCostDialog';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface ProductExamsProps {
  productId: string | number;
}

function ProductExams(props: ProductExamsProps) {
  const { t } = useTranslation();
  const { productId } = props;

  const datagridRefresh = useRef(null);
  const [mode, setMode] = useState('');

  const reloadExams = () => {
    if (datagridRefresh) datagridRefresh.current();
  };

  const getExams = (limit = 0, offset = 0, params: Record<string, unknown> = {}) => {
    return examService.getAllPaginated(limit, offset, { ...params, product: productId });
  };

  const columns = ExamsColumns(+productId, datagridRefresh);

  return (
    <>
      <b className="text-primary-600">{t('menu.exams')}</b>
      <IconButton color="primary" onClick={() => setMode('exams')}>
        <AddCircleOutlineIcon />
      </IconButton>
      <b className="text-primary-600">{t('menu.macro-exams')}</b>
      <IconButton color="primary" onClick={() => setMode('macroexams')}>
        <AddCircleOutlineIcon />
      </IconButton>
      <br />
      <EnhancedDataGrid
        getMethod={getExams}
        service={examService}
        columns={columns}
        refresh={datagridRefresh}
      />
      <ProductToCostDialog
        productId={+productId}
        mode={mode}
        setMode={setMode}
        callback={reloadExams}
      />
    </>
  );
}

export default ProductExams;
