import { ILaboratory } from 'interfaces';
import { BaseService } from './BaseService';

class LaboratoryService extends BaseService<ILaboratory> {
  constructor() {
    super('laboratories');
  }

  // Custom methods
}

export const laboratoryService = new LaboratoryService();
