import ReactFinalForm from 'components/form/ReactFinalForm';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { IRequestDepartmentTranscode } from 'interfaces';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';

interface RequestDepartmentTranscodeFormProps {
  transcode: IRequestDepartmentTranscode;
  onSubmit: any;
}
function RequestDepartmentTranscodeForm(props: RequestDepartmentTranscodeFormProps) {
  const { t } = useTranslation();
  const { transcode, onSubmit } = props;

  return (
    <div>
      {transcode && (
        <ReactFinalForm onSubmit={onSubmit} edit={!!transcode.id}>
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 p-0 md:pr-4">
              <IdentityField
                name="department"
                component={TextFieldAdapter}
                initialValue={transcode.department}
                label={t('global.department')}
                placeholder={t('global.department')}
                type="text"
              />
            </div>
            <div className="w-full md:w-1/2 ">
              <IdentityField
                name="laboratory"
                component={TextFieldAdapter}
                initialValue={transcode.laboratory}
                label={t('global.laboratory')}
                placeholder={t('global.laboratory')}
                type="text"
              />
            </div>
          </div>
        </ReactFinalForm>
      )}
    </div>
  );
}

export default RequestDepartmentTranscodeForm;
