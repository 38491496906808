import { IExam } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { ExportableResourceService } from './ExportableResourceService';

class ExamService extends ExportableResourceService<IExam> {
  constructor() {
    super('exams');
  }
  canBulkDelete = true;

  importFromFile = async (importFile: File, attachmentName = 'import_file') => {
    const res = await axiosPrivate.postForm(`${this.modulePrefix}/import-from-file/`, {
      [attachmentName]: importFile
    });
    return res.data;
  };

  assignProduct = async (examId: number, product: number) => {
    const res = await axiosPrivate.post(`${this.modulePrefix}/${examId}/assign_product/`, {
      products: [{ product }]
    });
    return res.data;
  };
}

export const examService = new ExamService();
