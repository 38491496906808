import { categoryService } from 'services/api';
import { loadingActions } from 'features';
import { useAppDispatch } from 'app/store';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import CategoryForm from '../CategoryForm';
import { useEffect, useState } from 'react';
import { ICategoryState } from 'interfaces';
import { useParams } from 'react-router-dom';
import { Card, CardContent } from '@mui/material';
import EntityHistory from 'components/widgets/EntityHistory';

export function EditCategory() {
  const translationPrefix = 'types.categories.new';
  const { categoryId } = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [category, setCategory] = useState<ICategoryState>();
  useEffect(() => {
    categoryService.get(+categoryId).then(setCategory);
  }, []);

  const updateCategory = async (values) => {
    dispatch(loadingActions.startLoading());
    categoryService.update(category.id, values).then((res) => {
      enqueueSnackbar('Categoria aggiunta con successo');
    });
    dispatch(loadingActions.stopLoading());
  };
  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      entityCode={category?.code}>
      <CategoryForm category={category} onSubmit={updateCategory} />
      <div className="w-full md:w-1/2  p-4">
        {category && (
          <Card variant="outlined">
            <CardContent>
              <EntityHistory service={categoryService} entityId={category.id} />
            </CardContent>
          </Card>
        )}
      </div>
    </TenantPage>
  );
}

export default EditCategory;
