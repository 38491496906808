import { TenantPage } from 'components/common';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { equipmentInvoiceLineService } from 'services/api';
import { EquipmentInvoiceLinesColumns } from 'components/common/enhanced/common-headers/equipment-invoice-lines';
import toast from 'features/toast';

export function ExpectedEquipmentInvoices() {
  const { t } = useTranslation();
  const translationPrefix = 'pages.equipment-invoices.list';
  const datagridRefresh = useRef(null);

  /**
   * Handles the deletion of an equipment invoice line.
   *
   * @param {number} id - The ID of the equipment invoice line to delete.
   * @return {void} This function does not return anything.
   */
  const handleOnDelete = (id: number) => {
    // Call the delete function of the equipmentInvoiceLineService to delete the specified equipment invoice line.
    equipmentInvoiceLineService.delete(id).then(() => {
      // Show a success message after the deletion is successful.
      toast.success(t('actions.successfully.deleted'));

      // Trigger the refresh of the datagrid.
      datagridRefresh.current();
    });
  };

  const columns = EquipmentInvoiceLinesColumns(handleOnDelete);

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <EnhancedDataGrid
        columns={columns}
        service={equipmentInvoiceLineService}
        getMethod={equipmentInvoiceLineService.getUnassigned}
        refresh={datagridRefresh}
        disableDelete
      />
    </TenantPage>
  );
}

export default ExpectedEquipmentInvoices;
