import { ISAPSettings } from 'interfaces';
import { BaseService } from './BaseService';

class SAPSettingsService extends BaseService<ISAPSettings> {
  constructor() {
    super('sap-settings');
  }
}

export const sapSettingsService = new SAPSettingsService();
