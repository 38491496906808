import { useAppDispatch } from 'app/store';
import { TenantPage } from 'components/common';
import { loadingActions } from 'features';
import { IWorkstation } from 'interfaces';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { workstationService } from 'services/api';
import WorkstationForm from '../WorkstationForm';

export function EditWorkstation() {
  const { t } = useTranslation();
  const translationPrefix = 'pages.workstation.edit';
  const { workstationId } = useParams();

  const [workstation, setWorkstation] = useState<IWorkstation>();

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const updateWorkstation = async (values) => {
    dispatch(loadingActions.startLoading());
    workstationService.update(workstation.id, values).then(() => {
      enqueueSnackbar('Workstation aggiornata con successo', { variant: 'success' });
    });
    dispatch(loadingActions.stopLoading());
  };

  useEffect(() => {
    workstationService.get(+workstationId).then(setWorkstation);
  }, []);

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      entityCode={workstation?.code}>
      <WorkstationForm workstation={workstation} onSubmit={updateWorkstation} />
    </TenantPage>
  );
}

export default EditWorkstation;
