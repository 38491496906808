import { ButtonGroup } from '@mui/material';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-premium';
import ImportFileUploader from 'components/common/file-uploader/ImportFileUploader';
import { IsSuperAdmin } from 'components/helpers/IsSuperAdmin';
import { useTranslation } from 'react-i18next';
import { contractLineService, contractService } from 'services/api';
import ConfirmationButtonDialog from 'components/helpers/ConfirmationButtonDelete';
import toast from 'features/toast';

function EnhancedExportToolbar(entityId?: number, refresh?: any) {
  const { t } = useTranslation();

  const handleDelete = (id?: number | null) => {
    contractService.purgeLines(entityId).then(() => {
      toast.success(t('actions.successfully.deleted'));
      if (refresh) refresh();
    });
  };

  const CustomToolBar = () => {
    return (
      <GridToolbarContainer>
        <div className="w-auto flex justify-end">
          <ButtonGroup size="small">
            <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
            <IsSuperAdmin>
              <ImportFileUploader service={contractLineService} entityId={entityId} />
              <ConfirmationButtonDialog handleDelete={handleDelete} />
            </IsSuperAdmin>
          </ButtonGroup>
        </div>
      </GridToolbarContainer>
    );
  };
  return CustomToolBar;
}
export default EnhancedExportToolbar;
