import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IAuthState } from '../interfaces';
import { axiosPrivate, axiosPublic } from '../services/axios';

const modulePrefix = 'auth';

const initialState: IAuthState = {
  user: null,
  email: null,
  groups: null,
  warehouse: null,
  sap_active: null
};

export const login = createAsyncThunk(
  `${modulePrefix}/login`,
  async (credentials: { username: string; password: string }) => {
    const res = await axiosPublic.post('token/', { ...credentials });
    return { ...res.data, username: credentials.username };
  }
);

export const logout = createAsyncThunk(`${modulePrefix}/logout`, async () => {
  const res = await axiosPrivate.post('logout/');
  return res.data;
});

export const authSlice = createSlice({
  name: modulePrefix,
  initialState: initialState,
  reducers: {
    superadminLogin: (state, { payload }) => {
      const { user, groups } = payload;
      state.user = user;
      state.groups = groups;
    },
    selectWarehouse: (state, { payload }) => {
      const { warehouse } = payload;
      state.warehouse = warehouse;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, { payload }) => {
        const { user, username, warehouse } = payload;
        state.user = username;
        state.email = user.email;
        state.groups = user.groups;
        state.warehouse = warehouse;
        state.sap_active = user.sap_active;
      })
      .addCase(login.rejected, (state) => {
        state.user = null;
        state.email = null;
        state.groups = null;
        state.warehouse = null;
        state.sap_active = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
        state.email = null;
        state.groups = null;
        state.warehouse = null;
        state.sap_active = null;
      })
      .addCase(logout.rejected, (state) => {
        state.user = null;
        state.email = null;
        state.groups = null;
        state.warehouse = null;
        state.sap_active = null;
      });
  }
});

export const authActions = authSlice.actions;
