const defaultOptions = {
  significantDigits: 3,
  thousandsSeparator: '.',
  decimalSeparator: ',',
  symbol: '€'
};

export const currencyFormatter = (v, options?) => {
  if (typeof v === 'string' && v.includes('*')) return v;
  if (v === null || v === undefined) return null;
  let value: string | number = +v;
  if (typeof value !== 'number') value = 0.0;
  options = { ...defaultOptions, ...options };
  value = value.toFixed(options.significantDigits);

  const [currency, decimal] = value.split('.');
  const res = `${options.symbol} ${currency.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    options.thousandsSeparator
  )}${options.decimalSeparator}${decimal}`;

  return res;
};
