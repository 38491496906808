import { IMetbaseDashboard } from 'interfaces';
import { BaseService } from './BaseService';

class MetabaseDashboardService extends BaseService<IMetbaseDashboard> {
  constructor() {
    super('dashboards');
  }
}

export const metabaseDashboardService = new MetabaseDashboardService();
