import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid-premium';
import DataGridCell from 'components/common/DataGridCell';
import { filterOperators, numberFilterOperators } from '../../../../constants';
import { currencyFormatter } from 'helpers/currency-formatter';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import { productService } from 'services/api';
import ConfirmationUnlinkTableDialog from 'components/helpers/ConfirmationGridUnlink';
import toast from 'features/toast';
import { baseCell } from '../cells/base-cell';

export function ExamsColumns(productId?: number, refresh?: any): GridColumns {
  const { t } = useTranslation();
  const translationPrefix = 'entities.exam';

  const columns: GridColumns = [
    {
      field: 'code',
      headerName: t(`${translationPrefix}.code`),
      width: 150,
      filterOperators,
      renderCell: (params: GridRenderCellParams) => {
        const supplier = params.row;
        return (
          <div>
            <Link to={`/tenant/cost-analysis/exams/${supplier.id}`}>
              <b className="text-secondary-500">{supplier.code}</b>
            </Link>
          </div>
        );
      }
    }
  ];

  ['workstations'].forEach((field) => {
    columns.push({
      field: field,
      width: 150,
      headerName: t(`global.workstations`),
      valueFormatter: ({ value }) => {
        const values = value.map((value) => value.code);
        return values.join(', ');
      },
      filterOperators: filterOperators,
      renderCell: (params: GridRenderCellParams) => {
        const value = params.value.map((value) => value.code);
        return <DataGridCell content={value ? value.join(',') : ''} />;
      }
    });
  });

  ['description', 'category', 'regional_code'].forEach((field) => {
    columns.push({
      field: field,
      width: 150,
      headerName: t(`${translationPrefix}.${field}`),
      filterOperators: filterOperators,
      sortable: false,

      renderCell: (params: GridRenderCellParams) => {
        return <DataGridCell content={params.value} />;
      }
    });
  });

  ['contractual_rate', 'regional_rate', 'rate_1', 'rate_2', 'rate_3', 'rate_4', 'rate_5'].forEach(
    (field) => {
      columns.push({
        field: field,
        width: 150,
        headerName: t(`${translationPrefix}.${field}`),
        editable: true,
        filterOperators: numberFilterOperators,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const price = params.value;
          return <DataGridCell content={currencyFormatter(price)} />;
        }
      });
    }
  );

  ['product_codes'].forEach((field) => {
    columns.push({
      field: field,
      width: 150,
      filterOperators: filterOperators,
      sortable: false,
      headerName: t(`${translationPrefix}.${field}`),
      renderCell: (params: GridRenderCellParams) => {
        const value = params.value;
        return <DataGridCell content={value ? value.join(',') : ''} />;
      }
    });
  });

  ['notes', 'extra_1', 'extra_2', 'extra_3', 'extra_4', 'extra_5'].forEach((field) => {
    columns.push({
      field: field,
      width: 200,
      headerName: t(`${translationPrefix}.${field}`),
      sortable: false,
      filterable: false,
      renderCell: baseCell
    });
  });

  ['tags', 'macro_exams'].forEach((field) => {
    columns.push({
      field: field,
      width: 150,
      filterOperators: filterOperators,
      sortable: false,
      headerName: t(`${translationPrefix}.${field}`),
      renderCell: (params: GridRenderCellParams) => {
        const value = params.value.map((value) => value.code);
        return <DataGridCell content={value ? value.join(',') : ''} />;
      }
    });
  });

  if (productId) {
    columns.push({
      field: 'actions',
      type: 'actions',
      headerName: t(`global.actions`),
      getActions: (params: GridRowParams) => {
        const actions = [];
        actions.push(
          <IsTenantAdministrator key={123}>
            <ConfirmationUnlinkTableDialog
              id={+params.row.id}
              handleUnlink={(id) => {
                productService.removeExam(productId, id).then(() => {
                  toast.success(t('actions.successfully.unlinked'));
                  if (refresh) refresh.current();
                });
              }}
            />
          </IsTenantAdministrator>
        );
        return actions;
      }
    });
  }

  return columns;
}
