import { GridColDef, GridColumns } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import { filterOperators, dateFilterOperators, numberFilterOperators } from '../../../../constants';
import { baseCell } from '../cells/base-cell';
import { currencyCell } from '../cells/currency-cell';

export function UnapprovedOrderlessMovementColumns(): GridColDef[] {
  const { t } = useTranslation();
  const translationPrefix = 'entities.movement';

  const columns: GridColumns | any[] = [
    {
      field: 'quantity',
      headerName: t(`${translationPrefix}.moved_quantity`),
      type: 'number',
      filterOperators: numberFilterOperators,
      renderCell: baseCell
    },
    {
      field: 'product',
      headerName: t(`global.product`),
      filterOperators,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'description',
      headerName: t(`global.description`),
      filterOperators,
      width: 300,
      renderCell: baseCell
    },
    {
      field: 'product_notes',
      headerName: t(`entities.product.product_notes`),
      filterable: false,
      sortable: false,
      width: 300,
      renderCell: baseCell
    },
    {
      field: 'order_notes',
      headerName: t(`entities.product.purchase-request-notes`),
      filterable: false,
      sortable: false,
      width: 300,
      renderCell: baseCell
    },
    {
      field: 'supplier',
      headerName: t(`global.supplier`),
      filterOperators,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'timestamp',
      headerName: t(`${translationPrefix}.timestamp`),
      filterOperators: dateFilterOperators,
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'price',
      headerName: t(`${translationPrefix}.price`),
      filterable: false,
      sortable: false,
      type: 'number',
      width: 150,
      renderCell: currencyCell
    },
    {
      field: 'unit_price',
      headerName: t(`${translationPrefix}.unit_price`),
      filterOperators,
      type: 'number',
      width: 150,
      renderCell: currencyCell
    },
    {
      field: 'warehouse',
      headerName: t(`global.warehouse`),
      filterOperators,
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'product_batch',
      headerName: t(`${translationPrefix}.product_batch`),
      filterOperators,
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'expiration_date',
      headerName: t(`${translationPrefix}.expiration_date`),
      filterOperators: dateFilterOperators,
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'movement_type',
      headerName: t(`${translationPrefix}.type`),
      filterOperators,
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'created_by',
      headerName: t(`${translationPrefix}.created_by`),
      filterOperators,
      width: 200,
      renderCell: baseCell
    }
  ];

  return columns;
}
