import { GridRenderCellParams } from '@mui/x-data-grid-premium';

function productStatusCell(t: any) {
  const renderCell = (params: GridRenderCellParams) => {
    const value = params.value;

    return (
      <div>
        <span className={value === 'ACTIVE' || value === 1 ? 'text-green-500' : 'text-orange-500'}>
          {t(`entities.product.status_options.${params.value}`)}
        </span>
      </div>
    );
  };
  return renderCell;
}

export default productStatusCell;
