import { Button } from '@mui/material';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { DatePickerAdapter } from 'components/form/DatePickerAdapter';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { composeValidators, mustBeCurrency } from 'helpers/validators';
import { IOption } from 'interfaces';
import { useEffect, useState } from 'react';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import {
  costElementService,
  equipmentService,
  examService,
  laboratoryService,
  macroExamService,
  operationalUnitService,
  sectorService,
  workstationService
} from 'services/api';

interface AdditionalEquipmentCostProps {
  onSubmit: any;
  equipments: IOption[];
}

function AdditionalEquipmentCostForm(props: AdditionalEquipmentCostProps) {
  const { onSubmit } = props;
  const { t } = useTranslation();

  const [layerTypes, setLayerTypes] = useState<any>([]);
  const [layerType, setLayerType] = useState<IOption>({ id: null, code: null });
  const [layers, setLayers] = useState<IOption[]>([]);

  useEffect(() => {
    costElementService.getLayerOptions().then(setLayerTypes);
  }, []);

  useEffect(() => {
    switch (layerType['model']) {
      case 'laboratory':
        laboratoryService.getAllBaseInformation().then((res) => {
          setLayers(
            res.map((item) => {
              return {
                id: item.id,
                code: item.description
              };
            })
          );
        });
        break;
      case 'operationalunit':
        operationalUnitService.getAllBaseInformation().then((res) => {
          setLayers(
            res.map((item) => {
              return {
                id: item.id,
                code: item.description
              };
            })
          );
        });
        break;
      case 'sector':
        sectorService.getAllBaseInformation().then((res) => {
          setLayers(
            res.map((item) => {
              return {
                id: item.id,
                code: item.description
              };
            })
          );
        });
        break;
      case 'workstation':
        workstationService.getAllBaseInformation().then((res) => {
          setLayers(
            res.map((item) => {
              return {
                id: item.id,
                code: item.description
              };
            })
          );
        });
        break;
      case 'equipment':
        equipmentService.getAllBaseInformation().then((res) => {
          setLayers(
            res.map((item) => {
              return {
                id: item.id,
                code: item.description
              };
            })
          );
        });
        break;
      case 'exam':
        examService.getAllBaseInformation().then((res) => {
          setLayers(
            res.map((item) => {
              return {
                id: item.id,
                code: item.description
              };
            })
          );
        });
        break;
      case 'macroexam':
        macroExamService.getAllBaseInformation().then((res) => {
          setLayers(
            res.map((item) => {
              return {
                id: item.id,
                code: item.description
              };
            })
          );
        });
        break;
    }
  }, [layerType]);

  return (
    <div>
      <ReactFinalForm onSubmit={onSubmit} formId="addAdditionalCost" hideControls={true}>
        <div className="flex flex-wrap">
          {/* <div className="w-1/2 mb-4 pr-4"> */}
          {/*   <IdentityField */}
          {/*     name="equipment" */}
          {/*     component={AutocompleteAdapter} */}
          {/*     options={equipments} */}
          {/*     label={t('global.equipment')} */}
          {/*     type="text" */}
          {/*   /> */}
          {/* </div> */}
          <div className="w-1/5 mb-4 pr-2 flex">
            <IdentityField
              name="code"
              component={TextFieldAdapter}
              label={t('global.id')}
              required
            />
          </div>
          <div className="w-2/5 mb-4 pr-2 flex">
            <IdentityField
              name="description"
              component={TextFieldAdapter}
              label={t('global.description')}
            />
          </div>
          <div className="w-1/5 mb-4 pr-2">
            <IdentityField
              name="amount"
              label={t('global.amount')}
              component={TextFieldAdapter}
              type="text"
              validate={composeValidators(mustBeCurrency)}
            />
          </div>
          <div className="w-1/5 mb-4 pr-2">
            <IdentityField name="date" label={t('global.date')} component={DatePickerAdapter} />
          </div>
          <div className="w-1/4 pr-2 my-2">
            <IdentityField
              name="content_type"
              component={AutocompleteAdapter}
              options={layerTypes}
              optionLabel="model"
              label={t('entities.cost_element.content_type')}
              type="number"
              setValue={setLayerType}
            />
          </div>
          <div className="w-1/2 mb-4 my-2 pr-2">
            <IdentityField
              name="object_id"
              component={AutocompleteAdapter}
              options={layers}
              label={t('entities.cost_element.object_id')}
              type="number"
            />
          </div>
          <div className="w-1/4 mb-4 flex pr-2">
            <Button type="submit" form="addAdditionalCost" variant="outlined" className="w-full">
              {t('actions.insert')}
            </Button>
          </div>
        </div>
      </ReactFinalForm>
    </div>
  );
}

export default AdditionalEquipmentCostForm;
