import { useSnackbar } from 'notistack';
import { loadingActions } from 'features';
import { supplierService } from 'services/api';
import { useAppDispatch } from 'app/store';
import TenantPage from 'components/common/TenantPage';
import { useTranslation } from 'react-i18next';
import SupplierForm from '../SupplierForm';

const initSupplier = {
  id: null,
  code: null,
  description: null,
  company_name: null,
  email: null
};
export const AddSupplier = () => {
  const translationPrefix = 'pages.supplier.new';
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const createSupplier = async (values) => {
    dispatch(loadingActions.startLoading());
    supplierService.add(values).then((res) => {
      enqueueSnackbar('Fornitore aggiunto con successo');
    });
    dispatch(loadingActions.stopLoading());
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <SupplierForm supplier={initSupplier} onSubmit={createSupplier} />
    </TenantPage>
  );
};

export default AddSupplier;
