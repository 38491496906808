import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  DialogContentText
} from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid-premium';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LinkOffIcon from '@mui/icons-material/LinkOff';

interface ConfirmationTableDialogProps {
  id: number;
  message?: string;
  handleUnlink?: (id: number) => void;
}

export default function ConfirmationUnlinkTableDialog(params: ConfirmationTableDialogProps) {
  const { id, message, handleUnlink } = params;
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);

  /**
   * Opens the confirmation dialog for deleting a row.
   *
   * @param {number} id - The ID of the row to be deleted.
   */
  const handleOpenDialog = () => {
    // Set the selected row ID and open the dialog.
    setOpenDialog(true);
  };

  /**
   * Closes the confirmation dialog.
   */
  const handleCloseDialog = () => {
    // Close the dialog and reset the selected row ID.
    setOpenDialog(false);
  };

  const handleConfirmUnlink = () => {
    handleUnlink(id);
    // Close the dialog and reset the selected row ID.
    setOpenDialog(false);
  };

  return (
    <>
      <GridActionsCellItem
        key={990}
        label={t('actions.unlink')}
        icon={<LinkOffIcon color="action" />}
        onClick={() => handleOpenDialog()}
      />
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{t('actions.confirmation.unlink')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message ? message : ''}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {t('actions.cancel')}
          </Button>
          <Button onClick={handleConfirmUnlink} color="primary" autoFocus>
            {t('actions.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
