import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { OrderDetailColumns } from 'components/common/enhanced/common-headers/order-detail';
import { IPurchaseOrder } from 'interfaces';

interface OrderDetailProps {
  order: IPurchaseOrder;
}

export const OrderDetail = (props: OrderDetailProps) => {
  const { order } = props;
  const columns: GridColDef[] = OrderDetailColumns();

  return (
    <div>
      <div style={{ height: 400, width: '100%' }}>
        <DataGridPremium
          rows={order.lines}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
      </div>
    </div>
  );
};
