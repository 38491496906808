import { ITags } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from '../BaseService';

class FMTagService extends BaseService<ITags> {
  constructor() {
    super('tags');
  }

  //   const deleteDocument = async (params, documentId) => {
  //     // Add your API call logic here
  //     return axios.delete(`/api/documents/${documentId}`);
  //   };
}

export const fmTagService = new FMTagService();
