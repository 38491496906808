import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { filterOperators } from './../../../../constants';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const WarehouseColumns = (): GridColDef[] => {
  const translationPrefix = 'entities.warehouse';

  const { t } = useTranslation();
  return [
    {
      field: 'code',
      headerName: t(`global.code`),
      width: 250,
      filterOperators,
      renderCell: (params: GridRenderCellParams) => {
        const warehouse = params.row;
        return (
          <div>
            <Link to={`${warehouse.id}`}>
              <b className="text-secondary-500">{warehouse.code}</b>
            </Link>
          </div>
        );
      }
    },
    {
      field: 'description',
      filterOperators,
      width: 250,
      headerName: t(`global.description`)
    },
    {
      field: 'laboratories',
      filterable: false,
      sortable: false,
      width: 150,
      headerName: t(`global.laboratories`)
    },
    {
      field: 'locations',
      filterable: false,
      sortable: false,
      width: 150,
      headerName: t(`global.locations`)
    },
    {
      field: 'city',
      filterOperators,
      width: 150,
      headerName: t(`${translationPrefix}.city`)
    },
    {
      field: 'district',
      filterOperators,
      width: 100,
      headerName: t(`${translationPrefix}.district`)
    },
    {
      field: 'zip_code',
      filterOperators,
      width: 100,
      headerName: t(`${translationPrefix}.zip_code`)
    },
    {
      field: 'phone_number',
      filterOperators,
      width: 150,
      headerName: t(`${translationPrefix}.phone_number`)
    },
    {
      field: 'order_prefix',
      width: 150,
      sortable: false,
      filterable: false,
      headerName: t(`${translationPrefix}.order_prefix`)
    }
  ];
};
