import { TenantPage } from 'components/common';
import { StockQuantitiesColumns } from 'components/common/enhanced/common-headers';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { useTranslation } from 'react-i18next';
import { stockQuantityService } from 'services/api';

function AllQuantities() {
  const { t } = useTranslation();
  const translationPrefix = 'pages.stock_quantity.all';
  const columns = StockQuantitiesColumns();

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <EnhancedDataGrid
        service={stockQuantityService}
        columns={columns}
        initialOptions={{
          pinnedColumns: {
            left: ['product'],
            right: ['quantity', 'actions']
          }
        }}
      />
    </TenantPage>
  );
}

export default AllQuantities;
