import { IOption } from 'interfaces';
import { BaseService } from './BaseService';

class ExamTagService extends BaseService<IOption> {
  constructor() {
    super('exam-tags');
  }
}

export const examTagService = new ExamTagService();
