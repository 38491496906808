import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { required } from 'helpers/validators';
import { IOption, ILocation } from 'interfaces';
import { useEffect, useState } from 'react';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { warehouseService } from 'services/api';

interface LaboratoryFormParams {
  location: ILocation;
  onSubmit: any;
}
function LocationForm(params: LaboratoryFormParams) {
  const { t } = useTranslation();

  const [warehouses, setWarehouses] = useState<IOption[]>([]);

  useEffect(() => {
    warehouseService.getAllBaseInformation().then(setWarehouses);
  }, []);

  const { location, onSubmit } = params;
  return (
    <div>
      {location && (
        <ReactFinalForm onSubmit={onSubmit} edit={!!location.id}>
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/3 p-2">
              <IdentityField
                name="code"
                initialValue={location.code}
                component={TextFieldAdapter}
                label={t('global.code')}
                type="text"
                validate={required}
              />
            </div>
            <div className="w-full md:w-2/3 p-2">
              <IdentityField
                name="description"
                initialValue={location.description}
                component={TextFieldAdapter}
                label={t('global.description')}
                type="text"
                validate={required}
              />
            </div>
            <div className="w-full my-4">
              {warehouses && (
                <IdentityField
                  name="warehouse"
                  component={AutocompleteAdapter}
                  initValue={warehouses.find((s) => s.id === location.warehouse)}
                  defaultValue={location.warehouse}
                  options={warehouses}
                  optionlabel="id"
                  label={t('global.warehouse')}
                  type="text"
                />
              )}
            </div>
          </div>
        </ReactFinalForm>
      )}
    </div>
  );
}

export default LocationForm;
