import { useEffect, useState } from 'react';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { IInvoice, IOption } from 'interfaces';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { supplierService } from 'services/api';
import { DatePickerAdapter } from 'components/form/DatePickerAdapter';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import SaveIcon from '@mui/icons-material/Save';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockIcon from '@mui/icons-material/Lock';
import { currencyFormatter } from 'helpers/currency-formatter';
import { IconButton } from '@mui/material';
import { CheckboxAdapter } from 'components/form/CheckboxAdapter';

interface InvoiceFormParams {
  invoice: IInvoice;
  onSubmit: any;
}

function InvoiceForm(params: InvoiceFormParams) {
  const { t } = useTranslation();
  const { invoice, onSubmit } = params;

  const [suppliers, setSuppliers] = useState<IOption[]>([]);

  useEffect(() => {
    supplierService.getAllBaseInformation().then((res) => {
      setSuppliers(
        res.map((supplier) => {
          return {
            ...supplier,
            code: `${supplier.code} - ${supplier.company_name}`
          };
        })
      );
    });
  }, []);

  return (
    <div>
      {invoice && (
        <ReactFinalForm
          onSubmit={onSubmit}
          edit={!!invoice.id}
          hideControls={!!invoice.id}
          formId="amountForm">
          <div className="w-full mb-3">
            <IdentityField
              name="ref"
              label={t(`global.notes`)}
              initialValue={invoice.notes}
              component={TextFieldAdapter}
              type="text"
            />
          </div>
          <div className="w-full mb-3">
            <IdentityField
              name="supplier"
              label={t(`global.supplier`)}
              initialValue={invoice.supplier}
              component={AutocompleteAdapter}
              options={suppliers}
              required
            />
          </div>
          <div className="flex items-center mb-3">
            <div className="mr-4">
              <IdentityField
                name="ref"
                label={t(`entities.invoice.ref`)}
                initialValue={invoice.ref}
                component={TextFieldAdapter}
                type="text"
              />
            </div>
            <div className="mr-4">
              <IdentityField
                name="date"
                label={t(`entities.invoice.date`)}
                component={DatePickerAdapter}
                type="date"
                initialValue={invoice?.date ?? null}
              />
            </div>
          </div>
          <div className="flex items-center">
            <div className="mr-4">
              <IdentityField
                name="credit_memo_ref"
                label={t(`entities.invoice.credit_memo_ref`)}
                initialValue={invoice.credit_memo_ref}
                component={TextFieldAdapter}
                type="text"
                disabled={+invoice.credit_memo_amount === 0}
              />
            </div>
            <div className="mr-4">
              <IdentityField
                name="credit_memo_amount"
                label={t(`entities.invoice.credit_memo_amount`)}
                initialValue={currencyFormatter(invoice.credit_memo_amount)}
                component={TextFieldAdapter}
                type="text"
                disabled
              />
            </div>
            <div className="mr-4">
              <IdentityField
                name="credit_memo_date"
                label={t(`entities.invoice.credit_memo_date`)}
                component={TextFieldAdapter}
                initialValue={invoice.credit_memo_date}
                disabled
              />
            </div>
            <div className="mr-4">
              <IdentityField
                name="closed"
                label={invoice?.closed ? t(`entities.invoice.closed`) : t(`entities.invoice.open`)}
                component={CheckboxAdapter}
                type="checkbox"
                initialValue={invoice?.closed ?? false}
                icon={<LockOpenOutlinedIcon />}
                checkedIcon={<LockIcon />}
              />
            </div>
            <IconButton type="submit" form="amountForm" color="primary">
              <SaveIcon />
            </IconButton>
          </div>
        </ReactFinalForm>
      )}
    </div>
  );
}

export default InvoiceForm;
