import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { MultipleAutocompleteAdapter } from 'components/form/MultipleAutocompleteAdapter';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { required } from 'helpers/validators';
import { IOption, ISector } from 'interfaces';
import { useEffect, useState } from 'react';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { operationalUnitService, productService } from 'services/api';

interface FormParams {
  sector: ISector;
  onSubmit: any;
}
function SectorForm(params: FormParams) {
  const { t } = useTranslation();

  const [operationalUnit, setOperationalUnits] = useState<IOption[]>([]);
  const [products, setProducts] = useState<IOption[]>([]);

  useEffect(() => {
    operationalUnitService.getAllBaseInformation().then(setOperationalUnits);
    productService.getAllBaseInformation().then(setProducts);
  }, []);

  const { sector, onSubmit } = params;
  return (
    <div>
      {sector && (
        <ReactFinalForm onSubmit={onSubmit} edit={!!sector.id}>
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/3 pr-4">
              <IdentityField
                name="code"
                initialValue={sector.code}
                component={TextFieldAdapter}
                label={t('global.code')}
                type="text"
                validate={required}
              />
            </div>
            <div className="w-full md:w-2/3">
              <IdentityField
                name="description"
                initialValue={sector.description}
                component={TextFieldAdapter}
                label={t('global.description')}
                type="text"
                validate={required}
              />
            </div>

            <div className="w-full my-4">
              {operationalUnit && (
                <IdentityField
                  name="operational_unit"
                  component={AutocompleteAdapter}
                  initValue={operationalUnit.find((s) => s.id === sector.operational_unit)}
                  defaultValue={sector.operational_unit}
                  options={operationalUnit}
                  label={t('global.operational_unit')}
                  type="text"
                />
              )}
            </div>
            <div className="p-2 w-full">
              <IdentityField
                name="products"
                component={MultipleAutocompleteAdapter}
                options={products}
                initValue={sector.products}
                label={t('global.products')}
                type="text"
              />
            </div>
          </div>
        </ReactFinalForm>
      )}
    </div>
  );
}

export default SectorForm;
