import { TenantPage } from 'components/common';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { equipmentInvoiceService, invoiceService } from 'services/api';
import { uniqueId } from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { InvoiceColumns } from 'components/common/enhanced/common-headers/invoices';
import InvoiceHaderForm from '../InvoiceHeaderForm';
import { PurgeButton } from 'components/helpers/PurgeButton';
import { IsSuperAdmin } from 'components/helpers/IsSuperAdmin';

export function Invoices() {
  const { t } = useTranslation();
  const translationPrefix = 'pages.invoices.list';
  const datagridRefresh = useRef(null);

  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onSubmit = (values) => {
    invoiceService.add(values).then(() => {
      setOpenDialog(false);
      datagridRefresh.current();
    });
  };

  const columns = InvoiceColumns(datagridRefresh.current);
  const filters = [];
  const initialParams = {};
  filters.push({
    id: uniqueId(),
    columnField: 'closed',
    operatorValue: 'is',
    value: 'false'
  });
  initialParams['closed'] = 'false';

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      menuRight={
        <div className="flex flex-wrap">
          <ButtonGroup size="small">
            <Button className="pr-4" onClick={() => setOpenDialog(true)}>
              <AddIcon />
              {t(`${translationPrefix}.new`)}
            </Button>
            <IsSuperAdmin>
              <PurgeButton service={invoiceService} refresh={datagridRefresh} />
            </IsSuperAdmin>
          </ButtonGroup>
        </div>
      }>
      <EnhancedDataGrid
        columns={columns}
        initialFilterItems={filters}
        initialParams={initialParams}
        service={invoiceService}
        refresh={datagridRefresh}
      />
      <Dialog open={openDialog} maxWidth="lg" fullWidth>
        <DialogTitle>{t(`${translationPrefix}.new`)}</DialogTitle>
        <DialogContent>
          <InvoiceHaderForm onSubmit={onSubmit} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="error">
            {t('actions.cancel')}
          </Button>
          <Button type="submit" form="newInvoice">
            {t(`actions.confirm`)}
          </Button>
        </DialogActions>
      </Dialog>
    </TenantPage>
  );
}

export default Invoices;
