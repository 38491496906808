import { IMaintenance } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class MaintenanceService extends BaseService<IMaintenance> {
  constructor() {
    super('maintenances');
  }
  canDelete = true;

  getClosed = async (limit = 0, offset = 0, params: Record<string, unknown> = {}) => {
    const res = await axiosPrivate.get(`${this.modulePrefix}/get_closed/`, {
      params: { limit, offset, ...params }
    });
    return res.data;
  };

  closeMaintenance = async (maintenanceId: number, params: Record<string, unknown> = {}) => {
    const res = await axiosPrivate.post(
      `${this.modulePrefix}/${maintenanceId}/close-maintenance/`,
      params
    );
    return res.data;
  };
}

export const maintenanceService = new MaintenanceService();
