import { ISector } from 'interfaces';
import { BaseService } from './BaseService';

class LabelThresholdService extends BaseService<ISector> {
  constructor() {
    super('label-thresholds');
  }
}

export const labelThresholdService = new LabelThresholdService();
