import { lisTranscodesService } from 'services/api';
import { ILISTranscode } from 'interfaces';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { useEffect, useState } from 'react';
import toast from 'features/toast';
import LISTranscodesForm from '../LISTranscodesForm';

export const EditLISTranscode = () => {
  const translationPrefix = 'pages.lis-transcodes.edit';
  const { t } = useTranslation();
  const { lisTranscodeId } = useParams();

  const [lisTranscode, setLisTranscode] = useState<ILISTranscode>();

  useEffect(() => {
    lisTranscodesService.get(+lisTranscodeId).then(setLisTranscode);
  }, []);

  const update = async (values) => {
    lisTranscodesService.update(lisTranscode.id, values).then(() => {
      toast.success('Transcodifica aggiornata con successo');
    });
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <LISTranscodesForm lisTranscode={lisTranscode} onSubmit={update} />
    </TenantPage>
  );
};

export default EditLISTranscode;
