import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from '../app/store';

interface RequireRolesProps {
  allowedRoles: string[];
}

const RequireRoles = (props: RequireRolesProps) => {
  const { allowedRoles } = props;
  const groups = useAppSelector((state) => state.auth.groups);
  const location = useLocation();
  return allowedRoles.some((role) => groups.includes(role)) ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default RequireRoles;
