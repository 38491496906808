import { inventoryCheckService } from 'services/api';
import { IInventoryCheck } from 'interfaces';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import InventoryCheckForm from '../InventoryCheckForm';
import { useNavigate } from 'react-router-dom';

const initInventory: IInventoryCheck = {
  id: null,
  warehouse: null,
  suppliers: [],
  locations: [],
  categories: [],
  date: null,
  partial: false
};

export const AddInventoryCheck = () => {
  const translationPrefix = 'pages.inventory-check';
  const { t } = useTranslation();
  const navigate = useNavigate();

  const createInventoryCheck = async (values) => {
    inventoryCheckService.add(values).then((res) => {
      if (res?.id) {
        navigate(`../${res.id}`);
      }
    });
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <InventoryCheckForm inventory={initInventory} onSubmit={createInventoryCheck} />
    </TenantPage>
  );
};

export default AddInventoryCheck;
