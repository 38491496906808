import { IMovementReason } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class MovementReasonService extends BaseService<IMovementReason> {
  constructor() {
    super('movement-types');
  }

  importFromFile = async (importFile: File, attachmentName = 'import_file') => {
    const res = await axiosPrivate.postForm(`${this.modulePrefix}/import-from-file/`, {
      [attachmentName]: importFile
    });
    return res.data;
  };
}

export const movementReasonService = new MovementReasonService();
