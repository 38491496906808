import { createTheme } from '@mui/material/styles';

const baseThemePalette = {
  palette: {
    success: {
      main: '#4CBA53'
    },
    error: {
      main: '#EF4933'
    },
    warning: {
      main: '#DDA059'
    },
    info: {
      main: '#87CCB9'
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.15
  }
};

const labTheme = createTheme({
  palette: {
    ...baseThemePalette,
    primary: {
      main: '#29456c'
    },
    secondary: {
      main: '#2578b0'
    }
  }
});

const refertingTheme = createTheme({
  palette: {
    ...baseThemePalette,
    primary: {
      main: '#0791e7'
    },
    secondary: {
      main: '#2578b0'
    }
  }
});

const evaluateTheme = createTheme({
  palette: {
    ...baseThemePalette,
    primary: {
      main: '#0163ae'
    },
    secondary: {
      main: '#2578b0'
    }
  }
});

const settingsTheme = createTheme({
  palette: {
    ...baseThemePalette,
    primary: {
      main: '#64b4fe'
    },
    secondary: {
      main: '#2578b0'
    }
  }
});

export { labTheme, refertingTheme, evaluateTheme, settingsTheme };
