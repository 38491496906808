import { Button, ButtonGroup } from '@mui/material';
import { TenantPage } from 'components/common';
import { CostElementsColumns } from 'components/common/enhanced/common-headers/cost-elements';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import SectionTitle from 'components/common/SectionTitle';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { costElementService } from 'services/api';
import { periodicCostElementService } from 'services/api/PeriodicCostElementService';

export function CostList() {
  const { t } = useTranslation();
  const translationPrefix = 'pages.cost.list';

  const columns = CostElementsColumns();
  const periodicCostColumns = CostElementsColumns(true);
  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      menuRight={
        <div className="flex justify-end items-center">
          <div>
            <ButtonGroup>
              <IsTenantAdministrator>
                <NavLink to="add">
                  <Button>{t(`${translationPrefix}.add`)}</Button>
                </NavLink>
              </IsTenantAdministrator>
            </ButtonGroup>
          </div>
        </div>
      }>
      <div className="my-4">
        <SectionTitle>{t(`${translationPrefix}.extraordinary_costs`)}</SectionTitle>
      </div>
      <div>
        <EnhancedDataGrid columns={columns} service={costElementService} />
      </div>
      <div className="my-4">
        <SectionTitle>{t(`${translationPrefix}.periodic_costs`)}</SectionTitle>
      </div>
      <div>
        <EnhancedDataGrid columns={periodicCostColumns} service={periodicCostElementService} />
      </div>
    </TenantPage>
  );
}

export default CostList;
