import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { filterOperators } from './../../../../constants';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { baseCell } from '../cells/base-cell';
import DataGridCell from 'components/common/DataGridCell';

export function CategoriesColumns(translationPrefix: string, detailNav = false): GridColDef[] {
  const { t } = useTranslation();
  return [
    {
      field: 'code',
      headerName: t(`global.code`),
      filterOperators,
      renderCell: (params: GridRenderCellParams) => {
        const category = params.row;
        return detailNav ? (
          <div>
            <Link to={`${category.id}`}>
              <b className="text-secondary-500">{category.code}</b>
            </Link>
          </div>
        ) : (
          <DataGridCell content={params.value as string} />
        );
      }
    },
    {
      field: 'description',
      headerName: t(`global.description`),
      filterOperators,
      editable: detailNav,
      width: 500,
      renderCell: baseCell
    },
    {
      field: 'sector',
      headerName: t(`global.sector`),
      filterOperators,
      width: 150,
      renderCell: baseCell
    }
  ];
}
