import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { dateFilterOperators, filterOperators } from '../../../../constants';
import { useTranslation } from 'react-i18next';
import { baseCell } from '../cells/base-cell';
import { currencyCell } from '../cells/currency-cell';

export function AdditionalEquipmentCostColumns(): GridColumns {
  const { t } = useTranslation();
  return [
    // {
    //   field: 'equipment',
    //   headerName: t(`global.equipment`),
    //   width: 150,
    //   renderCell: baseCell
    // },
    {
      field: 'code',
      headerName: t(`global.id`),
      width: 200,
      renderCell: baseCell
    },
    {
      field: 'description',
      headerName: t(`global.description`),
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'amount',
      headerName: t(`global.amount`),
      width: 240,
      renderCell: currencyCell,
      editable: true
    },
    {
      field: 'content_type',
      width: 180,
      headerName: t(`entities.cost_element.content_type`),
      renderCell: (params: GridRenderCellParams) => {
        return (
          params.value && (
            <div>
              <b className="text-secondary-500">{t(`global.${params.value}`)}</b>
            </div>
          )
        );
      }
    },
    {
      field: 'object_id',
      headerName: t(`entities.cost_element.object_id`),
      filterOperators,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'date',
      headerName: t(`global.date`),
      width: 200,
      filterOperators: dateFilterOperators,
      renderCell: baseCell,
      editable: true
    }
  ];
}
