import { locationService } from 'services/api';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import LocationForm from '../LocationForm';
import { ILocation } from 'interfaces';
import { useNavigate } from 'react-router-dom';

const initLocation: ILocation = {
  id: null,
  code: '',
  description: '',
  warehouse: null
};

export const CreateLocation = () => {
  const translationPrefix = 'pages.locations.new';

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const createLocation = async (values) => {
    locationService.add(values).then((res) => {
      enqueueSnackbar('Piazza aggiunta con successo');
      navigate(`../${res.id}`);
    });
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <LocationForm location={initLocation} onSubmit={createLocation} />
    </TenantPage>
  );
};

export default CreateLocation;
