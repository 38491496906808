import { Button, ButtonGroup } from '@mui/material';
import { warehouseService } from 'services/api';
import { GridColDef } from '@mui/x-data-grid-premium';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import TenantPage from 'components/common/TenantPage';
import { WarehouseColumns } from 'components/common/enhanced/common-headers';
import { PurgeButton } from 'components/helpers/PurgeButton';
import { useRef } from 'react';

export const Warehouses = () => {
  const translationPrefix = 'pages.warehouse.list';
  const { t } = useTranslation();
  const datagridRefresh = useRef(null);

  const columns: GridColDef[] = WarehouseColumns();

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <div className="flex justify-end items-center">
            <ButtonGroup>
              <IsTenantAdministrator>
                <Link to="new">
                  <Button variant="outlined" size="small">
                    <AddIcon />
                    {t(`${translationPrefix}.add_warehouse`)}
                  </Button>
                </Link>
              </IsTenantAdministrator>
            </ButtonGroup>
            <PurgeButton service={warehouseService} refresh={datagridRefresh} />
          </div>
        }>
        <div className="mt-6">
          <EnhancedDataGrid
            columns={columns}
            service={warehouseService}
            refresh={datagridRefresh}
          />
        </div>
      </TenantPage>
    </>
  );
};
