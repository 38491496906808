import toast from 'features/toast';
import { IExpertise } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class ExpertiseService extends BaseService<IExpertise> {
  constructor() {
    super('staff-management/expertises');
  }
  canDelete = true;

  getRoleNames = async () => {
    return (await axiosPrivate.get('staff-management/role-names/')).data;
  };
}

export const expertiseService = new ExpertiseService();
