import { ILocation } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class LocationService extends BaseService<ILocation> {
  constructor() {
    super('locations');
  }

  assignProduct = async (locationId: number, product: number) => {
    const res = await axiosPrivate.patch(`${this.modulePrefix}/${locationId}/assign_product/`, {
      product
    });
    return res.data;
  };

  removeProduct = async (locationId: number, product: number) => {
    const res = await axiosPrivate.patch(`${this.modulePrefix}/${locationId}/remove_product/`, {
      product
    });
    return res.data;
  };

  importFromFile = async (importFile: File, attachmentName = 'import_file') => {
    const res = await axiosPrivate.postForm(`${this.modulePrefix}/import-from-file/`, {
      [attachmentName]: importFile
    });
    return res.data;
  };
}

export const locationService = new LocationService();
