import { IconButton, InputAdornment, Skeleton, TextField, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SearchIcon from '@mui/icons-material/Search';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { IDocument } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { TenantPage } from 'components/common';
import { fmDocumentService } from 'services/api/documents/FMDocumentService';
import { AddDocumentDialog } from 'components/common/documentLM/AddDocumentDialog';
import toast from 'features/toast';
import ConfirmationButtonDialog from 'components/helpers/ConfirmationButtonDelete';

export const DocumentsList = () => {
  const translationPrefix = 'pages.document';
  const { t } = useTranslation();

  const [documents, setDocuments] = useState<IDocument[]>([]);

  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');

  const startingUploadDocuments = () => {
    setLoading(true);
    fmDocumentService.getAllPaginated(100, 0).then((response) => {
      setDocuments(response.results);
    });
    setLoading(false);
  };

  useEffect(startingUploadDocuments, []);

  const handleDeleteDocument = (documentId: number) => {
    fmDocumentService.delete(documentId).then((res) => {
      if (res.status === 204) {
        toast.success(t('actions.successfully.deleted'));
      } else {
        toast.error(t('actions.successfully.error-generic'));
      }
      startingUploadDocuments();
    });
  };

  const onSubmitNewDocument = (values) => {
    //upload document to API and handle response
    fmDocumentService
      .uploadDocument(
        values.category,
        values.attachment.item(0),
        values.name,
        values.description || ''
      )
      .then((res) => {
        if (res) {
          // if response is successful, show success toast message
          toast.success(t('entities.document.upload-success'));
          startingUploadDocuments();
        } else {
          // if response is unsuccessful, show error toast message
          toast.error(t('entities.document.upload-error'));
        }
      });
  };

  const getIcon = (filename) => {
    const _filename = (filename ?? '').toLowerCase();

    if (_filename.endsWith('.pdf')) {
      return <PictureAsPdfIcon fontSize="large" color="error" />;
    } else if (
      _filename.endsWith('.doc') ||
      _filename.endsWith('.docx') ||
      _filename.endsWith('.otd') ||
      _filename.endsWith('.pages')
    ) {
      return <TextSnippetIcon fontSize="large" color="info" />;
    } else if (_filename.endsWith('.png') || _filename.endsWith('.jpg')) {
      return <ImageIcon fontSize="large" color="info" />;
    } else {
      return <TextSnippetIcon fontSize="large" />;
    }
  };

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}>
        <div
          className="flex flex-col"
          // Container object
        >
          <div className="flex mb-4 pb-2">
            <div className="flex justify-between items-center w-full">
              <span className="text-xl">{t(`${translationPrefix}.list.title`)}</span>
              <TextField
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
                className="w-1/2"
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
              />
              <AddDocumentDialog onSubmit={onSubmitNewDocument} />
            </div>
          </div>
          <div className="w-8/12 mx-auto"></div>
          <div className="px-2 py-4">
            <div className="grid grid-cols-4 gap-4">
              {loading &&
                Array.from({ length: 24 }, (_, index) => index).map((el, i) => (
                  <Skeleton key={i} variant="rounded" className="w-full" height={64} />
                ))}
              {!loading &&
                documents &&
                documents
                  .filter(
                    (d) =>
                      !filter ||
                      d.name.toLowerCase().includes(filter.toLowerCase()) ||
                      d.description.toLowerCase().includes(filter.toLowerCase())
                  )
                  .map((document, i) => (
                    <div
                      key={i}
                      className="w-full block px-4 py-2 border rounded-md bg-white transition-all cursor-pointer hover:shadow-3xl hover:bg-sky-100">
                      <Tooltip title={document.description}>
                        <div className="flex gap-4 items-center justify-between">
                          <div className="flex gap-4 items-center justify-start">
                            {getIcon(document.attachment)}
                            <span className="text-xl ">{document.name}</span>
                          </div>
                          <div className="flex items-center justify-end">
                            {/* QUI CI POTREI METTERE IL BOTTONE LINK FRA ENTITà E DOCUMENTO...MA MOOOLTO COMPLESSO */}
                            <IconButton
                              className="z-10"
                              color="info"
                              onClick={() => fmDocumentService.downloadAttachment(document.id)}>
                              <DownloadIcon />
                            </IconButton>
                            <div className="z-10">
                              <ConfirmationButtonDialog
                                id={document.id}
                                handleDelete={handleDeleteDocument}
                                iconButton
                              />
                            </div>
                          </div>
                        </div>
                      </Tooltip>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </TenantPage>
    </>
  );
};

export default DocumentsList;
