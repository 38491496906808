import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridValueFormatterParams
} from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import { booleanFilterOperators, filterOperators } from '../../../../constants';
import { baseCell } from '../cells/base-cell';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import FileUploader from 'components/common/file-uploader';
import { ticketingService } from 'services/api';
import { Link } from 'react-router-dom';
import { Tooltip, Typography } from '@mui/material';
import { compliantCell } from '../cells/compliant-cell';
import VerifiedIcon from '@mui/icons-material/Verified';
import { useAppSelector } from 'app/store';

export const TicketColumns = (
  handleApprovation: boolean,
  approve?: (id: number, old_approvation_status?: boolean | null) => void
): GridColumns => {
  const translationPrefix = 'entities.equipment';
  const { t } = useTranslation();
  const groups = useAppSelector((state) => state.auth.groups);

  const statusColor = (statusString: string) => {
    switch (statusString) {
      case 'OPEN':
        return 'text-orange-500';
      case 'INTERVENTION_REQUESTED':
        return 'text-red-500';
      case 'UNDETERMINED':
        return 'text-yellow-500';
      default:
        return 'text-green-500';
    }
  };

  return [
    {
      field: 'id',
      headerName: t(`global.number`),
      width: 50,
      filterOperators,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div>
            <Link to={`/tenant/equipments/ticketing/${params.value}`}>
              <b className="text-secondary-500">#{params.value}</b>
            </Link>
          </div>
        );
      }
    },
    {
      field: 'approvation_status',
      headerName: t(`global.approvation-status`),
      width: 75,
      sortable: false,
      filterOperators: booleanFilterOperators,
      renderCell: (params: GridRenderCellParams) => (
        <div>
          {groups && (groups.includes('TECHNICIAN') || groups.includes('OPERATOR')) ? (
            <VerifiedIcon color={params.row.approvation_status === true ? 'success' : 'disabled'} />
          ) : (
            <GridActionsCellItem
              icon={
                <Tooltip
                  title={t('actions.approve', { user: params.row.approvation_user })}
                  placement="top">
                  <VerifiedIcon
                    color={params.row.approvation_status === true ? 'success' : 'disabled'}
                  />
                </Tooltip>
              }
              onClick={() => approve(params.row.id, params.row.approvation_status)}
              label={t('actions.approve')}
            />
          )}
        </div>
      )
    },
    {
      field: 'equipment',
      headerName: t(`global.equipment`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'equipment_description',
      headerName: t(`global.equipment-description`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'serial_number',
      headerName: t(`${translationPrefix}.serial_number`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'description',
      headerName: t(`global.description`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'supplier',
      headerName: t(`global.supplier`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'created_at',
      headerName: t(`entities.tickets.created_at`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'sent_at',
      headerName: t(`entities.tickets.sent_at`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'resolved_at',
      headerName: t(`entities.tickets.resolved_at`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'status',
      headerName: t(`global.status`),
      width: 100,
      valueFormatter: (params: GridValueFormatterParams) => {
        const status = params.api.getRow(params.id).status.display_name;
        return status === 'INTERVENTION_COMPLETED' || status === 'PHONE_RESOLVED'
          ? t(`pages.tickets.status_options.${params.value.display_name}`)
          : '';
      },
      renderCell: (params: GridRenderCellParams) => {
        return params.value.display_name === 'INTERVENTION_COMPLETED' ||
          params.value.display_name === 'PHONE_RESOLVED' ? (
          <Typography className={statusColor(params.value.display_name)}>
            {t(`pages.tickets.status_options.${params.value.display_name}`)}
          </Typography>
        ) : (
          <></>
        );
      }
    },
    {
      field: 'compliant',
      headerName: t(`global.compliant`),
      width: 200,
      filterOperators: booleanFilterOperators,
      renderCell: compliantCell(),
      valueFormatter: (params: GridValueFormatterParams) => {
        const status = params.api.getRow(params.id).status.display_name;
        if (status !== 'INTERVENTION_COMPLETED' && status !== 'PHONE_RESOLVED') return null;
        if (params.value === null) return t('pages.tickets.compliance_options.ND');
        return status
          ? t('pages.tickets.compliance_options.compliant')
          : t('pages.tickets.compliance_options.non-compliant');
      }
    },
    {
      field: 'technical_report',
      headerName: t(`${translationPrefix}.technical_report`),
      renderCell: (params: GridRenderCellParams) => {
        const ticket = params.row;
        return (
          <IsTenantAdministrator>
            <div>
              <FileUploader
                service={ticketingService}
                entityId={ticket.id}
                attachmentName={'technical_report'}
                filename={ticket.technical_report}
              />
            </div>
          </IsTenantAdministrator>
        );
      },
      width: 150,
      filterable: false,
      sortable: false
    }
  ];
};
