import toast from 'features/toast';
import { IInventoryCheck } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class InventoryCheckService extends BaseService<IInventoryCheck> {
  constructor() {
    super('inventory-check');
  }

  validate = async (checkId: string) => {
    const res = await axiosPrivate.post(`${this.modulePrefix}/${checkId}/validate/`);
    return res.data;
  };

  reopen = async (checkId: string) => {
    const res = await axiosPrivate.post(`${this.modulePrefix}/${checkId}/reopen/`);
    return res.data;
  };

  archive = async (checkId: string) => {
    const res = await axiosPrivate.patch(`${this.modulePrefix}/${checkId}/`, { archived: true });
    return res.data;
  };

  getArchived = async (limit = 0, offset = 0, params: Record<string, unknown> = {}) => {
    const res = await axiosPrivate.get(`${this.modulePrefix}/get_archived/`, {
      params: { limit, offset, ...params }
    });
    return res.data;
  };
}

export const inventoryCheckService = new InventoryCheckService();
