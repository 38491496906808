import { Button, ButtonGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import TenantPage from 'components/common/TenantPage';
import { equipmentService } from 'services/api';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { GridColDef } from '@mui/x-data-grid-premium';
import { EquipmentColumns } from 'components/common/enhanced/common-headers';
import { useEffect, useRef, useState } from 'react';
import { IStatusOption } from 'interfaces';
import { PurgeButton } from 'components/helpers/PurgeButton';
import { PurgeLinksButton } from 'components/helpers/PurgeLinksButton';
import { NotOperatorRoles } from '../../../../constants';
import { useAppSelector } from 'app/store';

export const Equipments = () => {
  const translationPrefix = 'pages.equipment.list';
  const { t } = useTranslation();
  const datagridRefresh = useRef(null);
  const groups = useAppSelector((state) => state.auth.groups);

  const [ivdrOptions, setIvdrOptions] = useState<IStatusOption[]>([]);

  useEffect(() => {
    equipmentService.options().then((res) => {
      setIvdrOptions(res.actions.POST.ivdr_class.choices);
    });
  }, []);

  const columns: GridColDef[] = EquipmentColumns(
    ivdrOptions.map((ivdrOption) => ivdrOption.display_name),
    datagridRefresh.current,
    NotOperatorRoles.some((role) => groups.includes(role))
  );

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <div className="flex justify-end items-center">
            <ButtonGroup>
              <IsTenantAdministrator>
                <Link to="new">
                  <Button variant="outlined" size="small">
                    <AddIcon />
                    {t(`${translationPrefix}.add`)}
                  </Button>
                </Link>
              </IsTenantAdministrator>
            </ButtonGroup>
            <PurgeButton service={equipmentService} refresh={datagridRefresh} />
            <PurgeLinksButton service={equipmentService} refresh={datagridRefresh} />
          </div>
        }>
        <div className="mt-6">
          <EnhancedDataGrid
            columns={columns}
            service={equipmentService}
            refresh={datagridRefresh}
          />
        </div>
      </TenantPage>
    </>
  );
};

export default Equipments;
