import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { ticketingService } from 'services/api';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { GridColDef } from '@mui/x-data-grid-premium';
import { TicketColumns } from 'components/common/enhanced/common-headers';
import { Button, ButtonGroup } from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import toast from 'features/toast';
import { ITicket } from 'interfaces';

export const Tickets = () => {
  const translationPrefix = 'pages.tickets';

  const { t } = useTranslation();
  const datagridRefresh = useRef(null);

  const handleDelete = (line) => {
    ticketingService.delete(line.id).then(() => {
      datagridRefresh.current();
    });
  };

  const approve = (id: number, old_approvation_status?: boolean | null) => {
    if (old_approvation_status && old_approvation_status === true) {
      toast.warning(t(`${translationPrefix}.already-approved`));
    } else {
      ticketingService.approvedByUser(+id).then(() => {
        toast.success(t(`${translationPrefix}.ticket-approved`));
        datagridRefresh.current();
      });
    }
  };

  const columns: GridColDef[] = TicketColumns(true, approve);
  columns.splice(7, 1);

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <div className="flex justify-end items-center">
            <ButtonGroup>
              <Link to="new">
                <Button variant="outlined" size="small">
                  <AddIcon />
                  {t(`${translationPrefix}.add`)}
                </Button>
              </Link>
              <Link to="archive">
                <Button variant="outlined" size="small" color="warning">
                  {t(`global.archive`)}
                </Button>
              </Link>
            </ButtonGroup>
          </div>
        }>
        <EnhancedDataGrid
          columns={columns}
          service={ticketingService}
          refresh={datagridRefresh}
          disableDelete
        />
      </TenantPage>
    </>
  );
};

export default Tickets;
