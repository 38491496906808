import { IRequestDepartmentTranscode } from 'interfaces';
import { BaseService } from './BaseService';

class RequestDepartmentLaboratoryService extends BaseService<IRequestDepartmentTranscode> {
  constructor() {
    super('req-deps-labs');
  }
  canDelete = true;
}

export const requestDepartmentLaboratoryService = new RequestDepartmentLaboratoryService();
