import {
  GridExcelExportOptions,
  GridCsvGetRowsToExportParams,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarContainer,
  gridVisibleSortedRowIdsSelector,
  useGridApiContext
} from '@mui/x-data-grid-premium';
import Button, { ButtonProps } from '@mui/material/Button';
import { createSvgIcon } from '@mui/material/utils';
import { ButtonGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import toast from 'features/toast';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import { IsSuperAdmin } from 'components/helpers/IsSuperAdmin';
import ImportFileUploader from 'components/common/file-uploader/ImportFileUploader';

const getFilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) =>
  gridVisibleSortedRowIdsSelector(apiRef);

const ExportIcon = createSvgIcon(
  <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
  'SaveAlt'
);

export const EnhancedToolbarProvider = (
  service: any,
  entityId?: number,
  outerParams?: Record<string, unknown>
) => {
  const CustomToolbar = () => {
    const { t } = useTranslation();
    const apiRef = useGridApiContext();

    const handleExport = (options: GridExcelExportOptions) =>
      apiRef.current.exportDataAsExcel(options);

    const exportAll = () => {
      if (outerParams) service.exportAll(outerParams);
      else service.exportAll();
      toast.info(t('messages.export-required'));
    };

    const exportSummary = () => {
      if (outerParams) service.exportSummary(outerParams);
      else service.exportSummary();
      toast.info(t('messages.export-required'));
    };

    const exportButtonProps: ButtonProps = {
      color: 'primary',
      size: 'small',
      startIcon: <ExportIcon />
    };

    return (
      <GridToolbarContainer>
        <div className="w-auto flex justify-end">
          <ButtonGroup size="small">
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <Button
              {...exportButtonProps}
              onClick={() => handleExport({ getRowsToExport: getFilteredRows })}>
              {t('data-grid.download-filtered-rows')}
            </Button>
            <IsTenantAdministrator>
              {!!service.exportAll && (
                <Button {...exportButtonProps} onClick={exportAll} color="success">
                  {t('data-grid.export-all')}
                </Button>
              )}
              {!!service.exportSummary && (
                <Button {...exportButtonProps} onClick={exportSummary} color="success">
                  {t('data-grid.export-summary')}
                </Button>
              )}
            </IsTenantAdministrator>
            <IsSuperAdmin>
              {(!!service.importFromFile || !!service.importFromFileDetail) && (
                <ImportFileUploader service={service} entityId={entityId} />
              )}
            </IsSuperAdmin>
          </ButtonGroup>
        </div>
      </GridToolbarContainer>
    );
  };
  return CustomToolbar;
};
