import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { IExpertise, IRoleName, IOption } from 'interfaces';
import { useEffect, useState } from 'react';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { staffRoleService } from 'services/api/StaffRoleService';
import Groups2Icon from '@mui/icons-material/Groups2';
import { expertiseService } from 'services/api/ExpertiseService';
import { Alert, TextField } from '@mui/material';
import { currencyFormatter } from 'helpers/currency-formatter';

interface CategoryFormProps {
  expertise: IExpertise;
  onSubmit: (values) => any;
}
function ExpertiseForm({ expertise, onSubmit }: CategoryFormProps) {
  const { t } = useTranslation();
  const [roles, setRoles] = useState<IOption[]>([]);
  const [roleNames, setRoleNames] = useState<IRoleName[]>([]);
  const [roleName, setRoleName] = useState<IRoleName>();
  const [selectedFte, setSelectedFte] = useState(expertise?.fte);

  useEffect(() => {
    staffRoleService.getAllPaginated().then((res) => {
      setRoles(res.results);
    });
    expertiseService.getRoleNames().then((res) => setRoleNames(res.results));
  }, []);

  useEffect(() => {
    if (expertise) setSelectedFte(expertise?.fte);
  }, [expertise]);

  const _submit = (values) => {
    const _values = {
      ...values,
      fte: selectedFte
    };
    onSubmit(_values);
  };

  return (
    <div>
      {expertise && (
        <ReactFinalForm onSubmit={_submit} edit={!!expertise.id}>
          <div className="flex flex-wrap">
            <div className="w-1/6 px-5 flex items-center justify-center">
              <Groups2Icon sx={{ fontSize: 96 }} color="primary" />
            </div>
            {/* Left side */}
            <div className="w-5/12 px-5 border-r-2 border-primary-500">
              <div className="w-full mb-4">
                {roles && (
                  <IdentityField
                    name="role"
                    component={AutocompleteAdapter}
                    initValue={roles.find((s) => s.code == expertise.role)}
                    options={roles}
                    label={t('entities.expertise.role')}
                    type="text"
                  />
                )}
              </div>
              <div className="w-full flex items-center mb-4">
                <div className="flex-1">
                  <IdentityField
                    name="role_name"
                    component={AutocompleteAdapter}
                    options={roleNames}
                    setValue={setRoleName}
                    optionValue={'code'}
                    optionLabel={'code'}
                    initValue={roleNames.find((s) => s.code == expertise.role_name)}
                    label={t('entities.expertise.role_name')}
                    type="text"
                  />
                </div>
              </div>
              <div className="w-full p-0 ">
                <IdentityField
                  name="name"
                  component={TextFieldAdapter}
                  initialValue={expertise.name}
                  label={t('entities.expertise.name')}
                  type="text"
                />
              </div>
            </div>
            {/* Right side */}
            <div className="w-5/12 px-5">
              {roleName && expertise && (
                <div className="w-full mb-4">
                  <TextField
                    name={'fte'}
                    value={selectedFte}
                    type="number"
                    label={`${t('entities.expertise.fte')} (max: ${
                      roleName ? +(roleName.fte - expertise.role_name_fte).toFixed(2) : '---'
                    })`}
                    onChange={(e) => {
                      setSelectedFte(+e.target.value);
                    }}
                    fullWidth
                  />
                </div>
              )}
              {roleName && selectedFte && (
                <div className="w-full mb-4">
                  {roleName.fte - (roleName.counters?.fte + selectedFte - expertise.fte) < 0 && (
                    <Alert severity="error">{t('pages.expertises.new.fte-exceeded')}</Alert>
                  )}

                  <p>
                    {t('entities.expertise.monthly_cost')}:{' '}
                    <b>{currencyFormatter(expertise.monthly_cost)}</b>
                  </p>
                  <p>
                    {t('entities.expertise.monthly_hours')}:{' '}
                    <b>{(+expertise.monthly_hours).toFixed(2).replace('.', ',')}</b>
                  </p>
                </div>
              )}
            </div>
          </div>
        </ReactFinalForm>
      )}
    </div>
  );
}

export default ExpertiseForm;
