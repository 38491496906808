import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosPrivate } from '../services/axios';

const modulePrefix = 'form';

interface IFormState {
  error: string;
  status: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState: IFormState = {
  status: 'idle',
  error: ''
};

export const moveProducts = createAsyncThunk(
  `${modulePrefix}/moveProducts`,
  async (values: {
    product: string;
    warehouse: string;
    product_batch: string;
    expiration_date: string;
    movement_type: string;
    moved_quantity: string;
  }) => {
    const res = await axiosPrivate.post('move_products/', values);
    return res.data;
  }
);

export const assignProduct = createAsyncThunk(
  `${modulePrefix}/assignProduct`,
  async (values: { product: string; location: string }) => {
    const { product, location } = values;
    const res = await axiosPrivate.patch(`locations/${location}/assign_product/`, { product });
    return res.data;
  }
);

export const createPurchaseRequest = createAsyncThunk(
  `${modulePrefix}/createPurchaseRequest`,
  async (values: { order_list: any[] }) => {
    const { order_list } = values;
    const res = await axiosPrivate.post(`purchase_request/`, { order_list });
    return res.data;
  }
);

export const updatePurchaseRequest = createAsyncThunk(
  `${modulePrefix}/updatePurchaseRequest`,
  async (values: { id: number; order_list: any[] }) => {
    const { id, order_list } = values;
    const res = await axiosPrivate.patch(`purchreqhead/${id}/`, { order_list });
    return res.data;
  }
);

export const reviewPurchaseRequest = createAsyncThunk(
  `${modulePrefix}/reviewChangePurchaseRequest`,
  async (values: { id: number; lines: any[] }) => {
    const { id, lines } = values;
    const res = await axiosPrivate.patch(`purchreqhead/${id}/pre_approve_request/`, { lines });
    return res.data;
  }
);

export const finalizeChangePurchaseRequest = createAsyncThunk(
  `${modulePrefix}/finalizeChangePurchaseRequest`,
  async (values: { id: number; lines: any[] }) => {
    const { id, lines } = values;
    const res = await axiosPrivate.patch(`purchreqhead/${id}/approve_request/`, { lines });
    return res.data;
  }
);

export const getStockQuantities = createAsyncThunk(
  `${modulePrefix}/getStockQuantities`,
  async (values) => {
    const res = await axiosPrivate.post(`get_stock_period/`, values);
    return res.data;
  }
);

export const formSlice = createSlice({
  name: modulePrefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(moveProducts.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(moveProducts.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(assignProduct.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(assignProduct.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(getStockQuantities.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(getStockQuantities.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(createPurchaseRequest.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(createPurchaseRequest.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(updatePurchaseRequest.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(updatePurchaseRequest.fulfilled, (state) => {
        state.status = 'succeeded';
      });
  }
});

export const formActions = formSlice.actions;
