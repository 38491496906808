import { useAppDispatch } from 'app/store';
import { TenantPage } from 'components/common';
import { loadingActions } from 'features';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { costElementService } from 'services/api';
import { periodicCostElementService } from 'services/api/PeriodicCostElementService';
import CostElementForm from '../CostElementForm';

const initCostElement = {
  id: null,
  active: true,
  code: null,
  object_id: null,
  content_type: null,
  cost: null,
  description: null,
  date: null
};
export function AddCostElement() {
  const { t } = useTranslation();
  const translationPrefix = 'pages.cost.new';
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const createCostElement = async (values, isPeriodical) => {
    dispatch(loadingActions.startLoading());

    (isPeriodical ? periodicCostElementService : costElementService).add(values).then(() => {
      enqueueSnackbar('Elemento di costo aggiunta con successo', { variant: 'success' });
      navigate('..');
    });
    dispatch(loadingActions.stopLoading());
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <div>
        <CostElementForm onSubmit={createCostElement} costElement={initCostElement} />
      </div>
    </TenantPage>
  );
}

export default AddCostElement;
