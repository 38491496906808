import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { filterOperators, numberFilterOperators } from '../../../../constants';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DataGridCell from 'components/common/DataGridCell';
import { currencyFormatter } from 'helpers/currency-formatter';

export function RegionalRateColumns(): GridColDef[] {
  const { t } = useTranslation();
  const translationPrefix = 'entities.regional_rate';

  return [
    {
      field: 'code',
      headerName: t(`global.code`),
      renderCell: (params: GridRenderCellParams) => {
        const type = params.row;
        return (
          <div>
            <Link to={`${type.id}`}>
              <b className="text-secondary-500">{type.code}</b>
            </Link>
          </div>
        );
      }
    },
    {
      field: 'description',
      headerName: t(`global.description`),
      filterOperators,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <DataGridCell content={params.value} />
    },
    {
      field: 'rate',
      width: 150,
      filterOperators: numberFilterOperators,
      headerName: t(`${translationPrefix}.rate`),
      renderCell: (params: GridRenderCellParams) => {
        const price = params.value;
        return <DataGridCell content={currencyFormatter(price)} />;
      }
    }
  ];
}
