import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Typography
} from '@mui/material';
import { Button } from '@mui/material';
import { contractLineService, productService, orderService } from 'services/api';
import { IOption, IPurchaseOrder, IPurchaseOrderRow } from 'interfaces';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveIcon from '@mui/icons-material/Save';
import TenantPage from 'components/common/TenantPage';
import PurchaseRequestLineForm from '../../PurchaseRequests/PurchaseRequestLineForm';
import { GridCellEditCommitParams, GridColumns, itIT, MuiEvent } from '@mui/x-data-grid-premium';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { v4 as uuidv4 } from 'uuid';
import { OrderLinesColumns } from 'components/common/enhanced/common-headers/order-lines';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import CancelIcon from '@mui/icons-material/Cancel';
import toast from 'features/toast';
import dayjs from 'dayjs';
import { useAppSelector } from 'app/store';

export const PurchaseOrder = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { purchaseOrderId } = useParams();

  let translationPrefix = 'pages.purchase-orders.new';
  if (purchaseOrderId) translationPrefix = 'pages.purchase-orders.view';

  const sap_active = useAppSelector((state) => state.auth.sap_active);
  const [productToAdd, setProductToAdd] = useState<any>(null);
  const [contractToAdd, setContractToAdd] = useState<any>(null);
  const [maxUnits, setMaxUnits] = useState<number | null>(null);
  const [unitPrice, setUnitPrice] = useState<number | null>(null);
  const [maxDiscountedUnits, setMaxDiscountedUnits] = useState<number | null>(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const [productOptions, setProductOptions] = useState<IOption[]>([]);
  const [contractOptions, setContractOptions] = useState<IOption[]>([]);

  const [purchaseOrder, setPurchaseOrder] = useState<IPurchaseOrder>({
    id: null,
    status: null,
    from_request: null,
    supplier: null,
    warehouse: null,
    ref: null,
    created_at: null,
    created_by: null,
    lines: []
  });
  const [newLines, setNewLines] = useState<IPurchaseOrderRow[]>([]);
  const [editedLines, setEditedLines] = useState<IPurchaseOrderRow[]>([]);
  const [deletedLines, setDeletedLines] = useState<IPurchaseOrderRow[]>([]);

  useEffect(() => {
    productService.getAllBaseInformation().then(setProductOptions);
    if (purchaseOrderId) {
      orderService.get(+purchaseOrderId).then((pr) => {
        if (!pr?.id) navigate('/tenant/orders');
        setPurchaseOrder(pr);
      });
    }
  }, []);

  useEffect(() => {
    if (productToAdd) {
      setContractToAdd(null);
      setContractOptions([]);
      contractLineService
        .getAllBaseInformation({ product_id: productToAdd.id, active: true })
        .then(setContractOptions);
    } else {
      setContractToAdd(null);
      setContractOptions([]);
    }
  }, [productToAdd]);

  useEffect(() => {
    if (contractToAdd) {
      contractLineService.getSummary(contractToAdd.id).then((res) => {
        setUnitPrice(+res.unit_price), setMaxUnits(res.available_units_not_blocked);
        setMaxDiscountedUnits(res.available_discounted_units_not_blocked);
      });
    } else {
      setMaxUnits(null);
      setMaxDiscountedUnits(null);
    }
  }, [contractToAdd]);

  const saveOrder = (sendEmail: boolean) => {
    orderService
      .updateAndNotify(
        purchaseOrder.id,
        {
          added: newLines,
          edited: editedLines,
          deleted: deletedLines
        },
        sendEmail
      )
      .then((res) => {
        setPurchaseOrder(res);
        setOpenEditDialog(false);
        setNewLines([]);
        setEditedLines([]);
        setDeletedLines([]);
        toast.success('Ordine salvato.');
      });
  };

  const cancelOrder = (sendEmail: boolean) => {
    orderService.cancelAndNotify(purchaseOrder.id, {}, sendEmail).then((res) => {
      setPurchaseOrder(res);
      setOpenEditDialog(false);
      toast.success('Ordine annullato.');
    });
    setNewLines([]);
    setEditedLines([]);
    setDeletedLines([]);
  };

  // Utility methods
  const handleDelete = (line: { id: number | string }) => {
    const orderLine = purchaseOrder.lines.find((l) => l.id === line.id);
    setPurchaseOrder((prevState) => {
      const lines = prevState.lines;
      const newLines = lines.map((obj) => {
        if (orderLine.id === obj.id) {
          if (+line.id) {
            setDeletedLines((prevDeleted) => [...prevDeleted, obj]);
          } else {
            setNewLines((prevNewLines) => {
              return prevNewLines.filter((line) => line.id !== orderLine.id);
            });
          }
          return { ...obj, status: 'DELETED' };
        }
        return obj;
      });
      return { ...prevState, lines: newLines };
    });
  };

  const onSubmitRow = (values: { quantity: number; discounted_quantity: number }) => {
    let errors = false;
    if (!contractOptions.map((c) => c.id).includes(values['contract_line'])) {
      toast.error('Seleziona un contratto valido');
      errors = true;
    }
    if (errors) return;
    setPurchaseOrder((prevState) => {
      const added = [];
      if (values.quantity > 0)
        added.push({
          id: uuidv4(),
          ...values,
          status: 'NEW',
          initial_quantity: values.quantity,
          remaining_quantity: values.quantity,
          amount: unitPrice * values.quantity,
          requested_shipment_date:
            values['requested_shipment_date'] &&
            dayjs(values['requested_shipment_date']).format('YYYY-MM-DD')
        });
      if (values.discounted_quantity > 0)
        added.push({
          id: uuidv4(),
          ...values,
          discounted: true,
          initial_quantity: values.discounted_quantity,
          remaining_quantity: values.discounted_quantity,
          status: 'NEW',
          amount: 0,
          requested_shipment_date:
            values['requested_shipment_date'] &&
            dayjs(values['requested_shipment_date']).format('YYYY-MM-DD')
        });
      setNewLines((prevNewLines) => [...prevNewLines, ...added]);
      return {
        ...prevState,
        lines: prevState.lines.concat(added)
      };
    });
  };

  const columns: GridColumns = OrderLinesColumns(
    purchaseOrder?.status === 'ACTIVE' && handleDelete
  );

  const onEditCell = (params: GridCellEditCommitParams, _event: MuiEvent) => {
    const payload = purchaseOrder.lines.find((line) => line.id === params.id);
    payload[params.field] = params.value;
    payload['status'] = 'EDITED';
    setPurchaseOrder((prevOrder) => {
      const lines = prevOrder.lines.map((line) => {
        if (line.id === params.id) {
          if (params.value instanceof Date) {
            line[params.field] = dayjs(params.value).format('YYYY-MM-DD');
          } else {
            line[params.field] = params.value;
          }
        }
        return line;
      });
      return {
        ...prevOrder,
        lines
      };
    });

    if (+params.id) {
      const index = editedLines.findIndex((row) => row.id === params.id);
      if (index !== -1) {
        setEditedLines((prevRows) => [
          ...prevRows.slice(0, index),
          payload,
          ...prevRows.slice(index + 1)
        ]);
      } else {
        setEditedLines((prevRows) => [...prevRows, payload]);
      }
    } else {
      setNewLines((prevNewLines) => {
        return prevNewLines.map((line) => {
          if (line.id === params.id) return payload;
          return line;
        });
      });
    }
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
  };

  return (
    <>
      {purchaseOrder && (
        <TenantPage
          title={t(`${translationPrefix}.title`, { id: purchaseOrder.ref })}
          subtitle={t(`${translationPrefix}.subtitle`)}
          entityCode={purchaseOrder?.ref + (sap_active ? ` (SAP ID ${purchaseOrder?.sap_id})` : '')}
          menuRight={
            <div className="flex items-center justify-end">
              {purchaseOrder.status === 'COMPLETED' && (
                <div className="pr-4">
                  <CheckCircleOutline color="inherit" />
                </div>
              )}
              {purchaseOrder.status === 'ACTIVE' && (
                <IsTenantAdministrator>
                  <ButtonGroup size="small" variant="outlined">
                    <Button
                      disabled={!newLines.length && !editedLines.length && !deletedLines.length}
                      onClick={() => setOpenEditDialog(true)}>
                      <Tooltip title={t('global.save')}>
                        <SaveIcon />
                      </Tooltip>
                    </Button>
                    {purchaseOrder?.lines.every((line) => {
                      if (line?.remaining_quantity === line?.initial_quantity) return true;
                      return false;
                    }) && (
                      <Button onClick={() => setOpenCancelDialog(true)}>
                        <Tooltip title={t('global.cancel')}>
                          <CancelIcon color="error" />
                        </Tooltip>
                      </Button>
                    )}
                  </ButtonGroup>
                </IsTenantAdministrator>
              )}
            </div>
          }>
          {purchaseOrder.id && purchaseOrder.status !== 'COMPLETED' && (
            <Accordion className="bg-slate-50 my-8">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{t('pages.purchase-requests.new.add-element')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <PurchaseRequestLineForm
                  onSubmit={onSubmitRow}
                  products={productOptions}
                  setProduct={setProductToAdd}
                  contracts={contractOptions}
                  setContract={setContractToAdd}
                  maxUnits={maxUnits}
                  maxDiscountedUnits={maxDiscountedUnits}
                />
              </AccordionDetails>
            </Accordion>
          )}
          {/* Item list */}
          <div>
            <DataGridPremium
              density="compact"
              initialState={{
                pinnedColumns: {
                  left: ['code', 'approved'],
                  right: ['actions']
                }
              }}
              rows={purchaseOrder.lines.filter((row) => row.status !== 'DELETED')}
              localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
              sx={{ border: 'none' }}
              autoHeight
              disableSelectionOnClick
              pagination
              columns={columns}
              onCellEditCommit={onEditCell}
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
            />
          </div>
          {/* Confirmation dialog */}
          <div>
            <Dialog open={openEditDialog} onClose={handleCloseEditDialog} fullWidth maxWidth="md">
              <DialogTitle id="alert-dialog-title">{'Conferma modifica ordine'}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <p>{t('pages.purchase-orders.view.edit-confirmation')}</p>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <div className="w-full flex justify-between">
                  <Button onClick={handleCloseEditDialog}>{t('actions.cancel')}</Button>
                  <div>
                    <Button className="me-2" onClick={() => saveOrder(false)}>
                      {t('actions.confirm')}
                    </Button>
                    <Button variant="contained" onClick={() => saveOrder(true)} autoFocus>
                      {t('actions.confirm-send-email')}
                    </Button>
                  </div>
                </div>
              </DialogActions>
            </Dialog>
          </div>
          <div>
            <Dialog
              open={openCancelDialog}
              onClose={handleCloseCancelDialog}
              fullWidth
              maxWidth="md">
              <DialogTitle id="alert-dialog-title">{'Conferma cancellazione ordine'}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <p>{t('pages.purchase-orders.view.cancel-confirmation')}</p>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <div className="w-full flex justify-between">
                  <Button onClick={handleCloseCancelDialog}>{t('actions.cancel')}</Button>
                  <div>
                    <Button className="me-2" onClick={() => cancelOrder(false)}>
                      {t('actions.confirm')}
                    </Button>
                    <Button variant="contained" onClick={() => cancelOrder(true)} autoFocus>
                      {t('actions.confirm-send-email')}
                    </Button>
                  </div>
                </div>
              </DialogActions>
            </Dialog>
          </div>
        </TenantPage>
      )}
    </>
  );
};

export default PurchaseOrder;
