import ReactFinalForm from 'components/form/ReactFinalForm';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';

interface MultiBatchesFormParams {
  movement: any;
  batches: any[];
  setBatch: any;
  onSubmit: any;
}

function SelectMultiBatches(params: MultiBatchesFormParams) {
  const { t } = useTranslation();
  const { batches, setBatch, onSubmit, movement } = params;
  const translationPrefix = 'pages.movements.multi-batches';

  return (
    <div>
      <div className="align-top p-3">
        {t(`${translationPrefix}.body`, {
          batch: movement.product_batch
        })}
      </div>
      <div className="align-middle p-2">
        <ReactFinalForm
          onSubmit={(values) => {
            const updatedMovement = {
              ...movement, // Spread the existing properties of the movement object
              product_batch: batches.find((batch) => batch.code === values.product_batch).id // Update the product_batch property
            };
            onSubmit(updatedMovement);
          }}
          formId="updateBatches"
          hideControls>
          <div className="p-2 ">
            <IdentityField
              name="product_batch"
              label={t('entities.movement.product_batch')}
              component={AutocompleteAdapter}
              options={batches}
              optionValue={'code'}
              initValue={batches.find((batch) => batch.id === movement.product_batch)}
              setValue={setBatch}
              type="text"
              request
            />
          </div>
        </ReactFinalForm>
      </div>
    </div>
  );
}

export default SelectMultiBatches;
