import { transportDocumentService } from 'services/api';
import { ITransportDocument } from 'interfaces';
import { loadingActions } from 'features';
import { useAppDispatch } from 'app/store';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import TransportDocumentForm from '../TransportDocumentForm';
import { useNavigate } from 'react-router-dom';

const initDdt: ITransportDocument = {
  id: null,
  ref: '',
  invoice_ref: '',
  closed: false,
  amount: 0,
  shipping_fare: 0
};

export const CreateTransportDocument = () => {
  const translationPrefix = 'pages.ddt.new';

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    dispatch(loadingActions.startLoading());
    transportDocumentService.add(values).then((res) => {
      if (res?.id) {
        enqueueSnackbar('DDT aggiunto con successo', { variant: 'success' });
        navigate(`../${res.id}`);
      }
    });
    dispatch(loadingActions.stopLoading());
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <TransportDocumentForm transport_document={initDdt} onSubmit={onSubmit} />
    </TenantPage>
  );
};

export default CreateTransportDocument;
