import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
//import { NavLink } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { notificationService } from 'services/api';
import { toInteger } from 'lodash';
import dayjs from 'dayjs';

export const NotificationDetail = () => {
  const { t } = useTranslation();
  const prefix = 'entities.inventory.notification';

  //const [notificationID, setNotificationID] = useState('');
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationDate, setNotificationDate] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');

  useEffect(() => {
    notificationService.get(toInteger(window.location.pathname.split('/')[4])).then((res) => {
      //setNotificationID(res.id)
      setNotificationTitle(res.notification.title);
      setNotificationDate(dayjs(res.notification.timestamp).format('DD/MM/YYYY HH:mm'));
      setNotificationMessage(res.notification.message);

      if (!res.read) {
        notificationService.update(res.id, { read: true });
      }
    });
  }, []);

  return (
    <TenantPage title={''} menuRight={<></>}>
      {/* <NavLink
        to={`/tenant/notifications/view/${ id - 1 }`}>
            PRECEDENTE
        </NavLink>
        <NavLink
        to={`/tenant/notifications/view/${ id + 1 }`}>
            SUCCESSIVO
        </NavLink> */}
      <Card sx={{ minWidth: 275, maxWidth: 1400 }}>
        <CardContent className="">
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {notificationDate}
          </Typography>
          <Typography variant="h5" component="div">
            {notificationTitle}
          </Typography>
          <br />
          <Typography variant="h5" component="div">
            {t(`${prefix}.message`)}
          </Typography>
          <div className="p-8">
            <div className="break-all">{notificationMessage}</div>
          </div>
        </CardContent>
      </Card>
    </TenantPage>
  );
};
