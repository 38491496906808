import { Alert, Button, Skeleton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { IContract } from 'interfaces';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { contractService } from 'services/api';
import HandshakeIcon from '@mui/icons-material/Handshake';

function ExpiringContracts() {
  const { t } = useTranslation();
  const [contracts, setContracts] = useState<IContract[]>();
  useEffect(() => {
    contractService
      .getAllPaginated(3, 0, {
        expiration_date__lte: dayjs().add(3, 'months').format('YYYY-MM-DD'),
        ordering: 'expiration_date'
      })
      .then((res) => setContracts(res.results));
  }, []);

  return (
    <div>
      <Typography sx={{ fontSize: 14 }} color="secondary" gutterBottom>
        <HandshakeIcon />
        {t('dashboard.expiration-contracts.title')}
      </Typography>
      {!contracts && (
        <>
          <Skeleton variant="rectangular" height={32} />
          <br />
          <Skeleton variant="rectangular" height={32} />
          <br />
          <Skeleton variant="rectangular" height={32} />
        </>
      )}
      {contracts &&
        contracts.map((contract) => (
          <Alert
            severity={dayjs().isAfter(contract.expiration_date) ? 'error' : 'warning'}
            key={contract.id}
            className="mb-2"
            action={
              <NavLink to={`/tenant/contracts/${contract.id}`}>
                <Button color="inherit" size="small">
                  {t('dashboard.expiration-contracts.go')}
                </Button>
              </NavLink>
            }>
            {t('dashboard.expiration-contracts.row-body', {
              code: contract.code,
              supplier: contract.supplier,
              date: contract.expiration_date
            })}
          </Alert>
        ))}
    </div>
  );
}

export default ExpiringContracts;
