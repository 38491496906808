import { loadingActions } from 'features';
import { regionalRateService } from 'services/api';
import { useAppDispatch } from 'app/store';
import TenantPage from 'components/common/TenantPage';
import { useTranslation } from 'react-i18next';
import { RegionalRateForm } from '../RegionalRateForm';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { IRegionalRate } from 'interfaces';
import toast from 'features/toast';

export const EditRegionalRate = () => {
  const translationPrefix = 'pages.regional-rate.edit';
  const { regionalRateId } = useParams();
  const [regionalRate, setRegionalRate] = useState<IRegionalRate>();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    regionalRateService.get(+regionalRateId).then(setRegionalRate);
  }, []);

  const updateType = async (values) => {
    dispatch(loadingActions.startLoading());
    regionalRateService.update(regionalRate.id, values).then(() => {
      toast.success('Tariffa aggiornata con successo');
    });
    dispatch(loadingActions.stopLoading());
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      entityCode={regionalRate?.code}>
      <RegionalRateForm regionalRate={regionalRate} onSubmit={updateType} />
    </TenantPage>
  );
};

export default EditRegionalRate;
