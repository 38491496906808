import { invoiceService } from 'services/api';
import { IInvoice } from 'interfaces';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import InvoiceForm from '../InvoiceForm';
import { useNavigate } from 'react-router-dom';
import toast from 'features/toast';

const initInvoice: IInvoice = {
  id: null,
  ref: '',
  closed: false,
  shipping_fare: 0,
  amount: 0
};

export const CreateInvoice = () => {
  const translationPrefix = 'pages.invoices.new';

  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    invoiceService.add(values).then((res) => {
      if (res?.id) {
        toast.success('Fattura aggiunta con successo');
        navigate(`../${res.id}`);
      }
    });
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <InvoiceForm invoice={initInvoice} onSubmit={onSubmit} />
    </TenantPage>
  );
};

export default CreateInvoice;
