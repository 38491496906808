import React from 'react';
import { SxProps, Tooltip, Typography } from '@mui/material';

const DataGridCell = (props: { content: string; sx?: SxProps }) => {
  return (
    <Tooltip
      placement="top"
      arrow
      title={
        <React.Fragment>
          <Typography color="inherit">{props.content}</Typography>
        </React.Fragment>
      }>
      <Typography color="inherit" sx={props.sx}>
        {props.content}
      </Typography>
    </Tooltip>
  );
};
export default DataGridCell;
