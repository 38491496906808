import { movementReasonService, tenantSettingsService } from 'services/api';
import { IdentityField } from 'components/form/IdentityField';
import { IOption, ISAPSettings, ITenantSettings } from 'interfaces';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { useTranslation } from 'react-i18next';
import { Card, CardContent } from '@mui/material';
import FileUploader from '../../file-uploader';
import { useEffect, useState } from 'react';
import SectionTitle from '../../SectionTitle';
import { IsSuperAdmin } from 'components/helpers/IsSuperAdmin';
import { CheckboxAdapter } from 'components/form/CheckboxAdapter';

interface SAPSettingsFormParams {
  settings: ISAPSettings;
  onSubmit: any;
}

const SAPSettingsForm = (params: SAPSettingsFormParams) => {
  const { t } = useTranslation();
  const [movementReasons, setMovementReasons] = useState<IOption[]>([]);

  const { settings, onSubmit } = params;

  useEffect(() => {
    movementReasonService.getAllPaginated().then((res) => {
      setMovementReasons(res.results);
    });
  }, []);

  return (
    <div>
      {settings && (
        <div className="flex flex-wrap">
          <div className="py-4 w-full p-4">
            <div>
              <ReactFinalForm onSubmit={onSubmit}>
                <div className="flex flex-wrap">
                  <div className="p-2 w-full">
                    <SectionTitle>{t('entities.tenant-settings.base-info')}</SectionTitle>
                  </div>
                  <div className="p-2 w-full">
                    <IdentityField
                      name="enabled"
                      label={t(`global.active`)}
                      initialValue={settings.enabled}
                      component={CheckboxAdapter}
                      placeholder={t('global.active')}
                      type="checkbox"
                    />
                  </div>
                  <div className="w-full mt-4 md:w-3/4 md:pr-2">
                    <IdentityField
                      name="base_url"
                      label={t(`entities.sap-settings.base_url`)}
                      initialValue={settings.base_url}
                      component={TextFieldAdapter}
                      type="text"
                    />
                  </div>
                  <div className="w-full mt-4 md:w-1/4 md:pl-2">
                    <IdentityField
                      name="sync_order_endpoint"
                      label={t(`entities.sap-settings.sync_order_endpoint`)}
                      initialValue={settings.sync_order_endpoint}
                      component={TextFieldAdapter}
                      type="text"
                    />
                  </div>
                  <div className="w-full mt-4 md:w-1/2 md:pr-2">
                    <IdentityField
                      name="username"
                      label={t(`entities.sap-settings.username`)}
                      initialValue={settings.username}
                      component={TextFieldAdapter}
                      type="text"
                    />
                  </div>
                  <div className="w-full mt-4 md:w-1/2 md:pl-2">
                    <IdentityField
                      name="password"
                      label={t(`entities.sap-settings.password`)}
                      initialValue={settings.password}
                      component={TextFieldAdapter}
                      type="text"
                    />
                  </div>
                </div>
              </ReactFinalForm>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SAPSettingsForm;
