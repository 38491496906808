import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { equipmentService, workstationService } from 'services/api';
import { IdentityField } from 'components/form/IdentityField';
import { IEquipment, IStatusOption } from 'interfaces';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { useEffect, useState } from 'react';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { useTranslation } from 'react-i18next';
import { DatePickerAdapter } from 'components/form/DatePickerAdapter';
import SectionTitle from 'components/common/SectionTitle';
import { CurrencyTextFieldAdapter } from 'components/form/CurrencyTextFieldAdapter';

interface EquipmentFormParams {
  equipment: IEquipment;
  onSubmit: any;
}

const EquipmentForm = (params: EquipmentFormParams) => {
  const translationPrefix = 'entities.equipment';
  const { t } = useTranslation();
  const { equipment, onSubmit } = params;

  const [statusOptions, setStatusOptions] = useState<IStatusOption[]>([]);
  const [ivdrOptions, setIvdrOptions] = useState<IStatusOption[]>([]);

  useEffect(() => {
    equipmentService.options().then((res) => {
      setStatusOptions(
        res.actions.POST.status.choices.map((item) => {
          return {
            ...item,
            display_name: t(`${translationPrefix}.status_options.${item.display_name}`)
          };
        })
      );
      setIvdrOptions(res.actions.POST.ivdr_class.choices);
    });
  }, []);

  return (
    <div>
      {equipment && (
        <ReactFinalForm onSubmit={onSubmit} edit={!!equipment.id}>
          <div className="flex flex-wrap">
            <div className="w-full p-2">
              <SectionTitle>{t('global.base-info')}</SectionTitle>
            </div>
            {/* <div className="w-full mb-4"> */}
            {/*   <IdentityField */}
            {/*     initValue={equipment.workstation} */}
            {/*     name="workstation" */}
            {/*     component={AutocompleteAdapter} */}
            {/*     options={workstations} */}
            {/*     label={t(`${translationPrefix}.workstation`)} */}
            {/*     type="text" */}
            {/*   /> */}
            {/* </div> */}
            <div className="w-1/4 pr-2 mb-4">
              <IdentityField
                name="code"
                defaultValue={equipment.code}
                component={TextFieldAdapter}
                label={t('global.code')}
                type="text"
              />
            </div>
            <div className="w-1/4 pr-2 mb-4">
              <IdentityField
                name="internal_id"
                defaultValue={equipment.internal_id}
                component={TextFieldAdapter}
                label={t(`${translationPrefix}.internal_id`)}
                type="text"
              />
            </div>
            <div className="w-1/2 pl-2 mb-4">
              <IdentityField
                name="description"
                defaultValue={equipment.description}
                component={TextFieldAdapter}
                label={t('global.description')}
                type="text"
              />
            </div>
            <div className="pr-2 w-full md:w-1/4 mb-4">
              <IdentityField
                name="serial_number"
                defaultValue={equipment.serial_number}
                component={TextFieldAdapter}
                label={t(`${translationPrefix}.serial_number`)}
                type="text"
              />
            </div>
            <div className="px-2 w-full md:w-1/4 mb-4">
              <IdentityField
                name="manufacturer"
                defaultValue={equipment.manufacturer}
                component={TextFieldAdapter}
                label={t(`${translationPrefix}.manufacturer`)}
                type="text"
              />
            </div>
            <div className="px-2 w-full md:w-1/4 mb-4">
              <IdentityField
                name="status"
                label={t(`${translationPrefix}.status`)}
                initValue={
                  equipment.status
                    ? {
                        ...equipment.status,
                        display_name: t(
                          `${translationPrefix}.status_options.${equipment.status.display_name}`
                        )
                      }
                    : {
                        value: 1,
                        display_name: t(`${translationPrefix}.status_options.NEW`)
                      }
                }
                defaultValue={equipment.status ? equipment.status.value : 1}
                component={AutocompleteAdapter}
                options={statusOptions}
                placeholder="Status"
                type="text"
                optionLabel="display_name"
                optionValue="value"
              />
            </div>
            <div className="pl-2 w-full md:w-1/4 mb-4">
              <IdentityField
                name="ivdr_class"
                label={t(`${translationPrefix}.ivdr_class`)}
                initValue={equipment.ivdr_class ?? null}
                defaultValue={equipment.ivdr_class ? equipment.ivdr_class['value'] : null}
                component={AutocompleteAdapter}
                options={ivdrOptions}
                type="text"
                optionLabel="display_name"
                optionValue="value"
              />
            </div>
            <div className="pr-2 w-full md:w-1/3 mb-4">
              <IdentityField
                name="supplier"
                defaultValue={equipment.supplier}
                component={TextFieldAdapter}
                label={t(`${translationPrefix}.supplier`)}
                type="text"
              />
            </div>
            <div className="px-2 w-full md:w-1/3 mb-4">
              <IdentityField
                name="position"
                defaultValue={equipment.position}
                component={TextFieldAdapter}
                label={t(`${translationPrefix}.position`)}
                type="text"
              />
            </div>
            <div className="w-1/3 mb-4">
              <IdentityField
                name="market_value"
                format={(value) => value && value.replace('.', ',')}
                defaultValue={equipment.market_value}
                label={t(`${translationPrefix}.market_value`)}
                component={CurrencyTextFieldAdapter}
                type="text"
              />
            </div>
            <div className="w-full p-2">
              <SectionTitle>{t('global.misc')}</SectionTitle>
            </div>
            <div className="pr-2 w-full md:w-1/2 mb-4">
              <IdentityField
                name="transport_document_ref"
                component={TextFieldAdapter}
                label={t(`${translationPrefix}.transport_document_ref`)}
                type="text"
                initialValue={equipment?.transport_document_ref ?? ''}
              />
            </div>
            <div className="pl-2 w-full md:w-1/2 mb-4">
              <IdentityField
                name="delivery_date"
                component={DatePickerAdapter}
                label={t(`${translationPrefix}.delivery_date`)}
                type="date"
                initialValue={equipment?.delivery_date ?? null}
              />
            </div>
            <div className="pr-2 w-full md:w-1/3 mb-4">
              <IdentityField
                name="installation_date"
                component={DatePickerAdapter}
                label={t(`${translationPrefix}.installation_date`)}
                type="date"
                initialValue={equipment?.installation_date ?? null}
              />
            </div>
            <div className="px-2 w-full md:w-1/3 mb-4">
              <IdentityField
                name="testing_date"
                component={DatePickerAdapter}
                label={t(`${translationPrefix}.testing_date`)}
                type="date"
                initialValue={equipment?.testing_date ?? null}
              />
            </div>
            <div className="pl-2 w-full md:w-1/3 mb-4">
              <IdentityField
                name="decommission_date"
                component={DatePickerAdapter}
                label={t(`${translationPrefix}.decommission_date`)}
                type="date"
                initialValue={equipment?.decommission_date ?? null}
              />
            </div>
            <div className="w-full">
              <div className="mb-4">
                <IdentityField
                  name="accessories"
                  defaultValue={equipment.accessories}
                  component={TextFieldAdapter}
                  label={t(`${translationPrefix}.accessories`)}
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="mb-4 w-full">
            <IdentityField
              name="notes"
              label={t(`global.notes`)}
              initialValue={equipment.notes}
              component={TextFieldAdapter}
            />
          </div>
        </ReactFinalForm>
      )}
    </div>
  );
};

export default EquipmentForm;
