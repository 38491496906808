import { warehouseService } from 'services/api';
import { loadingActions } from 'features';
import { useAppDispatch } from 'app/store';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import WarehouseForm from '../WarehouseForm';
import { IWarehouse } from 'interfaces';

const initWarehouse: IWarehouse = {
  id: null,
  code: '',
  description: '',
  laboratories: []
};

export const CreateWarehouse = () => {
  const translationPrefix = 'pages.warehouse.new';

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const createWarehouse = async (values) => {
    dispatch(loadingActions.startLoading());
    warehouseService.add(values).then((res) => {
      enqueueSnackbar('Magazzino aggiunto con successo');
    });
    dispatch(loadingActions.stopLoading());
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <WarehouseForm warehouse={initWarehouse} onSubmit={createWarehouse} />
    </TenantPage>
  );
};

export default CreateWarehouse;
