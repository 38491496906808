import { NavLink } from 'react-router-dom';
import { purchaseRequestService } from 'services/api';
import { useTranslation } from 'react-i18next';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import TenantPage from 'components/common/TenantPage';
import { ButtonGroup, Button } from '@mui/material';
import { useRef } from 'react';
import toast from 'features/toast';
import { PurgeButton } from 'components/helpers/PurgeButton';
import { PurchaseLineColumns } from 'components/common/enhanced/common-headers/purchase-request';
import { GridColDef } from '@mui/x-data-grid-premium';

export const PurchaseRequestList = () => {
  const translationPrefix = 'pages.purchase-requests.list';

  const { t } = useTranslation();
  const datagridRefresh = useRef(null);

  const handleDelete = (id: number) => {
    purchaseRequestService.delete(id).then(() => {
      toast.success(t('actions.successfully.deleted'));
      datagridRefresh.current();
    });
  };

  const columns: GridColDef[] = PurchaseLineColumns(
    t(`${translationPrefix}.delete-confirmation-extended`),
    handleDelete
  );

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <div>
            <ButtonGroup>
              <NavLink to="new">
                <Button variant="outlined" color="primary" size="small">
                  {t(`${translationPrefix}.add-new`)}
                </Button>
              </NavLink>
              <PurgeButton service={purchaseRequestService} refresh={datagridRefresh} />
            </ButtonGroup>
          </div>
        }>
        <EnhancedDataGrid
          columns={columns}
          service={purchaseRequestService}
          refresh={datagridRefresh}
          disableDelete
        />
      </TenantPage>
    </>
  );
};

export default PurchaseRequestList;
