import { Chip } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Check, Close } from '@mui/icons-material';

export const compliantCell = () => {
  const renderCell = (params: GridRenderCellParams) => {
    return (
      <Chip
        sx={{ background: 'transparent' }}
        icon={
          params.value !== null &&
          (params.value === true ? <Check color="success" /> : <Close color="error" />)
        }
      />
    );
  };
  return renderCell;
};
