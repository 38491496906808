import { Chip } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockClockIcon from '@mui/icons-material/LockClock';
import CloudSyncIcon from '@mui/icons-material/CloudSync';

const STATES = {
  EDITABLE: <LockOpenIcon color="info" />,
  LOCKED: <LockClockIcon color="warning" />,
  SENT: <CloudSyncIcon color="success" />
};

function sapStateCell(params: GridRenderCellParams) {
  return (
    <div>
      <Chip sx={{ background: 'transparent' }} icon={STATES[params.value]} />
    </div>
  );
}

export default sapStateCell;
