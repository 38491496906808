import { useEffect, useState } from 'react';
import { Autocomplete, createFilterOptions, TextField } from '@mui/material';
import { IOptionFM } from 'interfaces';
import { useField } from 'react-final-form';

const filter = createFilterOptions<IOptionFM>();

const DocumentAutoComplete = ({ input: { name }, options, label, ...rest }: any) => {
  const { input, meta } = useField(name);
  const [value, setValue] = useState<IOptionFM | null>(null);

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          input.onChange(newValue);
          setValue(value);
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          input.onChange(newValue.inputValue.toString());
          setValue({ name: newValue.inputValue.toString() });
        } else {
          input.onChange(newValue);
          setValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.name);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            name: `Add "${inputValue}"`
          });
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id={label}
      options={options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
      sx={{ width: 300 }}
      freeSolo
      renderInput={(params) => <TextField {...params} {...rest} label={label} />}
    />
  );
};

export default DocumentAutoComplete;
