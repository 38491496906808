import { Button, ButtonGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import TenantPage from 'components/common/TenantPage';
import { workstationService } from 'services/api';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { GridColDef } from '@mui/x-data-grid-premium';
import { WorkstationsColumns } from 'components/common/enhanced/common-headers/workstations';

export const Workstations = () => {
  const translationPrefix = 'pages.workstation.list';
  const { t } = useTranslation();

  const columns: GridColDef[] = WorkstationsColumns();

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <div className="flex justify-end items-center">
            <ButtonGroup>
              <IsTenantAdministrator>
                <Link to="new">
                  <Button variant="outlined" size="small">
                    <AddIcon />
                    {t(`${translationPrefix}.add`)}
                  </Button>
                </Link>
              </IsTenantAdministrator>
            </ButtonGroup>
          </div>
        }>
        <div className="mt-6">
          <EnhancedDataGrid columns={columns} service={workstationService} />
        </div>
      </TenantPage>
    </>
  );
};

export default Workstations;
