import { Button, ButtonGroup } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import TenantPage from 'components/common/TenantPage';
import { RoleNameColumns } from 'components/common/enhanced/common-headers/role-names';
import { roleNameService } from 'services/api/RoleNameService';
import AddRoleNameDialog from '../AddRoleNameDialog';
import { useRef, useState } from 'react';

function RoleNameList() {
  const translationPrefix = 'pages.role-names.list';
  const { t } = useTranslation();

  const [addDialog, setAddDialog] = useState(false);

  const columns: GridColDef[] = RoleNameColumns();
  const refreshTable = useRef();

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      menuRight={
        <div className="flex justify-end items-center">
          <ButtonGroup size="small" variant="outlined">
            <Link to="by-role">
              <Button>{t(`${translationPrefix}.by-role`)}</Button>
            </Link>
            <IsTenantAdministrator>
              <Button
                color="success"
                onClick={() => {
                  setAddDialog(true);
                }}>
                <AddIcon />
                {t(`${translationPrefix}.add_role_name`)}
              </Button>
            </IsTenantAdministrator>
          </ButtonGroup>
        </div>
      }>
      <div className="mt-6">
        <EnhancedDataGrid columns={columns} service={roleNameService} refresh={refreshTable} />
      </div>
      <AddRoleNameDialog open={addDialog} setOpen={setAddDialog} callback={refreshTable.current} />
    </TenantPage>
  );
}

export default RoleNameList;
