import { TextField } from '@mui/material';
import { useState } from 'react';

export const TextFieldAdapter = ({
  input: { name, onChange, value, ...restInput },
  meta,
  ...rest
}: any) => {
  const [_value, _setValue] = useState(value);
  const _onChange = (e) => {
    _setValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <TextField
      {...rest}
      name={name}
      onChange={_onChange}
      helperText={meta.touched ? meta.error : ''}
      error={meta.error && meta.touched}
      value={_value}
      inputProps={restInput}
      fullWidth
    />
  );
};
