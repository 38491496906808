import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { orderLineService } from 'services/api';
import { GridColDef } from '@mui/x-data-grid-premium';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { OrderDetailColumns } from 'components/common/enhanced/common-headers/order-detail';
import { uniqueId } from 'lodash';

export function DeliveryExpected() {
  const translationPrefix = 'pages.orders.delivery';
  const { t } = useTranslation();
  const columns: GridColDef[] = OrderDetailColumns();
  const [searchParams] = useSearchParams();

  const ref = searchParams.get('ref');
  const filters = [];
  const initialParams = {};
  if (ref) {
    filters.push({
      id: uniqueId(),
      columnField: 'ref',
      operatorValue: 'equals',
      value: ref
    });
    initialParams['ref'] = ref;
  }

  const blameMode = searchParams.get('blameMode');
  if (blameMode) {
    filters.push({
      id: uniqueId(),
      columnField: 'status',
      operatorValue: 'is',
      value: 'PENDING'
    });
    // filters.push({
    //   id: uniqueId(),
    //   columnField: 'requested_shipment_date',
    //   operatorValue: 'onOrBefore',
    //   value: dayjs().format('YYYY-MM-DD')
    // });
    initialParams['status'] = 'PENDING';
    // initialParams['requested_shipment_date'] = dayjs().format('YYYY-MM-DD');
  }

  return (
    <TenantPage
      title={!ref ? t(`${translationPrefix}.title`) : t(`global.delivery`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      menuRight={<div></div>}>
      <EnhancedDataGrid
        columns={columns}
        service={orderLineService}
        initialFilterItems={filters}
        // initialParams={initialParams}
        initialParams={initialParams}
      />
    </TenantPage>
  );
}

export default DeliveryExpected;
