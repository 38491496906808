import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { GridColDef } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { MovementsColumns } from 'components/common/enhanced/common-headers/movements';
import { movementReasonService, movementService } from 'services/api';
import { useEffect, useRef, useState } from 'react';
import { IOption } from 'interfaces';
import { loadingActions } from 'features';
import { useAppDispatch, useAppSelector } from 'app/store';
import { useSearchParams } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import toast from 'features/toast';

export const CollapsedMovements = () => {
  const translationPrefix = 'pages.movements';
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const datagridRefresh = useRef(null);
  const sap_active = useAppSelector((state) => state.auth.sap_active);

  const [movementReasons, setMovementReasons] = useState<IOption[]>([]);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [movementToDelete, setMovementToDelete] = useState<number | null>(null);

  useEffect(() => {
    dispatch(loadingActions.startLoading());
    movementReasonService.getAllPaginated().then((res) => {
      setMovementReasons(res.results);
    });
    dispatch(loadingActions.stopLoading());
  }, []);

  useEffect(() => {
    if (movementToDelete) {
      setOpenConfirmationDialog(true);
    }
  }, [movementToDelete]);

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
    setMovementToDelete(null);
  };

  const columns: GridColDef[] = MovementsColumns(
    movementReasons.map((reason) => reason.code),
    true,
    false,
    true,
    setMovementToDelete,
    true,
    sap_active
  );

  const deleteMovement = () => {
    movementService.delete(movementToDelete).then(() => {
      toast.success(t('actions.successfully.deleted'));
      datagridRefresh.current();
    });
    setOpenConfirmationDialog(false);
    setMovementToDelete(null);
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <EnhancedDataGrid
        columns={columns}
        service={movementService}
        outerParams={{
          product__id: searchParams.get('product__id'),
          warehouse: searchParams.get('warehouse'),
          type: searchParams.get('type'),
          created_by: searchParams.get('created_by'),
          product_batch: searchParams.get('product_batch'),
          supplier: searchParams.get('supplier'),
          purchase_order: searchParams.get('purchase_order'),
          timestamp: searchParams.get('timestamp'),
          discounted: searchParams.get('discounted')
        }}
        refresh={datagridRefresh}
      />
      <div>
        {/* Confirmation dialog PRE-ConfirmationButtonDelete */}
        <Dialog
          open={openConfirmationDialog}
          onClose={handleCloseConfirmationDialog}
          fullWidth
          maxWidth="md">
          <DialogTitle id="alert-dialog-title">
            {t(`${translationPrefix}.delete-confirmation`)}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p>{t(`${translationPrefix}.delete-confirmation-extended`)}</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div className="w-full flex justify-between">
              <Button onClick={handleCloseConfirmationDialog}>{t('actions.cancel')}</Button>
              <div>
                <Button variant="contained" color="error" onClick={deleteMovement} autoFocus>
                  {t('actions.delete')}
                </Button>
              </div>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    </TenantPage>
  );
};

export default CollapsedMovements;
