import { GridRenderCellParams } from '@mui/x-data-grid-premium';

function workstationPhaseCell(t: any) {
  const renderCell = (params: GridRenderCellParams) => {
    return (
      <div>
        {/* <span className={value === 'ACTIVE' || value === 1 ? 'text-green-500' : 'text-orange-500'}> */}
        {params.value ? t(`entities.workstation.phase_options.${params.value}`) : ''}
        {/* </span> */}
      </div>
    );
  };
  return renderCell;
}

export default workstationPhaseCell;
