import { useAppSelector } from 'app/store';
import { ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN } from './../../constants';

export function IsTenantAdministrator({ children }) {
  const groups = useAppSelector((state) => state.auth.groups);
  if (
    (groups && groups.includes(ROLE_TENANT_ADMIN)) ||
    (groups && groups.includes(ROLE_SUPER_ADMIN))
  )
    return children;
}
