import { Button } from '@mui/material';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { DatePickerAdapter } from 'components/form/DatePickerAdapter';
import { MultiDatePickerAdapter } from 'components/form/MultiDatePickerAdapter';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { composeValidators, mustBeCurrency, required } from 'helpers/validators';
import { IOption } from 'interfaces';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';

interface ContractEquipmentFormProps {
  onSubmit: any;
  equipments: IOption[];
}

function ContractEquipmentForm(props: ContractEquipmentFormProps) {
  const { onSubmit, equipments } = props;
  const { t } = useTranslation();

  return (
    <div>
      <ReactFinalForm onSubmit={onSubmit} formId="addEquipment" hideControls={true}>
        <div className="flex flex-wrap">
          <div className="w-3/5 mb-4 pr-4">
            <IdentityField
              name="equipment"
              component={AutocompleteAdapter}
              options={equipments}
              label={t('global.equipment')}
              type="text"
            />
          </div>
          <div className="w-2/5 mb-4">
            <IdentityField
              name="annual_invoices"
              label={t(`entities.contract.annual_invoices`)}
              component={TextFieldAdapter}
              type="number"
            />
          </div>
          <div className="w-1/5 mb-4 pr-4">
            <IdentityField
              name="yearly_lease_amount"
              label={t('entities.contract-equipment.yearly_lease_amount')}
              component={TextFieldAdapter}
              type="text"
              validate={composeValidators(mustBeCurrency)}
            />
          </div>
          <div className="w-1/5 mb-4 pr-4">
            <IdentityField
              name="purchase_amount"
              label={t('entities.contract-equipment.purchase_amount')}
              component={TextFieldAdapter}
              type="text"
              validate={composeValidators(mustBeCurrency)}
            />
          </div>
          <div className="w-1/5 mb-4 pr-4">
            <IdentityField
              name="depreciation_years"
              label={t('entities.contract-equipment.depreciation_years')}
              component={TextFieldAdapter}
              type="number"
            />
          </div>
          <div className="w-1/5 mb-4 pr-4">
            <IdentityField
              name="yearly_maintenance_amount"
              label={t('entities.contract-equipment.yearly_maintenance_amount')}
              component={TextFieldAdapter}
              type="text"
              validate={composeValidators(mustBeCurrency)}
              required
            />
          </div>
          <div className="w-1/5 mb-4 py-auto flex">
            <Button type="submit" form="addEquipment" variant="outlined" className="w-full">
              {t('actions.insert')}
            </Button>
          </div>
        </div>
      </ReactFinalForm>
    </div>
  );
}

export default ContractEquipmentForm;
