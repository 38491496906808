import { ICostReport } from 'interfaces';
import { BaseService } from './BaseService';

class CostReportService extends BaseService<ICostReport> {
  constructor() {
    super('cost-analyses');
  }
  canDelete = true;
}

export const costReportService = new CostReportService();
