import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationsEn from '../translations/en.json';
import translationsIt from '../translations/it.json';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationsEn },
    it: { translation: translationsIt }
  },
  lng: 'it',
  fallbackLng: 'it',
  interpolation: { escapeValue: false }
});

export default i18n;
