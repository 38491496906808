import toast from 'features/toast';
import { IContractLine } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class ContractLineService extends BaseService<IContractLine> {
  constructor() {
    super('contract-lines');
  }
  canDelete = true;

  public async getSummary(id: number | string) {
    try {
      const res = await axiosPrivate.get(`${this.modulePrefix}/${id}/get_summary/`);
      return res.data;
    } catch (e) {
      toast.error(e.message);
    }
  }

  importFromFileDetail = async (
    importFile: File,
    contractId: number,
    attachmentName = 'import_file'
  ) => {
    const res = await axiosPrivate.postForm(`contracts/${contractId}/import-from-file/`, {
      [attachmentName]: importFile
    });
    return res.data;
  };
}

export const contractLineService = new ContractLineService();
