import { GridColumns } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import { baseCell } from '../cells/base-cell';
import { currencyCell } from '../cells/currency-cell';

export function ContractEquipmentColumns(): GridColumns {
  const { t } = useTranslation();
  return [
    {
      field: 'equipment',
      headerName: t(`global.equipment`),
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'description',
      headerName: t(`global.description`),
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'yearly_lease_amount',
      headerName: t(`entities.contract-equipment.yearly_lease_amount`),
      width: 240,
      renderCell: currencyCell,
      editable: true
    },
    {
      field: 'yearly_maintenance_amount',
      headerName: t(`entities.contract-equipment.yearly_maintenance_amount`),
      width: 240,
      renderCell: currencyCell,
      editable: true
    },
    {
      field: 'purchase_amount',
      headerName: t(`entities.contract-equipment.purchase_amount`),
      width: 240,
      renderCell: currencyCell,
      editable: true
    },
    {
      field: 'depreciation_years',
      headerName: t(`entities.contract-equipment.depreciation_years`),
      width: 200,
      renderCell: baseCell
    },
    {
      field: 'annual_invoices',
      headerName: t(`entities.contract.annual_invoices`),
      width: 200,
      renderCell: baseCell
    }
  ];
}
