import ReactFinalForm from 'components/form/ReactFinalForm';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { IBaseEntity, ICategoryState, IOption } from 'interfaces';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';

interface CategoryFormProps {
  type: IBaseEntity;
  onSubmit: any;
}
function TypeForm(props: CategoryFormProps) {
  const { t } = useTranslation();
  const { type, onSubmit } = props;

  return (
    <div>
      {type && (
        <ReactFinalForm onSubmit={onSubmit} edit={!!type.id}>
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/3 p-0 md:pr-4">
              <IdentityField
                name="code"
                component={TextFieldAdapter}
                initialValue={type.code}
                label={t('global.code')}
                placeholder={t('global.code')}
                type="text"
              />
            </div>
            <div className="w-full md:w-2/3 ">
              <IdentityField
                name="description"
                component={TextFieldAdapter}
                initialValue={type.description}
                label={t('global.description')}
                placeholder={t('global.description')}
                type="text"
              />
            </div>
          </div>
        </ReactFinalForm>
      )}
    </div>
  );
}

export default TypeForm;
