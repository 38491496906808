import { IContract, IContractLine } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { ExportableResourceService } from './ExportableResourceService';

class ContractService extends ExportableResourceService<IContract> {
  constructor() {
    super('contracts');
  }
  canDelete = true;
  canBulkDelete = true;

  assignProducts = async (products: IContractLine[], contractId: number) => {
    const res = await axiosPrivate.patch(`${this.modulePrefix}/${contractId}/assign_products/`, {
      lines: products
    });
    return res.data;
  };

  removeProducts = async (products: number[], contractId: number) => {
    const res = await axiosPrivate.post(`${this.modulePrefix}/${contractId}/delete_lines/`, {
      lines: products
    });
    return res.data;
  };

  getProducts = async (contractId: number) => {
    const res = await axiosPrivate.get(`contract-lines/?contract_header=${contractId}`);
    return res.data;
  };

  importFromFile = async (importFile: File, attachmentName = 'import_file') => {
    const res = await axiosPrivate.postForm(`${this.modulePrefix}/import-from-file/`, {
      [attachmentName]: importFile
    });
    return res.data;
  };

  purgeLines = async (contractId: number) => {
    const res = await axiosPrivate.post(`${this.modulePrefix}/${contractId}/purge-lines/`);
    return res.data;
  };

  importAggregatedLinesFromFile = async (importFile: File, attachmentName = 'import_file') => {
    const res = await axiosPrivate.postForm(
      `${this.modulePrefix}/import-aggregated-lines-from-file/`,
      {
        [attachmentName]: importFile
      }
    );
    return res.data;
  };

  getAggregatedImportTemplate = async () => {
    axiosPrivate
      .get(`${this.modulePrefix}/aggregated_import_template/`, {
        responseType: 'blob'
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const contentDisposition = res.headers['content-disposition'];
        if (contentDisposition?.indexOf('attachment') !== -1) {
          const filenameMatches = contentDisposition.match(
            /(?:.*filename\*|filename)=(?:([^'"]*)''|("))([^;]+)\2(?:[;`\n]|$)/
          );
          const filename = filenameMatches.pop();
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        }
      });
  };
}

export const contractService = new ContractService();
