import { IPurchaseOrder } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class OrderService extends BaseService<IPurchaseOrder> {
  constructor() {
    super('purchordhead');
  }
  canDelete = true;
  canBulkDelete = true;

  async openOrders(limit = 0, offset = 0, params: Record<string, unknown> = {}) {
    const res = await axiosPrivate.get(`${this.modulePrefix}/open-orders/`, {
      params: { limit, offset, ...params }
    });
    return res.data;
  }

  downloadAttachment = async (orderId: number) => {
    axiosPrivate
      .get(`${this.modulePrefix}/${orderId}/download-attachment/`, {
        responseType: 'blob'
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const contentDisposition = res.headers['content-disposition'];
        if (contentDisposition?.indexOf('attachment') !== -1) {
          const filenameMatches = contentDisposition.match(
            /(?:.*filename\*|filename)=(?:([^'"]*)''|("))([^;]+)\2(?:[;`\n]|$)/
          );
          const filename = filenameMatches.pop();
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        }
      });
  };

  async updateAndNotify(orderId: number, params: Record<string, unknown>, sendEmail?: boolean) {
    const res = await axiosPrivate.patch(`${this.modulePrefix}/${orderId}/update-order/`, {
      ...params,
      send_email: sendEmail
    });
    return res.data;
  }

  async cancelAndNotify(orderId: number, params: Record<string, unknown>, sendEmail?: boolean) {
    const res = await axiosPrivate.patch(`${this.modulePrefix}/${orderId}/cancel-order/`, {
      ...params,
      send_email: sendEmail
    });
    return res.data;
  }

  async regeneratePDF(orderId: number) {
    const res = await axiosPrivate.post(`${this.modulePrefix}/${orderId}/regenerate-pdf/`);
    return res.data;
  }

  importFromFile = async (importFile: File, attachmentName = 'import_file') => {
    const res = await axiosPrivate.postForm(`${this.modulePrefix}/import-from-file/`, {
      [attachmentName]: importFile
    });
    return res.data;
  };

  importAggregatedLinesFromFile = async (importFile: File, attachmentName = 'import_file') => {
    const res = await axiosPrivate.postForm(
      `${this.modulePrefix}/import-aggregated-lines-from-file/`,
      {
        [attachmentName]: importFile
      }
    );
    return res.data;
  };

  getAggregatedImportTemplate = async () => {
    axiosPrivate
      .get(`${this.modulePrefix}/aggregated_import_template/`, {
        responseType: 'blob'
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const contentDisposition = res.headers['content-disposition'];
        if (contentDisposition?.indexOf('attachment') !== -1) {
          const filenameMatches = contentDisposition.match(
            /(?:.*filename\*|filename)=(?:([^'"]*)''|("))([^;]+)\2(?:[;`\n]|$)/
          );
          const filename = filenameMatches.pop();
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        }
      });
  };
}

export const orderService = new OrderService();
