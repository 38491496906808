import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { filterOperators } from '../../../../constants';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import workstationPhaseCell from '../cells/workstation-status-cell';
import DataGridCell from 'components/common/DataGridCell';

const phaseOptions = ['ANALYTICAL', 'PRE-ANALYTICAL', 'MANAGEMENT'];

export const WorkstationsColumns = (): GridColDef[] => {
  const translationPrefix = 'entities.workstation';

  const { t } = useTranslation();
  return [
    {
      field: 'code',
      headerName: t(`${translationPrefix}.code`),
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        const workstation = params.row;
        return (
          <div>
            <Link to={`${workstation.id}`}>
              <b className="text-secondary-500">{workstation.code}</b>
            </Link>
          </div>
        );
      }
    },
    { field: 'description', width: 250, headerName: t(`${translationPrefix}.description`) },
    {
      field: 'equipments',
      headerName: t(`global.equipments`),
      filterOperators,
      width: 250,
      valueGetter: (params) => params.value && params.value.join(', '),
      renderCell: (params: GridRenderCellParams) => {
        return <DataGridCell content={params.value} />;
      }
    },
    {
      field: 'exams',
      headerName: t(`global.exams`),
      filterOperators,
      width: 250,
      valueGetter: (params) => params.value && params.value.join(', '),
      renderCell: (params: GridRenderCellParams) => {
        return <DataGridCell content={params.value} />;
      }
    },
    {
      field: 'phase',
      type: 'singleSelect',
      valueOptions: phaseOptions,
      headerName: t(`${translationPrefix}.phase`),
      width: 100,
      renderCell: workstationPhaseCell(t)
    },
    { field: 'sector', width: 250, headerName: t(`${translationPrefix}.sector`) }
  ];
};
