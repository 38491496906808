import { macroExamService } from 'services/api';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import MacroExamForm from '../MacroExamForm';
import toast from 'features/toast';
import { IMacroExam } from 'interfaces';

const initMacroExam: IMacroExam = {
  id: null,
  code: null,
  description: null
};

export const CreateMacroExam = () => {
  const translationPrefix = 'pages.macro-exams.new';
  const { t } = useTranslation();
  const navigate = useNavigate();

  const createMacroExam = async (values) => {
    macroExamService.add(values).then((res) => {
      if (res?.id) {
        navigate(`../${res.id}`);
      }
    });
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <MacroExamForm macroExam={initMacroExam} onSubmit={createMacroExam} />
    </TenantPage>
  );
};

export default CreateMacroExam;
