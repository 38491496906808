import { GridColDef } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import TenantPage from 'components/common/TenantPage';
import { expertiseService } from 'services/api/ExpertiseService';
import { RoleColumns } from 'components/common/enhanced/common-headers/roles';

function RoleNameList() {
  const translationPrefix = 'pages.roles.list';
  const { t } = useTranslation();

  const columns: GridColDef[] = RoleColumns();

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <div className="mt-6">
        <EnhancedDataGrid columns={columns} service={expertiseService} />
      </div>
    </TenantPage>
  );
}

export default RoleNameList;
