import { useAppDispatch } from 'app/store';
import { TenantPage } from 'components/common';
import { loadingActions } from 'features';
import { ISAPSettings } from 'interfaces';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sapSettingsService } from 'services/api/SapSettingsService';
import SAPSettingsForm from './SAPSettingsForm';

export const SAPSettings = () => {
  const { t } = useTranslation();
  const translationPrefix = 'pages.sap-settings';

  const [sapSettings, setSapSettings] = useState<ISAPSettings>();

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const updateSettings = async (values) => {
    dispatch(loadingActions.startLoading());
    sapSettingsService.update(1, values).then(() => {
      enqueueSnackbar('Impostazioni salvate con successo', { variant: 'success' });
    });
    dispatch(loadingActions.stopLoading());
  };

  useEffect(() => {
    sapSettingsService.get(1).then(setSapSettings);
  }, []);

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <SAPSettingsForm settings={sapSettings} onSubmit={updateSettings} />
    </TenantPage>
  );
};

export default SAPSettings;
