import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import DataGridCell from 'components/common/DataGridCell';

function listCell(params: GridRenderCellParams) {
  if (params.value) {
    return <DataGridCell content={params.value.join(', ')} />;
  } else return <div></div>;
}

export default listCell;
