import { Paper, Stack } from '@mui/material';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { memo } from 'react';
import { inventoryCheckLineService } from 'services/api';

const InventoryCheckDetail = ({ row: rowProp, columns: columns }: any) => {
  const params = {
    inventory_check: rowProp.id
  };

  return (
    <Stack sx={{ py: 2, height: '100%', boxSizing: 'border-box' }} direction="column">
      <Paper sx={{ flex: 1, mx: 'auto', width: '95%', p: 1 }}>
        <Stack direction="column" spacing={1} sx={{ height: 1 }}>
          <EnhancedDataGrid
            columns={columns}
            service={inventoryCheckLineService}
            outerParams={params}
          />
        </Stack>
      </Paper>
    </Stack>
  );
};

export default memo(InventoryCheckDetail);
