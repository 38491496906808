import { ISector } from 'interfaces';
import { BaseService } from './BaseService';

class SectorService extends BaseService<ISector> {
  constructor() {
    super('sectors');
  }
}

export const sectorService = new SectorService();
