import { useEffect, useState } from 'react';
import { IOption } from 'interfaces';
import { warehouseService } from 'services/api';
import { useAppDispatch, useAppSelector } from 'app/store';
import { Autocomplete, Box, TextField } from '@mui/material';
import { userService } from 'services/api/UserService';
import { authActions } from 'features';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { IsTechnicianOrAbove } from 'components/helpers/IsTechnicianOrAbove';

export const WarehouseSelector = () => {
  const dispatch = useAppDispatch();
  const warehouse = useAppSelector((state) => state.auth.warehouse);
  const [warehouses, setWarehouses] = useState<IOption[]>([]);

  useEffect(() => {
    warehouseService.getAllBaseInformation().then((res) => setWarehouses(res.map((wh) => wh.code)));
  }, []);

  const onChange = (event, value) => {
    userService.selectWarehouse(value).then((res) => dispatch(authActions.selectWarehouse(res)));
  };

  return (
    <IsTechnicianOrAbove>
      <div className="p-2 min-w-[300px] flex items-center">
        <WarehouseIcon color="primary" />
        <Autocomplete
          className="flex-grow-1 w-full ml-3"
          multiple={false}
          disablePortal
          options={warehouses}
          onChange={onChange}
          value={warehouse}
          disabled={!warehouses.length}
          renderOption={(props, option) => {
            return (
              <Box component="li" {...props}>
                {option}
              </Box>
            );
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                fullWidth
                hiddenLabel={true}
                variant="standard"
                size="small"
                margin="none"
              />
            );
          }}
        />
      </div>
    </IsTechnicianOrAbove>
  );
};

export default WarehouseSelector;
