import { GridColDef, GridRenderCellParams, renderEditDateCell } from '@mui/x-data-grid-premium';
import {
  dateFilterOperators,
  filterOperators,
  numberFilterOperators
} from './../../../../constants';
import { useTranslation } from 'react-i18next';
import { baseCell } from '../cells/base-cell';

export function ActivitiesColumns(translationPrefix: string): GridColDef[] {
  const { t } = useTranslation();
  return [
    {
      field: 'name',
      headerName: t(`entities.activity.name`),
      width: 250,
      editable: true,
      filterOperators
    },
    {
      field: 'description',
      headerName: t(`global.description`),
      width: 250,
      editable: true,
      filterOperators
    },
    {
      field: 'expertise',
      headerName: t(`entities.activity.expertise`),
      filterOperators,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'monthly_percentage',
      headerName: t(`entities.activity.monthly_percentage`),
      editable: true,
      filterOperators: numberFilterOperators,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'monthly_hours',
      headerName: t(`entities.activity.monthly_hours`),
      editable: true,
      filterOperators: numberFilterOperators,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'output_number',
      headerName: t(`entities.activity.output_number`),
      editable: true,
      filterOperators: numberFilterOperators,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'content_type',
      width: 180,
      headerName: t(`entities.cost_element.content_type`),
      sortable: true,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          params.value && (
            <div>
              <b className="text-secondary-500">{t(`global.${params.value}`)}</b>
            </div>
          )
        );
      }
    },
    {
      field: 'object_id',
      headerName: t(`entities.cost_element.object_id`),
      filterable: false,
      sortable: false,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'date_start',
      headerName: t(`global.date_start`),
      editable: true,
      filterOperators: dateFilterOperators,
      renderEditCell: renderEditDateCell,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'date_end',
      headerName: t(`global.date_end`),
      editable: true,
      filterOperators: dateFilterOperators,
      renderEditCell: renderEditDateCell,
      width: 150,
      renderCell: baseCell
    }
  ];
}
