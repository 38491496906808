import {
  GridColumns,
  GridRenderCellParams,
  GridValueFormatterParams
} from '@mui/x-data-grid-premium';
import DataGridCell from 'components/common/DataGridCell';
import FileUploader from 'components/common/file-uploader';
import SectionTitle from 'components/common/SectionTitle';
import { currencyFormatter } from 'helpers/currency-formatter';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { invoiceService } from 'services/api';
import {
  booleanFilterOperators,
  filterOperators,
  numberFilterOperators
} from '../../../../constants';
import { baseCell } from '../cells/base-cell';
import { booleanCell } from '../cells/boolean-cell';
import { booleanValueFormatter } from '../data-grid/utils';
import { currencyCell } from '../cells/currency-cell';
import { dateCell } from '../cells/date-cell';

export function InvoiceColumns(onUploadSuccess): GridColumns {
  const { t } = useTranslation();

  const columns: GridColumns = [
    {
      field: 'ref',
      headerName: t(`entities.invoice.ref`),
      width: 200,
      renderCell: (params: GridRenderCellParams<string>) => (
        <NavLink to={`./${params.row?.id}`}>
          <SectionTitle>{params.value}</SectionTitle>
        </NavLink>
      )
    },
    {
      field: 'closed',
      headerName: t(`entities.invoice.closed`),
      width: 100,
      filterOperators: booleanFilterOperators,
      renderCell: booleanCell(t)
    },
    {
      field: 'date',
      headerName: t(`entities.invoice.date`),
      filterOperators,
      width: 150,
      renderCell: dateCell
    },
    // {
    //   field: 'invoice_ref',
    //   headerName: t(`entities.ddt.invoice_ref`),
    //   filterOperators,
    //   width: 150,
    //   renderCell: baseCell
    // },
    {
      field: 'credit_memo_amount',
      headerName: t(`entities.invoice.credit_memo_amount`),
      filterOperators: numberFilterOperators,
      width: 150,
      valueFormatter: (params: GridValueFormatterParams) => {
        return currencyFormatter(params.value);
      },
      renderCell: currencyCell
    },
    // {
    //   field: 'orders',
    //   headerName: t(`entities.ddt.orders`),
    //   filterOperators,
    //   filterable: false,
    //   sortable: false,
    //   width: 150,
    //   valueFormatter: ({ value }) => {
    //     return value.map((order, i) => order.ref).join(', ');
    //   },
    //   renderCell: (params: GridRenderCellParams<IPurchaseOrder[]>) =>
    //     params.value.map((order, i) => (
    //       <NavLink to={`/tenant/orders/delivery?ref=${order.id}`} key={i}>
    //         <SectionTitle>{order.ref}</SectionTitle>
    //       </NavLink>
    //     ))
    // },
    {
      field: 'supplier',
      headerName: t(`global.supplier`),
      filterOperators,
      width: 200,
      renderCell: baseCell
    },
    {
      field: 'notes',
      headerName: t(`global.notes`),
      width: 100,
      filterOperators,
      renderCell: baseCell
    }
  ];

  ['amount', 'expected_amount', 'shipping_fare'].forEach((colName) => {
    columns.push({
      field: colName,
      headerName: t(`entities.ddt.${colName}`),
      filterOperators,
      type: 'number',
      width: 150,
      valueFormatter: (params: GridValueFormatterParams) => {
        return currencyFormatter(params.value);
      },
      renderCell: (params: GridRenderCellParams<string>) => (
        <DataGridCell content={currencyFormatter(params.value)} />
      )
    });
  });

  ['invoice_file', 'invoice_revision_file'].forEach((file) => {
    columns.push({
      field: file,
      headerName: t(`entities.ddt.${file}`),
      filterOperators,
      filterable: false,
      sortable: false,
      width: 150,
      valueFormatter: booleanValueFormatter,
      renderCell: (params: GridRenderCellParams<string>) => (
        <FileUploader
          onUploadSuccess={onUploadSuccess}
          service={invoiceService}
          entityId={params.row?.id}
          attachmentName={file}
          filename={params.value}
        />
      )
    });
  });

  return columns;
}
