import { examService } from 'services/api';
import { IExam } from 'interfaces';
import { loadingActions } from 'features';
import { useAppDispatch } from 'app/store';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import ExamForm from '../ExamForm';

const initExam: IExam = {
  id: null,
  code: null,
  description: null,
  workstations: [],
  equipment: [],
  products: [],
  regional_code: null
};

export const CreateExam = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const translationPrefix = 'pages.exams.new';

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const createProduct = async (values) => {
    dispatch(loadingActions.startLoading());
    examService.add(values).then(() => {
      enqueueSnackbar('Esame aggiunto con successo', { variant: 'success' });
      navigate('..');
    });
    dispatch(loadingActions.stopLoading());
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <ExamForm exam={initExam} onSubmit={createProduct} />
    </TenantPage>
  );
};

export default CreateExam;
