import { Button, ButtonGroup } from '@mui/material';
import { examService } from 'services/api';
import { GridColDef } from '@mui/x-data-grid-premium';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import BloodtypeIcon from '@mui/icons-material/Bloodtype';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import TenantPage from 'components/common/TenantPage';
import { ExamsColumns } from 'components/common/enhanced/common-headers';
import { IsSuperAdmin } from 'components/helpers/IsSuperAdmin';
import { PurgeButton } from 'components/helpers/PurgeButton';

export const Exams = () => {
  const translationPrefix = 'pages.exams.list';
  const { t } = useTranslation();

  const columns: GridColDef[] = ExamsColumns();

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <div className="flex justify-end items-center">
            <ButtonGroup>
              <IsSuperAdmin>
                <Link to="/tenant/cost-analysis/macro-exams">
                  <Button variant="outlined" size="small">
                    <BloodtypeIcon />
                    {t(`menu.macro-exams`)}
                  </Button>
                </Link>
              </IsSuperAdmin>
              <IsTenantAdministrator>
                <Link to="new">
                  <Button variant="outlined" size="small">
                    <AddIcon />
                    {t(`${translationPrefix}.add`)}
                  </Button>
                </Link>
              </IsTenantAdministrator>
            </ButtonGroup>
            <PurgeButton service={examService} />
          </div>
        }>
        <div className="mt-6">
          <EnhancedDataGrid columns={columns} service={examService} />
        </div>
      </TenantPage>
    </>
  );
};

export default Exams;
