import { Button, ButtonGroup } from '@mui/material';
import { productService, typeService } from 'services/api';
import { GridColDef } from '@mui/x-data-grid-premium';
import { IOption } from 'interfaces';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import { loadingActions } from 'features';
import { useAppDispatch, useAppSelector } from 'app/store';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import SearchBar from 'components/form/SearchBar';
import TenantPage from 'components/common/TenantPage';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ProductsColumns } from 'components/common/enhanced/common-headers/products';
import { PurgeButton } from 'components/helpers/PurgeButton';
import { PurgeLinksButton } from 'components/helpers/PurgeLinksButton';
import { NotOperatorRoles } from '../../../../constants';

export const Products = () => {
  const translationPrefix = 'pages.product.list';

  const [types, setTypes] = useState<IOption[]>([]);

  const sap_active = useAppSelector((state) => state.auth.sap_active);
  const [queryText, setQueryText] = useState<string>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const groups = useAppSelector((state) => state.auth.groups);

  const datagridRefresh = useRef(null);

  useEffect(() => {
    dispatch(loadingActions.startLoading());
    typeService.getAllBaseInformation().then((res) => {
      setTypes(res);
    });

    dispatch(loadingActions.stopLoading());
  }, []);

  const getContractLines = (params) => {
    return params.row.contract_lines;
  };

  const columns: GridColDef[] = ProductsColumns(
    getContractLines,
    types.map((type) => type.code),
    datagridRefresh.current,
    sap_active,
    NotOperatorRoles.some((role) => groups.includes(role))
  );

  const onSubmitSearch = async (values) => {
    setQueryText(values['q']);
  };

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <div className="flex justify-end items-center">
            <div className="mr-2">
              <SearchBar onSubmit={onSubmitSearch} />
            </div>
            <div>
              <ButtonGroup>
                <IsTenantAdministrator>
                  <Button size="small" onClick={() => navigate('new')}>
                    <AddIcon />
                    {t(`${translationPrefix}.add`)}
                  </Button>
                </IsTenantAdministrator>
              </ButtonGroup>
              <PurgeButton service={productService} refresh={datagridRefresh} />
              <PurgeLinksButton service={productService} refresh={datagridRefresh} />
            </div>
          </div>
        }>
        <EnhancedDataGrid
          refresh={datagridRefresh}
          service={productService}
          columns={columns}
          initialParams={{ code: searchParams.get('code') } ?? null}
          fullTextQuery={queryText}
        />
      </TenantPage>
    </>
  );
};

export default Products;
