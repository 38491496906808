import { classNames } from 'helpers/classNames';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from 'app/store';
import { Tooltip } from '@mui/material';

export const Menu = (navigation: any[], collapsed?: boolean) => {
  const { t } = useTranslation();
  const groups = useAppSelector((state) => state.auth.groups);
  const context = useAppSelector((state) => state.contextData.context);

  return (
    <nav className="w-full px-2 pt-2 space-y-1">
      {navigation
        .filter(
          (item) => !item.roles || item.roles?.length === 0 || item.roles?.includes(groups[0])
        )
        .filter((item) => !item.contexts || item.contexts.includes(context))
        .map((item, index) =>
          item.spacer ? (
            <div className="h-4" key={`spacer-${index}`}></div>
          ) : (
            <div key={item.name}>
              <NavLink
                to={item.href}
                end={item.end}
                className={({ isActive }) =>
                  classNames(
                    isActive
                      ? 'bg-secondary-800 text-white'
                      : 'text-neutral-100 hover:bg-secondary-800 hover:text-neutral-100',
                    'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                  )
                }>
                <div className="flex">
                  <Tooltip
                    title={item?.translate === false ? item.name : t(`menu.${item.name}`)}
                    placement="right">
                    <item.icon
                      className={({ collapsed }) =>
                        (!collapsed ? classNames('mr-4') : '') +
                        'mr-4 flex-shrink-0 h-6 w-6 text-neutral-100'
                      }
                      aria-hidden="true"
                    />
                  </Tooltip>
                  {!collapsed && (
                    <span className="mx-2">
                      {item?.translate === false ? item.name : t(`menu.${item.name}`)}
                    </span>
                  )}
                </div>
              </NavLink>
            </div>
          )
        )}
    </nav>
  );
};

export default Menu;
