import { requestDepartmentLaboratoryService } from 'services/api';
import TenantPage from 'components/common/TenantPage';
import { useTranslation } from 'react-i18next';
import RequestDepartmentTranscodeForm from '../RequestDepartmentTranscodeForm';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { IRequestDepartmentTranscode } from 'interfaces';
import toast from 'features/toast';

export function EditRequestDepartmentTranscode() {
  const translationPrefix = 'pages.request-dep-lab.new';
  const { typeId } = useParams();
  const [transcode, setTranscode] = useState<IRequestDepartmentTranscode>();

  const { t } = useTranslation();

  useEffect(() => {
    requestDepartmentLaboratoryService.get(+typeId).then(setTranscode);
  }, []);

  const updateType = async (values) => {
    requestDepartmentLaboratoryService.update(transcode.id, values).then((res) => {
      toast.success('Tipo aggiunto con successo');
    });
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      entityCode={transcode?.department}>
      <RequestDepartmentTranscodeForm transcode={transcode} onSubmit={updateType} />
    </TenantPage>
  );
}

export default EditRequestDepartmentTranscode;
