import { IContractEquipment } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class ContractEquipmentService extends BaseService<IContractEquipment> {
  constructor() {
    super('contract-equipments');
  }
  canDelete = true;

  importFromFileDetail = async (
    importFile: File,
    contractId: number,
    attachmentName = 'import_file'
  ) => {
    const res = await axiosPrivate.postForm(
      `contracts/${contractId}/import-equipments-from-file/`,
      {
        [attachmentName]: importFile
      }
    );
    return res.data;
  };

  aggregatedContract = async (limit = 0, offset = 0, params: Record<string, unknown> = {}) => {
    const res = await axiosPrivate.get(`${this.modulePrefix}/aggregated-contract/`, {
      params: { limit, offset, ...params }
    });
    return res.data;
  };

  addMaintenanceDates = async (contractEquipmentId: number, dates: any[]) => {
    const res = await axiosPrivate.post(
      `${this.modulePrefix}/${contractEquipmentId}/add-maintenance-dates/`,
      {
        preemptive_maintenance_dates: dates
      }
    );
    return res.data;
  };

  // attachFiles = async (attachment: File, attachmentName: string) => {
  //   const res = await axiosPrivate.postForm(`${this.modulePrefix}/attach-files/`, {
  //     [attachmentName]: attachment
  //   });
  //   return res.data;
  // };
  //
  // downloadAttachment = async (_: number, attachmentName: string) => {
  //   axiosPrivate
  //     .get(`${this.modulePrefix}/download-attachment/${attachmentName}/`, {
  //       responseType: 'blob'
  //     })
  //     .then((res) => {
  //       const url = window.URL.createObjectURL(new Blob([res.data]));
  //       const contentDisposition = res.headers['content-disposition'];
  //       if (contentDisposition?.indexOf('attachment') !== -1) {
  //         const filenameMatches = contentDisposition.match(
  //           /(?:.*filename\*|filename)=(?:([^'"]*)''|("))([^;]+)\2(?:[;`\n]|$)/
  //         );
  //         const filename = filenameMatches.pop();
  //         const link = document.createElement('a');
  //         link.href = url;
  //         link.setAttribute('download', filename);
  //         document.body.appendChild(link);
  //         link.click();
  //       }
  //     });
  // };
}

export const contractEquipmentService = new ContractEquipmentService();
