import { useSnackbar } from 'notistack';
import { loadingActions } from 'features';
import { movementReasonService, supplierService } from 'services/api';
import { useAppDispatch } from 'app/store';
import TenantPage from 'components/common/TenantPage';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IMovementReason } from 'interfaces';
import { useEffect, useState } from 'react';
import MovementReasonsForm from '../MovementReasonsForm';
import { Card, CardContent } from '@mui/material';
import EntityHistory from 'components/widgets/EntityHistory';

export const EditMovementReason = () => {
  const translationPrefix = 'pages.reason.edit';
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { movementReasonId } = useParams();
  const [movementReason, setMovementReason] = useState<IMovementReason>();

  useEffect(() => {
    movementReasonService.get(+movementReasonId).then(setMovementReason);
  }, []);

  const updateMovementReason = async (values) => {
    dispatch(loadingActions.startLoading());
    movementReasonService.update(movementReason.id, values).then((res) => {
      enqueueSnackbar('Causale aggiornata con successo');
      setMovementReason(res);
    });
    dispatch(loadingActions.stopLoading());
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <div className="flex flex-wrap">
        <div className="w-full p-4">
          <MovementReasonsForm movementReason={movementReason} onSubmit={updateMovementReason} />
        </div>
        <div className="w-full md:w-1/2 p-4">
          {movementReason && (
            <Card variant="outlined">
              <CardContent>
                <EntityHistory service={movementReasonService} entityId={movementReason.id} />
              </CardContent>
            </Card>
          )}
        </div>
      </div>
    </TenantPage>
  );
};

export default EditMovementReason;
