import { IRoleName } from 'interfaces';
import { BaseService } from './BaseService';

class RoleNameService extends BaseService<IRoleName> {
  constructor() {
    super('staff-management/role-names');
  }
  canDelete = true;
}

export const roleNameService = new RoleNameService();
