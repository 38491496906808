import { movementReasonService, movementService, tenantSettingsService } from 'services/api';
import { GridColDef, GridSelectionModel } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { ConsumptionsColumns } from 'components/common/enhanced/common-headers/consumptions';
import { useEffect, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import * as React from 'react';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import Box from '@mui/material/Box';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { Button, ButtonGroup } from '@mui/material';
import { productWarehouseService } from 'services/api/ProductWarehouseService';
import toast from 'features/toast';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import { IdentityField } from 'components/form/IdentityField';
import { MultipleAutocompleteAdapter } from 'components/form/MultipleAutocompleteAdapter';
import { IOption } from 'interfaces';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { IsSuperAdmin } from 'components/helpers/IsSuperAdmin';

interface DateRangeQueryParams {
  timestamp__gte: string;
  timestamp__lte: string;
}

export function ConsumptionDetail() {
  const { t } = useTranslation();
  const datagridRefresh = useRef(null);
  const rowsRef = useRef(null);

  const year = dayjs().year();
  const month = dayjs().month();
  const day = dayjs().subtract(1, 'month').daysInMonth();

  const [range, setRange] = useState<DateRange<Dayjs>>([
    dayjs(`${year - 1}-${month + 1}-01`),
    dayjs(`${year}-${month}-${day}`)
  ]);

  const [params, setParams] = useState<DateRangeQueryParams>({
    timestamp__gte: range[0]?.format('YYYY-MM-DD'),
    timestamp__lte: range[1]?.format('YYYY-MM-DD')
  });

  const [productWarehouses, setProductWarehouses] = useState<any[]>([]);
  const [movementReasons, setMovementReasons] = useState<IOption[]>([]);
  const [tenantSettings, setTenantSettings] = useState<any>(null);

  const onChange = (date: DateRange<Dayjs>, keyboardInputValue?: string) => {
    setRange([date[0], date[1]]);
  };

  useEffect(() => {
    tenantSettingsService.get(1).then(setTenantSettings);
    movementReasonService.getAllPaginated().then((res) => {
      setMovementReasons(res.results);
    });
  }, []);

  useEffect(() => {
    setParams({
      timestamp__gte: range[0]?.format('YYYY-MM-DD'),
      timestamp__lte: range[1]?.format('YYYY-MM-DD')
    });
  }, [range]);

  const columns: GridColDef[] = ConsumptionsColumns(range[0], range[1]);

  const onSelectionModelChange = (selectionModel: GridSelectionModel) => {
    const selectedRows = rowsRef.current.filter((row) => selectionModel.includes(row.id));
    setProductWarehouses(selectedRows);
  };

  const onSubmit = (values) => {
    tenantSettingsService.update(1, values).then(() => {
      toast.success('Causali aggiornate con successo.');
    });
  };

  const updateThresholds = () => {
    const newThresholds = productWarehouses.map((row) => {
      return {
        product: row.product,
        warehouse: row.warehouse,
        understock_threshold: Math.round(Math.abs(row.avg)) || 1,
        restock_threshold: Math.round(Math.abs(row.avg) / 2 + Math.abs(row.avg)) || 1,
        overstock_threshold: Math.round(Math.abs(row.avg) * 3) || 1
      };
    });
    productWarehouseService
      .updateThresholds(newThresholds)
      .then(() => toast.success(`Soglie aggiornate con successo`));
  };

  return (
    <TenantPage
      title={t('pages.consumption.title')}
      subtitle={t('pages.consumption.subtitle')}
      menuRight={
        <div className="flex items-center">
          <div className="mr-5">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker
                inputFormat="DD/MM/YYYY"
                calendars={2}
                value={range}
                onChange={onChange}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <TextField {...startProps} variant="standard" label={t('global.start')} />

                    <Box sx={{ mx: 2 }}>
                      <ArrowForwardIosIcon color={range[1] ? 'primary' : 'disabled'} />
                    </Box>

                    <TextField {...endProps} variant="standard" label={t('global.end')} />
                  </React.Fragment>
                )}
              />
            </LocalizationProvider>
          </div>
          <IsTenantAdministrator>
            <ButtonGroup size="small">
              <Button onClick={updateThresholds} disabled={productWarehouses.length === 0}>
                <AutoFixHighIcon />
                &nbsp;
                {t(`actions.update-thresholds`)}
              </Button>
            </ButtonGroup>
          </IsTenantAdministrator>
        </div>
      }>
      {tenantSettings && (
        <ReactFinalForm onSubmit={onSubmit}>
          <div className="flex flex-wrap">
            <IsSuperAdmin>
              <div className="p-2 w-full">
                <IdentityField
                  name="consumption_analysis_reasons"
                  component={MultipleAutocompleteAdapter}
                  options={movementReasons}
                  initValue={tenantSettings.consumption_analysis_reasons}
                  label={t('entities.tenant-settings.consumption-analysis-reasons')}
                  type="text"
                />
              </div>
            </IsSuperAdmin>
          </div>
        </ReactFinalForm>
      )}
      <div>
        <EnhancedDataGrid
          initialOptions={{
            pinnedColumns: {
              left: ['product', 'actions']
            }
          }}
          outerParams={params}
          service={movementService}
          refresh={datagridRefresh}
          getMethod={movementService.consumptionDetail}
          columns={columns}
          rows={rowsRef}
          checkboxSelection={true}
          selectionModelChange={onSelectionModelChange}
        />
      </div>
    </TenantPage>
  );
}

export default ConsumptionDetail;
