import { useRef, useState } from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { pastStockQuantityService } from 'services/api';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { filterOperators } from '../../../../constants';
import { baseCell } from 'components/common/enhanced/cells/base-cell';
import { currencyFormatter } from 'helpers/currency-formatter';
import { IsTenantCoordinatorOrAdmin } from 'components/helpers/IsTenantCoordinator';
import DataGridCell from 'components/common/DataGridCell';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs from 'dayjs';

export const StockQuantitiesAtDate = () => {
  const translationPrefix = 'pages.stock_quantity.list';

  const { t } = useTranslation();
  const datagridRefresh = useRef(null);
  const [date, setDate] = useState(dayjs().subtract(1, 'day'));

  const aggregatedColumns: GridColumns = [
    {
      field: 'product',
      headerName: t(`global.product`),
      width: 250,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'description',
      headerName: t(`global.description`),
      width: 300,
      filterOperators,
      renderCell: baseCell
    },
    // {
    //   field: 'status',
    //   valueGetter: (params) => params.row.product__status,
    //   headerName: t(`global.status`),
    //   filterable: false,
    //   width: 250,
    //   renderCell: productStatusCell(t)
    // },
    {
      field: 'notes',
      headerName: t(`global.notes`),
      width: 250,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'unit_price',
      type: 'string',
      headerName: t(`entities.movement.unit_price`),
      filterable: false,
      sortable: false,
      width: 100,
      valueFormatter: ({ value }) => {
        try {
          return value.map(currencyFormatter).join(',');
        } catch {
          return '';
        }
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <IsTenantCoordinatorOrAdmin>
            <DataGridCell content={params.value.map(currencyFormatter).join(',')} />;
          </IsTenantCoordinatorOrAdmin>
        );
      }
    },
    // {
    //   field: 'total_price',
    //   type: 'string',
    //   headerName: t(`global.total_price`),
    //   filterable: false,
    //   sortable: false,
    //   width: 100,
    //   valueFormatter: ({ value }) => {
    //     try {
    //       return value.map(currencyFormatter).join(',');
    //     } catch {
    //       return '';
    //     }
    //   },
    //   renderCell: (params: GridRenderCellParams) => {
    //     return (
    //       <IsTenantCoordinatorOrAdmin>
    //         <DataGridCell content={params.value.map(currencyFormatter).join(',')} />;
    //       </IsTenantCoordinatorOrAdmin>
    //     );
    //   }
    // },
    {
      field: 'category',
      headerName: t(`global.category`),
      width: 100,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'warehouse',
      headerName: t(`global.warehouse`),
      width: 250,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'supplier',
      headerName: t(`global.supplier`),
      width: 250,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'quantity',
      headerName: t(`entities.stock_quantity.total_quantity`),
      width: 150,
      type: 'number',
      filterable: false,
      sortable: false,
      renderCell: baseCell
    }
  ];

  const onChange = (date: dayjs.Dayjs) => {
    if (date.isValid()) setDate(date);
  };

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <div className="flex items-center">
            <div className="mr-5">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  label={t('global.select-date')}
                  value={date}
                  onChange={onChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </div>
          </div>
        }>
        <EnhancedDataGrid
          columns={aggregatedColumns}
          service={pastStockQuantityService}
          outerParams={{ date: date.format('YYYY-MM-DD') }}
          refresh={datagridRefresh}
        />
      </TenantPage>
    </>
  );
};

export default StockQuantitiesAtDate;
