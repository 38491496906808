import { Paper, Stack } from '@mui/material';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { memo } from 'react';
import { unapprovedMovementService } from 'services/api';

const DetailPanelContent = ({
  row: rowProp,
  columns: columns,
  uuid: uuid,
  setSelectedMovements: setSelectedMovements
}: any) => {
  const params = rowProp;

  return (
    <Stack sx={{ py: 2, height: '100%', boxSizing: 'border-box' }} direction="column">
      <Paper sx={{ flex: 1, mx: 'auto', width: '95%', p: 1 }}>
        <Stack direction="column" spacing={1} sx={{ height: 1 }}>
          <EnhancedDataGrid
            columns={columns}
            service={unapprovedMovementService}
            outerParams={params}
            checkboxSelection={true}
            selectionModelChange={(newRowSelectionModel) => {
              setSelectedMovements(uuid, newRowSelectionModel);
            }}
          />
        </Stack>
      </Paper>
    </Stack>
  );
};

export default memo(DetailPanelContent);
