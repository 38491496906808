import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { productInitialState } from '../constants';
import { IInventoryState } from '../interfaces';
import { axiosPrivate } from '../services/axios';

const modulePrefix = 'products';

const initialState: IInventoryState = {
  entity: productInitialState,
  entities: [],
  limit: 25,
  offset: 0,
  count: 0,
  code_filter: '',
  description_filter: '',
  status_options: [],
  error: '',
  status: 'idle'
};

export const getProduct = createAsyncThunk(`${modulePrefix}/get`, async (id: number) => {
  const res = await axiosPrivate.get(`products/${id}`);
  return res.data;
});

export const productSlice = createSlice({
  name: modulePrefix,
  initialState,
  reducers: {
    setLimit: (state, { payload }) => {
      state.limit = payload;
    },
    setOffset: (state, { payload }) => {
      state.offset = payload;
    },
    setCodeFilter: (state, { payload }) => {
      state.code_filter = payload;
    },
    setDescriptionFilter: (state, { payload }) => {
      state.description_filter = payload;
    },
    resetEntity: (state) => {
      state.entity = productInitialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProduct.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.entity = payload;
      })
      .addCase(getProduct.pending, (state) => {
        state.status = 'pending';
      });
  }
});

export const productActions = productSlice.actions;
