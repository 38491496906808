import { Alert, Button, Skeleton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { IMaintenance } from 'interfaces';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { maintenanceService } from 'services/api';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

function ExpiringMaintenances() {
  const { t } = useTranslation();
  const [maintenances, setMaintenances] = useState<IMaintenance[]>();
  useEffect(() => {
    maintenanceService
      .getAllPaginated(3, 0, {
        expected_date__lte: dayjs().add(7, 'days').format('YYYY-MM-DD'),
        ordering: 'expected_date'
      })
      .then((res) => setMaintenances(res.results));
  }, []);

  return (
    <div>
      <Typography sx={{ fontSize: 14 }} color="secondary" gutterBottom>
        <ManageAccountsIcon />
        {t('dashboard.expiration-maintenances.title')}
      </Typography>
      {!maintenances && (
        <>
          <Skeleton variant="rectangular" height={32} />
          <br />
          <Skeleton variant="rectangular" height={32} />
          <br />
          <Skeleton variant="rectangular" height={32} />
        </>
      )}
      {maintenances &&
        maintenances.map((maintenance) => (
          <Alert
            severity={dayjs().isAfter(maintenance.expected_date) ? 'error' : 'warning'}
            key={maintenance.id}
            className="mb-2"
            action={
              <NavLink
                to={`/tenant/equipments/maintenance?equipment_id=${maintenance.equipment_id}`}>
                <Button color="inherit" size="small">
                  {t('dashboard.expiration-maintenances.go')}
                </Button>
              </NavLink>
            }>
            {t('dashboard.expiration-maintenances.row-body', {
              code: maintenance.equipment,
              serial_number: maintenance.serial_number,
              date: maintenance.expected_date
            })}
          </Alert>
        ))}
    </div>
  );
}

export default ExpiringMaintenances;
