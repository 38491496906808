import { IProduct } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { ExportableResourceService } from './ExportableResourceService';

class ProductService extends ExportableResourceService<IProduct> {
  constructor() {
    super('products');
  }
  canDelete = true;
  canBulkDelete = true;

  importFromFile = async (importFile: File, attachmentName = 'import_file') => {
    const res = await axiosPrivate.postForm(`${this.modulePrefix}/import-from-file/`, {
      [attachmentName]: importFile
    });
    return res.data;
  };

  addCostLink = async (productId: number, values: Record<string, unknown>) => {
    const res = await axiosPrivate.post(`${this.modulePrefix}/${productId}/add-cost-link/`, values);
    return res.data;
  };

  removeCostLink = async (productId: number, values: Record<string, unknown>) => {
    const res = await axiosPrivate.post(
      `${this.modulePrefix}/${productId}/remove-cost-link/`,
      values
    );
    return res.data;
  };

  purgeLinks = async (productId: number) => {
    const res = await axiosPrivate.post(`${this.modulePrefix}/${productId}/purge-links/`);
    return res.data;
  };

  purgeAllLinks = async () => {
    const res = await axiosPrivate.post(`${this.modulePrefix}/purge-all-links/`);
    return res.data;
  };

  removeExam = async (productId: number, examId: number) => {
    const res = await axiosPrivate.post(`${this.modulePrefix}/${productId}/remove-exam/`, {
      exam: examId
    });
    return res.data;
  };

  exportSummary = async () => {
    axiosPrivate
      .get(`${this.modulePrefix}/export_summary/`, {
        responseType: 'blob'
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const contentDisposition = res.headers['content-disposition'];
        if (contentDisposition?.indexOf('attachment') !== -1) {
          const filenameMatches = contentDisposition.match(
            /(?:.*filename\*|filename)=(?:([^'"]*)''|("))([^;]+)\2(?:[;`\n]|$)/
          );
          const filename = filenameMatches.pop();
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        }
      });
  };
}

export const productService = new ProductService();
