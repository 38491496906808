import { Fragment, useEffect, useState } from 'react';
import { INotification } from 'interfaces';
import { loadingActions } from 'features';
import { notificationService } from 'services/api';
import { Pagination } from '@mui/material';
import { useAppDispatch } from 'app/store';
import { useTranslation } from 'react-i18next';
import CollapsableColumns from 'components/common/CollapsableColumns';
import dayjs from 'dayjs';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TenantPage from 'components/common/TenantPage';
import Typography from '@mui/material/Typography';
import { useSearchParams } from 'react-router-dom';

export const NotificationList = () => {
  const { t } = useTranslation();
  const prefix = 'entities.inventory.notifications';

  const dispatch = useAppDispatch();

  const [selectedNotification, setSelectedNotification] = useState<INotification>(null);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  // aggiunto boolean per controllare la visibilità del componente che lampeggia per vedere
  const [searchParams, setSearchParams] = useSearchParams();

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    dispatch(loadingActions.startLoading());
    notificationService.getAllPaginated(limit, offset).then((res) => {
      setNotifications(res.results);
      setCount(res.count);
    });
    dispatch(loadingActions.stopLoading());
  }, [limit, offset]);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setOffset((newPage - 1) * limit);
  };

  const onSelectNotification = (value: INotification, idx: number) => {
    setSelectedNotification(value);
    if (!value.read) {
      notificationService.update(value.id, { read: true }).then((res) => {
        setNotifications(
          notifications.map((x) => {
            if (x.id == value.id) return { ...x, read: true };
            return x;
          })
        );
      });
    }
  };

  return (
    <TenantPage title={t(`${prefix}.profile`)} menuRight={<></>}>
      <CollapsableColumns
        size="w-1/2"
        expand={!!selectedNotification}
        onClose={() => setSelectedNotification(null)}
        contentLeft={
          <div>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {notifications.map((n, idx) => (
                <div key={n.id}>
                  <ListItem
                    alignItems="flex-start"
                    className="relative cursor-pointer"
                    onClick={() => {
                      onSelectNotification(n, idx);
                    }}>
                    {!n.read && (
                      <span className="flex h-3 w-3 absolute right-0">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-400 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-primary-500"></span>
                      </span>
                    )}
                    <ListItemText
                      primary={n.notification.message}
                      secondary={
                        <Fragment>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"></Typography>
                          {dayjs(n.notification.timestamp).format('hh:mm DD/MM/YYYY')}
                        </Fragment>
                      }
                    />
                  </ListItem>
                  <Divider variant="fullWidth" component="li" />
                </div>
              ))}
              <div className="flex justify-center mt-4">
                <Pagination
                  count={Math.ceil(count / limit)}
                  page={Math.ceil(offset / limit) + 1}
                  onChange={handleChangePage}
                />
              </div>
            </List>
          </div>
        }
        contentRight={
          <div className="flex flex-col items-start px-4">
            <span className="mb-4 text-xs font-bold">
              {selectedNotification &&
                dayjs(selectedNotification.notification.timestamp).format('DD/MM/YYYY HH:mm')}
            </span>
            <span>{selectedNotification && selectedNotification.notification.message}</span>
          </div>
        }
      />
    </TenantPage>
  );
};
