import { Checkbox, FormControlLabel } from '@mui/material';
import SectionTitle from 'components/common/SectionTitle';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { CheckboxAdapter } from 'components/form/CheckboxAdapter';
import { DatePickerAdapter } from 'components/form/DatePickerAdapter';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { required } from 'helpers/validators';
import { ICostElement, IOption, IPeriodicCostElemet } from 'interfaces';
import { useEffect, useState } from 'react';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import {
  costElementService,
  equipmentService,
  examService,
  laboratoryService,
  macroExamService,
  sectorService,
  workstationService
} from 'services/api';
import { operationalUnitService } from 'services/api/OperationalUnitService';

const timeUnits = [
  { id: 1, code: 'Days' },
  { id: 2, code: 'Weeks' },
  { id: 3, code: 'Months' },
  { id: 4, code: 'Years' }
];

interface CostElementFormProps {
  onSubmit: any;
  costElement: ICostElement | IPeriodicCostElemet;
  periodical?: boolean;
}
function CostElementForm(props: CostElementFormProps) {
  const { t } = useTranslation();
  const { onSubmit, costElement, periodical } = props;

  const [layerTypes, setLayerTypes] = useState<any>([]);
  const [isPeriodical, setIsPeriodical] = useState<boolean>(!!periodical);
  const [layerType, setLayerType] = useState<IOption>({ id: null, code: null });
  const [layers, setLayers] = useState<IOption[]>([]);

  useEffect(() => {
    costElementService.getLayerOptions().then(setLayerTypes);
  }, []);

  useEffect(() => {
    switch (layerType['model']) {
      case 'laboratory':
        laboratoryService.getAllBaseInformation().then((res) => {
          setLayers(
            res.map((item) => {
              return {
                id: item.id,
                code: item.description
              };
            })
          );
        });
        break;
      case 'operationalunit':
        operationalUnitService.getAllBaseInformation().then((res) => {
          setLayers(
            res.map((item) => {
              return {
                id: item.id,
                code: item.description
              };
            })
          );
        });
        break;
      case 'sector':
        sectorService.getAllBaseInformation().then((res) => {
          setLayers(
            res.map((item) => {
              return {
                id: item.id,
                code: item.description
              };
            })
          );
        });
        break;
      case 'workstation':
        workstationService.getAllBaseInformation().then((res) => {
          setLayers(
            res.map((item) => {
              return {
                id: item.id,
                code: item.description
              };
            })
          );
        });
        break;
      case 'equipment':
        equipmentService.getAllBaseInformation().then((res) => {
          setLayers(
            res.map((item) => {
              return {
                id: item.id,
                code: item.description
              };
            })
          );
        });
        break;
      case 'exam':
        examService.getAllBaseInformation().then((res) => {
          setLayers(
            res.map((item) => {
              return {
                id: item.id,
                code: item.description
              };
            })
          );
        });
        break;
      case 'macroexam':
        macroExamService.getAllBaseInformation().then((res) => {
          setLayers(
            res.map((item) => {
              return {
                id: item.id,
                code: item.description
              };
            })
          );
        });
        break;
    }
  }, [layerType]);

  return (
    <div>
      {costElement && (
        <ReactFinalForm
          onSubmit={(values) => onSubmit(values, isPeriodical)}
          costElement
          edit={!!costElement.id}>
          <div className="flex flex-wrap">
            <div className="w-1/4 pr-4 my-2">
              <IdentityField
                name="code"
                defaultValue={costElement.code}
                component={TextFieldAdapter}
                label={t('global.code')}
                type="text"
              />
            </div>
            <div className="w-1/2 pr-4 my-2">
              <IdentityField
                name="description"
                defaultValue={costElement.description}
                component={TextFieldAdapter}
                label={t('global.description')}
                type="text"
              />
            </div>
            <div className="w-1/8 my-2">
              <FormControlLabel
                label={t('entities.cost_element.periodical')}
                control={
                  <Checkbox onClick={() => setIsPeriodical(!isPeriodical)} checked={isPeriodical} />
                }
              />
            </div>
            <div className="w-1/8 my-2">
              <IdentityField
                name="active"
                initialValue={costElement.active}
                defaultValue={costElement.active}
                component={CheckboxAdapter}
                label={t('global.active')}
                type="checkbox"
              />
            </div>
            <div className="w-1/4 my-2 pr-4">
              <IdentityField
                name="cost"
                defaultValue={+costElement.cost}
                component={TextFieldAdapter}
                label={t('entities.cost_element.cost')}
                type="number"
              />
            </div>
            <div className="w-1/2 pr-4 my-2">
              <IdentityField
                name="content_type"
                defaultValue={costElement.content_type}
                initValue={costElement.content_type}
                component={AutocompleteAdapter}
                options={layerTypes}
                optionLabel="model"
                label={t('entities.cost_element.content_type')}
                type="number"
                setValue={setLayerType}
              />
            </div>
            <div className="w-1/4 mb-4 my-2">
              <IdentityField
                name="object_id"
                defaultValue={costElement.object_id}
                initValue={costElement.object_id}
                component={AutocompleteAdapter}
                options={layers}
                label={t('entities.cost_element.object_id')}
                type="number"
              />
            </div>
            {!isPeriodical && (
              <div className="my-4 w-full">
                <SectionTitle>{t('entities.cost_element.single')}</SectionTitle>
                <div className="my-4 w-full flex flex-wrap">
                  <div className="w-1/4 pr-4">
                    <IdentityField
                      name="date"
                      defaultValue={costElement['date']}
                      component={DatePickerAdapter}
                      label={t('entities.cost_element.date')}
                      type="date"
                      validate={required}
                    />
                  </div>
                </div>
              </div>
            )}
            {isPeriodical && (
              <div className="my-4 w-full">
                <SectionTitle>{t('entities.cost_element.periodical')}</SectionTitle>
                <div className="my-4 w-full flex flex-wrap">
                  <div className="w-1/4 pr-4">
                    <IdentityField
                      name="time_unit"
                      defaultValue={costElement['time_unit']}
                      component={AutocompleteAdapter}
                      options={timeUnits}
                      label={t('entities.cost_element.time_unit')}
                      type="text"
                    />
                  </div>
                  <div className="w-1/4 pr-4">
                    <IdentityField
                      name="frequency_units"
                      defaultValue={costElement['frequency_units']}
                      component={TextFieldAdapter}
                      label="Frequency"
                      type="number"
                    />
                  </div>
                  <div className="w-1/4 pr-4">
                    <IdentityField
                      name="date_start"
                      defaultValue={costElement['date_start']}
                      component={DatePickerAdapter}
                      label={t('entities.cost_element.date_start')}
                      type="date"
                      validate={required}
                    />
                  </div>
                  <div className="w-1/4">
                    <IdentityField
                      name="date_end"
                      component={DatePickerAdapter}
                      label={t('entities.cost_element.date_end')}
                      type="date"
                      validate={required}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </ReactFinalForm>
      )}
    </div>
  );
}

export default CostElementForm;
