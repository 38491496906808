import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { filterOperators } from './../../../../constants';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const LocationColumns = (): GridColDef[] => {
  const { t } = useTranslation();
  return [
    {
      field: 'code',
      headerName: t(`global.code`),
      width: 250,
      filterOperators,
      renderCell: (params: GridRenderCellParams) => {
        const location = params.row;
        return (
          <div>
            <Link to={`${location.id}`}>
              <b className="text-secondary-500">{location.code}</b>
            </Link>
          </div>
        );
      }
    },
    {
      field: 'description',
      filterOperators,
      width: 250,
      headerName: t(`global.description`)
    },
    {
      field: 'warehouse',
      filterable: false,
      sortable: false,
      width: 250,
      headerName: t(`global.warehouse`)
    },
    {
      field: 'products',
      filterable: false,
      sortable: false,
      valueGetter: (params) => params.row.products.map((product) => product.code),
      width: 300,
      headerName: t(`global.products`)
    }
  ];
};
