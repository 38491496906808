import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { ticketingService } from 'services/api';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import WidgetSquarePlaceholder from './WidgetSquarePlaceholder';

function OpenTickets() {
  const { t } = useTranslation();
  const [counter, setCounter] = useState(undefined);

  useEffect(() => {
    ticketingService.getAllPaginated(1, 0).then((res) => setCounter(res.count));
  }, []);

  return counter !== undefined ? (
    <div className="w-full flex flex-col justify-center items-center px-1">
      {counter > 0 && (
        <>
          <ConfirmationNumberIcon fontSize="large" color="warning" />
          <span className="text-center">{t(`dashboard.open-tickets.body-error`)}</span>
          <NavLink to={'/tenant/equipments/ticketing'}>
            <Button color="warning">{t(`dashboard.open-tickets.go`)}</Button>
          </NavLink>
        </>
      )}
      {counter === 0 && (
        <>
          <ConfirmationNumberIcon fontSize="large" color="success" />
          <span className="text-center">{t(`dashboard.open-tickets.body-success`)}</span>
        </>
      )}
    </div>
  ) : (
    <>
      {counter}
      <WidgetSquarePlaceholder />
    </>
  );
}

export default OpenTickets;
