import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { inventoryCheckService } from 'services/api';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { DataGridPremiumProps, GridColDef, GridColumns } from '@mui/x-data-grid-premium';
import { InventoryCheckColumns } from 'components/common/enhanced/common-headers';
import { InventoryCheckArchiveColumns } from 'components/common/enhanced/common-headers/inventory-check-archive';
import InventoryCheckDetail from './detail/InventoryCheckDetail';

export const InventoryCheckArchive = () => {
  const translationPrefix = 'pages.inventory-check-archive';

  const { t } = useTranslation();
  const datagridRefresh = useRef(null);

  const aggregatedColumns: GridColumns = InventoryCheckArchiveColumns(
    inventoryCheckService,
    datagridRefresh
  );

  const columns: GridColDef[] = InventoryCheckColumns();

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelContent']>
  >(({ row }) => <InventoryCheckDetail row={{ ...row }} columns={columns} />, [columns]);

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}>
        <EnhancedDataGrid
          columns={aggregatedColumns}
          service={inventoryCheckService}
          getMethod={inventoryCheckService.getArchived}
          refresh={datagridRefresh}
          getDetailPanelContent={getDetailPanelContent}
          disableDelete
        />
      </TenantPage>
    </>
  );
};

export default InventoryCheckArchive;
