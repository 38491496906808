import ReactFinalForm from 'components/form/ReactFinalForm';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';

interface FormParams {
  onSubmit: any;
}
function LabelThresholdForm(params: FormParams) {
  const { onSubmit } = params;
  const { t } = useTranslation();

  return (
    <div>
      <ReactFinalForm onSubmit={onSubmit} formId="addLabelThreshold" hideControls>
        <div className="flex my-4">
          <div className="mx-2">
            <IdentityField name="code" component={TextFieldAdapter} label={t('global.label')} />
          </div>
        </div>
      </ReactFinalForm>
    </div>
  );
}

export default LabelThresholdForm;
