import { loadingActions } from 'features';
import { workstationService } from 'services/api';
import { useAppDispatch } from 'app/store';
import TenantPage from 'components/common/TenantPage';
import { useTranslation } from 'react-i18next';
import { IWorkstation } from 'interfaces';
import { useNavigate } from 'react-router-dom';
import WorkstationForm from '../WorkstationForm';
import toast from 'features/toast';

const initWorkstation: IWorkstation = {
  id: null,
  code: '',
  description: '',
  sector: null
};
export const CreateWorkstation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const translationPrefix = 'pages.workstation.new';

  const dispatch = useAppDispatch();

  const onSubmit = async (values) => {
    dispatch(loadingActions.startLoading());
    workstationService.add(values).then(() => {
      toast.success('Workstation aggiunta con successo');
      navigate('..');
    });
    dispatch(loadingActions.stopLoading());
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <WorkstationForm workstation={initWorkstation} onSubmit={onSubmit} />
    </TenantPage>
  );
};

export default CreateWorkstation;
